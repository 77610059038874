import axios from "axios";

export default {

    namespaced: true,

    state: {

        smses: [],
        sms: {}

    },

    getters: {

        smses(state) {
            return state.smses;
        },
        sms(state) {
            return state.sms;
        }

    },

    mutations: {

        SET_SMSES(state, smses) {
            return state.smses = smses
        },

        SET_SMS(state, sms) {
            return state.sms = sms
        }

    },

    actions: {

        async fetchSmses({ commit }, params) {

            await axios.get('/api/v1/sms', { params: params })
                .then(res => {
                    commit('SET_SMSES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchSms({ commit }, sms_id) {

            await axios.get(`/api/v1/sms/${sms_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_SMS', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createSms({ commit, dispatch }, sms) {

            await axios.post('/api/v1/sms', sms)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchSmses');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateSms({ commit, dispatch }, sms) {

            await axios("/api/v1/sms/" + sms.sms_id, {
                method: "patch",
                data: sms,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchSmses');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteSms({ commit }, sms_id) {

            await axios.delete(`/api/v1/sms/${sms_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchSmses');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
