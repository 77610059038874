import axios from "axios";


export default {

    namespaced: true,

    state: {
        vaultaccount: {},
        vaultaccounts: [],
        vaultrequests: [],
        branchvault: {},
        statements:[],
    },

    getters: {

        vaultaccount(state) {
            return state.vaultaccount;
        },
        vaultaccounts(state) {
            return state.vaultaccounts;
        },

        vaultrequests(state) {
            return state.vaultrequests;
        },

        branchvault(state) {
            return state.branchvault;
        },

        statements(state) {
            return state.statements;
        },
    },

    actions: {
        fetchvaultaccounts({ commit }, params) {
            axios.get('/api/v1/vaultaccount', { params: params })
                .then(res => {
                    commit('FETCH_vaultaccount_LIST', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },


        async fetchvaultaccount({ commit }, id) {
            await axios.get('/api/v1/vaultaccount/' + id,)
                .then(res => {
                    commit('FETCH_vaultaccount', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async createvaultaccount({ commit, dispatch }, payload) {
            await axios.post('/api/v1/vaultaccount', payload);
        },

        async createvaultrequest({ commit, dispatch }, payload) {
            await axios.post('/api/v1/vaultaccount/request', payload);
        },

        fetchvaultrequests({ commit }, params) {
            axios.get('/api/v1/vaultaccount/request', { params: params })
                .then(res => {
                    commit('FETCH_VAULT_REQUEST', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        
        async fetchbranchvaults({ commit }) {
            await axios.get('/api/v1/vaultaccount/branchvault')
                .then(res => {
                    commit('FETCH_BRANCH_VAULT', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        fetchstatements({ commit }, params) {
            axios.get('/api/v1/vaultaccount/statements',{params: params})
                .then(res => {
                    commit('FETCH_STATEMENTS', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },



    },

    mutations: {
        FETCH_vaultaccount(state, data) {
            state.vaultaccount = data;
        },
        FETCH_vaultaccount_LIST(state, data) {
            state.vaultaccounts = data;
        },

        FETCH_VAULT_REQUEST(state, data) {
            state.vaultrequests = data;
        },

        FETCH_BRANCH_VAULT(state, data) {
            state.branchvault = data;
        },

        FETCH_STATEMENTS(state, data) {
            state.statements = data;
        },

    }

}