//import axios from "axios";

export default {

    namespaced: true,

    state: {
        lineitems: [{}],
        financialyearFilter: [],
        payableStatements: [],
        receivablesStatements: [],
        customerbalances: [],
        vendorbalances:[],
    },

    getters: {
        lineitems(state) {
            return state.lineitems
        },
        financialyearFilter(state) {
            return state.financialyearFilter;
        },
        payableStatements(state) {
            return state.payableStatements;
        },
        receivablesStatements(state) {
            return state.receivablesStatements;
        },
        customerbalances(state) {
            return state.customerbalances;
        },
        vendorbalances(state) {
            return state.vendorbalances;
        },
    },

    mutations: {
        FETCH_LINEITEMS(state, data) {
            state.lineitems = data;
        },
        ADD_ROW(state) {
            // console.log(state.lineitems)
            state.lineitems.push({
                quantity: 1,
                unit_amount: 0,
                description: "",
                account_product_id: "",
            });
        },
        DELETE_ROW(state, data) {
            state.lineitems.splice(data, 1);
        },
        RESET_FORM(state) {
            state.lineitems = [];
        },
        FINANCIAL_YEAR_FILTER(state, data) {
            state.financialyearFilter = data;
        },
        FETCH_PAYABLE_STATEMENTS(state, data) {
            state.payableStatements = data;
        },
        FETCH_RECEIVABLE_STATEMENTS(state, data) {
            state.receivablesStatements = data;
        },
        FETCH_CUSTOMER_BALANCES(state, data) {
            state.customerbalances = data;
        },

        FETCH_VENDOR_BALANCES(state, data) {
            state.vendorbalances = data;
        },

    },

    actions: {

        addRow({ commit }) {
            commit('ADD_ROW');
        },
        deleteRow({ commit }, index) {
            commit('DELETE_ROW', index);

        },
        resetForm({ commit }) {
            commit('RESET_FORM');

        },
        fetchCurrentFYear({ commit }, params) {
            axios.get('/api/v1/financialyears/currentFyear', { params: params })
                .then(res => {
                    commit('FINANCIAL_YEAR_FILTER', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        fetchPayableStatements({ commit }, vendor_id) {
            axios.get(`/api/v1/payables/Statement/${vendor_id}`)
                .then(res => {
                    if (res.statusText === 'OK')
                        commit('FETCH_PAYABLE_STATEMENTS', res.data.data)
                }).catch(err => {
                    return Promise.reject(err);
                })
        },
        fetchReceivablesStatements({ commit }, customer_id) {
            axios.get(`/api/v1/receivables/Statement/${customer_id}`)
                .then(res => {
                    if (res.statusText === 'OK')
                        commit('FETCH_RECEIVABLE_STATEMENTS', res.data.data)
                }).catch(err => {
                    return Promise.reject(err);
                })
        },
        fetchCustomerBalances({ commit }, params) {
            axios.get(`/api/v1/receivables/`, { params: params })
                .then(res => {
                    if (res.statusText === 'OK')
                        commit('FETCH_CUSTOMER_BALANCES', res.data)
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        fetchvendorBalances({ commit }, params) {
            axios.get(`/api/v1/payables/`, { params: params })
                .then(res => {
                    if (res.statusText === 'OK')
                        commit('FETCH_VENDOR_BALANCES', res.data)
                }).catch(err => {
                    return Promise.reject(err);
                })
        },
        
        // createSalesInvoice({ commit }, params) {
        //     axios.post(`/api/v1/receivables/invoice`, params)
        //         .then(res => {
        //         }).catch(err => {
        //             return Promise.reject(err);
        //         })
        // },
        // createOtherReceipts({ commit }, params) {
        //     axios.post(`/api/v1/receivables/otherreceipts`, params)
        //         .then(res => {
        //         }).catch(err => {
        //             return Promise.reject(err);
        //         })
        // },

        
    }
}
