import axios from "axios";

export default {
  namespaced: true,
  state: {
    member_types: [],
    member_type: {},
  },
  getters: {
    member_types(state) {
      return state.member_types;
    },
    member_type(state) {
      return state.member_type;
    },
  },

  mutations: {
    SET_MEMBER_TYPES(state, member_types) {
      return (state.member_types = member_types);
    },

    SET_MEMBER_TYPE(state, member_type) {
      return (state.member_type = member_type);
    },
  },

  actions: {
    async fetchMemberTypes({ commit }, params) {
      await axios
        .get("/api/v1/member-types", { params: params })
        .then((res) => {
          // console.log(res.data.data);
          commit("SET_MEMBER_TYPES", res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async fetchMemberType({ commit }, member_type_id) {
      await axios
        .get(`/api/v1/member-types/${member_type_id}`)
        .then((res) => {
          // console.log("fetchBranch store == ", res);
          if (res.statusText === "OK") {
            commit("SET_MEMBER_TYPE", res.data);
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async createStaffType({ commit, dispatch }, member_type) {
      await axios
        .post("/api/v1/member-types", member_type)
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchMemberTypes");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async updateStaffType({ commit, dispatch }, member_type) {
      await axios("/api/v1/member-types/" + member_type.member_type_id, {
        method: "put",
        data: member_type,
      })
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchMemberTypes");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async deleteStaffType({ commit, dispatch }, member_type) {
      await axios
        .delete(`/api/v1/member-types/${member_type.member_type_id}`)
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchMemberTypes");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
};
