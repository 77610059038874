<template>

    <input
        class="form-control"
        ref="inputRef"
        type="text"
        :value="formattedValue"
    >

</template>

<script>
import { watch, ref } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'

export default {
    name: 'CurrencyInput',
    props: {
        modelValue: Number, // Vue 2: value
        options: Object
    },
    setup (props) {

        let options = ref(props.options);

        if (!options.value) {
            options = { currency: 'KES',valueRange:{min:0,max:undefined }};
        }

        const {
            formattedValue,
            inputRef,
            setOptions,
            setValue
        } = useCurrencyInput(options)

        watch(() => props.modelValue, (value) => { // Vue 2: props.value
            setValue(value)
        })

        watch(() => props.options, (options) => {
            setOptions(options)
        })

        return { inputRef, formattedValue }
    }
}
</script>
