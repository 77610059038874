const depositStatusRoutes = [

    {

        path: '',
        name: 'depositstatusHome',
        component: () => import ('./ListDepositStatus.vue'),
        meta: {
            auth: true,
            title: 'Deposit Status Listing',
            breadcrumb: 'Deposit Status Listing',
            permission: ['view depositstatus'] //'view Deposit status'
        }

    },

    {
        path: 'create',
        name: 'createdepositstatus',
        component: () => import('./CreateEditDepositStatus.vue'),
        meta: {
            auth: true,
            title: 'Create Account Status',
            breadcrumb: 'Create Account Status',
            permission: ['create depositstatus'] //'create Deposit status'
        }
    },

    {
        path: ':id/edit',
        name: 'editdepositstatus',
        component: () => import ('./CreateEditDepositStatus.vue'),
        meta: {
            auth: true,
            title: 'Edit Account Status',
            breadcrumb: 'Edit Account Status',
            permission: ['update depositstatus'] //'update Deposit status'
        }
    },

];


export default depositStatusRoutes;
