import axios from "axios";

export default {

    namespaced: true,

    state: {

        loanapplications: [],
        loanapplication: {},
        loanschedule: {},

    },

    getters: {

        loanapplications(state) {
            return state.loanapplications;
        },
        loanapplication(state) {
            return state.loanapplication;
        },
     
        loanschedule(state) {
            return state.loanschedule;
        },
       
    },

    mutations: {

        SET_LOAN_APPLICATIONS(state, loanapplications) {
            return state.loanapplications = loanapplications
        },

        SET_LOAN_APPLICATION(state, loanapplication) {
            return state.loanapplication = loanapplication
        },

      
        SET_LOAN_SCHEDULE(state, loanschedule) {
            return state.loanschedule = loanschedule
        },

      
    },

    actions: {

        async fetchLoanApplications({ commit }, params) {

            await axios.get('/api/v1/loan-applications', { params: params })
                .then(res => {
                    // console.log("res == ", res);
                    commit('SET_LOAN_APPLICATIONS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },



        async fetchLoanApplication({ commit }, loanapplication_id) {

            await axios.get(`/api/v1/loan-applications/${loanapplication_id}`)
                .then(res => {
                    // console.log("fetchLoanApplication store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_LOAN_APPLICATION', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

 

        async fetchLoanSchedule({ commit }, payload) {

            await axios.post(`/api/v1/loan-applications/calculateSchedule`, payload)
                .then(res => {
                    // console.log("fetchLoanSchedule  == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_LOAN_SCHEDULE', res.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },
        async approveDeclineloan({ commit}, approvedata) {
            await axios.post('/api/v1/loan-applications/approvedecline', approvedata)
                .then(res => {
                 
                 commit('SET_LOAN_APPLICATION', res.data.data);
                    
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createLoanApplication({ commit, dispatch }, loanapplication) {
            await axios.post('/api/v1/loan-applications', loanapplication)
                .then(res => {
                    commit('SET_LOAN_APPLICATION', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },
        async updateLoanApplication({ commit, dispatch },loanapplication) {
            await axios.put(`/api/v1/loan-applications/${loanapplication.loan_application_id}`, loanapplication)
                .then(res => {
                    commit('SET_LOAN_APPLICATION', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async disburseLoan({ commit, dispatch }, params) {
            await axios.post('/api/v1/loan-applications/disburse', params);
        },

        async requestLoanApproval({ commit, dispatch }, params) {
            await axios.post('/api/v1/loan-applications/requestloanapproval', params);
        },
          

        async deleteLoanApplication({ commit }, loanapplication_id) {

            await axios.delete(`/api/v1/loan-applications/${loanapplication_id}`);

        }

    }

}
