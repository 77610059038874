<template>
  <div
    class="app"
    :style="{
      '--primary-color': companyColor?.primary_color,
      '--sidebar-color': companyColor?.sidebar_color,
      '--header-navbar-color': companyColor?.header_navbar_color,
      '--header-navbar-bottom-color': companyColor?.header_navbar_bottom_color,
      '--auth-header-bg-color': companyColor?.auth_header_bg_color,
      '--auth-header-main-text-color':
        companyColor?.auth_header_main_text_color,
      '--auth-header-sub-text-color': companyColor?.auth_header_sub_text_color,
      '--btn-info-color': companyColor?.btn_info_color,
      '--btn-primary-color': companyColor?.btn_primary_color,
      // '--warning-color': companyColor?.warning_color,
    }"
  >
    <loading :is-visible="loadingStatus"></loading>

    <component :is="layout"></component>
  </div>
</template>

<style>
/* route transitions */
.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
.route-enter-active {
  transition: all 0.3s ease-in;
}

/* fade-slide out-in */
.fade-slide-enter-active,
.fade-slide-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-out;
}

.fade-slide-enter-from {
  opacity: 0;
  transform: translateX(100%);
}

.fade-slide-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-slide-enter-to,
.fade-slide-leave-from {
  opacity: 1;
  transform: translateX(0);
}

/* fade scale - adminlayout */
.fade-scale-enter-active,
.fade-scale-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.fade-scale-enter-from,
.fade-scale-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.fade-scale-enter-to,
.fade-scale-leave-from {
  opacity: 1;
  transform: scale(1);
}

/* custom */
.custom-transition-enter-active,
.custom-transition-leave-active {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.custom-transition-enter-from,
.custom-transition-leave-to {
  animation-name: slide-in-right;
}

.custom-transition-enter-to,
.custom-transition-leave-from {
  animation-name: slide-out-left;
}

/* fade out in */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-out-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>

<script>
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import AuthLayout from "./layouts/AuthLayout";
import AdminLayout from "./layouts/AdminLayout";
import TwoFactorLayout from "./layouts/TwoFactorLayout";
import CustomerLayout from "./layouts/CustomerLayout";
import CompanyColorService from "@/services/general/CompanyColorService";
// import TranslationService from "@/services/general/TranslationService";

const module = "auth";
const companyColorModule = "companycolors";
// const translationModule = "translations";
// const translationModule = "translations";

export default defineComponent({
  name: "App",

  components: {
    AuthLayout,
    AdminLayout,
    TwoFactorLayout,
    CustomerLayout,
  },

  setup() {
    const store = useStore();
    const route = useRoute();

    let loggedIn = ref(false);
    let twofactor = ref(false);
    let user = ref({});
    let loadingStatus = ref(false);
    let layout = ref("");

    const companyColorService = new CompanyColorService();

    onMounted(async () => {
      // console.log("onMounted hook called");
      document.body.classList = "account-body accountbg dark-sidenav";

      try {
        // console.log("Fetching active company color");
        await companyColorService.getActiveCompanyColor(
          store,
          companyColorModule
        );
        // console.log("Fetched active company color");
      } catch (error) {
        // console.error("Error during onMounted:", error);
      }
    });

    watch(route, (to) => {
      // set layout by route meta
      if (to.meta.layout !== undefined) {
        layout.value = to.meta.layout;
      } else {
        // default layout if route meta is not set
        layout.value = "AdminLayout";
      }
    });

    watch(
      () => layout,
      (newLayout, oldLayout) => {
        console.log("layout prop changed from", oldLayout, "to", newLayout);
      }
    );

    loadingStatus = computed(() => {
      return store.getters[`loader/loadingStatus`];
    });

    loggedIn = computed(() => {
      return store.getters[`${module}/loggedIn`];
    });

    twofactor = computed(() => {
      return store.getters[`${module}/twofactor`];
    });

    user = computed(() => {
      return store.getters[`${module}/user`];
    });

    const companyColor = computed(() => {
      return store.getters[`${companyColorModule}/companycolor`];
    });

    return {
      loadingStatus,
      user,
      twofactor,
      loggedIn,
      layout,
      /* primaryColor,
      secondaryColor, */
      companyColor,
    };
  },
});
</script>

<style>
/*
    CUSTOMIZING ADDITIONAL STYLES:
    1. Copy styles from the css files to be customized here
    2. Add the new style variable alongside the following section:
        <div class="app" :style="{ '--primary-color': companyColor?.primary_color,
          '--secondary-color': companyColor?.secondary_color }">
    3. Refer to the css variable in the css section below e.g.
        background-color: var(--primary-color) !important;
    4. Styles can be added as HEX e.g. #177DC2 or RGBA e.g. rgba(13, 125, 203, 0.95)
  >
 */
/* start main config */
/* start auth header */
.auth-header-box .text-white {
  color: var(--auth-header-main-text-color) !important;
}
.auth-header-box .text-muted {
  color: var(--auth-header-sub-text-color) !important;
}
/* end auth header */

/* start buttons */
/* .btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
} */
/* .btn-warning {
  background-color: var(--warning-color) !important;
  border-color: var(--warning-color) !important;
} */

.btn-info {
  background-color: var(--btn-info-color) !important;
  border-color: var(--btn-info-color) !important;
}

.btn-primary {
  background-color: var(--btn-primary-color) !important;
  border-color: var(--btn-primary-color) !important;
}
/* end buttons */

/* start navbar */
.navbar-custom {
  background-color: var(--header-navbar-color) !important;
  min-height: 74px;
  position: relative;
  /* border-bottom: 1px solid #e3ebf6 */
  border-bottom: 1px solid var(--header-navbar-bottom-color) !important;
}

.navbar-custom .nav-link {
  padding: 0 0.75rem;
  color: #7081b9;
  line-height: 74px;
  max-height: 74px;
}
/* end navbar */

/* auth bg */
.account-body .auth-header-box {
  background-color: var(--auth-header-bg-color) !important;
}
/* auth bg */

body.dark-sidenav .left-sidenav {
  /* background-color: #177DC2 */
  /* background-color: rgba(13, 125, 203, 0.95) */
  background-color: var(--sidebar-color) !important;
}
body.dark-sidenav .left-sidenav .brand .logo .logo-lg {
  display: none;
}

body.dark-sidenav .left-sidenav .brand .logo .logo-lg.logo-light {
  display: inline-block;
}

body.dark-sidenav .left-sidenav hr.hr-dashed.hr-menu {
  border-color: #1b97d8;
}

body.dark-sidenav .left-sidenav-menu li > a {
  color: #bee5f6;
}

body.dark-sidenav .left-sidenav-menu li > a.active {
  color: #fff;
}

body.dark-sidenav .left-sidenav-menu li > a:hover {
  color: #e6e6e6;
}

body.dark-sidenav .left-sidenav-menu li > a:hover i {
  color: #0f4fff;
}

body.dark-sidenav .left-sidenav-menu li > a:hover span i {
  color: #bee5f6;
}

body.dark-sidenav .left-sidenav-menu li > a i {
  color: #bee5f6;
}

body.dark-sidenav .left-sidenav-menu li ul li > a {
  color: #bee5f6;
}

body.dark-sidenav .left-sidenav-menu li ul li > a:hover {
  color: #e6e6e6;
}

body.dark-sidenav .left-sidenav-menu li ul li > a:hover i {
  color: #fdd835;
}

body.dark-sidenav .left-sidenav-menu li.mm-active .mm-active > a {
  color: #fdd835;
  background: #0d7dcb;
}

body.dark-sidenav .left-sidenav-menu li.mm-active .mm-active > a.active {
  color: #fdd835;
  background-color: transparent;
  font-weight: 500;
}

body.dark-sidenav .left-sidenav-menu li.mm-active .mm-active > a i,
.active > a i {
  /* color: #1761fd */
  color: #fdd835;
}

body.dark-sidenav
  .left-sidenav-menu
  li.mm-active
  .mm-active
  .mm-show
  li
  a.active {
  color: #fdd835;
}

body.dark-sidenav .left-sidenav-menu li.mm-active > a {
  color: #bee5f6;
}

body.dark-sidenav .left-sidenav-menu li.mm-active > a i {
  color: #bee5f6;
}

body.dark-sidenav
  .left-sidenav-menu
  li.mm-active
  .nav-item.active
  a.nav-link.active {
  background-color: transparent;
  color: #fdd835;
}

body.dark-sidenav
  .left-sidenav-menu
  li.mm-active
  .nav-item.active
  a.nav-link.active
  i {
  color: #fdd835;
}

body.dark-sidenav .left-sidenav-menu .menu-arrow i {
  width: 15px;
  color: #ffffff;
}
/* end main config */

/* start theme */
a:hover,
.hover_menu_style1 .navbar-expand-lg .navbar-nav > li:hover > a,
.hover_menu_style1 .navbar-expand-lg .navbar-nav > li > a.active,
.hover_menu_style1 .navbar-expand-lg .navbar-nav.attr-nav > li > a.active,
.hover_menu_style1 .navbar-expand-lg .navbar-nav.attr-nav > li:hover > a,
.hover_menu_style2 .navbar-expand-lg .navbar-nav.attr-nav li a.active,
.hover_menu_style2 .navbar-expand-lg .navbar-nav.attr-nav > li:hover > a,
.hover_menu_style3 .navbar-expand-lg .navbar-nav > li > a.active,
.hover_menu_style3 .navbar-expand-lg .navbar-nav > li:hover > a,
.hover_menu_style4 .navbar-expand-lg .navbar-nav.attr-nav > li > a.active,
.hover_menu_style4 .navbar-expand-lg .navbar-nav.attr-nav > li:hover > a,
.top-header.light_skin .list_menu li a:hover,
.social_white .border_social li a:hover,
.social_white.border_social li a:hover,
.btn-outline-default,
.link_container a,
.list_style_1.color_blue li::before,
.list_style_2.color_blue li::before,
.list_style_4.color_blue li::before,
.btn-outline-white:hover,
.contact_info a:hover,
.newsletter_form .btn-submit:hover,
.newsletter_form .btn-submit:hover span,
.post_content h6 a:hover,
.footer_link li a:hover,
.widget_links li a:hover,
.widget_links_style1 li a:hover,
.icon_box_style_3 i,
.owl-theme .owl-nav [class*="owl-"]:hover,
.social_icons.hover_style2 li a:hover,
header.light_skin.nav-fixed .social_icons li a:hover,
.sub_title,
.icon_box_style_4 i,
.slider_tab li.active i,
.slider_tab li:hover i,
.icon_box_style_5 i,
.overlay_menu .navbar-collapse > .navbar-nav > li > a:hover,
.tab-style4 .nav-tabs .nav-item a.active,
.portfolio_filter li a.current,
.portfolio_filter li a:hover,
.tweet_feed li::before,
.tweet_feed a,
.post_navigation a:hover .nav_meta,
.pagination .page-item a,
.pagination_style5 .page-item.active .page-link,
.pagination_style5 .page-item .page-link:hover,
.pagination_style6 .page-item.active .page-link,
.pagination_style6 .page-item .page-link:hover,
.tab-style1 .nav-tabs .nav-item a.active,
.tab-style2 .nav-tabs .nav-item a.active,
.tab-style6 .nav-tabs .nav-item a.active,
.toggle_info a,
.indicators_style1.carousel-indicators li.active::before,
.menu_price span,
.header_info li i,
.icon_box_style_9 .box_icon i,
.list_menu li a:hover,
.widget_links_style2 li a:hover,
.icon_box_style_11 .box_icon i,
.icon_box_style_13 .box_icon i,
.widget_links_style3 li a:hover,
.postcontent_light .blog_heading a:hover,
.postcontent_light .blog_author_data a:hover,
.postcontent_light .blog_postmeta li a:hover,
.shorting_icon.active,
.classes_schedule li i,
.event_meta li i,
.single_event_info li i,
.tab-style2.tab_white .nav-tabs .nav-item a:hover,
.tab-style2.tab_white .nav-tabs .nav-item a.active,
.tab-style4.tab_white .nav-tabs li.nav-item a:hover,
.tab-style4.tab_white .nav-tabs li.nav-item a.active,
.tab-style5.tab_white .nav-tabs li.nav-item a:hover,
.tab-style6.tab_white .nav-tabs li.nav-item a:hover,
.tab-style6.tab_white .nav-tabs li.nav-item a.active,
.discount_num,
.footer_dark .tags a:hover,
.domain_price li span,
.icon_box_style_16 .box_icon i,
.widget_links_style4 li a:hover,
.event_info li i,
.author_info span {
  color: var(--primary-color) !important;
}

.btn-default.btn-aylen:hover,
.blog_heading *:hover,
.blog_postmeta a:hover {
  /* color: #177DC2 !important; */
  color: var(--primary-color) !important;
}

.btn-default,
.cart_buttons .view-cart,
.cart_buttons .checkout,
.heading_s1::before,
.btn-outline-default:hover,
.icon_box_style_1 i,
.filter_tab1 li a.current,
.filter_tab1 li a:hover,
.testimonial_slider[data-center="true"] .owl-item.center .testimonial_box,
.testimonial_slider[data-center="true"]
  .owl-item.center
  .testimonial_box::before,
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span,
.list_style_3.color_blue li::before,
.bg_blue,
.footer_social li a:hover,
.scrollup:hover,
.loader_grid .loader_box,
.icon_box_style_3 i::after,
.icon_box_style_4 i::after,
.filter_tab3 li a.current,
.filter_tab3 li a:hover,
.filter_tab4 li a.current,
.filter_tab4 li a:hover,
.heading_s2::after,
.heading_s2::before,
.skill_content .progress-bar,
.filter_tab2 li a.current,
.filter_tab2 li a:hover,
.filter_tab5 li a.current,
.filter_tab5 li a:hover,
.icon_box_style_5::before,
.team_hover_style1:hover .team_title,
.portfolio_style4 .portfolio_content,
.icon_box_style_2 i,
.page-item.active .page-link,
.page-item .page-link:hover,
.custome-radio input[type="radio"] + .form-check-label::after,
.custome-checkbox input[type="checkbox"]:checked + .form-check-label::before,
.tab-style3 .nav-tabs .nav-item a.active,
.tab-style5 .nav-tabs .nav-item a.active,
.icon_box_style_9:hover,
.cart_count,
.accordion_style4.accordion .card-header a,
.ripple,
.ripple::before,
.ripple::after,
.tab-style7 .nav-tabs .nav-item a.active,
.tab-style7 .nav-tabs .nav-item a:hover,
.sliding_tab .tab_bg,
.tab-style8 .nav-item a.active,
.list_style_5 li::before,
.pricing_ribbon,
.hover_menu_style4 .navbar-expand-lg .navbar-nav > li:hover > a,
.hover_menu_style4 .navbar-expand-lg .navbar-nav > li > a.active,
.filter_tab6 li a.current,
.filter_tab6 li a:hover,
.filter_tab li a.active,
.heading_s5::before,
.widget_title3::before,
.pagination_style7 .page-item.active .page-link,
.pagination_style7 .page-item .page-link:hover,
.pr_action_btn li a:not(.btn):hover,
.filter_price .ui-slider .ui-slider-range,
.filter_price .ui-slider .ui-slider-handle,
.event_date,
.event_date::before,
.cl_price,
.accordion_style5.accordion .card-header a[aria-expanded="true"],
.tab-style9 .nav-item a.active,
.tab-style9 .nav-item a:hover,
.progress-bar::before,
.tk_counter_inner .progress-bar,
.roadmap_box.rd_complete::after,
.roadmap_box.rd_complete::before,
.domain_search .dropdown .ddChild li:hover,
.icon_box_style_16::before,
.switch_slide,
.tab-style10 .nav-tabs .nav-item a.active,
.circle_list::before,
.affiliate_table thead tr td.populet_txt,
.tab-style11 .nav-tabs .nav-item a:hover,
.tab-style11 .nav-tabs .nav-item a.active,
.slide_tab li.active .icon_box,
.pagination_style8 .page-item.active .page-link,
.pagination_style8 .page-item .page-link:hover,
.room_services i:hover,
.widget_reservation h5,
.widget_search_box .btn,
.widget_search_box button {
  /* background-color: #177DC2; */
  background-color: var(--primary-color) !important;
}

.btn-default:hover,
.cart_buttons .view-cart:hover,
.cart_buttons .checkout:hover {
  background-color: #1083c8;
}

.hover_menu_style4 .navbar-expand-lg .navbar-nav.attr-nav li a.active,
.hover_menu_style4 .navbar-expand-lg .navbar-nav.attr-nav > li:hover > a {
  background-color: transparent;
}

.btn-default,
.btn-outline-default,
.filter_tab1 li a.current,
.filter_tab1 li a:hover,
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span,
.footer_social li a:hover,
.icon_box_style_3 i,
.filter_tab3 li a.current,
.filter_tab3 li a:hover,
.filter_tab4 li a.current,
.filter_tab4 li a:hover,
.icon_box_style_4 i,
.filter_tab2 li a.current,
.filter_tab2 li a:hover,
.tab-style4 .nav-tabs .nav-item a.active,
.portfolio_filter li a.current,
.portfolio_filter li a:hover,
.page-item.active .page-link,
.page-item .page-link:hover,
.blockquote,
.custome-radio input[type="radio"]:checked + .form-check-label::before,
.pagination_style5 .page-item.active .page-link,
.pagination_style5 .page-item .page-link:hover,
.pagination_style6 .page-item.active .page-link,
.pagination_style6 .page-item .page-link:hover,
.pricing_box.pricing_style3.highlight_box,
.tab-style2 .nav-tabs .nav-item a.active,
.tab-style5 .nav-tabs .nav-item a.active,
.newsletter-pop-up::before,
.form_style3 .form-control:focus,
.filter_tab6 li a.current,
.filter_tab6 li a:hover,
.affiliate_table colgroup:nth-child(3),
.tab-style11 .nav-tabs .nav-item a:hover,
.tab-style11 .nav-tabs .nav-item a.active,
.dw_event_schedule a:hover,
.pagination_style8 .page-item.active .page-link,
.pagination_style8 .page-item .page-link:hover {
  /* border-color: #177dc2; */
  border-color: var(--primary-color) !important;
}

.btn-default:hover {
  border-color: #1083c8;
}

.portfolio_style1 .portfolio_item::before,
.portfolio_style2 .portfolio_content,
.portfolio_style3 .portfolio_content,
.blue_overlay_bg::before,
.portfolio_style5 .portfolio_content,
.instafeed li a::before,
.bg_blue_transparent {
  /* background-color: rgba(14,147,216,0.80); */
  background-color: rgba(23, 124, 194, 0.7);
}

.post_date::before,
.cl_price::before,
.event_date:after {
  border-color: transparent #1083c8 transparent transparent;
}
.tab-style4 .nav-tabs {
  border-color: rgba(14, 147, 216, 0.2);
}
.text_default {
  color: #55bbeb !important;
}
.filter_tab1 li a.current,
.filter_tab1 li a:hover,
.filter_tab2 li a.current,
.filter_tab2 li a:hover,
.filter_tab3 li a.current,
.filter_tab3 li a:hover,
.filter_tab4 li a.current,
.filter_tab4 li a:hover,
.filter_tab5 li a.current,
.filter_tab5 li a:hover,
.filter_tab6 li a.current,
.filter_tab6 li a:hover {
  color: #fff;
}

.custome_select::before {
  /* border-top-color: #177dc2; */
  border-top-color: var(--primary-color) !important;
}

.classis_box:hover:before {
  background: -moz-linear-gradient(
    90deg,
    rgba(14, 147, 216, 1) 0%,
    rgba(255, 255, 255, 0.06) 100%
  ); /* ff3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 0.06)),
    color-stop(100%, rgba(14, 147, 216, 1))
  ); /* safari4+,chrome */
  background: -webkit-linear-gradient(
    90deg,
    rgba(14, 147, 216, 1) 0%,
    rgba(255, 255, 255, 0.06) 100%
  ); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(
    90deg,
    rgba(14, 147, 216, 1) 0%,
    rgba(255, 255, 255, 0.06) 100%
  ); /* opera 11.10+ */
  background: -ms-linear-gradient(
    90deg,
    rgba(14, 147, 216, 1) 0%,
    rgba(255, 255, 255, 0.06) 100%
  ); /* ie10+ */
  background: linear-gradient(
    0deg,
    rgba(14, 147, 216, 1) 0%,
    rgba(255, 255, 255, 0.06) 100%
  ); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#177DC2',GradientType=0 ); /* ie6-9 */
}

.filter_tab li a.active {
  /* box-shadow: 0 0 10px #177dc2; */
  box-shadow: 0 0 10px var(--primary-color) !important;
}

.btn_box_shadow:hover,
.btn_box_shadow:focus,
.btn_box_shadow.active:focus,
.btn_box_shadow:active:focus {
  /* box-shadow: 0 0 15px #177dc2 !important; */
  box-shadow: 0 0 15px var(--primary-color) !important;
}

.tab-style7 li a.active::before {
  border-color: #177dc2 transparent transparent transparent;
}
/* end theme */
</style>
