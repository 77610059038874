import axios from "axios";

export default {

    namespaced: true,

    state: {

        businessappraisals: [],
        businessappraisal: {},

    },

    getters: {
        businessappraisals(state) {
            return state.businessappraisals;
        },
        businessappraisal(state) {
            return state.businessappraisal;
        }

    },


    mutations: {

        SET_BUSINESSAPPRAISALS(state, businessappraisals) {
            return state.businessappraisals = businessappraisals
        },

        SET_BUSINESSAPPRAISAL(state, businessappraisal) {
            return state.businessappraisal = businessappraisal
        }

    },

    actions: {

        async fetchbusinessappraisals({ commit }, params) {
            try {
                const response = await axios.get('/api/v1/business-appraisal', { params: params });
                commit('SET_BUSINESSAPPRAISALS', response.data);
            } catch (error) {
                return Promise.reject(error);
            }
        },

  

        async fetchbusinessappraisal({ commit }, business_appraisal_id) {
            await axios.get(`/api/v1/business-appraisal/${business_appraisal_id}`)
                .then(res => {
                    commit('SET_BUSINESSAPPRAISAL', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

         async businessappraisal({ commit, dispatch }, data) {
            return await axios.post('/api/v1/business-appraisal/', data);
         },

         async updatebusinessappraisal({ commit, dispatch }, businessappraisal) {
            await axios("/api/v1/business-appraisal/" + businessappraisal.business_appraisal_id, 
            { method: "put", data: businessappraisal})

        },
    }

}
