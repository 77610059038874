const companyRegistrationRoutes = [

    {

        name: 'companyHome',
        path: '',
        component: () => import('./ListCompanies.vue'),
        meta: {
            auth: true,
            title: 'Companies Listing',
            breadcrumb: 'Companies Listing',
            permission: ['view company']
        }

    },

    {
        path: 'create',
        name: 'createCompany',
        component: () => import('./CreateCompany.vue'),
        meta: {
            auth: true,
            title: 'Create Company',
            breadcrumb: 'Create Company',
            permission: ['create company']
        }
    },

    {
        path: ':id',
        name: 'showCompany',
        component: () => import('./ShowCompany.vue'),
        meta: {
            auth: true,
            title: 'Showing Company',
            breadcrumb: 'Showing Company',
            permission: ['view company']
        }
    },

    {
        path: ':id/edit',
        name: 'editCompany',
        component: () => import('./EditCompany.vue'),
        meta: {
            auth: true,
            title: 'Edit Company',
            breadcrumb: 'Edit Company',
            permission: ['update company']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteCompany',
        meta: {
            auth: true,
            title: 'Delete Company',
            permission: ['delete company']
        }
    },

];

export default companyRegistrationRoutes;
