const stndingOrderRoutes = [

    {

        path: '',
        name: 'standingOrderHome',
        component: () => import('./HomeStandingOrder.vue'),
        meta: {
            auth: true,
            title: 'Standing Order',
            breadcrumb: 'Standing Order',
            permission: []
        },

    },

    {
        path: 'standingorder',
        name: 'standingorderList',
        component: () => import('./StandingOrderList.vue'),
        meta: {
            auth: true,
            title: 'Standing Order List',
            breadcrumb: 'Standing Order List',
            permission: ['view standingorder']
        },
    },

    {
        path: 'failedstandingorder',
        name: 'failedstandingorderList',
        component: () => import('./FailedStandingOrderList.vue'),
        meta: {
            auth: true,
            title: 'Failed Standing Order',
            breadcrumb: 'Failed Standing Order',
            permission: ['view standingorder']
        },
    },


    {
        path: 'create',
        name: 'createstandingorder',
        component: () => import('./CreateEditStandingOrder.vue'),
        meta: {
            auth: true,
            title: 'Create Standing Order',
            breadcrumb: 'Create Standing Order',
            permission: ['create standingorder']
        }
    },

    {
        path: ':id/edit',
        name: 'editstandingorder',
        component: () => import ('./CreateEditStandingOrder.vue'),
        meta: {
            auth: true,
            title: 'Edit Standing Order',
            breadcrumb: 'Edit Standing Order',
            permission: ['update standingorder']
        }
    },


];

export default stndingOrderRoutes;
