import axios from "axios";

export default {
  namespaced: true,

  state: {
    files: [],
    file: {},
    upload_errors: [],
  },

  getters: {
    files(state) {
      return state.files;
    },
    upload_errors(state) {
      return state.upload_errors;
    },
    file(state) {
      return state.file;
    },
  },

  mutations: {
    SET_FILES(state, files) {
      return (state.files = files);
    },

    SET_FILE(state, file) {
      return (state.file = file);
    },

    SET_UPLOAD_ERRORS(state, upload_errors) {
      return (state.upload_errors = upload_errors);
    },
  },

  actions: {
    /* async fetchUploads({ commit }, params) {
      await axios
        .get("/api/v1/uploads", { params: params })
        .then((res) => {
          commit("SET_FILES", res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }, */

    async fetchUploads({ commit }, params) {
      try {
        const response = await axios.get("/api/v1/uploads", { params: params });
        commit("SET_FILES", response.data);
        // return response.data; // Return the data from the response
      } catch (error) {
        console.log("Error fetching uploads:", error);
        throw error;
      }
    },

    async fetchUpload({ commit }, file_id) {
      await axios
        .get(`/api/v1/uploads/${file_id}`)
        .then((res) => {
          // console.log("fetchUpload store == ", res);
          if (res.statusText === "OK") {
            commit("SET_FILE", res.data.data);
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async createUpload({ commit, dispatch }, file) {
      await axios
        .post("/api/v1/uploads", file)
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchUploads");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async updateUpload({ commit, dispatch }, upload) {
      return new Promise((resolve, reject) => {
        axios
          .patch("/api/v1/uploads/" + upload.upload_id, upload)
          .then((response) => {
            // console.log("response.statusText", response.statusText);
            if (response.statusText === "OK") {
              // dispatch("fetchUploads");
              resolve(response.data);
            }
          })
          .catch((error) => {
            console.log("Error updating upload:", error);
            reject(error);
          });
      });
    },

    /* async updateUpload({ commit, dispatch }, upload) {
      await axios("/api/v1/uploads/" + upload.upload_id, {
        method: "patch",
        data: upload,
      })
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchUploads");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }, */

    // add file to array
    async addUpload({ commit }, file) {
      // console.log("init file", file);
      commit("SET_FILES", file);
    },

    // update file in array
    /* async updateUpload({ commit }, file) {
            commit('SET_FILES', file);
            const newState = state.map(obj =>
                obj.id === "101" ? { ...obj, completed: true } : obj
            );
        }, */

    // remove file from array
    async removeUpload({ commit, state }, file) {
      /* let files = state.files;
            let index = files.indexOf(file);
            files.splice(index, 1);
            console.log("index, files", index, files);
            commit('SET_FILES', files); */
    },

    async deleteUpload({ commit }, file_id) {
      await axios
        .delete(`/api/v1/uploads/${file_id}`)
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchUploads");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    setUploadErrors({ commit }, data) {
      commit("SET_UPLOAD_ERRORS", data);
    },
  },
};
