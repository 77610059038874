<template>
  <div class="py-1 search-flex">
    <div class="flex-item" v-if="display_staff">
      <div class="form-group">
        <label v-if="display_labels">Staff:</label>
        <select
          class="form-control"
          v-model="state.employee_id"
          @change="onEmployeeChange"
          v-if="search_employees.data"
        >
          <option :value="''">Select Staff</option>
          <option
            v-for="(item, index) in search_employees.data"
            :key="index"
            :selected="index === 0"
            :value="item.employee_id"
          >
            {{ item.full_name }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex-item" v-if="display_branches">
      <div class="form-group">
        <label v-if="display_labels">Branch:</label>
        <select
          class="form-control"
          v-model="state.branch_id"
          @change="onBranchChange"
          v-if="search_branches.data"
        >
          <option :value="''">Select Branch</option>
          <option
            v-for="(item, index) in search_branches.data"
            :key="index"
            :selected="index === 0"
            :value="item.branch_id"
          >
            {{ item.branch_name }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex-item" v-if="display_teams">
      <div class="form-group">
        <label v-if="display_labels">Team:</label>
        <select
          class="form-control"
          v-model="state.team_id"
          @change="onTeamChange"
          v-if="search_teams.data"
        >
          <option :value="''">Select Team</option>
          <option
            v-for="(item, index) in search_teams.data"
            :key="index"
            :selected="index === 0"
            :value="item.team_id"
          >
            {{ item.team_name }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex-item" v-if="display_team_member_types">
      <div class="form-group">
        <label v-if="display_labels">Team Member Type:</label>
        <select
          class="form-control"
          v-model="state.member_type_id"
          @change="onTeamMemberTypeChange"
          v-if="search_team_member_types.data"
        >
          <option :value="''">Select Team Member Type</option>
          <option
            v-for="(item, index) in search_team_member_types.data"
            :key="index"
            :selected="index === 0"
            :value="item.member_type_id"
          >
            {{ item.member_type_name }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex-item" v-if="display_statuses">
      <div class="form-group">
        <label v-if="display_labels">Status:</label>
        <select
          class="form-control"
          title="Status"
          v-model="state.status_id"
          @change="onStatusChange"
          v-if="search_statuses.data"
        >
          <option :value="''">Select Status</option>
          <option
            v-for="(item, index) in search_statuses.data"
            :key="index"
            :selected="index === 0"
            :value="item.status_id"
          >
            {{ item.status_name }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex-item" v-if="display_maker_checker_sections">
      <div class="form-group">
        <label v-if="display_labels">Section:</label>
        <select
          class="form-control"
          title="Maker Checker Section"
          v-model="state.section_code"
          @change="onMakerCheckerSectionChange"
          v-if="search_maker_checker_sections.data"
        >
          <option :value="''">Select Section</option>
          <option
            v-for="(item, index) in search_maker_checker_sections.data"
            :key="index"
            :selected="index === 0"
            :value="item.section_code"
          >
            {{ item.section_name }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex-item" v-if="display_start_date">
      <div class="form-group">
        <label v-if="display_labels" class="float-right">Start Date:</label>
        <my-date-picker
          :mode="'date'"
          :title="'Start Date'"
          :styles="'text-right'"
          :placeholder="'Start Date'"
          v-model="state.start_date"
          @onchange="onStartDateChange"
        >
        </my-date-picker>
        <!-- @update:modelValue="onDateChange" -->
        <!-- <input class="form-control" type="date" name="start_date" id="start_date"
               v-model="state.start_date" @change="onStartDateChange" 
               :placeholder="'Start Date'" :title="'Start Date'"/> -->
      </div>
    </div>

    <div class="flex-item" v-if="display_end_date">
      <div class="form-group">
        <label v-if="display_labels" class="float-right">End Date:</label>
        <my-date-picker
          :mode="'date'"
          :title="'End Date'"
          :styles="'text-right'"
          :placeholder="'End Date'"
          v-model="state.end_date"
          @onchange="onEndDateChange"
        >
        </my-date-picker>
        <!-- <input class="form-control" type="date" name="end_date" id="end_date"
               v-model="state.end_date" @change="onEndDateChange" 
               :placeholder="'End Date'" :title="'End Date'"/> -->
      </div>
    </div>

    <div class="flex-item text-right float-right" v-if="display_download_button">
      <button
        type="button"
        class="btn btn-outline-success btn-sm dropdown-toggle text-center"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Download Report"
      >
        <i class="fa fa-download mr-2"></i>
        <i class="mdi mdi-chevron-down"></i>
      </button>
      <div class="dropdown-menu">
        <a class="dropdown-item thelink" @click="downloadXlsxClick">
          <i
            class="la la-file-excel-o text-success mr-1 la-2x icon-center icon-bigger"
          ></i>
          Download Excel
        </a>
        <a class="dropdown-item thelink" @click="downloadPdfClick">
          <i class="la la-file-pdf-o text-danger mr-1 la-2x icon-center icon-bigger"></i>
          Download PDF
        </a>
        <a class="dropdown-item thelink" @click="printPdfClick">
          <i class="la la-file-pdf-o text-danger mr-1 la-2x icon-center icon-bigger"></i>
          Print PDF
        </a>
      </div>
    </div>
  </div>

  <!-- <hr class="small" /> -->
</template>

<script>
import { computed, ref, defineComponent, watch, reactive } from "vue";
import { useStore } from "vuex";
import MyDatePicker from "./MyDatePicker.vue";

const branches_module = "branches";
const employees_module = "employees";
const teams_module = "teams";
const team_member_types_module = "membertypes";
const statuses_module = "statuses";
const maker_checker_sections_module = "makercheckersections";

export default defineComponent({
  components: { MyDatePicker },

  name: "SearchBarListing",

  props: {
    inputHandler: {
      type: Function,
      default: () => {},
    },
    statusHandler: {
      type: Function,
      default: () => {},
    },
    makerCheckerSectionHandler: {
      type: Function,
      default: () => {},
    },
    branchHandler: {
      type: Function,
      default: () => {},
    },
    teamHandler: {
      type: Function,
      default: () => {},
    },
    teamMemberTypeHandler: {
      type: Function,
      default: () => {},
    },
    employeeHandler: {
      type: Function,
      default: () => {},
    },
    createHandler: {
      type: Function,
      default: () => {},
    },
    downloadCsvHandler: {
      type: Function,
      default: () => {},
    },
    downloadXlsxHandler: {
      type: Function,
      default: () => {},
    },
    downloadPdfHandler: {
      type: Function,
      default: () => {},
    },
    printPdfHandler: {
      type: Function,
      default: () => {},
    },
    dateHandler: {
      type: Function,
      default: () => {},
    },
    startDateHandler: {
      type: Function,
      default: () => {},
    },
    endDateHandler: {
      type: Function,
      default: () => {},
    },

    displayDownloadButton: {
      type: Boolean,
      default: false,
    },

    displayStatuses: {
      type: Boolean,
      default: false,
    },

    displayLabels: {
      type: Boolean,
      default: true,
    },
    displayBranches: {
      type: Boolean,
      default: false,
    },
    displayTeams: {
      type: Boolean,
      default: false,
    },
    displayTeamMemberTypes: {
      type: Boolean,
      default: false,
    },
    statusSection: {
      type: String,
      default: "users",
    },
    displayDate: {
      type: Boolean,
      default: false,
    },
    displayStartDate: {
      type: Boolean,
      default: false,
    },
    displayEndDate: {
      type: Boolean,
      default: false,
    },
    displayStaff: {
      type: Boolean,
      default: false,
    },
    displayMakerCheckerSections: {
      type: Boolean,
      default: false,
    },
    displayDateMode: {
      type: String,
      default: "dateTime",
    },
    sections: {
      type: Array,
      default: () => [""],
    },
    appModule: {
      type: String,
      default: "",
    },
  },

  emits: ["termsChange"],

  setup(props, { emit }) {
    const store = useStore();

    const display_download_button = ref(props.displayDownloadButton);
    const display_search_box = ref(props.displaySearchBox);
    const display_labels = ref(props.displayLabels);
    const display_start_date = ref(props.displayStartDate);
    const display_end_date = ref(props.displayEndDate);
    const display_date = ref(props.displayDate);
    const display_date_mode = ref(props.displayDateMode);
    const display_statuses = ref(props.displayStatuses);
    const display_branches = ref(props.displayBranches);
    const display_teams = ref(props.displayTeams);
    const display_team_member_types = ref(props.displayTeamMemberTypes);
    const display_staff = ref(props.displayStaff);
    const display_maker_checker_sections = ref(props.displayMakerCheckerSections);
    const status_section = ref(props.statusSection);
    const search_terms = ref(null);
    const start_date = ref(null);
    const end_date = ref(null);
    // const status = ref(null);
    // const branch = ref(null);

    const state = reactive({
      status_id: "",
      section_code: "",
      branch_id: "",
      team_id: "",
      member_type_id: "",
      employee_id: "",
      start_date: undefined,
      end_date: undefined,
    });

    // do we load branches?
    if (display_branches.value) {
      // load data on create component
      let payload = {
        report: "1", // fetch all data
        relations: "1", // fetch data with relations
      };
      store.dispatch(`${branches_module}/fetchBranches`, payload);
    }

    // do we load teams?
    if (display_teams.value) {
      // load data on create component
      let payload = {
        report: "1", // fetch all data
        relations: "1", // fetch data with relations
      };
      store.dispatch(`${teams_module}/fetchTeams`, payload);
    }

    // do we load team_member_types?
    if (display_team_member_types.value) {
      // load data on create component
      let payload = {
        report: "1", // fetch all data
        relations: "1", // fetch data with relations
      };
      store.dispatch(`${team_member_types_module}/fetchMemberTypes`, payload);
    }

    if (display_staff.value) {
      let payload = { report: "1", relations: "1" };
      store.dispatch(`${employees_module}/fetchEmployees`, payload);
    }

    // do we show statuses?
    if (display_statuses.value) {
      // load statuses
      let status_payload = {
        report: "1", // fetch all data
        section: status_section.value, // get statuses in this section only
      };
      store.dispatch(`${statuses_module}/fetchStatuses`, status_payload);
    }

    // do we show statuses?
    if (display_maker_checker_sections.value) {
      let sectionsProp = "";
      let moduleProp = "";
      if (props.sections) {
        // serialize the sections array into a comma-separated string
        sectionsProp = props.sections.join(",");
      }
      if (props.appModule) {
        moduleProp = props.appModule;
      }
      let maker_checker_sections_payload = {
        report: "1", // fetch all data
        sections: sectionsProp,
        module: moduleProp,
      };
      store.dispatch(
        `${maker_checker_sections_module}/fetchMakerCheckerSections`,
        maker_checker_sections_payload
      );
    }

    function onInputChange(event) {
      props.inputHandler(event.target.value);
    }

    function onCreateClick() {
      props.createHandler();
    }

    function downloadXlsxClick() {
      props.downloadXlsxHandler();
    }

    function downloadPdfClick() {
      props.downloadPdfHandler();
    }

    function printPdfClick() {
      props.printPdfHandler();
    }

    function onDateChange(date) {
      props.dateHandler(date);
    }

    function onBranchChange() {
      props.branchHandler(state.branch_id);
    }

    function onTeamChange() {
      props.teamHandler(state.team_id);
    }

    function onTeamMemberTypeChange() {
      props.teamMemberTypeHandler(state.member_type_id);
    }

    function onEmployeeChange() {
      props.employeeHandler(state.employee_id);
    }

    function onStatusChange() {
      props.statusHandler(state.status_id);
    }

    function onMakerCheckerSectionChange() {
      props.makerCheckerSectionHandler(state.section_code);
    }

    /* function onStartDateChange(date) {
      console.log("onStartDateChange", date);
      props.startDateHandler(date);
    } */

    function onStartDateChange(date) {
      // const startDate = state.end_date;
      // console.log("onStartDateChange HHHH", date);
      state.start_date = date;
      start_date.value = date;
      // emit("onstartdatechange", date); 
      props.startDateHandler(date);
    }

    function onEndDateChange(date) {
      // const endDate = state.end_date;
      // console.log("onEndDateChange", endDate);
      props.endDateHandler(date);
    }

    /* function onDateChange(newValue) {
      start_date.value = newValue; 
    } */

    return {
      onInputChange,
      display_labels,
      display_search_box,
      display_download_button,
      display_statuses,
      display_branches,
      display_teams,
      display_team_member_types,
      display_staff,
      display_start_date,
      display_end_date,
      display_date,
      display_date_mode,
      display_maker_checker_sections,
      onCreateClick,
      downloadXlsxClick,
      downloadPdfClick,
      printPdfClick,
      onDateChange,
      onStartDateChange,
      onEndDateChange,
      onBranchChange,
      onEmployeeChange,
      onTeamChange,
      onTeamMemberTypeChange,
      onStatusChange,
      onMakerCheckerSectionChange,
      search_terms,
      start_date,
      end_date,
      state,
      search_branches: computed(() => store.getters[`${branches_module}/branches`]),
      search_teams: computed(() => store.getters[`${teams_module}/teams`]),
      search_team_member_types: computed(() => store.getters[`${team_member_types_module}/member_types`]),
      search_employees: computed(() => store.getters[`${employees_module}/employees`]),
      search_statuses: computed(() => store.getters[`${statuses_module}/statuses`]),
      search_maker_checker_sections: computed(
        () => store.getters[`${maker_checker_sections_module}/makercheckersections`]
      ),
    };
  },
});
</script>

<style scoped>
.btn.btn-outline-primary:hover {
  color: #ffffff !important;
  background-color: #25a8e0;
}

.search-boxx {
  transition: all 0.7s ease-in;
}

.search-text {
  font-family: "Inter", sans-serif;
  font-size: inherit;
  font-weight: 400;
  margin-left: 3px;
}
.search-enter-from {
  opacity: 0;
  transform: translateY(-20px);
}

.search-enter-active {
  transition: all 0.5s ease-out;
}

.search-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.search-leave-active {
  transition: all 0.5s ease-in;
}
</style>
