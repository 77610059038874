import axios from "axios";

export default {

    namespaced: true,

    state: {

        loansecurities: [],
        loansecurity: {}

    },

    getters: {

        loansecurities(state) {
            return state.loansecurities;
        },
        loansecurity(state) {
            return state.loansecurity;
        }

    },

    mutations: {

        SET_LOAN_SECURITIES(state, loansecurities) {
            return state.loansecurities = loansecurities
        },

        SET_LOAN_SECURITY(state, loansecurity) {
            return state.loansecurity = loansecurity
        }

    },

    actions: {

        async fetchloansecurities({ commit }, params) {

            await axios.get('/api/v1/loan-securities', { params: params })
                .then(res => {
                    commit('SET_LOAN_SECURITIES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchloansecurity({ commit }, loan_security_id) {

            await axios.get(`/api/v1/loan-securities/${loan_security_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_LOAN_SECURITY', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createloansecurity({ commit, dispatch }, loansecurity) {
            await axios.post('/api/v1/loan-securities', loansecurity)
                .then(res => {
          
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateloansecurity({ commit, dispatch }, loansecurity) {

            await axios("/api/v1/loan-securities/" + loansecurity.loan_security_id, {
                method: "patch",
                data: loansecurity,
            }).then(res => {
          
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteloansecurity({ commit }, loan_security_id) {

            await axios.delete(`/api/v1/loan-securities/${loan_security_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchloansecurities');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
