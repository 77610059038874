const companySettingRoutes = [

    {

        name: 'companySettingHome',
        path: '',
        component: () => import('./ListCompanySettings.vue'),
        meta: {
            auth: true,
            title: 'Company Settings Listing',
            breadcrumb: 'Company Settings Listing',
            permission: ['view companysetting']
        }

    },

    {
        path: ':id',
        name: 'showCompanySetting',
        component: () => import('./ShowCompanySetting.vue'),
        meta: {
            auth: true,
            title: 'Showing Company Setting',
            breadcrumb: 'Showing Company Setting',
            permission: ['view companysetting']
        }
    },

    {
        path: ':id/edit',
        name: 'editCompanySetting',
        component: () => import('./EditCompanySetting.vue'),
        meta: {
            auth: true,
            title: 'Edit Company Setting',
            breadcrumb: 'Edit Company Setting',
            permission: ['update companysetting']
        }
    },
];

export default companySettingRoutes;
