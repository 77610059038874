<template>
  <div class="container-fluid no-gutters px-0">
    <div class="row">
      <div class="col-12 mt-3 mb-2">
        <form @submit.prevent="createJournal">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-lg-2">
                  <div class="form-group">
                    <label>Transaction Date:</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="state.transaction_date"
                      required
                    />
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label>Branch:</label>
                    <select
                      class="form-control"
                      v-model="state.branch_id"
                      required
                    >
                      <option value="">Select Branch</option>
                      <option
                        v-for="(item, index) in branches.data"
                        :key="index"
                        :value="item.branch_id"
                      >
                        {{ item.branch_name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label>Description:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.description"
                    />
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label>Reference No:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="state.reference_no"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" v-if="state.accounts">
              <div class="table-responsive table_height">
                <table class="table table-borderless mb-0">
                  <thead class="mb-2">
                    <tr>
                      <th class="col-auto">Account Name</th>
                      <th class="col-auto">Dr/Cr</th>
                      <th class="col-auto">Amount</th>
                      <th class="col-auto">
                        <span class="btn btn-outline-success" @click="addRow">
                          <i class="fas fa-plus mr-2"></i> Add
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(input, index) in state.accounts" :key="index">
                      <td>
                  
                          <Multiselect
                            v-model="input.account_id"
                            id="customer_id"
                            :options="ledgeraccounts"
                            mode="single"
                            :searchable="true"
                            trackBy="account_code_name"
                            label="account_code_name"
                            valueProp="account_id"
                            class="form-control col-auto"
                          />
                       
                      </td>

                      <td>
                        <select
                          class="form-control"
                          v-model="input.DrOrCr"
                          @change="validate"
                          required
                        >
                          <option value="Dr">Debit</option>
                          <option value="Cr">Credit</option>
                        </select>
                      </td>

                      <td>
                        <currency-input
                          type="text"
                          class="form-control"
                          placeholder="Enter Amount"
                          required
                          @change="validate"
                          v-model="input.amount"
                        />
                      </td>

                      <td>
                        <span
                          @click="deleteRow(index)"
                          class="btn btn-outline-danger"
                        >
                          <i class="mdi mdi-delete mr-2"></i> Delete
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-6"></div>
                <div class="col-6 float-right">
                  <div class="form-group">
                    <button
                      class="btn btn-outline-primary m-3 float-right"
                      type="submit"
                      :disabled="state.invalid"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table_height {
  max-height: 260px;
  min-height: 260px;
}
</style>

<script>
import { defineComponent, reactive, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Multiselect from "@vueform/multiselect";
import axios from 'axios';

const financeaccountModule = "financeaccounts";
const branchModule = "branches";

export default defineComponent({
  name: "journalComponent",

  components: {Multiselect},

  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      accounts: [],
      transaction_date: "",
      reference_no: "",
      description: "",
      branch_id: "",
      invalid: true,
    });

    function validate() {
      if (state.accounts.length != 0) {
        var DrCount = state.accounts.filter(
          (e) => e.DrOrCr == "Dr" && e.amount !== ""
        );
        var CrCount = state.accounts.filter(
          (e) => e.DrOrCr == "Cr" && e.amount !== ""
        );
        var DrCountSum =
          DrCount.length == 0
            ? 0
            : DrCount.map((e) => parseFloat(e.amount)).reduce((a, b) => a + b);
        var CrCountSum =
          CrCount.length == 0
            ? 0
            : CrCount.map((e) => parseFloat(e.amount)).reduce((a, b) => a + b);
        if ((DrCountSum == 0) | (CrCountSum == 0)) {
          state.invalid = true;
        } else {
          state.invalid = DrCountSum.toFixed(2) !== CrCountSum.toFixed(2);
        }
      } else {
        state.invalid = true;
      }
    }

    function addRow() {
      state.accounts.push({
        amount: 0,
        DrOrCr: "Dr",
        transaction_id: "",
        account_id: "",
      });
      state.accounts.push({
        amount: 0,
        DrOrCr: "Cr",
        transaction_id: "",
        account_id: "",
      });
      validate();
    }

    function createJournal() {
      axios
        .post("/api/v1/journals", state)
        .then((res) => {
          resetForm();
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    function resetForm() {
      Object.assign(state, {
        accounts: [],
        transaction_date: "",
        reference_no: "",
        description: "",
        branch_id: "",
        invalid: true,
      });
    }

    function deleteRow(index) {
      state.accounts.splice(index, 1);
      validate();
    }

    const branches = computed(() => store.getters[`${branchModule}/branches`]);

    const ledgeraccounts = computed(
      () => store.getters[`${financeaccountModule}/postingAccounts`]
    );

    onMounted(() => {
      store.dispatch(`${branchModule}/fetchBranches`);
      store.dispatch(`${financeaccountModule}/fetchPostingAccounts`);
    });

    return {
      state,
      store,
      router,
      validate,
      ledgeraccounts,
      branches,
      addRow,
      deleteRow,
      createJournal,
    };
  },
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
