<template>

    <modal v-show="showModal"  @close="toggleEnableDisableUserPopup" :modalSize="'modal-md'">

        <template v-slot:header>
            {{ userData?.status_id == statusEnum.ACTIVE ? 'Disable' : 'Enable' }}
            <span v-if="userType=='EMPLOYEE'">Staff</span>
            <span v-if="userType=='CUSTOMER'">Member</span>
            <span v-if="userType=='GROUP'">Group</span>
        </template>

        <template v-slot:body>
            
            <!-- Hapa == {{ userData?.user?.status_id == statusEnum.ACTIVE ? 'Disable' : 'Enable' }}<br> -->

            <form @submit.prevent="onSubmitted">

                <div class="row">

                    <!-- {{ userData }} -->

                    <div class="col-lg-12">
                        <div class="form-group row">
                            <label for="first_name" class="col-sm-4 col-form-label text-right">
                                <span v-if="userType=='STAFF'">Name</span>
                            </label>
                            <div class="col-sm-8">

                                <input class="form-control"
                                        :value="userData.first_name + ' ' + userData.last_name"
                                        type="text" disabled/>

                                <input class="form-control"
                                        v-model="model.user_id.$model"
                                        type="hidden" readonly/>

                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="first_name" class="col-sm-4 col-form-label text-right">
                                Comments (optional)
                            </label>
                            <div class="col-sm-8">                                
                                <textarea class="form-control" rows="3" v-model="model.comments.$model" />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">

                        <hr class="row no-top-margin"/>

                        <div class="form-group row">
                            <div class="col-sm-4">&nbsp;</div>
                            <div class="col-sm-8">
                                <div class="form-group">
                                    <button class="btn btn-block mr-3"
                                            type="submit"
                                            :class="{
                                                'btn-danger': userData?.status_id == statusEnum.ACTIVE,
                                                'btn-success': userData?.status_id !== statusEnum.ACTIVE
                                            }"
                                            :disabled="model.$invalid">
                                        {{ userData?.status_id == statusEnum.ACTIVE ? 'Disable' : 'Enable' }} 
                                        <span v-if="userType=='EMPLOYEE'">Staff</span>
                                        <span v-if="userType=='CUSTOMER'">Member</span>
                                        <span v-if="userType=='GROUP'">Group</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </form>

        </template>

    </modal>

</template>


<script>

    import { defineComponent, ref } from "vue";
    import { useStore } from 'vuex';
    import { useVuelidate } from "@vuelidate/core";
    import { statusEnum } from "@/enums";


    const user_module = 'users';

    export default defineComponent({

        name: "EnableDisableUser",

        props: {
            userData: {
                type: Object,
                required: true,
            },
            userType: {
                type: String,
                required: false,
            },
            showModal: {
                type: Boolean,
                default: true,
            },
            toggleEnableDisableUserPopup: {
                type: Function,
                default: () => {},
            },
        },

        components: {},

        setup(props, { emit }) {

            const enableDisableUserData = ref({
                user_id: props.userData.user_id,
                comments: "",
                employee_id: props.userData.employee_id,
                customer_id: props.userData.customer_id,
                status_id: props.userData.status_id == statusEnum.ACTIVE ? statusEnum.INACTIVE : statusEnum.ACTIVE,
            });

            const store = useStore();
            const userData = ref(props.userData);
            const showModal = ref(props.showModal);
            const userType = ref(props.userType);
            const toggleEnableDisableUserPopup = ref(props.toggleEnableDisableUserPopup);

            let enableDisableUserForm = ref(enableDisableUserData);

            // set validation rules
            const rules = {
                user_id: { },
                comments: { },
                employee_id: { },
                customer_id: { },
                status_id: { },
            };

            // validate form
            const model = useVuelidate(rules, enableDisableUserForm);

            async function onSubmitted(){
                try {
                    await store.dispatch(`${user_module}/enableDisableUser`, enableDisableUserForm.value);
                    // console.log("enableDisableUserForm.value ", enableDisableUserForm.value);
                    emit('toggleEnableDisableUserPopup', toggleEnableDisableUserPopup);
                } catch (error) {
                }
            }

            return {
                onSubmitted,
                model,
                statusEnum,
                userData,
                // showModal,
            };

        },

    });

</script>


<style>

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

    .modal-fade-enter, .modal-fade-leave-to  {
        opacity: 0;
    }
    .modal-fade-enter-active {
        transition: all 0.8s ease-out;
    }
    .modal-fade-enter-active {
        transition: all 0.8s ease-in;
    }

</style>

