<template>

    <div class="alert alert-danger border-0 text-center" role="alert" v-html="message"></div>

</template>

<script>

import { defineComponent, ref } from "vue";

export default defineComponent({

  name: "RecordsNotFound",

  props: {
    message: {
        type: String,
        default: "<strong>No Records</strong> Found"
    },
  },

  setup(props, { emit }) {

    const message = ref(props.message);

    return {
      // message
    };

  },

});
</script>


<style scoped>
    .alert {
        margin-bottom: 0 !important;
    }
</style>
