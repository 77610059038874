import axios from "axios";

export default {

    namespaced: true,

    state: {

        products: [],
        product: {}

    },

    getters: {

        products(state) {
            return state.products;
        },
        product(state) {
            return state.product;
        }

    },

    mutations: {

        SET_PRODUCTS(state, products) {
            return state.products = products
        },

        SET_PRODUCT(state, product) {
            return state.product = product
        }

    },

    actions: {

        async fetchProducts({ commit }, params) {

            await axios.get('/api/v1/product-settings', { params: params })
                .then(res => {
                    // console.log("res == ", res);
                    commit('SET_PRODUCTS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchProduct({ commit }, product_id) {

            await axios.get(`/api/v1/product-settings/${product_id}`)
                .then(res => {
                    // console.log("fetchProduct store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_PRODUCT', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createProduct({ commit, dispatch }, product) {

            // console.log("product createProduct ", product);
            await axios.post('/api/v1/product-settings', product)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchProducts');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateProduct({ commit, dispatch }, product) {

            await axios("/api/v1/product-settings/" + product.product_id, {
                method: "patch",
                data: product,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchProducts');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteProduct({ commit }, product_id) {

            await axios.delete(`/api/v1/product-settings/${product_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchProducts');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
