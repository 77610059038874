import axios from "axios";

export default {

    namespaced: true,

    state: {

        makercheckersectionsmaster: [],
        makercheckersectionmaster: {},
        makercheckersectionsmasterhistory: {}

    },

    getters: {

        makercheckersectionsmaster(state) {
            return state.makercheckersectionsmaster;
        },
        makercheckersectionmaster(state) {
            return state.makercheckersectionmaster;
        },
        makercheckersectionsmasterhistory(state) {
            return state.makercheckersectionsmasterhistory;
        }

    },

    mutations: {

        SET_MAKER_CHECKER_SECTIONS_MASTER(state, makercheckersectionsmaster) {
            return state.makercheckersectionsmaster = makercheckersectionsmaster
        },

        SET_MAKER_CHECKER_SECTION_MASTER(state, makercheckersectionmaster) {
            return state.makercheckersectionmaster = makercheckersectionmaster
        },

        SET_MAKER_CHECKER_SECTIONS_MASTER_HISTORY(state, makercheckersectionsmasterhistory) {
            return state.makercheckersectionsmasterhistory = makercheckersectionsmasterhistory
        }

    },

    actions: {

        async fetchMakerCheckerSectionsMaster({ commit }, params) {

            await axios.get('/api/v1/maker-checker-sections-master', { params: params })
                .then(res => {
                    commit('SET_MAKER_CHECKER_SECTIONS_MASTER', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchMakerCheckerSectionsMasterHistory({ commit }, params) {

            await axios.get(`/api/v1/maker-checker-sections-master/history/${params.email_template_master_id}`, { params: params })
                .then(res => {
                    commit('SET_MAKER_CHECKER_SECTIONS_MASTER_HISTORY', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchMakerCheckerSectionMaster({ commit }, email_template_master_id) {

            await axios.get(`/api/v1/maker-checker-sections-master/${email_template_master_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_MAKER_CHECKER_SECTION_MASTER', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createMakerCheckerSectionMaster({ commit, dispatch }, makercheckersectionmaster) {

            await axios.post('/api/v1/maker-checker-sections-master', makercheckersectionmaster)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchMakerCheckerSectionsMaster');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateMakerCheckerSectionMaster({ commit, dispatch }, makercheckersectionmaster) {

            await axios("/api/v1/maker-checker-sections-master/" + makercheckersectionmaster.media_template_master_id, {
                method: "patch",
                data: makercheckersectionmaster,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchMakerCheckerSectionsMaster');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async resetMakerCheckerSectionMaster({ commit, dispatch }, email_template_master_id) {

            await axios.post(`/api/v1/maker-checker-sections-master/reset/${email_template_master_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    // dispatch('fetchMakerCheckerSections');
                    dispatch('fetchMakerCheckerSectionMaster', email_template_master_id);
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async resetAllMakerCheckerSectionMaster({ commit, dispatch }) {

            await axios.post(`/api/v1/maker-checker-sections-master/resetall`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchMakerCheckerSectionsMaster');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteMakerCheckerSectionMaster({ commit }, email_template_master_id) {

            await axios.delete(`/api/v1/maker-checker-sections-master/${email_template_master_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchMakerCheckerSectionsMaster');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
