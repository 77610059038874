import Base from '../../Common/Base.vue';

const clientCustomerRecommendModuleRoutes = [

    {
        name: 'clientCustomerRecommend',
        path: '',
        component: () =>
            import ('../../../components/Customer/Recommends/ListRecommends.vue'),
        meta: {
            auth: true,
            title: 'My Recommendations',
            breadCrumb: 'My Recommendations',
            permission: ['view customer']
        }
    },

    {
        path: 'create',
        name: 'clientCustomerCreateRecommend',
        component: () => import('../../../components/Customer/Recommends/CreateRecommend.vue'),
        meta: {
            auth: true,
            title: 'Create Recommendation',
            breadcrumb: 'Create Recommendation',
            permission: ['view customer']
        }
    },

    {
        path: ':id/edit',
        name: 'clientCustomerEditRecommend',
        component: () => import('../../../components/Customer/Recommends/EditRecommend.vue'),
        meta: {
            auth: true,
            title: 'Edit Recommendation',
            breadcrumb: 'Edit Recommendation',
            permission: ['view customer']
        }
    },

    {
        path: ':id',
        name: 'clientCustomerShowRecommend',
        component: () => import('../../../components/Customer/Recommends/ShowRecommend.vue'),
        meta: {
            auth: true,
            title: 'Showing Recommendation',
            breadcrumb: 'Showing Recommendation',
            permission: ['view customer']
        }
    }

];

export default clientCustomerRecommendModuleRoutes;
