import axios from "axios";

export default {

    namespaced: true,

    state: {

        makercheckersections: [],
        makercheckersection: {},
        makercheckersectionmaxlevel: {},
        makercheckersectionshistory: {}

    },

    getters: {

        makercheckersections(state) {
            return state.makercheckersections;
        },
        makercheckersection(state) {
            return state.makercheckersection;
        },
        makercheckersectionmaxlevel(state) {
            return state.makercheckersectionmaxlevel;
        },
        makercheckersectionshistory(state) {
            return state.makercheckersectionshistory;
        }

    },

    mutations: {

        SET_MAKER_CHECKER_SECTIONS(state, makercheckersections) {
            return state.makercheckersections = makercheckersections
        },

        SET_MAKER_CHECKER_SECTION(state, makercheckersection) {
            return state.makercheckersection = makercheckersection
        },

        SET_MAKER_CHECKER_SECTION_MAX_LEVEL(state, makercheckersectionmaxlevel) {
            return state.makercheckersectionmaxlevel = makercheckersectionmaxlevel
        },

        SET_MAKER_CHECKER_SECTIONS_HISTORY(state, makercheckersectionshistory) {
            return state.makercheckersectionshistory = makercheckersectionshistory
        }

    },

    actions: {

        async fetchMakerCheckerSections({ commit }, params) {

            // console.log("params ==== ", params);

            await axios.get('/api/v1/maker-checker-sections', { params: params })
                .then(res => {
                    commit('SET_MAKER_CHECKER_SECTIONS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchMakerCheckerSectionsHistory({ commit }, params) {

            await axios.get(`/api/v1/maker-checker-sections/history/${params.maker_checker_section_id}`, { params: params })
                .then(res => {
                    commit('SET_MAKER_CHECKER_SECTIONS_HISTORY', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchMakerCheckerSection({ commit }, maker_checker_section_id) {

            await axios.get(`/api/v1/maker-checker-sections/${maker_checker_section_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_MAKER_CHECKER_SECTION', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchMakerCheckerSectionMaxLevel({ commit }, maker_checker_section_id) {

            await axios.get(`/api/v1/maker-checker-sections/${maker_checker_section_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_MAKER_CHECKER_SECTION_MAX_LEVEL', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateMakerCheckerSectionConfigurations({ commit, dispatch }) {

            await axios.get(`/api/v1/maker-checker-sections/update-configurations`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchMakerCheckerSections');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createMakerCheckerSection({ commit, dispatch }, makercheckersection) {

            await axios.post('/api/v1/maker-checker-sections', makercheckersection)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchMakerCheckerSections');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateMakerCheckerSection({ commit, dispatch }, makercheckersection) {

            await axios("/api/v1/maker-checker-sections/" + makercheckersection.maker_checker_section_id, {
                method: "patch",
                data: makercheckersection,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchMakerCheckerSections');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async resetMakerCheckerSection({ commit, dispatch }, maker_checker_section_id) {

            await axios.post(`/api/v1/maker-checker-sections/reset/${maker_checker_section_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    // dispatch('fetchMakerCheckerSections');
                    dispatch('fetchMakerCheckerSection', maker_checker_section_id);
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteMakerCheckerSection({ commit }, maker_checker_section_id) {

            await axios.delete(`/api/v1/maker-checker-sections/${maker_checker_section_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchMakerCheckerSections');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
