import axios from "axios";
import { downloadFile, printPdf } from "../../../utils";

export default {

    namespaced: true,

    actions: {

        async fetchCustomersReport({ commit }, data) {

            await axios.post('/api/v1/exports/customers', data)
                .then(res => {
                    downloadFile(res.data.filename, res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async printCustomersReport({ commit }, data) {

            await axios.post('/api/v1/exports/customers', data)
                .then(res => {
                    printPdf(res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchEmployeesReport({ commit }, data) {

            await axios.post('/api/v1/exports/employees', data)
                .then(res => {
                    downloadFile(res.data.filename, res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async printEmployeesReport({ commit }, data) {

            await axios.post('/api/v1/exports/employees', data)
                .then(res => {
                    printPdf(res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchRecommendsReport({ commit }, data) {

            await axios.post('/api/v1/exports/recommends', data)
                .then(res => {
                    downloadFile(res.data.filename, res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async printRecommendsReport({ commit }, data) {

            await axios.post('/api/v1/exports/recommends', data)
                .then(res => {
                    printPdf(res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        }

    }

}


