const paybillRegistrationRoutes = [

    {

        name: 'paybillHome',
        path: '',
        component: () => import('./ListPaybills.vue'),
        meta: {
            auth: true,
            title: 'Paybills Listing',
            breadcrumb: 'Paybills Listing',
            permission: ['view paybill'],
            section: 'paybill'
        }

    },

    {
        path: 'create',
        name: 'createPaybill',
        component: () => import('./CreatePaybill.vue'),
        meta: {
            auth: true,
            title: 'Create Paybill',
            breadcrumb: 'Create Paybill',
            permission: ['create paybill'],
            section: 'paybill'
        }
    },

    {
        path: ':id',
        name: 'showPaybill',
        component: () => import('./ShowPaybill.vue'),
        meta: {
            auth: true,
            title: 'Showing Paybill',
            breadcrumb: 'Showing Paybill',
            permission: ['view paybill'],
            section: 'paybill'
        }
    },

    {
        path: ':id/edit',
        name: 'editPaybill',
        component: () => import('./EditPaybill.vue'),
        meta: {
            auth: true,
            title: 'Edit Paybill',
            breadcrumb: 'Edit Paybill',
            permission: ['update paybill'],
            section: 'paybill'
        }
    },

    {
        path: 'delete/:id',
        name: 'deletePaybill',
        meta: {
            auth: true,
            title: 'Delete Paybill',
            permission: ['delete paybill'],
            section: 'paybill'
        }
    },

    {
        path: 'register-mpesa-paybill',
        name: 'registerMpesaPaybill',
        component: () => import('./RegisterMpesaPaybill.vue'),
        meta: {
            auth: true,
            title: 'Register Mpesa Paybill',
            permission: ['update Mpesa paybill'],
            section: 'paybill'
        }
    }

];

export default paybillRegistrationRoutes;
