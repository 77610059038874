<template>
  <div class="col-md-6 col-lg-4 fill-flex-container">
    <div class="card report-card">
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col">
            <p class="text-dark mb-1 font-weight-semibold">
              {{ capitalizeFirstLetter(title) }}
            </p>
            <h3 class="my-2">
              {{ numberFormatter(count) }}
            </h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-light-alt">
              <i :class="iconClass" class="align-self-center text-muted"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { numberFormatter } from '@/filters';
import { capitalizeFirstLetter } from '@/helpers/utils';

export default defineComponent({
  name: 'SummaryDataCard',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    count: {
      required: true
    }
  },
  setup(props) {
    const iconClass = computed(() => `${props.icon}`);

    return {
      iconClass,
      numberFormatter,
      capitalizeFirstLetter
    };
  }
});
</script>
