import axios from "axios";

export default {

    namespaced: true,

    state: {

        employees: [],
        employee: {}

    },

    getters: {

        employees(state) {
            return state.employees;
        },
        employee(state) {
            return state.employee;
        }

    },

    mutations: {

        SET_EMPLOYEES(state, employees) {
            return state.employees = employees
        },

        SET_EMPLOYEE(state, employee) {
            return state.employee = employee
        }

    },

    actions: {

        async fetchEmployees({ commit }, params) {

            // console.log("****** fetchemployees *******", params);

            await axios.get('/api/v1/employees', { params: params })
                .then(res => {
                    commit('SET_EMPLOYEES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchEmployee({ commit }, employee_id) {

            await axios.get(`/api/v1/employees/${employee_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_EMPLOYEE', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async assignEmployeeRole({ commit }, employee) {

            await axios.post(`/api/v1/employees/${employee.employee_id}/assignRole`, employee)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_EMPLOYEE', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createEmployee({ commit, dispatch }, employee) {

            await axios.post('/api/v1/employees', employee)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchEmployees');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateEmployee({ commit, dispatch }, employee) {

            await axios("/api/v1/employees/" + employee.employee_id, {
                method: "patch",
                data: employee,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchEmployees');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteEmployee({ commit }, employee_id) {

            await axios.delete(`/api/v1/employees/${employee_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchEmployees');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
