import axios from "axios";

export default {
  namespaced: true,

  state: {
    customers: [],
    customer: {},
    security_customers: [],
    active_customers: [],
    lastUpdated: null, // Timestamp to track when data was last fetched
  },

  getters: {
    customers(state) {
      return state.customers;
    },

    customer(state) {
      return state.customer;
    },

    security_customers(state) {
      return state.security_customers;
    },

    active_customers(state) {
      return state.active_customers;
    },

    isDataStale: (state) => {
      // Consider data stale if it was last updated more than 5 minutes ago
      const staleThreshold = 5 * 60 * 1000; // 5 minutes in milliseconds
      return (
        !state.lastUpdated || Date.now() - state.lastUpdated > staleThreshold
      );
    },
  },

  mutations: {
    SET_CUSTOMERS(state, customers) {
      return (state.customers = customers);
    },

    SET_ACTIVE_CUSTOMERS(state, active_customers) {
      return (state.active_customers = active_customers);
    },
    SET_SECURITY_CUSTOMERS(state, security_customers) {
      return (state.security_customers = security_customers);
    },
    SET_CUSTOMER(state, customer) {
      return (state.customer = customer);
    },

    SET_NEXT_OF_KIN(state, nextofkin) {
      return state.customer.next_of_kins.push(nextofkin);
    },

    // Commit to update timestamp
    SET_LAST_UPDATED(state) {
      state.lastUpdated = Date.now();
    },
  },

  actions: {
    async fetchSecurityCustomers({ commit }, params) {
      await axios
        .get("/api/v1/customers", { params: params })
        .then((res) => {
          commit("SET_SECURITY_CUSTOMERS", res.data.data);
          commit('SET_LAST_UPDATED'); // Update timestamp
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async fetchActiveMembers({ commit }, params) {
      await axios
        .get("/api/v1/statistics/total", { params: params })
        .then((res) => {
          commit("SET_ACTIVE_CUSTOMERS", res.data);
          commit('SET_LAST_UPDATED'); // Update timestamp
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async fetchCustomers({ commit }, params) {
      await axios
        .get("/api/v1/customers", { params: params })
        .then((res) => {
          commit("SET_CUSTOMERS", res.data);
          commit('SET_LAST_UPDATED'); // Update timestamp
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async fetchCustomersLess({ commit }, params) {
      await axios
        .get("/api/v1/customers/less", { params: params })
        .then((res) => {
          commit("SET_CUSTOMERS", res.data);
          commit('SET_LAST_UPDATED'); // Update timestamp
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async filterCustomers({ commit }, params) {
      await axios
        .get("/api/v1/customers/filter", { params: params })
        .then((res) => {
          commit("SET_CUSTOMERS", res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async filterSecurityCustomers({ commit }, params) {
      await axios
        .get("/api/v1/customers/security-customers-filter", { params: params })
        .then((res) => {
          commit("SET_SECURITY_CUSTOMERS", res.data);

          return res.data;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async addNextOfKin({ commit }, nextofkin) {
      commit("SET_NEXT_OF_KIN", nextofkin);
    },

    async fetchCustomer({ commit }, customer_id) {
      await axios
        .get(`/api/v1/customers/${customer_id}`)
        .then((res) => {
          if (res.statusText === "OK") {
            commit("SET_CUSTOMER", res.data.data);
            commit('SET_LAST_UPDATED'); // Update timestamp
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async createCustomer({ commit, dispatch }, customer) {
      await axios
        .post("/api/v1/customers", customer)
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchCustomers");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async updateCustomer({ commit, dispatch }, customer) {
      await axios("/api/v1/customers/" + customer.customer_id, {
        method: "patch",
        data: customer,
      })
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchCustomers");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async deleteCustomer({ commit }, dispatch, customer_id) {
      await axios
        .delete(`/api/v1/customers/${customer_id}`)
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchCustomers");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async activateMember({ commit, dispatch }, user) {
      await axios
        .post("/api/v1/customers/activate", user)
        .then((res) => {})
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
};
