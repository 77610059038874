<template>

    <modal v-show="showModal"  @close="toggleActivateMemberPopup" :modalSize="'modal-lg'">

        <template v-slot:header>
            Activate
            <span v-if="userType=='CUSTOMER'">Member</span>
            <span v-if="userType=='GROUP'">Group</span>
        </template>

        <template v-slot:body>

            <!-- Hapa == {{ userData }}<br> -->

            <form @submit.prevent="onSubmitted">

                <div class="row">

                    <div class="col-lg-6">
                        <div class="form-group row">
                            <label for="first_name" class="col-sm-6 col-form-label text-right">
                                <span v-if="userType=='CUSTOMER'">First Name</span>
                                <span v-if="userType=='GROUP'">Group Name</span>
                            </label>
                            <div class="col-sm-6">

                                <input class="form-control"
                                        :value="userData.first_name"
                                        type="text" disabled/>

                                <input class="form-control"
                                        v-model="model.user_id.$model"
                                        type="hidden" readonly/>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6" v-if="userType!='GROUP'">
                        <div class="form-group row">
                            <label for="last_name" class="col-sm-6 col-form-label text-right">
                                Last Name
                            </label>
                            <div class="col-sm-6">

                                <input class="form-control"
                                        :value="userData.last_name"
                                        type="text" disabled/>

                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-6">

                        <div class="form-group row">

                            <label for="amount" class="col-sm-6 col-form-label text-right">
                                Activation Amount
                            </label>
                            <div class="col-sm-6">

                                <currency-input class="form-control"
                                    :class="{ 'form-control-danger' : model.amount.$invalid }"
                                    type="text"
                                    v-model="model.amount.$model"
                                />

                                <error-msg :property="model.amount" />

                            </div>

                        </div>

                    </div> -->

                    <!-- <div class="col-lg-12">

                        <div class="form-group row"
                                :class="{ 'has-error' : model.comments.$invalid }">
                            <label for="comments" class="col-sm-3 col-form-label text-right">
                                Comments
                            </label>
                            <div class="col-sm-9">

                                <textarea class="form-control" cols="4"
                                        :class="{ 'form-control-danger' : model.comments.$invalid }"
                                        v-model="model.comments.$model" >
                                </textarea>

                                <error-msg :property="model.comments" />

                            </div>
                        </div>

                    </div> -->

                    <div class="col-lg-12">

                        <hr class="row no-top-margin"/>

                        <div class="form-group">
                            <button class="btn btn-primary mr-3" type="submit"
                                :disabled="model.$invalid"
                                :class="{ 'btn-secondary' : model.$invalid }">
                                Activate Member
                            </button>
                        </div>

                    </div>

                </div>

            </form>

        </template>

    </modal>

</template>


<script>

    import { defineComponent, ref } from "vue";
    import { useStore } from 'vuex';
    import { useVuelidate } from "@vuelidate/core";
    import { required, decimal,minValue } from "@vuelidate/validators";


    const customer_module = 'customers';

    export default defineComponent({

        name: "ActivateMember",

        props: {
            userData: {
                type: Object,
                required: true,
            },
            userType: {
                type: String,
                required: false,
            },
            showModal: {
                type: Boolean,
                default: true,
            },
            toggleActivateMemberPopup: {
                type: Function,
                default: () => {},
            },
        },

        components: {},

        setup(props, { emit }) {

            const activateMemberData = {
                user_id: props.userData.user_id,
                comments: "",
                amount:props.userData.reg_fee,
                customer_id:props.userData.customer_id,
            };

            const store = useStore();
            const userData = ref(props.userData);
            const showModal = ref(props.showModal);
            const userType = ref(props.userType);
            const toggleActivateMemberPopup = ref(props.toggleActivateMemberPopup);

            let activateMemberForm = ref(activateMemberData);

            // set validation rules
            const rules = {
                user_id: { },
                comments: { },
                amount:{required,decimal,minValue:minValue(0)}
            };

            // validate form
            const model = useVuelidate(rules, activateMemberForm);

            async function onSubmitted(){
                try {
                    await store.dispatch(`${customer_module}/activateMember`, activateMemberForm.value);
                    emit('toggleActivateMemberPopup', toggleActivateMemberPopup);
                } catch (error) {
                    
                }
           
            }

            return {
                onSubmitted,
                model
            };

        },

    });

</script>


<style>

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

    .modal-fade-enter, .modal-fade-leave-to  {
        opacity: 0;
    }
    .modal-fade-enter-active {
        transition: all 0.8s ease-out;
    }
    .modal-fade-enter-active {
        transition: all 0.8s ease-in;
    }




    /* -- zoom -- */
    /* @-webkit-keyframes modal-zoom-enter {
        from {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    @keyframes modal-zoom-enter {
        from {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    .modal-zoom-enter-active {
        -webkit-animation: modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
        animation: modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
    }

    @-webkit-keyframes modal-zoom-leave {
        to {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    @keyframes modal-zoom-leave {
        to {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    .modal-zoom-leave-active {
        -webkit-animation: modal-zoom-leave both;
        animation: modal-zoom-leave both;
    } */

</style>

