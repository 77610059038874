import axios from "axios";

export default {

    namespaced: true,

    state: {
        account: {},
        accounts: [],
        detailTypes: [],
        creditAccounts: [],
        parentAccounts: [],
        incomeAccounts: [],
        expenseAccounts: [],
        bankingAccounts: [],
        postingAccounts: [],
        allpostingAccounts: [],
        groupingAccounts: [],
        nonBankingAccounts: [],
        accountsReceivable: [],
        currentLiabilities: [],
        capitalAccounts: [],
    },

    getters: {
        accounts(state) {
            return state.accounts;
        },
        account(state) {
            return state.account;
        },
        detailTypes(state) {
            return state.detailTypes;
        },

        creditAccounts(state) {
            return state.creditAccounts;
        },
        incomeAccounts(state) {
            return state.incomeAccounts;
        },
        capitalAccounts(state) {
            return state.capitalAccounts;
        },
        bankingAccounts(state) {
            return state.bankingAccounts;
        },
        nonBankingAccounts(state) {
            return state.nonBankingAccounts;
        },
        postingAccounts(state) {
            return state.postingAccounts;
        },
        allpostingAccounts(state) {
            return state.postingAccounts;
        },
        groupingAccounts(state) {
            return state.groupingAccounts;
        },
        accountsReceivable(state) {
            return state.accountsReceivable;
        },
        expenseAccounts(state) {
            return state.expenseAccounts;
        },

        currentLiabilities(state) {
            return state.currentLiabilities;
        },
    },

    mutations: {
        FETCH_ACCOUNTS(state, data) {
            state.accounts = data;
        },
        FETCH_DETAIL_TYPES(state, data) {
            state.detailTypes = data;
        },
        FETCH_GROUPING_ACCOUNTS(state, data) {
            state.groupingAccounts = data;
        },
        FETCH_POSTING_ACCOUNTS(state, data) {
            state.postingAccounts = data;
        },

        FETCH_ALL_POSTING_ACCOUNTS(state, data) {
            state.allpostingAccounts = data;
        },

        FETCH_BANKING_ACCOUNTS(state, data) {
            state.bankingAccounts = data;
        },
        FETCH_EXPENSE_ACCOUNTS(state, data) {
            state.expenseAccounts = data;
        },
        FETCH_INCOME_ACCOUNTS(state, data) {
            state.incomeAccounts = data;
        },
        FETCH_CREDIT_ACCOUNTS(state, data) {
            state.creditAccounts = data;
        },
        FETCH_ACCOUNTS_RECEIVABLE(state, data) {
            state.accountsReceivable = data;
        },
        FETCH_NON_BANKING_ACCOUNTS(state, data) {
            state.nonBankingAccounts = data;
        },
        FETCH_CURRENT_LIABILITIES_ACCOUNTS(state, data) {
            state.currentLiabilities = data;
        },

        FETCH_CAPITAL_ACCOUNTS(state, data) {
            state.capitalAccounts = data;
        },

    },

    actions: {
        fetchAccounts({ commit }, params) {
            axios.get('/api/v1/accounts', { params: params })
                .then(res => {
                    commit('FETCH_ACCOUNTS', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },
        fetchDetailTypes({ commit }, params) {
            axios.get('/api/v1/accounts/getDetailTypes', { params: params })
                .then(res => {
                    commit('FETCH_DETAIL_TYPES', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },
        fetchPostingAccounts({ commit }, params) {

            axios.get('/api/v1/accounts/getPostingAccounts', { params: params })
                .then(res => {
                    const data = res?.data?.data??[];
                    const postingAccounts = [];
                    const bankingAccounts = [];
                    const nonBankingAccounts = [];
                    const expenseAccounts = [];
                    const incomeAccounts = [];
                    const currentLiabilitiesAccounts = [];
                    const accountsReceivable = [];
                    const capitalAccounts = [];
                   
                    data.forEach(e => {
                       
                        if (e.prevent_manual_posting == false) {
                            postingAccounts.push(e);
                          
                            if (e.detail_type == "200") {
                                bankingAccounts.push(e);
                            } else if (e.detail_type == "1200") {
                                expenseAccounts.push(e);
                            } else if (e.detail_type == "1100") {
                                incomeAccounts.push(e);
                            } else if (e.detail_type == "800") {
                                currentLiabilitiesAccounts.push(e);
                            } else if (e.detail_type == "300") {
                                accountsReceivable.push(e);
                            } else if (e.detail_type == "1400") {
                                capitalAccounts.push(e);
                            }else if (!(["200", "300", "700"].includes(e.detail_type))) {
                                nonBankingAccounts.push(e);
                            }
                        }
                    });
             
                    commit('FETCH_ALL_POSTING_ACCOUNTS', data);
                    commit('FETCH_POSTING_ACCOUNTS', postingAccounts);
                    commit('FETCH_BANKING_ACCOUNTS', bankingAccounts);
                    commit('FETCH_NON_BANKING_ACCOUNTS', nonBankingAccounts);
                    commit('FETCH_EXPENSE_ACCOUNTS', expenseAccounts);
                    commit('FETCH_INCOME_ACCOUNTS', incomeAccounts);
                    commit('FETCH_CURRENT_LIABILITIES_ACCOUNTS', currentLiabilitiesAccounts);
                    commit('FETCH_ACCOUNTS_RECEIVABLE', accountsReceivable);
                    commit('FETCH_CAPITAL_ACCOUNTS', capitalAccounts);

                }).catch(err => {
                    return Promise.reject(err);
                })
        },
  

        fetchGroupingAccounts({ commit }, params) {

            axios.get('/api/v1/accounts/getGroupingAccounts', { params: params })
                .then(res => {
                    commit('FETCH_GROUPING_ACCOUNTS', res.data.data ? res.data.data : []);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

    },

}
