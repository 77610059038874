import axios from "axios";

export default {

    namespaced: true,

    state: {

        userbanks: [],
        userbank: {}

    },

    getters: {

        userbanks(state) {
            return state.userbanks;
        },
        userbank(state) {
            return state.userbank;
        }

    },

    mutations: {

        SET_USER_BANKS(state, userbanks) {
            return state.userbanks = userbanks
        },

        SET_USER_BANK(state, userbank) {
            return state.userbank = userbank
        },

        ADD_USER_BANK(state, userbank) {
            // return state.userbank = userbank
            return state.userbanks.push(userbank);
        }

    },

    actions: {

        async fetchUserBanksData({ commit }, params) {

            await axios.get('/api/v1/userbanksdata', { params: params })
                .then(res => {
                    commit('SET_USER_BANKS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchUserBankData({ commit }, userbank_id) {

            await axios.get(`/api/v1/userbanksdata/${userbank_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_USER_BANK', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createUserBankData({ commit, dispatch }, userbank) {

            await axios.post('/api/v1/userbanksdata', userbank)
                .then(res => {
                    if (res.statusText === 'OK') {
                        // dispatch('fetchUserBanks');
                        console.log("res.data.data", res.data.data);
                        commit('ADD_USER_BANK', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateUserBankData({ commit, dispatch }, userbank) {

            console.log("userbank ", userbank);
            await axios("/api/v1/userbanksdata/" + userbank.user_bank_id, {
                method: "patch",
                data: userbank,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchUserBanks');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteUserBankData({ commit }, userbank_id) {

            await axios.delete(`/api/v1/userbanksdata/${userbank_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchUserBanks');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }


}
