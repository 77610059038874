const smsMessageTemplateAdministrationRoutes = [

    {

        name: 'smsTemplateHome',
        path: '',
        component: () => import('./ListSmsTemplates.vue'),
        meta: {
            auth: true,
            title: 'Sms Templates Listing',
            breadcrumb: 'Sms Templates Listing',
            permission: ['view messagetemplate']
        }

    },

    {
        path: 'create',
        name: 'createSmsTemplate',
        component: () => import('./CreateSmsTemplate.vue'),
        meta: {
            auth: true,
            title: 'Create Sms Template',
            breadcrumb: 'Create Sms Template',
            permission: ['create messagetemplate']
        }
    },

    {
        path: ':id',
        name: 'showSmsTemplate',
        component: () => import('./ShowSmsTemplate.vue'),
        meta: {
            auth: true,
            title: 'Showing Sms Template',
            breadcrumb: 'Showing Sms Template',
            permission: ['view messagetemplate']
        }
    },

    {
        path: ':id/edit',
        name: 'editSmsTemplate',
        component: () => import('./EditSmsTemplate.vue'),
        meta: {
            auth: true,
            title: 'Edit Sms Template',
            breadcrumb: 'Edit Sms Template',
            permission: ['update messagetemplate']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteSmsTemplate',
        meta: {
            auth: true,
            title: 'Delete Sms Template',
            permission: ['delete messagetemplate']
        }
    },

];

export default smsMessageTemplateAdministrationRoutes;
