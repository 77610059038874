<template>

    <modal v-show="showModal"  @close="togglePopup">

        <template v-slot:header>
            Change User Password
        </template>

        <template v-slot:body>

            <!-- Hapa == {{ userData }}<br> -->

            <form @submit.prevent="onSubmitted">

                <div class="row">

                    <div class="col-lg-6">
                        <div class="form-group row">
                            <label for="first_name" class="col-sm-6 col-form-label text-right">
                                First Name
                            </label>
                            <div class="col-sm-6">

                                <input class="form-control"
                                        :value="userData.first_name"
                                        type="text" disabled/>

                                <input class="form-control"
                                        v-model="model.user_id.$model"
                                        type="hidden" readonly/>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group row">
                            <label for="last_name" class="col-sm-6 col-form-label text-right">
                                Last Name
                            </label>
                            <div class="col-sm-6">

                                <input class="form-control"
                                        :value="userData.last_name"
                                        type="text" disabled/>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">

                        <div class="form-group row"
                                :class="{ 'has-error' : model.password.$invalid }">
                            <label for="password" class="col-sm-6 col-form-label text-right">
                                New Password
                            </label>
                            <div class="col-sm-6">
                                <input class="form-control"
                                        :class="{ 'form-control-danger' : model.password.$invalid }"
                                        type="password"
                                        v-model="model.password.$model" />

                                <error-msg :property="model.password" />

                            </div>
                        </div>

                    </div>

                    <div class="col-lg-6">

                        <div class="form-group row"
                                :class="{ 'has-error' : model.password_confirmation.$invalid }">
                            <label for="password_confirmation" class="col-sm-6 col-form-label text-right">
                                Confirm New Password
                            </label>
                            <div class="col-sm-6">
                                <input class="form-control"
                                        :class="{ 'form-control-danger' : model.password_confirmation.$invalid }"
                                        type="password"
                                        v-model="model.password_confirmation.$model" />

                                <error-msg :property="model.password_confirmation" />

                            </div>
                        </div>

                    </div>

                    <div class="col-lg-12 col-sm-12">

                        <hr class="row no-top-margin"/>

                        <div class="form-group text-center">

                            <div class="col-sm-8ss">

                                <div class="custom-control custom-switch mt-2">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="change_password_next_login"
                                        v-model="model.change_password_next_login.$model"
                                        :checked="model.change_password_next_login"
                                        @change="checkMustChangePasswordNextLogin($event)"
                                    />
                                    <label class="custom-control-label" for="change_password_next_login">
                                        User Must Change Password At Next Login
                                    </label>
                                </div>
                                <error-msg :property="model.change_password_next_login" />

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">

                        <hr class="row no-top-margin"/>

                        <div class="form-group">
                            <button class="btn btn-primary mr-3" type="submit"
                                :disabled="model.$invalid"
                                :class="{ 'btn-secondary' : model.$invalid }">
                                Submit
                            </button>
                        </div>

                    </div>

                </div>

            </form>

        </template>

    </modal>

</template>


<script>

    import { defineComponent, ref } from "vue";
    import { useStore } from 'vuex';
    import { useVuelidate } from "@vuelidate/core";
    import { required, email } from "@vuelidate/validators";

    const module = 'users';

    export default defineComponent({

        name: "ChangeUserPassword",

        props: {
            userData: {
                type: Object,
                required: true,
            },
            userType: {
                type: String,
                required: false,
            },
            showModal: {
                type: Boolean,
                default: true,
            },
            togglePopup: {
                type: Function,
                default: () => {},
            },
        },

        components: {},

        setup(props, { emit }) {

            const changePasswordData = {
                password: "",
                password_confirmation: "",
                change_password_next_login: true,
                user_id: props.userData.user_id,
            };

            const store = useStore();
            const userData = ref(props.userData);
            const showModal = ref(props.showModal);
            const userType = ref(props.userType);
            const togglePopup = ref(props.togglePopup);

            let changePasswordForm = ref(changePasswordData);

            // set validation rules
            const rules = {
                user_id: { },
                password: { required },
                password_confirmation: { required },
                change_password_next_login: { },
            };

            // validate form
            const model = useVuelidate(rules, changePasswordForm);

            function checkMustChangePasswordNextLogin(event) {
                if (event.target.checked) {
                    changePasswordForm.value.change_password_next_login = true;
                } else {
                    changePasswordForm.value.change_password_next_login = false;
                }
            }

            async function onSubmitted(){
                await store.dispatch(`${module}/changeUserPassword`, changePasswordForm.value)
                    .then(res => {
                    emit('togglePopup', togglePopup);
                });
            }

            return {
                onSubmitted,
                // userData,
                // userType,
                // togglePopup,
                model,
                checkMustChangePasswordNextLogin,
            };

        },

    });

</script>


<style>

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

    .modal-fade-enter, .modal-fade-leave-to  {
        opacity: 0;
    }
    .modal-fade-enter-active {
        transition: all 0.8s ease-out;
    }
    .modal-fade-enter-active {
        transition: all 0.8s ease-in;
    }




    /* -- zoom -- */
    /* @-webkit-keyframes modal-zoom-enter {
        from {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    @keyframes modal-zoom-enter {
        from {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    .modal-zoom-enter-active {
        -webkit-animation: modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
        animation: modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
    }

    @-webkit-keyframes modal-zoom-leave {
        to {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    @keyframes modal-zoom-leave {
        to {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    .modal-zoom-leave-active {
        -webkit-animation: modal-zoom-leave both;
        animation: modal-zoom-leave both;
    } */

</style>

