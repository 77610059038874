<template>
  <div class="container-fluid no-gutters px-0">
    <div class="col-lg-12 mt-3xx mb-2">
      <search-bar :bread-crumb-only="true"></search-bar>

      <div class="card">
        <div class="card-body">
          <form @submit.prevent="onSubmitted">
            <div class="row">
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6 section-title section-title-1">Send Email</div>
                  <div class="col-lg-6 section-title text-right">
                    <!-- Email Bal: <span style="color:green;">20,000</span> -->
                  </div>
                </div>

                <hr />

                <div class="form-group no-bottom">
                  <!-- <label for="message">Enter Message</label> -->
                  <textarea
                    class="form-control"
                    rows="5"
                    id="message"
                    placeholder="Enter Message"
                    v-model="model.message.$model"
                    @input="onInputMessage"
                  ></textarea>
                </div>

                <div class="form-group">
                  <div class="col-sm-12s">
                    <!-- <span class="float-left">
                                            <strong id="remaining">{{ remaining_chars ? remaining_chars : 160 }}</strong> characters remaining
                                        </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span class="float-right">
                                            <strong id="messages">{{ num_messages ? num_messages : 1 }}</strong> message(s)
                                        </span>
                                        <div class="clearfix"></div> -->
                  </div>
                </div>

                <div
                  class="form-group mb-0 rowz"
                  style="
                    background-color: rgba(37, 168, 224, 0.07);
                    border-radius: 5px;
                    padding: 10px;
                  "
                >
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-check-inline my-1">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="now"
                            name="send_time"
                            value="now"
                            v-model="model.send_time.$model"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="now">Send Now</label>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 text-right">
                      <div class="form-check-inline my-1">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="schedule"
                            name="send_time"
                            value="schedule"
                            v-model="model.send_time.$model"
                            class="custom-control-input"
                            @change="showScheduleEmailModal()"
                            @click="showScheduleEmailModal()"
                          />
                          <label class="custom-control-label float-right" for="schedule"
                            >Schedule Email</label
                          >
                        </div>
                      </div>

                      <div
                        style="color: green; font-size: 0.7rem"
                        v-show="model.send_time.$model == 'schedule'"
                      >
                        <v-date-picker
                          v-model="model.schedule_time.$model"
                          mode="dateTime"
                          :attributes="attrs"
                          is12hr
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <input
                              class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 text-right"
                              :value="inputValue"
                              v-on="inputEvents"
                              readonly
                            />
                          </template>
                        </v-date-picker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-7 section-title section-title-1">
                    Select Users To Send Email To
                  </div>
                  <div class="col-lg-5 section-title text-right">
                    Count:
                    <span style="color: blue">{{ selected_customers.length }}</span>
                  </div>
                </div>

                <hr />

                <div
                  class="form-group"
                  :class="{ 'has-error': model.search_terms.$invalid }"
                >
                  <input
                    class="form-control"
                    :class="{ 'form-control-danger': model.search_terms.$invalid }"
                    type="text"
                    placeholder="Enter email or name to search"
                    v-model="model.search_terms.$model"
                    @input="onEmailUsersSearch"
                  />
                </div>

                <div class="sms-users-height" data-simplebar>
                  <div class="activity">
                    <div class="activity-info">
                      <div class="table-responsive">
                        <table class="table mb-0">
                          <!-- <thead class="thead-light">
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Phone</th>
                                                        </tr>
                                                    </thead> -->
                          <tbody>
                            <!-- <td>

                                                            <div class="checkbox checkbox-info">
                                                                <input type="checkbox"
                                                                    name="selectAll"
                                                                    @change="customerSelectedToggle"
                                                                    :value="item.customer_id"
                                                                    v-model="model.customers.$model"
                                                                    :id="item.customer_id">
                                                                <label :for="item.customer_id">{{ item.full_name }}</label>
                                                            </div>

                                                        </td>

                                                        <td>{{ item.phone }}</td> -->

                            <tr v-for="(item, index) in customers.data" :key="index">
                              <td>
                                <div class="checkbox checkbox-info">
                                  <input
                                    type="checkbox"
                                    name="customers"
                                    @change="customerSelectedToggle"
                                    :value="item.customer_id"
                                    v-model="model.customers.$model"
                                    :id="item.customer_id"
                                  />
                                  <label :for="item.customer_id">{{
                                    item.full_name
                                  }}</label>
                                </div>
                              </td>

                              <td>{{ item?.email }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-lg-6">
                <div class="form-group rowz">
                  <div class="checkbox my-2">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="attach_content"
                        data-parsley-mincheck="2"
                        v-model="model.attach_content.$model"
                      />
                      <label class="custom-control-label" for="attach_content"
                        >Attach Dynamic Message Content</label
                      >
                    </div>
                  </div>
                </div>

                <div class="form-group" v-if="model.attach_content.$model">
                  <div class="float-right">
                    <a @click="showHowToUseModal()" class="custom-link">How To Use</a>
                  </div>
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="validatedCustomFile"
                      required=""
                    />
                    <label class="custom-file-label" for="validatedCustomFile"
                      >Choose file...</label
                    >
                    <div class="invalid-feedback">
                      Example invalid custom file feedback
                    </div>
                  </div>

                  <!-- <div class="col-sm-12">
                                        <div class="fileinput fileinput-new input-group" data-provides="fileinput">
                                            <div class="form-control" data-trigger="fileinput">
                                            <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                            <span class="fileinput-filename"></span>
                                            </div>
                                            <span class="input-group-addon fileupload btn btn-info btn-anim btn-file">
                                            <i class="fa fa-upload"></i>
                                            <span class="fileinput-new btn-text">Select file</span>
                                            <span class="fileinput-exists btn-text">Change</span>
                                            <input type="file" name="import_file" ref="import_file"
                                                @change="handleFileUpload()">
                                            </span>
                                            <a href="#" class="input-group-addon btn btn-danger btn-anim fileinput-exists" data-dismiss="fileinput">
                                            <i class="fa fa-trash"></i>
                                            <span class="btn-text"> Remove</span>
                                            </a>
                                        </div>
                                    </div> -->
                </div>
              </div>

              <div class="col-lg-6">
                <!-- <div class="row">
                                    <div class="col-sm-12 text-right"><button type="submit"
                                        class="btn-lg btn-primary px-4">Send SMS</button></div>
                                </div> -->

                <!-- <div class="form-group no-bottom">
                                    <label for="message">SMS Preview</label>
                                    <textarea class="form-control" rows="4" id="message" v-model="model.message.$model" readonly></textarea>

                                </div> -->
              </div>
            </div>

            <hr />

            <!-- show maker checker form buttons/ status -->
            <maker-checker-button-component
                :makerChecker="makerChecker"
                v-if="isMakerChecker"
              />

            <div class="row" v-else>
              <div class="col-lg-6">
                <!-- <div class="row">
                                    <div class="col-sm-12 text-right">
                                        <button type="submit" class="btn btn-block btn-lg btn-outline-secondary px-4">
                                        Preview Email</button></div>
                                </div> -->
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 text-right">
                    <button type="submit" class="btn-lg btn-block btn-primary px-4">
                      Send Email
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- How To Use Modal -->
  <modal v-show="isHowToUseModalVisible" @close="closeHowToUseModal">
    <template v-slot:header>
      <h5 class="modal-title" id="myModalLabel">Hot To Attach Dynamic Message Content</h5>
    </template>

    <template v-slot:body>
      <p class="mb-15">
        1. Load <strong>csv</strong> file which has the data you want to use by selecting
        it in the "Attach Dynamic Message Content" box.
      </p>
      <p class="mb-15">
        2. The first line in the excel file should have the column titles. An example file
        can be downloaded here: &nbsp;
        <a href="http://snb.co.ke/public/templates/sms_template.csv">
          <i class="fa fa-file-excel-o text-success"></i>
          Sample Excel CSV File
        </a>
      </p>
      <p class="mb-15">
        3. The column that contains email address has to be titled <strong>email</strong>
        for the phone numbers to be detected correctly.
      </p>
      <p class="mb-15">
        4. Once file is loaded, you can attach the data by referencing the column names.
        <br />
        For example, if your excel file has two columns titled:
        "<strong>first_name</strong>" and "<strong>bill_balance</strong>", you can refer
        to them in your sms message as
        <br />"<strong>[[first_name]]</strong>" &nbsp;and&nbsp;
        "<strong>[[bill_balance]]</strong>"<br />
        without spaces in between the words and [[]]markers.
        <br /><br />
        An example sms message would be:
        <br /><br />
        Dear [[first_name]],
        <br />
        Please complete your bill payment of Kshs. [[bill_balance]] by 20th Dec
        {{ new Date().getFullYear() }}...
      </p>
      <p>
        5. <strong>[[first_name]]</strong> &nbsp; and &nbsp;
        <strong>[[bill_balance]]</strong> fields will then be replaced individually for
        each unique line of data in your excel file.
      </p>
    </template>
  </modal>
</template>

<script>
import { defineComponent, computed, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, url } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import { DatePicker } from '../../../Common/MyDatePicker.vue';

const emailForm = {
  sms_method_id: "",
  customer_id: "",
  customers: [],
  search_terms: "",
  send_time: "now",
  schedule_time: new Date(),
  message: "",
  attach_content: "",
  comments: "",
  company_id: "",
  trans_time: "",
  sms_id: undefined,
};

const module = "sms";
const company_module = "companies";
const customer_module = "customers";

export default defineComponent({
  name: "EmailDetail",

  // components: { DatePicker },

  /* props: {
			isEdit: {
				type: Boolean,
				required: true,
				default: false,
			}
		}, */

  props: {
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    authUser: {
      required: false,
    },
    isMakerChecker: {
      type: Boolean,
      required: false,
      default: false,
    },
    makerChecker: {
      type: Object,
      required: false,
    },
  },

  emits: ["submitForm", "cancelForm"],

  setup(props, { emit }) {

    // avoid destructuring props directly in the root scope of setup
    const emailProp = toRefs(props);

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    let email = ref(emailForm);
    const isScheduleEmailModalVisible = ref(false);
    const isHowToUseModalVisible = ref(false);
    const search_terms = ref(null);
    const remaining_chars = ref(null);
    const num_messages = ref(null);
    const selected_customers = ref([]);

    // load all companies on create component
    let payload = { report: "1" };
    store.dispatch(`${company_module}/fetchCompanies`, payload);

    // load customers
    store.dispatch(`${customer_module}/fetchCustomers`);

    // on created. Are we in edit mode?
    if (props.isEdit || props.isShow) {
      email = computed(() => store.getters[`${module}/email`]);
    }

    // if we are in maker checker, use maker checker data
    if (emailProp.isMakerChecker.value) {
      email = emailProp.makerChecker.value?.data;
    }

    const rules = {
      sms_method_id: { required },
      customer_id: {},
      customers: {},
      search_terms: {},
      send_time: {},
      schedule_time: {},
      message: {},
      attach_content: {},
      comments: {},
      company_id: { required },
      trans_time: {},
      sms_id: {},
    };

    function onInputMessage(event) {
      let chars = event.target.value.length;
      // console.log(event.target.value);
      let messages = Math.ceil(chars / 160);
      let remaining = messages * 160 - (chars % (messages * 160) || messages * 160);
      remaining_chars.value = remaining;
      num_messages.value = messages;
    }

    // validate form
    const model = useVuelidate(rules, sms);

    function onSubmitted() {
      emit("submitForm", sms);
    }

    function onCancelled() {
      emit("cancelForm");
    }

    // close modals
    function closeScheduleEmailModal() {
      isScheduleEmailModalVisible.value = false;
    }

    // showScheduleEmailModal
    function showScheduleEmailModal() {
      isScheduleEmailModalVisible.value = true;
    }

    // close HowToUseModal
    function closeHowToUseModal() {
      isHowToUseModalVisible.value = false;
    }

    // showHowToUseModal
    function showHowToUseModal() {
      isHowToUseModalVisible.value = true;
    }

    const onEmailUsersSearch = _.debounce((searchTerms) => {
      search_terms.value = searchTerms.target.value;
      searchData();
    }, 500);

    function customerSelectedToggle(event) {
      let item = event.target.value;
      if (event.target.checked) {
        selected_customers.value.push(item);
      } else {
        let customer_index = selected_customers.value.indexOf(item);
        selected_customers.value.splice(customer_index, 1);
      }
    }

    // perform search
    function searchData() {
      payload = getPayload();
      // fetch data with page param
      store.dispatch(`${customer_module}/fetchCustomers`, payload);
    }

    // get search payload
    function getPayload() {
      payload = {
        search_terms: search_terms.value,
        report: "1",
      };
      return payload;
    }

    // get the data
    const companies = computed(() => store.getters[`${company_module}/companies`]);
    const customers = computed(() => store.getters[`${customer_module}/customers`]);

    return {
      submitForm: props.submitForm,
      companies,
      customers,
      model,
      // isEdit: props.isEdit,
      // email: computed(() => store.getters[`${module}/email`]),
      email,
      onSubmitted,
      onCancelled,
      isScheduleEmailModalVisible,
      isHowToUseModalVisible,
      showScheduleEmailModal,
      showHowToUseModal,
      closeScheduleEmailModal,
      closeHowToUseModal,
      onEmailUsersSearch,
      onInputMessage,
      num_messages,
      remaining_chars,
      customerSelectedToggle,
      selected_customers,

      date: new Date(),
      timezone: "",
      attrs: [
        /* {
                    key: 'today',
                    highlight: true,
                    dates: new Date(),
                    }, */
      ],
    };
  },
});
</script>

<style scoped>
.section-title-1 {
  font-weight: bold;
}
.section-title {
  font-size: 1rem;
}
</style>
