const depositcontractRoutes = [

    {

        path: '',
        name: 'depositcontractHome',
        component: () => import ('./ListDepositContracts.vue'),
        meta: {
            auth: true,
            title: 'Deposit Contract Listing',
            breadcrumb: 'Deposit Contract Listing',
            permission: ['view depositcontracts'] //'view collateral'
        }

    },

    {
        path: 'create',
        name: 'createdepositcontract',
        component: () => import('./CreateEditDepositContract.vue'),
        meta: {
            auth: true,
            title: 'Create Deposit Contract',
            breadcrumb: 'Create Deposit Contract',
            permission: ['create depositcontracts'] //'create collateral'
        }
    },

    {
        path: ':id/edit',
        name: 'editdepositcontract',
        component: () => import ('./CreateEditDepositContract.vue'),
        meta: {
            auth: true,
            title: 'Edit Deposit Contract',
            breadcrumb: 'Edit Deposit Contract',
            permission: ['update depositcontracts'] //'update collateral'
        }
    },

];


export default depositcontractRoutes;
