import axios from "axios";


export default {

    namespaced: true,

    state: {
        cashvault: {},
        cashvaultlist: [],
       
    },

    getters: {

        cashvault(state) {
            return state.cashvault;
        },
        cashvaultlist(state) {
           return state.cashvaultlist;
       },
       
    },

    actions: {
        fetchcashvaultlist({ commit }, params) {
            axios.get('/api/v1/cashvault', { params: params })
                .then(res => {
                    commit('FETCH_CASHVAULT_LIST', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

      
        async fetchcashvault({ commit }, id) {
            await axios.get('/api/v1/cashvault/' + id, )
                .then(res => {
                    commit('FETCH_CASHVAULT', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async createcashvault({ commit, dispatch }, payload) {
            await axios.post('/api/v1/cashvault', payload)
                .then(res => {
         
                }).catch(err => {
                    return Promise.reject(err);
                })

        },


        async transferfromtobank({ commit, dispatch }, payload) {
            await axios.post('/api/v1/cashvault/transferfromtobank', payload )
            .then(res => {
                
            }).catch(err => {
                return Promise.reject(err);
            })

        },
     
  
        async vaulttransfer({ commit, dispatch }, payload) {
            await axios.put('/api/v1/cashvault/vaulttransfer/'+payload.cash_vault_id, payload )
            .then(res => {
                
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async reconcile({ commit, dispatch }, payload) {
            await axios.post('/api/v1/cashvault/reconcile',payload)
            .then(res => {
            }).catch(err => {
                return Promise.reject(err);
            })

        },

       
  
    },

    mutations: {
        FETCH_CASHVAULT(state, data) {
            state.cashvault = data;
        },
        FETCH_CASHVAULT_LIST(state, data) {
            state.cashvaultlist = data;
        },

       
      
    }

}