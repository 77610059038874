export default {

    namespaced: true,

    state: {
        userdata: null,
        loggedInUser:null
    },

    getters: {
        getUserDetails(state) {
            return state.userdata;
        },
        getLoggedInUserDetails(state) {
            return state.loggedInUser;
        },
    },

    actions: {
        setUserDetails({ commit }, data) {
            commit("SET_USER", data);
        },
        setLoggedInUserDetails({ commit }, data) {
            commit("SET_LOGGED_IN_USER", data);
        }
    },

    mutations: {
        SET_USER: (state, data) => {
            state.userdata = data;
        },
        SET_LOGGED_IN_USER: (state, data) => {
            state.loggedInUser = data;
        },
    }

}
