import axios from "axios";

export default {

    namespaced: true,

    state: {

        loans: [],
        loan: {},
        loanbalance: {}

    },

    getters: {

        loans(state) {
            return state.loans;
        },
        loan(state) {
            return state.loan;
        },
        loanbalance(state) {
            return state.loanbalance;
        }

    },

    mutations: {

        SET_LOANS(state, loans) {
            return state.loans = loans
        },

        SET_LOAN(state, loan) {
            return state.loan = loan
        },

        SET_LOAN_BALANCE(state, loanbalance) {
            return state.loanbalance = loanbalance
        }

    },

    actions: {

        async fetchCustomerLoans({ commit }, params) {

            await axios.get('/api/v1/customerdata/loans', { params: params })
                .then(res => {
                    commit('SET_LOANS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchLoans({ commit }, params) {

            await axios.get('/api/v1/loansdata', { params: params })
                .then(res => {
                    commit('SET_LOANS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchLoanBalance({ commit }) {

            await axios.get('/api/v1/loansdata/loanbalances')
                .then(res => {
                    commit('SET_LOAN_BALANCE', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchLoan({ commit }, loan_id) {

            await axios.get(`/api/v1/loansdata/${loan_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_LOAN', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createLoan({ commit, dispatch }, loan) {

            await axios.post('/api/v1/loansdata', loan)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchLoans');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateLoan({ commit, dispatch }, loan) {

            await axios("/api/v1/loansdata/" + loan.customer_id, {
                method: "patch",
                data: loan,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchLoans');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteLoan({ commit }, loan_id) {

            await axios.delete(`/api/v1/loansdata/${loan_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchLoans');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
