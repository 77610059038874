import axios from "axios";

export default {

    namespaced: true,

    state: {

        productcategories: [],
        productcategory: {}

    },

    getters: {

        productcategories(state) {
            return state.productcategories;
        },
        productcategory(state) {
            return state.productcategory;
        }

    },

    mutations: {

        SET_PRODUCT_CATEGORIES(state, productcategories) {
            return state.productcategories = productcategories
        },

        SET_PRODUCT_CATEGORY(state, productcategory) {
            return state.productcategory = productcategory
        }

    },

    actions: {

        async fetchproductcategories({ commit }, params) {

            await axios.get('/api/v1/product-categories', { params: params })
                .then(res => {
                    commit('SET_PRODUCT_CATEGORIES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchproductcategory({ commit }, product_category_id) {

            await axios.get(`/api/v1/product-categories/${product_category_id}`)
                .then(res => {
                    // console.log("fetchProduct store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_PRODUCT_CATEGORY', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createproductcategory({ commit, dispatch }, productcategory) {

            // console.log("product createProduct ", product);
            await axios.post('/api/v1/product-categories', productcategory)
                .then(res => {
                    dispatch('fetchproductcategories');
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateproductcategory({ commit, dispatch }, productcategory) {

            await axios("/api/v1/product-categories/" + productcategory.product_category_id, {
                method: "patch",
                data: productcategory,
            }).then(res => {
                dispatch('fetchproductcategories');
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteproductcategory({ commit }, product_category_id) {

            await axios.delete(`/api/v1/product-categories/${product_category_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchproductcategories');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
