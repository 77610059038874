const receivableFinanceRoutes = [

    {
        path: 'sales-invoice',
        name: 'createSInvoice',
        component: () => import('./SalesInvoice.vue'),
        meta: {
            auth: true,
            title: 'Create Sales Invoice',
            breadcrumb: 'Create Sales Invoice',
            permission: ['view salesinvoice', 'create salesinvoice', 'update salesinvoice', 'delete salesinvoice']
        }
    },

    {
        path: 'other-receipts',
        name: 'otherReceipts',
        component: () => import('./OtherReceipts.vue'),
        meta: {
            auth: true,
            title: 'Other Receipts',
            breadcrumb: 'Other Receipts',
            permission: ['view otherreceipt', 'create otherreceipt', 'update otherreceipt', 'delete otherreceipt']
        }
    },

    {
        path: 'customers',
        name: 'customerListings',
        component: () => import('./CustomerList.vue'),
        meta: {
            auth: true,
            title: 'Customers',
            breadcrumb: 'Customers',
            permission: ['view customerreceivable', 'create customerreceivable', 'update customerreceivable', 'delete customerreceivable']
        }
    },

    {
        path: 'statements/customer/:id',
        name: 'customerStatements',
        component: () => import('./CustomerStatements.vue'),
        meta: {
            auth: true,
            title: 'Customer Statements',
            breadcrumb: 'Customer Statements',
            permission: ['view customerstatement']
            // permission: ['view customerreceivable', 'create customerreceivable', 'update customerreceivable', 'delete customerreceivable']
        }
    },

    {
        path: 'receive-payments',
        name: 'customerReceipts',
        component: () => import('./CustomerReceipt.vue'),
        meta: {
            auth: true,
            title: 'Customer Receipts',
            breadcrumb: 'Customer Receipts',
            // permission: ['view customerreceivable', 'create customerreceivable', 'update customerreceivable', 'delete customerreceivable']
            permission: ['view customerreceivable', 'view customerreceits']
        }
    },

    {
        path: 'customer-adjustments',
        name: 'customerAdjustments',
        component: () => import('./CustomerAdjustment.vue'),
        meta: {
            auth: true,
            title: ' Customer Adjustments',
            breadcrumb: ' Customer Adjustments',
            permission: ['view customeradjustment', 'create customeradjustment', 'update customeradjustment', 'delete customeradjustment']
        }
    },

];

export default receivableFinanceRoutes;
