const makerCheckerSectionAdministrationRoutes = [

    {

        name: 'makerCheckerSectionHome',
        path: '',
        component: () => import('./ListMakerCheckerSections.vue'),
        meta: {
            auth: true,
            title: 'Maker Checker Sections Listing',
            breadcrumb: 'Maker Checker Sections Listing',
            permission: ['view makercheckersection']
        }

    },

    {
        name: 'createMakerCheckerSection',
        path: 'create',
        component: () => import('./CreateMakerCheckerSection.vue'),
        meta: {
            auth: true,
            title: 'Create Maker Checker Section',
            breadcrumb: 'Create Maker Checker Section',
            permission: ['create makercheckersection']
        }
    },

    {
        path: ':id',
        name: 'showMakerCheckerSection',
        component: () => import('./ShowMakerCheckerSection.vue'),
        meta: {
            auth: true,
            title: 'Showing Maker Checker Section',
            breadcrumb: 'Showing Maker Checker Section',
            permission: ['view makercheckersection']
        }
    },

    {
        path: ':id/edit',
        name: 'editMakerCheckerSection',
        component: () => import('./EditMakerCheckerSection.vue'),
        meta: {
            auth: true,
            title: 'Edit Maker Checker Section',
            breadcrumb: 'Edit Maker Checker Section',
            permission: ['update makercheckersection']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteMakerCheckerSection',
        meta: {
            auth: true,
            title: 'Delete Maker Checker Section',
            permission: ['delete makercheckersection']
        }
    },

];

export default makerCheckerSectionAdministrationRoutes;
