<template>
  <ol class="breadcrumb">
    <li
      class="breadcrumb-item"
      v-for="(item, index) in levelList"
      :key="item.path"
    >
      <template
        v-if="item.redirect === 'noRedirect' || index == levelList.length - 1"
      >
        <span class="no-redirect"> {{ item.meta.title }} </span>
      </template>

      <template v-else>
        <router-link :to="item.path">{{ item.meta.title }}</router-link>
      </template>
    </li>
  </ol>
</template>

<script>
import { pathToRegexp } from "path-to-regexp";

export default {
  name: "Breadcrumb",

  data() {
    return {
      levelList: null,
    };
  },

  computed: {
    crumbs() {
      let p = this.$route.path.split("/");
      p.shift();
      const t = this;
      let b = p.reduce(function (c, path, i) {
        c.push({
          path: path,
          to: c[i - 1] ? "/" + c[i - 1].path + "/" + path : "/" + path,
          text:
            t.$route.matched[i]?.meta?.breadCrumb ||
            t.$route.matched[i]?.meta?.title ||
            t.$route.matched[i].name,
        });

        return c;
      }, []);
      return b;
    },
  },

  watch: {
    $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getBreadcrumb();
    },
  },

  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );

      this.levelList = matched.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
    },

    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return (
        name.trim().toLocaleLowerCase() === "Dashboard".toLocaleLowerCase()
      );
    },

    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      const { params } = this.$route;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(this.pathCompile(path));
    },
  },
};
</script>

<style scoped>
.breadcrumb a {
  color: #177DC2 !important;
}

.breadcrumb a:hover {
  cursor: pointer !important;
  color: #e42129 !important;
}
.breadcrumb-height {
  min-height: 60px;
}
</style>
