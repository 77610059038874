<template>

    <div class="card">

        <!-- customerData:: {{ customerData }} -->

        <div class="card-header">
            <div class="row align-items-center">
                <div class="col">
                    <h4 class="card-title">Personal Information</h4>
                </div>
            </div>
        </div>

        <div class="card-body">

            <form @submit.prevent="onSubmitted">

                <div class="form-group row" :class="{ 'has-error' : model.first_name.$invalid }">
                    <label class="col-xl-4 col-lg-5 col-form-label">First Name</label>
                    <div class="col-lg-7 col-xl-8">
                        <input class="form-control" :class="{ 'form-control-danger' : model.first_name.$invalid }"
                                    type="text" disabled v-model="model.first_name.$model">
                        <error-msg :property="model.first_name" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.last_name.$invalid }">
                    <label class="col-xl-4 col-lg-5 col-form-label">Last Name</label>
                    <div class="col-lg-7 col-xl-8">
                        <input class="form-control" :class="{ 'form-control-danger' : model.last_name.$invalid }"
                                    type="text" disabled v-model="model.last_name.$model">
                        <error-msg :property="model.last_name" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.phone.$invalid }">
                    <label for="phone" class="col-xl-4 col-lg-5 col-form-label">
                        Phone
                    </label>
                    <div class="col-lg-7 col-xl-8">
                        <div class="row">
                            <span class="col-sm-5">
                                <select class="form-control" disabled v-model="model.phone_country.$model" v-if="countries.data">
                                    <option v-for="(item, index) in countries.data" :key="index" :value="item.iso2">
                                        {{ item.iso2 }} ({{ item.phonecode }})
                                    </option>
                                </select>
                            </span>
                            <span class="col-sm-7">
                                <input class="form-control" :class="{ 'form-control-danger' : model.phone.$invalid }" type="text"
                                    disabled v-model="model.phone.$model"/>
                            </span>
                            <error-msg :property="model.phone" />
                        </div>
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.email.$invalid }">
                    <label class="col-xl-4 col-lg-5 col-form-label">Email</label>
                    <div class="col-lg-7 col-xl-8">
                        <input class="form-control" :class="{ 'form-control-danger' : model.email.$invalid }"
                                    type="text" disabled v-model="model.email.$model">
                        <error-msg :property="model.email" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.id_no.$invalid }">
                    <label class="col-xl-4 col-lg-5 col-form-label">ID No</label>
                    <div class="col-lg-7 col-xl-8">
                        <input class="form-control" :class="{ 'form-control-danger' : model.id_no.$invalid }"
                                    type="text" disabled v-model="model.id_no.$model">
                        <error-msg :property="model.id_no" />
                    </div>
                </div>

                <div class="form-group row">
                    <label for="gender" class="col-xl-4 col-lg-5 col-form-label">Gender</label>
                    <div class="col-lg-7 col-xl-8">
                        <div class="form-check-inline my-1">
                            <div class="custom-control custom-radio">
                                <input type="radio" id="male" name="gender" disabled v-model="model.gender.$model"
                                    value="Male" class="custom-control-input"/>
                                <label class="custom-control-label" for="male">Male</label>
                            </div>
                        </div>
                        <div class="form-check-inline my-1">
                            <div class="custom-control custom-radio">
                                <input type="radio" id="female" name="gender" disabled v-model="model.gender.$model"
                                    value="Female" class="custom-control-input"/>
                                <label class="custom-control-label" for="female">Female</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.date_of_birth.$invalid }">
                    <label for="date_of_birth" class="col-xl-4 col-lg-5 col-form-label">Date of Birth</label>
                    <div class="col-lg-7 col-xl-8">
                        <my-date-picker :mode="'date'" :max-date="new Date()"
                                :styles="{ 'form-control-danger' : model.date_of_birth.$invalid }"
                                type="date" :isDisabled="true" v-model="model.date_of_birth.$model">
                        </my-date-picker>
                        <error-msg :property="model.date_of_birth" />

                    </div>
                </div>

                <hr>

                <div class="form-group row" :class="{ 'has-error' : model.alternative_phone.$invalid }">
                    <label for="alternative_phone" class="col-xl-4 col-lg-5 col-form-label">
                        Alternative Phone
                    </label>
                    <div class="col-lg-7 col-xl-8">
                        <div class="row">
                            <span class="col-sm-5">
                                <select class="form-control" :disabled="isShow" v-model="model.alternative_phone_country.$model" v-if="countries.data">
                                    <option v-for="(item, index) in countries.data" :key="index" :value="item.iso2">
                                        {{ item.iso2 }} ({{ item.phonecode }})
                                    </option>
                                </select>
                            </span>
                            <span class="col-sm-7">
                                <input class="form-control" :class="{ 'form-control-danger' : model.alternative_phone.$invalid }" type="text"
                                    :disabled="isShow" v-model="model.alternative_phone.$model"/>
                            </span>
                            <error-msg :property="model.alternative_phone" />
                        </div>
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.marital_status_id.$invalid }">
                    <label for="marital_status_id" class="col-xl-4 col-lg-5 col-form-label">
                        Marital Status
                    </label>
                    <div class="col-lg-7 col-xl-8">
                        <select class="form-control" :disabled="isShow" v-model="model.marital_status_id.$model" v-if="maritalstatuses.data">
                            <option v-for="(item, index) in maritalstatuses.data" :key="index" :value="item.marital_status_id">
                                {{ item.name }}
                            </option>
                        </select>
                        <error-msg :property="model.marital_status_id" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.religion.$invalid }">
                    <label for="religion" class="col-xl-4 col-lg-5 col-form-label">Religion</label>
                    <div class="col-lg-7 col-xl-8">
                        <select class="form-control" :disabled="isShow" v-model="model.religion.$model">
                            <option value="christian">Christian</option>
                            <option value="muslim">Muslim</option>
                            <option value="hindu">Hindu</option>
                            <option value="other">Other</option>
                        </select>
                        <error-msg :property="model.religion" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.source_of_income.$invalid }">
                    <label class="col-xl-4 col-lg-5 col-form-label">Source of Income</label>
                    <div class="col-lg-7 col-xl-8">
                        <input class="form-control" :class="{ 'form-control-danger' : model.source_of_income.$invalid }"
                                    type="text" :disabled="isShow" v-model="model.source_of_income.$model">
                        <error-msg :property="model.source_of_income" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.monthly_target_savings.$invalid }">
                    <label class="col-xl-4 col-lg-5 col-form-label">Monthly Target Savings</label>
                    <div class="col-lg-7 col-xl-8">
                        <input class="form-control" :class="{ 'form-control-danger' : model.monthly_target_savings.$invalid }"
                                    type="text" :disabled="isShow" v-model="model.monthly_target_savings.$model">
                        <error-msg :property="model.monthly_target_savings" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.monthly_target_savings_date.$invalid }">
                    <label class="col-xl-4 col-lg-5 col-form-label">Monthly Target Savings Date</label>
                    <div class="col-lg-7 col-xl-8">
                        <input class="form-control" :class="{ 'form-control-danger' : model.monthly_target_savings_date.$invalid }"
                                    type="text" :disabled="isShow" v-model="model.monthly_target_savings_date.$model">
                        <error-msg :property="model.monthly_target_savings_date" />
                    </div>
                </div>

                <div class="card-footer">

                    <button type="submit" class="btn btn-primary btn-sm"
                        @click.prevent="toggleShowPersonalInfo()" v-if="isShow">
                        Edit Personal Info
                    </button>

                    <button class="btn btn-primary btn-sm mr-3" type="submit" :disabled="model.$invalid"
                        :class="{ 'btn-secondary' : model.$invalid }" v-if="isEdit">
                        Submit
                    </button>

                    <button class="btn btn-danger btn-sm"
                        @click.prevent="toggleShowPersonalInfo()" v-if="!isShow">
                        Cancel
                    </button>

                </div>

            </form>

        </div>

    </div>

</template>


<script>

import { defineComponent, computed, ref } from "vue";
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useVuelidate } from "@vuelidate/core";
import { required, email, maxLength, minLength, } from "@vuelidate/validators";

import { numberFormatter, formatDate } from "../../../filters";

import _ from "lodash";

const customerPersonalInfoForm = {
    first_name: "",
    last_name: "",
    user_type: "CUSTOMER",
    phone: "",
    phone_country: "KE",
    alternative_phone: "",
    alternative_phone_country: "KE",
    email: "",
    id_no: "",
    marital_status_id: "1",
    gender: "Male",
    religion: "christian",
    date_of_birth: "1992-08-09",
    is_group: 0,
    monthly_target_savings: undefined,
    monthly_target_savings_date: "",
    source_of_income: "",
};

const customer_data_module = 'customerdata';
const country_module = 'countries';
const statuses_module = "statuses";
const maritalstatuses_module = "maritalstatuses";

export default defineComponent({

    name: "CustomerPersonalInfo",

    props: {
			customerData: {
				type: Object,
				required: false,
			},
            authUser: {
				required: false,
			},
		},

    emits: ["submitForm", "cancelForm"],

    setup(props) {

            const store = useStore();

            const isShow = ref(true);
            const isEdit = ref(false);

            let customerPersonalInfo = ref(customerPersonalInfoForm);

            if (props.customerData) {
                customerPersonalInfo = ref(props.customerData);
            }

            const rules = {
                first_name: { required },
                last_name: { required },
                user_type: { },
                phone: { },
                phone_country: { },
                alternative_phone: { },
                alternative_phone_country: { },
                email: { email },
                id_no: { required },
                marital_status_id: { },
                gender: { required },
                religion: { },
                date_of_birth: { required },
                is_group: { required },
                monthly_target_savings: { },
                monthly_target_savings_date: {
                    maxLength: maxLength(2),
                    minLength: minLength(2),
                },
                source_of_income: { },
            };

            // validate form
            const model = useVuelidate(rules, customerPersonalInfo);

            let payload = { 'report': '1' };

            // load countries
            store.dispatch(`${country_module}/fetchCountries`, payload);

            // load marital statuses
            store.dispatch(`${maritalstatuses_module}/fetchMaritalStatuses`, payload);

            // toggleShowPersonalInfo
            function toggleShowPersonalInfo() {
                isShow.value = !isShow.value;
                isEdit.value = !isEdit.value;
            }

            async function onSubmitted() {
                await store.dispatch(`${customer_data_module}/updateCustomerData`, customerPersonalInfo)
                .then(res => {
                    isShow.value = !isShow.value;
                    isEdit.value = !isEdit.value;
                })
                .catch(err => {
                    return Promise.reject(err);
                });
			}

            return {
                countries: computed(() => store.getters[`${country_module}/countries`]),
                statuses: computed(() => store.getters[`${statuses_module}/statuses`]),
                maritalstatuses: computed(() => store.getters[`${maritalstatuses_module}/maritalstatuses`]),
                model,
                // customerData: props.customerData,
                // customerData: computed(() => store.getters[`${customer_data_module}/customer`]),
                isShow,
                isEdit,
                toggleShowPersonalInfo,
                numberFormatter,
                formatDate,
                onSubmitted
            };

    },

});


</script>

