import axios from "axios";

export default {

    namespaced: true,

    state: {

        loanexposurelimits: [],
        loanexposurelimit: {}

    },

    getters: {

        loanexposurelimits(state) {
            return state.loanexposurelimits;
        },
        loanexposurelimit(state) {
            return state.loanexposurelimit;
        }

    },

    mutations: {

        SET_LOAN_EXPOSURE_LIMITS(state, loanexposurelimits) {
            return state.loanexposurelimits = loanexposurelimits
        },

        SET_LOAN_EXPOSURE_LIMIT(state, loanexposurelimit) {
            return state.loanexposurelimit = loanexposurelimit
        }

    },

    actions: {

        async fetchLoanExposureLimits({ commit }, params) {

            await axios.get('/api/v1/loan-exposure-limits', { params: params })
                .then(res => {
                    // console.log("res == ", res.data);
                    commit('SET_LOAN_EXPOSURE_LIMITS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchLoanExposureLimit({ commit }, loanexposurelimit_id) {

            await axios.get(`/api/v1/loan-exposure-limits/${loanexposurelimit_id}`)
                .then(res => {
                    // console.log("fetchLoanExposureLimit store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_LOAN_EXPOSURE_LIMIT', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createLoanExposureLimit({ commit, dispatch }, loanexposurelimit) {

            console.log("loanexposurelimit createLoanExposureLimit ", loanexposurelimit);
            await axios.post('/api/v1/loan-exposure-limits', loanexposurelimit)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchLoanExposureLimits');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateLoanExposureLimit({ commit, dispatch }, loanexposurelimit) {

            await axios("/api/v1/loan-exposure-limits/" + loanexposurelimit.loan_exposure_limit_id, {
                method: "patch",
                data: loanexposurelimit,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchLoanExposureLimits');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteLoanExposureLimit({ commit }, loanexposurelimit_id) {

            await axios.delete(`/api/v1/loan-exposure-limits/${loanexposurelimit_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchLoanExposureLimits');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
