const pendingMessagingRoutes = [
	{
		name: "pendingMessagingHome",
		path: "",
		component: () => import("./ListPendingMessaging.vue"),
		meta: {
			auth: true,
			title: "Pending Items Listing",
			breadcrumb: "Pending Items Listing",
			permission: ["view makerchecker"],
		},
	},

	{
		path: ":id",
		name: "showPendingMessaging",
		component: () => import("./ShowPendingMessaging.vue"),
		meta: {
			auth: true,
			title: "Showing Pending Item",
			breadcrumb: "Showing Pending Item",
			permission: ["view makerchecker"],
		},
	},
];

export default pendingMessagingRoutes;
