const configurationRoutes = [

    {
        path: '',
        name: 'configurationHome',
        component: () => import('./CreateConfiguration.vue'),
        meta: {
            auth: true,
            title: 'Configuration',
            breadcrumb: 'Configuration',
            permission: ['create configuration']
        }
    },

];

export default configurationRoutes;
