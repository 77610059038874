import axios from "axios";

export default {

    namespaced: true,

    state: {

        depositcharges: [],
        depositcharge: {}

    },

    getters: {

        depositcharges(state) {
            return state.depositcharges;
        },
        depositcharge(state) {
            return state.depositcharge;
        }

    },

    mutations: {

        SET_DEPOSIT_CHARGES(state, depositcharges) {
            return state.depositcharges = depositcharges
        },

        SET_DEPOSIT_CHARGE(state, depositcharge) {
            return state.depositcharge = depositcharge
        }

    },

    actions: {

        async fetchdepositcharges({ commit }, params) {

            await axios.get('/api/v1/deposit_charges', { params: params })
                .then(res => {
                    commit('SET_DEPOSIT_CHARGES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchdepositcharge({ commit }, deposit_charge_id) {

            await axios.get(`/api/v1/deposit_charges/${deposit_charge_id}`)
                .then(res => {
                    // console.log("fetchProduct store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_DEPOSIT_CHARGE', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createdepositcharge({ commit, dispatch }, depositcharge) {
            // console.log("product createProduct ", product);
            await axios.post('/api/v1/deposit_charges', depositcharge);
        },

        async updatedepositcharge({ commit, dispatch }, depositcharge) {
            await axios("/api/v1/deposit_charges/" + depositcharge.deposit_charge_id, { method: "patch",data: depositcharge});
        },

        async deletedepositcharge({ commit }, deposit_charge_id) {

            await axios.delete(`/api/v1/deposit_charges/${deposit_charge_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchdepositcharges');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        },

        async fetchdepositcharges_search({ commit }, params) {
            await axios.get('/api/v1/deposit_charges', { params: params })
                .then(res => {
                    commit('SET_DEPOSIT_CHARGES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

    }

}
