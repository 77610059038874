import axios from "axios";

export default {

    namespaced: true,

    state: {

        Loanappraisals: [],
        Loanappraisal: {}

    },

    getters: {

        Loanappraisals(state) {
            return state.Loanappraisals;
        },
        Loanappraisal(state) {
            return state.Loanappraisal;
        }

    },

    mutations: {

        SET_lOAN_APPRAISALS(state, Loanappraisals) {
            return state.Loanappraisals = Loanappraisals
        },

        SET_lOAN_APPRAISAL(state, Loanappraisal) {
            return state.Loanappraisal = Loanappraisal
        }

    },

    actions: {

        async fetchLoanappraisals({ commit }, params) {

            await axios.get('/api/v1/loan-appraisal', { params: params })
                .then(res => {
                    commit('SET_lOAN_APPRAISALS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchLoanappraisal({ commit }, loan_appraisal_id) {

            await axios.get(`/api/v1/loan-appraisal/${loan_appraisal_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_lOAN_APPRAISAL', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createLoanappraisal({ commit, dispatch }, Loanappraisal) {

            await axios.post('/api/v1/loan-appraisal', Loanappraisal)
                .then(res => {
                    dispatch('fetchLoanappraisals');
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateLoanappraisal({ commit, dispatch }, Loanappraisal) {

            await axios("/api/v1/loan-appraisal/" + Loanappraisal.loan_appraisal_id, {
                method: "patch",
                data: Loanappraisal,
            }).then(res => {
                dispatch('fetchLoanappraisals');
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteLoanappraisal({ commit }, loan_appraisal_id) {

            await axios.delete(`/api/v1/loan-appraisal/${loan_appraisal_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchLoanappraisals');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
