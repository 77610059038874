import axios from "axios";

export default {

    namespaced: true,

    state: {

        shariacontracts: [],
        shariacontract: {}

    },

    getters: {

        shariacontracts(state) {
            return state.shariacontracts;
        },
        shariacontract(state) {
            return state.shariacontract;
        }

    },

    mutations: {

        SET_SHARIA_CONTRACTS(state, shariacontracts) {
            return state.shariacontracts = shariacontracts
        },

        SET_SHARIA_CONTRACT(state, shariacontract) {
            return state.shariacontract = shariacontract
        }

    },

    actions: {

        async fetchshariacontracts({ commit }, params) {

            await axios.get('/api/v1/sharia-contracts', { params: params })
                .then(res => {
                    commit('SET_SHARIA_CONTRACTS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchshariacontract({ commit }, sharia_contract_id) {

            await axios.get(`/api/v1/sharia-contracts/${sharia_contract_id}`)
                .then(res => {
                    // console.log("fetchProduct store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_SHARIA_CONTRACT', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createshariacontract({ commit, dispatch }, shariacontract) {

            // console.log("product createProduct ", product);
            await axios.post('/api/v1/sharia-contracts', shariacontract)
                .then(res => {
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateshariacontract({ commit, dispatch }, shariacontract) {

            await axios("/api/v1/sharia-contracts/" + shariacontract.sharia_contract_id, {
                method: "patch",
                data: shariacontract,
            }).then(res => {
             
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteshariacontract({ commit }, sharia_contract_id) {

            await axios.delete(`/api/v1/sharia-contracts/${sharia_contract_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchshariacontracts');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
