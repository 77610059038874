import axios from "axios";
import { downloadFile, printPdf } from "../../../utils";

export default {

    namespaced: true,

    actions: {

        async fetchIncomeStatementReport({ commit}, data) {

            // console.log("data", data);
            await axios.post('/api/v1/exports/income-statement', data)
                .then(res => {
                    downloadFile(res.data.filename, res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async printIncomeStatementReport({ commit}, data) {

            // console.log("data", data);
            await axios.post('/api/v1/exports/income-statement', data)
                .then(res => {
                    printPdf(res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchTrialBalanceReport({ commit}, data) {

            // console.log("data", data);
            await axios.post('/api/v1/exports/trial-balance', data)
                .then(res => {
                    downloadFile(res.data.filename, res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async printTrialBalanceReport({ commit}, data) {

            // console.log("data", data);
            await axios.post('/api/v1/exports/trial-balance', data)
                .then(res => {
                    printPdf(res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },
        
        async fetchReceiptReport({ commit}, data) {

            // console.log("data", data);
            await axios.post('/api/v1/exports/receipt', data)
                .then(res => {
                    downloadFile(res.data.filename, res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async printReceiptReport({ commit}, data) {

            // console.log("data", data);
            await axios.post('/api/v1/exports/receipt', data)
                .then(res => {
                    printPdf(res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchConsolidatedStatement({ commit}, data) {

            // console.log("data", data);
            await axios.post('/api/v1/exports/consolidated-statement', data)
                .then(res => {
                   downloadFile(res.data.filename, res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async printConsolidatedStatement({ commit}, data) {

            // console.log("data", data);
            await axios.post('/api/v1/exports/consolidated-statement', data)
                .then(res => {
                    printPdf(res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        }

    }

}

