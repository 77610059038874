<template>

    <section
        class="page-title-light breadcrumb_section background_bg blue_overlay_bg section-small"
        data-img-src="../../assets/images/breadcrumb_bg.jpg"
    >
        <div class="container">
            <div class="row align-items-center">
                <div class="col-sm-6">
                    <div class="page-title">
                        <h1>{{ pageTitle }}</h1>
                    </div>
                </div>
                <div class="col-sm-6">
                    <nav aria-label="breadcrumb">
                        <breadcrumb-customer />
                    </nav>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

export default {

    data() {
        return {
            pageTitle: "",
        };
    },

    watch: {
        // watch for changes in route and update page title as appropriate
        $route(route) {
        // if you go to the redirect page, do not update
        if (route.path.startsWith("/redirect/")) {
            return;
        }
        this.getPageTitle();
        },
    },

    created() {
        this.getPageTitle();
    },

    methods: {

        getPageTitle() {
            // only show routes with meta.title
            let matched = this.$route.matched.filter(
                (item) => item.meta && item.meta.title
            );
            const first = matched[matched.length - 1];
            if (first) {
                this.pageTitle = first.meta.title;
            }
        }

    }

};
</script>
