import axios from "axios";

export default {

    namespaced: true,

    state: {

        shareDepositAccounts: [],
        shareDepositAccount: {},
        shareDepositAccountBalances:[],
        customerdepositstatement: [],
        depositstatements:[],

    },

    getters: {

        shareDepositAccounts(state) {
            return state.shareDepositAccounts;
        },
        shareDepositAccount(state) {
            return state.shareDepositAccount;
        },

        shareDepositAccountbalances(state) {
            return state.shareDepositAccountBalances;
        },

        customerdepositstatement(state) {
            return state.customerdepositstatement;
        },

        depositstatements(state) {
            return state.depositstatements;
        },

    },

    mutations: {

        SET_SHARES_ACCOUNTS(state, shareDepositAccounts) {
            return state.shareDepositAccounts = shareDepositAccounts
        },

        SET_SHARE_ACCOUNT(state, shareDepositAccount) {
            return state.shareDepositAccount = shareDepositAccount
        },

        SET_SHARE_ACCOUNT_BALANCES(state, shareDepositAccounts){
            return state.shareDepositAccountBalances = shareDepositAccounts
        },

        SET_CUSTOMER_DEPOSIT_STATEMENT(state, customerdepositstatement) {
            return state.customerdepositstatement = customerdepositstatement
        },

        FETCH_DEPOSIT_STATEMENTS(state, depositstatements) {
            state.depositstatements = depositstatements;
        },

    },

    actions: {
        
        async fetchshareDepositAccounts({ commit }, params) {

            await axios.get('/api/v1/share-deposit-accounts', { params: params })
                .then(res => {
                    commit('SET_SHARES_ACCOUNTS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchshareDepositAccountbalances({ commit }, params) {

            await axios.get('/api/v1/share-deposit-accounts/balances', { params: params })
                .then(res => {
                    commit('SET_SHARE_ACCOUNT_BALANCES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchshareDepositAccount({ commit }, deposit_application_id) {

            await axios.get(`/api/v1/share-deposit-accounts/${deposit_application_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_SHARE_ACCOUNT', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createshareaccount({ commit, dispatch }, shareDepositAccount) {

            await axios.post('/api/v1/share-deposit-accounts', shareDepositAccount);
        },

        async initiatesharespurchase({ commit }, params) {
            await axios.post('/api/v1/share-deposit-accounts/shares-purchase', params);
        },

        async initiatesharesredemption({ commit }, params) {
            await axios.post('/api/v1/share-deposit-accounts/shares-redemption', params);
        },

        async initiatesharestransfer({ commit }, params) {
            await axios.post('/api/v1/share-deposit-accounts/shares-transfer', params);
        },

        async updateshareDepositAccount({ commit, dispatch }, shareDepositAccount) {

            await axios("/api/v1/share-deposit-accounts/" + shareDepositAccount.deposit_application_id, {
                method: "patch",
                data: shareDepositAccount,
            })

        },

        async deleteshareDepositAccount({ commit }, deposit_application_id) {
            await axios.delete(`/api/v1/share-deposit-accounts/${deposit_application_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchshareDepositAccounts');
                }
            }).catch(err => {
                return Promise.reject(err);
            });
        },


        async fetchCustomerDepositStatement({ commit }, params) {
            await axios.get('/api/v1/current-deposit-accounts/depositstatements', { params: params })
                .then(res => {
                    commit('FETCH_DEPOSIT_STATEMENTS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
            })
        },

    }

}
