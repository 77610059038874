const smsMessageTemplateMasterAdministrationRoutes = [

    {

        name: 'smsTemplateMasterHome',
        path: '',
        component: () => import('./ListSmsTemplatesMaster.vue'),
        meta: {
            auth: true,
            title: 'Sms Templates Master Listing',
            breadcrumb: 'Sms Templates Master Listing',
            permission: ['view messagetemplatemaster']
        }

    },

    {
        path: 'create',
        name: 'createSmsTemplateMaster',
        component: () => import('./CreateSmsTemplateMaster.vue'),
        meta: {
            auth: true,
            title: 'Create Sms Template Master',
            breadcrumb: 'Create Sms Template Master',
            permission: ['create messagetemplatemaster']
        }
    },

    {
        path: ':id',
        name: 'showSmsTemplateMaster',
        component: () => import('./ShowSmsTemplateMaster.vue'),
        meta: {
            auth: true,
            title: 'Showing Sms Template Master',
            breadcrumb: 'Showing Sms Template Master',
            permission: ['view messagetemplatemaster']
        }
    },

    {
        path: ':id/edit',
        name: 'editSmsTemplateMaster',
        component: () => import('./EditSmsTemplateMaster.vue'),
        meta: {
            auth: true,
            title: 'Edit Sms Template Master',
            breadcrumb: 'Edit Sms Template Master',
            permission: ['update messagetemplatemaster']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteSmsTemplateMaster',
        meta: {
            auth: true,
            title: 'Delete Sms Template Master',
            permission: ['delete messagetemplatemaster']
        }
    },

];

export default smsMessageTemplateMasterAdministrationRoutes;
