const journalFinanceRoutes = [

    {
        path: 'create',
        name: 'createJournal',
        component: () => import('./JournalsComponent.vue'),
        meta: {
            auth: true,
            title: 'Create Journal',
            breadcrumb: 'Create Journal',
            permission: ['create journal']
        }
    },

    {
        path: 'bank-transfer',
        name: 'bankTransfer',
        component: () => import('./BankTransferComponent.vue'),
        meta: {
            auth: true,
            title: 'Bank Transfer',
            breadcrumb: 'Bank Transfer',
            permission: ['create banktransfer']
        }
    },

];

export default journalFinanceRoutes;
