import axios from "axios";

export default {

    namespaced: true,

    state: {

        loans: [],
        loan: {},
        loanschedule: {},
        loanbalances: [],
        customerloanstatement: [],
        loanpaymentreport:[],
        loandefaultersreport:[],
        loandefaultedinstallmentreport:[]

    },



    getters: {

        loans(state) {
            return state.loans;
        },
    
        loan(state) {
            return state.loan;
        },
        loanschedule(state) {
            return state.loanschedule;
        },
        loanbalances(state) {
            return state.loanbalances;
        },
    
        customerloanstatement(state) {
            return state.customerloanstatement;
        },
        loanpaymentreport(state){
            return state.loanpaymentreport;
        },
        loandefaultersreport(state){
            return state.loandefaultersreport;
        },
        loandefaultedinstallmentreport(state){
            return state.loandefaultedinstallmentreport;
        }

    },

    mutations: {

        SET_LOANS(state, loans) {
            return state.loans = loans
        },

    
        SET_LOAN(state, loan) {
            return state.loan = loan
        },

        SET_LOAN_SCHEDULE(state, loanschedule) {
            return state.loanschedule = loanschedule
        },

        SET_LOAN_BALANCES(state, loanbalances) {
            return state.loanbalances = loanbalances
        },

        SET_CUSTOMER_LOAN_STATEMENT(state, customerloanstatement) {
            return state.customerloanstatement = customerloanstatement
        },

        SET_LOAN_PAYMENT_REPORT(state,data){
            return state.loanpaymentreport=data;
        },
        SET_LOAN_DEFAULTERS_REPORT(state,data){
            return state.loandefaultersreport=data;
        },
        SET_LOAN_DEFAULTED_INSTALLMENT_REPORT(state,data){
            return state.loandefaultedinstallmentreport=data;
        }

    },

    actions: {

        async fetchloanpaymentreport({ commit }, params) {

            await axios.get('/api/v1/loans/paymentreport', { params: params })
                .then(res => {
                    commit('SET_LOAN_PAYMENT_REPORT', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchloandefaultersreport({ commit }, params) {

            await axios.get('/api/v1/loans/defaultersreport', { params: params })
                .then(res => {
                    commit('SET_LOAN_DEFAULTERS_REPORT', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },
        async fetchloandefaultedinstallmentreport({ commit }, params) {

             commit('SET_LOAN_DEFAULTED_INSTALLMENT_REPORT', []);
            await axios.get('/api/v1/loans/defaultedinstallment', { params: params })
                .then(res => {
                    commit('SET_LOAN_DEFAULTED_INSTALLMENT_REPORT', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchLoans({ commit }, params) {

            await axios.get('/api/v1/loans', { params: params })
                .then(res => {
                    // console.log("res == ", res);
                    commit('SET_LOANS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

  

        async fetchCustomerLoanStatement({ commit }, loanId) {

            // console.log("loanId == ", loanId);
            await axios.get(`/api/v1/loans/loanStatement/${loanId}`)
                .then(res => {
                    // console.log("res == ", res.data);
                    commit('SET_CUSTOMER_LOAN_STATEMENT', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchLoanBalances({ commit }, params) {

            await axios.get('/api/v1/loans/loanbalances', { params: params })
                .then(res => {
                    // console.log("res == ", res.data);
                    commit('SET_LOAN_BALANCES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

     

        async fetchLoan({ commit }, loan_id) {
            await axios.get(`/api/v1/loans/${loan_id}`)
                .then(res => {
                    // console.log("fetchLoanApplication store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_LOAN', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async loanadjustment({ commit, dispatch }, data) {
            return await axios.post('/api/v1/loans/loanadjustment', data)
                 .then(res => {
                     return res;
                 }).catch(err => {
                     return Promise.reject(err);
                 })
         },

        async fetchLoanSchedule({ commit }, payload) {

            // await axios.post(`/api/v1/loans/calculateSchedule`, payload)
            //     .then(res => {
            //         // console.log("fetchLoanSchedule  == ", res);
            //         if (res.statusText === 'OK') {
            //             commit('SET_LOAN_SCHEDULE', res.data);
            //         }
            //     }).catch(err => {
            //         return Promise.reject(err);
            //     })

        },
 
        async repayLoan({ commit, dispatch }, params) {
           return await axios.post('/api/v1/loans/repayment', params);
        },
         async rebate({ commit, dispatch }, data) {
            return await axios.post('/api/v1/loans/rebate', data);
         },

         async waiver({ commit, dispatch }, data) {
            return await axios.post('/api/v1/loans/waiver', data);
         },

         async restructure({ commit, dispatch }, data) {
            return await axios.post('/api/v1/loans/restructure', data);
         },

         async writeoff({ commit, dispatch }, data) {
            return await axios.post('/api/v1/loans/writeoff', data);
         },

         async businessappraisal({ commit, dispatch }, data) {
            return await axios.post('/api/v1/loans/businessappraisal', data);
         },

    }

}
