<template>
  <div class="container-fluid no-gutters px-0">
    <div class="col-lg-12 mt-3xx mb-2">
      <search-bar :bread-crumb-only="true"></search-bar>

      <div class="card">
        <div class="card-body">
          <form @submit.prevent="onSubmitted">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-check-inline my-1">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="daraja"
                      name="is_daraja_config"
                      :disabled="isShow"
                      v-model="model.is_daraja_config.$model"
                      value="1"
                      class="custom-control-input"
                      @change="changeDarajaConfig($event)"
                    />
                    <label class="custom-control-label" for="daraja">
                      Use Mpesa Daraja Settings
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-check-inline my-1">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="pendoadmin"
                      name="is_daraja_config"
                      :disabled="isShow"
                      class="custom-control-input"
                      v-model="model.is_daraja_config.$model"
                      value="0"
                      @change="changeDarajaConfig($event)"
                    />
                    <label class="custom-control-label" for="pendoadmin">
                      Use Pendoadmin Settings
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-12">
                <hr />
              </div>
            </div>

            <div class="row">

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.paybill_id.$invalid }"
                >
                  <label for="paybill_id" class="col-sm-4 col-form-label text-right">
                    Paybill
                  </label>
                  <div class="col-sm-8">
                    <select
                      class="form-control"
                      :disabled="isShow"
                      v-model="model.paybill_id.$model"
                      v-if="paybills.data"
                    >
                      <option value="" selected v-if="!isEdit">Select Paybill</option>
                      <option
                        v-for="(item, index) in paybills.data"
                        :key="index"
                        :value="item.paybill_id"
                      >
                        {{ item.paybill_no }} - {{ item.paybill_type }} - {{ item.paybill_name }}
                      </option>
                    </select>

                    <error-msg :property="model.paybill_id" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.username.$invalid }"
                >
                  <label
                    for="username"
                    class="col-sm-4 col-form-label text-right"
                    v-if="isDarajaConfig"
                  >
                    Client id
                  </label>
                  <label for="username" class="col-sm-4 col-form-label text-right" v-else>
                    Username
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.username?.$invalid }"
                      type="text" :disabled="isShow"
                      v-model="model.username.$model"
                    />

                    <error-msg :property="model.username" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.password.$invalid }"
                >
                  <label
                    for="password"
                    class="col-sm-4 col-form-label text-right"
                    v-if="isDarajaConfig"
                  >
                    Client Secret
                  </label>
                  <label for="password" class="col-sm-4 col-form-label text-right" v-else>
                    Password
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.password?.$invalid }"
                      type="text" :disabled="isShow"
                      v-model="model.password.$model"
                    />

                    <error-msg :property="model.password" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group row">
                  <label for="company_id" class="col-sm-4 col-form-label text-right">
                    Company Name
                  </label>
                  <div class="col-sm-8">
                    <select
                      class="form-control"
                      v-model="model.company_id.$model"
                      v-if="companies.data" :disabled="isShow"
                    >
                      <option value="" selected v-if="!isEdit">Select Company</option>
                      <option
                        v-for="(item, index) in companies.data"
                        :key="index"
                        :value="item.company_id"
                      >
                        {{ item.company_name }}
                      </option>
                    </select>
                    <error-msg :property="model.company_id" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.status_id.$invalid }"
                >
                  <label for="status_id" class="col-sm-4 col-form-label text-right"
                    >Status</label
                  >
                  <div class="col-sm-8">
                    <div>
                      <div class="form-check-inline my-1">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="status_active"
                            name="status_id"
                            v-model="model.status_id.$model"
                            value="1" :disabled="isShow"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="status_active"
                            >Active</label
                          >
                        </div>
                      </div>

                      <div class="form-check-inline my-1">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="status_inactive"
                            name="status_id"
                            v-model="model.status_id.$model"
                            value="99" :disabled="isShow"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="status_inactive"
                            >Inactive</label
                          >
                        </div>
                      </div>
                    </div>

                    <error-msg :property="model.status_id" />
                  </div>
                </div>
              </div>

              <!-- show maker checker form buttons/ status -->
              <div class="col-lg-12">
                <hr />

                <!-- show maker checker form buttons/ status -->
                <maker-checker-button-component
                  :makerChecker="makerChecker"
                  v-if="isMakerChecker"
                />

                <div class="form-group" v-else>
                  <button
                    class="btn btn-primary mr-3"
                    type="submit"
                    :disabled="model.$invalid"
                    :class="{ 'btn-secondary': model.$invalid }" 
                    v-if="!isShow"
                  >
                    <slot name="submitButton">Submit</slot>
                  </button>
                  <button
                      class="btn btn-primary mr-3"
                      type="button"
                      @click="onEdit"
                      v-if="isShow"
                  >Edit</button>
                  <button class="btn btn-danger" type="button" @click="onCancelled">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, url } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

const mpesaSettingForm = {
  username: "",
  password: "",
  paybill_id: undefined,
  is_daraja_config: "1",
  company_id: undefined,
  status_id: "1"
};

const mpesaSettingFormGd = {
  username: "testuser",
  password: "testpass",
  paybill_id: 1,
  // paybill_id: undefined,
  //status_id: 1,
  is_daraja_config: "1",
  company_id: "2",
  status_id: "1"
};

const module = "mpesasettings";
const company_module = "companies";
const paybill_module = "paybills";

export default defineComponent({
  name: "MpesaSettingDetail",

  components: {},

  props: {
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMakerChecker: {
      type: Boolean,
      required: false,
      default: false,
    },
    makerChecker: {
      type: Object,
      required: false,
    },
  },

  emits: ["submitForm", "cancelForm", "resetForm", "editForm"],

  setup(props, { emit }) {

    // avoid destructuring props directly in the root scope of setup
    const mpesasettingProp = toRefs(props);

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    let mpesasetting = ref(mpesaSettingForm);
    const isDarajaConfig = ref(1);

    // load all companies on create component
    let payload = { report: "1" };

    // load and set company list to the first item
    store.dispatch(`${company_module}/fetchCompanies`, payload).then(() => {
      // Set the first company as the default value
      if (store.getters[`${company_module}/companies`].data.length > 0) {
        mpesasetting.value.company_id = store.getters[`${company_module}/companies`].data[0].company_id;
      }
    });

    // load paybills
    store.dispatch(`${paybill_module}/fetchPaybills`, payload);

    // on created. Are we in edit mode?
    if (props.isEdit || props.isShow) {
      mpesasetting = computed(() => store.getters[`${module}/mpesasetting`]);
    }
    
    // if we are in maker checker, use maker checker data
    if (mpesasettingProp.isMakerChecker.value) {
      mpesasetting = mpesasettingProp.makerChecker.value?.data;
    }

    const rules = {
      username: { required },
      password: { required },
      paybill_id: { required },
      status_id: { required },
      is_daraja_config: { required },
      company_id: { required },
      //status_id: {},
    };

    // validate form
    const model = useVuelidate(rules, mpesasetting);

    function onSubmitted() {
      emit("submitForm", mpesasetting);
      // console.log("mpesasetting === ", mpesasetting);
    }

    function onCancelled() {
      emit("cancelForm", mpesasetting);
    }

    function onEdit() {
      emit("editForm", mpesasetting);
    }

    function changeDarajaConfig(event) {
      // console.log("changeDarajaConfig(event)", event, event.target.checked);
      const value = event.target.value;
      isDarajaConfig.value = value === 1;
      model.value.is_daraja_config = value === 1;
      console.log("value", value);
    }

    return {
      submitForm: props.submitForm,
      companies: computed(() => store.getters[`${company_module}/companies`]),
      model,
      // mpesasetting: computed(() => store.getters[`${module}/mpesasetting`]),
      mpesasetting,
      paybills: computed(() => store.getters[`${paybill_module}/paybills`]),
      onSubmitted,
      onCancelled,
      onEdit,
      changeDarajaConfig,
      isDarajaConfig,
    };
  },
});
</script>
