<template>
  <div class="card-body p-0 auth-header-box">
    <div class="text-center p-3">
      <div class="logo logo-admin">
        <img
          v-if="
            companyColor &&
            companyColor?.company_logo
          "
          :src="companyColor.company_logo"
          height="50"
          alt="logo"
          class="auth-logo"
        />
        <img
          v-else
          src="./../../assets/images/logo-sm.png"
          height="70"
          alt="logo"
          class="auth-logo"
        />
      </div>

      <!-- <h4 class="mt-3 mb-1 font-weight-semibold text-white font-18">Save N Borrow</h4> -->
      <h4 class="mt-3 mb-1 font-weight-semibold text-white font-18">
        {{ companyColor && companyColor?.company_name_text ? companyColor?.company_name_text : "Save N Borrow" }}
      </h4>

      <p class="text-muted mb-0">{{ pageTitle }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "AuthHeader",

  props: {
    pageTitle: {
      type: String,
      default: "",
    },
    companyColor: {
      type: Object,
      default: null,
    },
  },

  setup() {
    return {};
  },
});
</script>

<style scoped>
.account-body .auth-header-box {
  background-color: #0c213a;
}
</style>
