import axios from "axios";

export default {

    namespaced: true,

    state: {

        branches: [],
        branch: {}

    },

    getters: {

        branches(state) {
            return state.branches;
        },
        branch(state) {
            return state.branch;
        }

    },

    mutations: {

        SET_BRANCHES(state, branches) {
            return state.branches = branches
        },

        SET_BRANCH(state, branch) {
            return state.branch = branch
        }

    },

    actions: {

        async fetchBranches({ commit }, params) {

            await axios.get('/api/v1/branches', { params: params })
                .then(res => {
                    // console.log(res.data.data);
                    commit('SET_BRANCHES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchBranch({ commit }, branch_id) {

            await axios.get(`/api/v1/branches/${branch_id}`)
                .then(res => {
                    // console.log("fetchBranch store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_BRANCH', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createBranch({ commit, dispatch }, branch) {

            await axios.post('/api/v1/branches', branch)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchBranches');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateBranch({ commit, dispatch }, branch) {

            await axios("/api/v1/branches/" + branch.branch_id, {
                method: "patch",
                data: branch,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchBranches');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteBranch({ commit }, dispatch, branch_id) {

            await axios.delete(`/api/v1/branches/${branch_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchBranches');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
