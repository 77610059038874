<template>

    <div class="card">

        <!-- customerData:: {{ customerData }} -->

        <div class="card-header">
            <div class="row align-items-center">
                <div class="col">
                    <h4 class="card-title">Location Information</h4>
                </div>
            </div>
        </div>

        <div class="card-body">

            <form @submit.prevent="onSubmitted">

                <div class="form-group row" :class="{ 'has-error' : model.country_id.$invalid }">
                    <label for="country_id" class="col-xl-4 col-lg-5 col-form-label">
                        Country
                    </label>
                    <div class="col-lg-7 col-xl-8">
                        <select class="form-control" :disabled="isShow" v-model="model.country_id.$model" v-if="countries.data">
                            <option value="" selected v-if="!isEdit">Select Nationality</option>
                            <option v-for="(item, index) in countries.data" :key="index" :value="item.country_id">
                                {{ item.name }}
                            </option>
                        </select>
                        <error-msg :property="model.country_id" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.nationality_id.$invalid }">
                    <label for="nationality_id" class="col-xl-4 col-lg-5 col-form-label">
                        Nationality
                    </label>
                    <div class="col-lg-7 col-xl-8">
                        <select class="form-control" :disabled="isShow" v-model="model.nationality_id.$model" v-if="countries.data">
                            <option value="" selected v-if="!isEdit">Select Nationality</option>
                            <option v-for="(item, index) in countries.data" :key="index" :value="item.country_id">
                                {{ item.name }}
                            </option>
                        </select>
                        <error-msg :property="model.nationality_id" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.county_id.$invalid }">
                    <label for="county_id" class="col-xl-4 col-lg-5 col-form-label">County</label>
                    <div class="col-lg-7 col-xl-8">
                        <select class="form-control" :disabled="isShow" v-model="model.county_id.$model"
                            v-if="counties.data" @change="onChangeCounty($event)">
                            <option v-for="(item, index) in counties.data" :key="index" :value="item.county_id">
                                {{ item.name }}
                            </option>
                        </select>
                        <error-msg :property="model.county_id" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.constituency_id.$invalid }">
                    <label for="constituency_id" class="col-xl-4 col-lg-5 col-form-label">Constituency</label>
                    <div class="col-lg-7 col-xl-8">
                        <select class="form-control" :disabled="isShow" v-model="model.constituency_id.$model"
                            v-if="constituencies.data" @change="onChangeConstituency($event)">
                            <option v-for="(item, index) in constituencies.data" :key="index" :value="item.constituency_id">
                                {{ item.name }}
                            </option>
                        </select>
                        <error-msg :property="model.constituency_id" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.ward_id.$invalid }">
                    <label for="ward_id" class="col-sm-4 col-form-label text-right">Ward</label>
                    <div class="col-sm-8">
                        <select class="form-control" :disabled="isShow" v-model="model.ward_id.$model" v-if="wards.data">
                            <option v-for="(item, index) in wards.data" :key="index" :value="item.ward_id">
                                {{ item.name }}
                            </option>
                        </select>
                        <error-msg :property="model.ward_id" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.postal_address.$invalid }">
                    <label class="col-xl-4 col-lg-5 col-form-label">Postal Address</label>
                    <div class="col-lg-7 col-xl-8">
                        <input class="form-control" :class="{ 'form-control-danger' : model.postal_address.$invalid }"
                                    type="text" :disabled="isShow" v-model="model.postal_address.$model">
                        <error-msg :property="model.postal_address" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.postal_code.$invalid }">
                    <label class="col-xl-4 col-lg-5 col-form-label">Postal Code</label>
                    <div class="col-lg-7 col-xl-8">
                        <input class="form-control" :class="{ 'form-control-danger' : model.postal_code.$invalid }"
                                    type="text" :disabled="isShow" v-model="model.postal_code.$model">
                        <error-msg :property="model.postal_code" />
                    </div>
                </div>

                <div class="form-group row" :class="{ 'has-error' : model.street_address.$invalid }">
                    <label class="col-xl-4 col-lg-5 col-form-label">Street Address</label>
                    <div class="col-lg-7 col-xl-8">
                        <input class="form-control" :class="{ 'form-control-danger' : model.street_address.$invalid }"
                                    type="text" :disabled="isShow" v-model="model.street_address.$model">
                        <error-msg :property="model.street_address" />
                    </div>
                </div>

                <div class="card-footer">

                    <button type="submit" class="btn btn-primary btn-sm"
                        @click.prevent="toggleShowLocationInfo()" v-if="isShow">
                        Edit Location Info
                    </button>

                    <button class="btn btn-primary btn-sm mr-3" type="submit" :disabled="model.$invalid"
                        :class="{ 'btn-secondary' : model.$invalid }" v-if="isEdit">
                        Submit
                    </button>

                    <button class="btn btn-danger btn-sm"
                        @click.prevent="toggleShowLocationInfo()" v-if="!isShow">
                        Cancel
                    </button>

                </div>

            </form>

        </div>

    </div>

</template>


<script>

import { defineComponent, computed, ref, watch } from "vue";
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useVuelidate } from "@vuelidate/core";
import { required, email, maxLength, minLength, } from "@vuelidate/validators";

import { numberFormatter, formatDate } from "../../../filters";

import _ from "lodash";

const customerLocationInfoForm = {
    country_id: "113",
    nationality_id: "113",
    county_id: undefined,
    constituency_id: undefined,
    ward_id: undefined,
    street_address: "",
    postal_code: "",
    postal_address: "",
    latitude: "",
    longitude: "",
};

const customer_data_module = 'customerdata';
const country_module = 'countries';
const county_module = 'counties';
const constituency_module = 'constituencies';
const ward_module = 'wards';
const statuses_module = "statuses";
const maritalstatuses_module = "maritalstatuses";

export default defineComponent({

    name: "CustomerLocationInfo",

    props: {
			customerData: {
				type: Object,
				required: false,
			},
            authUser: {
				required: false,
			},
		},

    emits: ["submitForm", "cancelForm"],

    setup(props) {

            const store = useStore();
            const router = useRouter();
            const route = useRoute();

            const isShow = ref(true);
            const isEdit = ref(false);

            let customerLocationInfo = ref(customerLocationInfoForm);

            if (props.customerData) {
                customerLocationInfo = ref(props.customerData);
            }

            const rules = {
                country_id: { required },
                nationality_id: { },
                county_id: { },
                constituency_id: { },
                ward_id: { },
                street_address: { },
                postal_code: { },
                postal_address: { },
                latitude: { },
                longitude: { }
            };

            // validate form
            const model = useVuelidate(rules, customerLocationInfo);

            let payload = { 'report': '1' };

            // load countries
            store.dispatch(`${country_module}/fetchCountries`, payload);

            // load counties
            let county_payload = { 'country_id': '113', 'report': '1' }
            store.dispatch(`${county_module}/fetchCounties`, county_payload);

            // load marital statuses
            store.dispatch(`${maritalstatuses_module}/fetchMaritalStatuses`, payload);

            // toggleShowLocationInfo
            function toggleShowLocationInfo() {
                isShow.value = !isShow.value;
                isEdit.value = !isEdit.value;
            }

            function onChangeCountry(event) {
                let country_payload = { 'country_id' : event.target.value, 'report': '1' }
                store.dispatch(`${county_module}/fetchCounties`, country_payload);
            }

            function onChangeCounty(event) {
                // console.log("onChangeCounty event", event.target);
                let county_payload = { 'county_id' : event.target.value, 'report': '1' }
                store.dispatch(`${constituency_module}/fetchConstituencies`, county_payload);
            }

            function onChangeConstituency(event) {
                // console.log("onChangeConstituency event", event.target);
                let constituency_payload = { 'constituency_id' : event.target.value, 'report': '1' }
                store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
            }

            // Are we in edit mode?
            if (isEdit.value) {

                // START ON PAGE REFRESH
                // check if we should fetch counties
                if (customerLocationInfo.value?.country_id){
                    let country_payload = { 'country_id' : customerLocationInfo.value?.country_id, 'report': '1' }
                    store.dispatch(`${county_module}/fetchCounties`, country_payload);
                }

                // check if we should fetch constituencies
                if (customerLocationInfo.value?.county_id){
                    let county_payload = { 'county_id' : customerLocationInfo.value?.county_id, 'report': '1' }
                    store.dispatch(`${constituency_module}/fetchConstituencies`, county_payload);
                }

                // check if we should fetch wards
                if (customerLocationInfo.value?.constituency_id){
                    let constituency_payload = { 'constituency_id' : customerLocationInfo.value?.constituency_id, 'report': '1' }
                    store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
                }
                // END ON PAGE REFRESH

            }

            // START ON ROUTE CHANGES
            // watch for country changes
            watch(() => customerLocationInfo.value?.country_id,
                (newVal, oldVal) => {
                    // fetch counties
                    let country_payload = { 'country_id' : newVal, 'report': '1' }
                    store.dispatch(`${county_module}/fetchCounties`, country_payload);
                }
            );

            // watch for county changes
            watch(() => customerLocationInfo.value?.county_id,
                (newVal, oldVal) => {
                    // fetch constituencies
                    let county_payload = { 'county_id' : newVal, 'report': '1' }
                    store.dispatch(`${constituency_module}/fetchConstituencies`, county_payload);
                }
            );

            // watch for constituency changes
            watch(() => customerLocationInfo.value?.constituency_id,
                (newVal, oldVal) => {
                    // fetch wards
                    let constituency_payload = { 'constituency_id' : newVal, 'report': '1' }
                    store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
                }
            );
            // END ON ROUTE CHANGES

            async function onSubmitted() {
                await store.dispatch(`${customer_data_module}/updateCustomerData`, customerLocationInfo)
                .then(res => {
                    isShow.value = !isShow.value;
                    isEdit.value = !isEdit.value;
                })
                .catch(err => {
                    return Promise.reject(err);
                });
			}

            return {
                countries: computed(() => store.getters[`${country_module}/countries`]),
                counties: computed(() => store.getters[`${county_module}/counties`]),
                constituencies: computed(() => store.getters[`${constituency_module}/constituencies`]),
                wards: computed(() => store.getters[`${ward_module}/wards`]),
                statuses: computed(() => store.getters[`${statuses_module}/statuses`]),
                maritalstatuses: computed(() => store.getters[`${maritalstatuses_module}/maritalstatuses`]),
                model,
                // customerData: props.customerData,
                // customerData: computed(() => store.getters[`${customer_data_module}/customer`]),
                isShow,
                isEdit,
                toggleShowLocationInfo,
                numberFormatter,
                formatDate,
                onSubmitted,
                // changedLocation,
                onChangeCountry,
                onChangeCounty,
                onChangeConstituency,
            };

    },

});


</script>

