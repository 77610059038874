<template>
  <div class="col-lg-4 d-flex flex-column">
    <div class="card flex-fill">
      <div class="card-header">
        <div class="row align-items-center">

          <div class="col">
            <h4 class="card-title">Activity Overview</h4>
          </div>

          <div class="col-auto">
            <div class="dropdown">
              <a
                  href="#"
                  class="btn btn-sm btn-outline-light dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">{{ currentFilter }}<i class="las la-angle-down ml-1"></i></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#" @click.prevent="selectFilter('Yesterday')">Yesterday</a>
                <a class="dropdown-item" href="#" @click.prevent="selectFilter('Last Week')">Last 7 Days</a>
                <a class="dropdown-item" href="#" @click.prevent="selectFilter('This Month')">This Month</a>
                <a class="dropdown-item" href="#" @click.prevent="selectFilter('Last Month')">Last Month</a>
                <a class="dropdown-item" href="#" @click.prevent="selectFilter('This Year')">This Year</a>
              </div>
            </div>
          </div>

          <!--  -->
        </div>
      </div>

      <div class="card-body">
        <div class="my-5">
          <div class="apex-charts d-block w-90 mx-auto">
            <apexchart
                class="apex-charts"
                type="donut"
                :options="options_ana_1"
                :series="seriesActivityOverviewChart"
            >
            </apexchart>
          </div>
          <hr class="hr-dashed w-25 mt-0" />
        </div>

          <hr class="hr-dashed w-25 mt-0" />

        <div class="text-center">
          <h4>Loan and Savings Statistics</h4>

          <!-- Add dynamic data for the following statistics -->
          <p class="text-muted mt-2">
            <strong>Total Savings:</strong> KSH {{ numberFormatter(totalSavings) }}<br />
            <strong>Loan Applications:</strong> KSH {{ numberFormatter(loanApplications) }}<br />
            <strong>Loan Approval Rate:</strong> {{ loanApprovalRate }}%<br />
            <strong>Total Loan Disbursed:</strong> KSH {{ numberFormatter(totalLoanDisbursed) }}<br />
            <strong>Loan Repayments:</strong> KSH {{numberFormatter(loanRepayments) }}
          </p>

<!--          <button-->
<!--              type="button"-->
<!--              class="btn btn-sm btn-outline-primary px-3 mt-2"-->
<!--          >-->
<!--            More Details-->
<!--          </button>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import {defineComponent, onMounted, ref} from "vue";
import { numberFormatter } from "@/filters";
import { capitalizeFirstLetter } from "@/helpers/utils";
import {getFilterParams} from "@/helpers/chartUtils";
import sessionStorageService from "@/services/SessionStorageService";
import SharedChart from "@/components/Admin/DashboardComponent/SharedChart.vue";

const module = "statistics";

export default defineComponent({
  name: "ActivityOverview",
  components: {SharedChart},
  props: {
  },
  data: () => ({

    options_ana_1: {
      chart: {
        height: 265,
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "85%",
          },
        },
      },
      dataLabels: {
        enabled: !1,
      },
      stroke: {
        show: !0,
        width: 2,
        colors: ["transparent"],
      },

      legend: {
        show: !0,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: !1,
        fontSize: "13px",
        offsetX: 0,
        offsetY: 0,
      },
      labels: ["Savings", "Loans", "Fees"],
      colors: ["#2a76f4", "rgba(42, 118, 244, .5)", "rgba(42, 118, 244, .18)"],
      responsive: [
        {
          breakpoint: 600,
          options: {
            plotOptions: {
              donut: {
                customScale: 0.2,
              },
            },
            chart: {
              height: 240,
            },
            legend: {
              show: !1,
            },
          },
        },
      ],
      tooltip: {
        y: {
          formatter: function (e) {
            return e + " %";
          },
        },
      },
    },
  }),
  // setup
  setup() {
    const user = sessionStorageService.getData("user");
    const companyId = user?.active_company_id;
    const currentFilter = ref("Today");
    const totalSavings = ref("");
    const loanApplications = ref("");
    const totalLoanDisbursed = ref("");
    const loanRepayments = ref("");
    const loanApprovalRate = ref(0);

    const seriesActivityOverviewChart = ref([]);
    const fetchFinancialOverviewStatistics = async (params) => {
      try {
        const response = await store.dispatch(
            `${module}/fetchFinancialOverviewStatistics`,
            params
        );
        return response.data;
      } catch (error) {
        console.error("Error loading fetchFinancialOverviewStatistics:", error);
      }
    };
    const selectFilter = async (filter) => {
      currentFilter.value = filter;
      try {
        const { custom_key, summary_type, period } = getFilterParams(filter);

        const params = {
          company_id: companyId,
          metrics: ["disbursed_loans_value", "deposits_value","disbursed_loans_value","loan_repayment_value","loan_applications_count","loan_applications_value"],
          summary_type,
          custom_key,
          period,
        };

        const data = await fetchActivityOverview(params);
        updateChartData(data);
      } catch (error) {
        console.error(
            `Error fetching ${filter.toLowerCase()} activity overview statistics: `,
            error
        );
      }
    };

    const calculateOverviewPercentage  = async (filter)=> {
      currentFilter.value = filter;

      try {
        const { custom_key, summary_type, period } = getFilterParams(filter);

        const params = {
          company_id: companyId,
          metrics: ["shares_value", "approved_loans_value","fees_collected_value"],
          summary_type,
          custom_key,
          period,
        };

        const data = await fetchFinancialOverviewStatistics(params);
        updateFinancialOverviewChartData(data);
      } catch (error) {
        console.error(
            `Error fetching ${filter.toLowerCase()} disbursed loans statistics: `,
            error
        );
      }

    }

    const fetchActivityOverview = async (params) => {
      try {
        const response = await store.dispatch(
            `${module}/fetchFinancialOverviewStatistics`,
            params
        );
        return response.data;
      } catch (error) {
        console.error("Error loading fetchActivityOverview:", error);
      }
    };

    const sumArray = (array) => array.reduce((acc, val) => acc + val, 0);

    const updateChartData = (data) => {
      // Summing the values of each category
      //TODO: set these calculations to backend
      const totalLoanDisbursedSum = sumArray(data.disbursed_loans_value);
      const totalSavingsSum = sumArray(data.deposits_value);
      const loanRepaymentsSum = sumArray(data.loan_repayment_value);
      const loanApplicationsValue = sumArray(data.loan_applications_value);
      const loanApplicationsSum = sumArray(data.loan_applications_count);
      const loansApprovedCountSum = data.approved_loans_count;

      if (loanApplicationsSum === 0) {
        loanApprovalRate.value = 0;
      } else {
        loanApprovalRate.value = ((loansApprovedCountSum / loanApplicationsSum) * 100).toFixed(2);
      }
      loanRepayments.value = loanRepaymentsSum;
      totalSavings.value = totalSavingsSum;
      loanApplications.value = loanApplicationsValue;
      totalLoanDisbursed.value = totalLoanDisbursedSum;
      seriesActivityOverviewChart.value = [loanApplications.value,totalLoanDisbursed.value,totalLoanDisbursed.value];
    };
    const updateFinancialOverviewChartData = (data) => {


       const feesSum = sumArray(data.fees_collected_value);
      const savingsSum =sumArray(data.shares_value);
     const loansSum = sumArray(data.approved_loans_value);


      const total = feesSum + savingsSum+ loansSum;

      let feesPercentage = 0;
      let savingsPercentage = 0;
      let loansPercentage = 0;

      if (total > 0) {
        feesPercentage = (feesSum / total) * 100;
        savingsPercentage = (savingsSum / total) * 100;
        loansPercentage = (loansSum / total) * 100;
      }
      seriesActivityOverviewChart.value= [feesPercentage,savingsPercentage,loansPercentage];

    };
    onMounted(() => {
      selectFilter("Today");
      calculateOverviewPercentage("Today");

    });

    return {
      numberFormatter,
      capitalizeFirstLetter,
      selectFilter,
      loanRepayments,
      totalSavings,
      loanApplications,
      totalLoanDisbursed,
      currentFilter,
      seriesActivityOverviewChart,
      loanApprovalRate
    };

  }
});
</script>
