const customerRegistrationRoutes = [

    {

        name: 'customerHome',
        path: '',
        component: () => import('./ListCustomers.vue'),
        meta: {
            auth: true,
            title: 'Members Lists',
            breadcrumb: 'Members Lists',
            permission: ['view member'],
            section: 'customer'
        }

    },

    {
        path: 'loans/:customerId',
        name: 'memberLoans',
        component: () => import('./../../DepositLoan/Loan/ListCustomerLoans.vue'),
        meta: {
            auth: true,
            title: 'Showing Customer Loans',
            breadcrumb: 'Showing Customer Loans',
            permission: ['view member']
        }
    },

    {
        path: 'deposits/:customerId',
        name: 'memberDeposits',
        component: () => import('./../../DepositLoan/DepositAccount/ListDepositAccounts.vue'),
        meta: {
            auth: true,
            title: 'Showing Customer Deposits',
            breadcrumb: 'Showing Current Accounts',
            permission: ['view member']
        }
    },

    {
        path: 'shares/:customerId',
        name: 'memberShares',
        component: () => import('./../../DepositLoan/ShareDepositAccount/ListSharesAccounts.vue'),
        meta: {
            auth: true,
            title: 'Showing Customer Shares',
            breadcrumb: 'Showing Customer Shares',
            permission: ['view member']
        }
    },

    {
        path: 'create',
        name: 'createCustomer',
        component: () => import('./CreateCustomer.vue'),
        meta: {
            auth: true,
            makerChecker: {
                enabled: true,
                section: "customer",
            },
            title: 'Create Member',
            breadcrumb: 'Create Member',
            permission: ['create member'],
            section: 'customer'
        }
    },

    {
        path: ':id',
        name: 'showCustomer',
        component: () => import('./ShowCustomer.vue'),
        meta: {
            auth: true,
            title: 'Showing Member',
            breadcrumb: 'Showing Member',
            permission: ['view member'],
            section: 'customer'
        }
    },

    {
        path: 'consolidatedstatement/:id',
        name: 'consolidatedstatement',
        component: () => import('./ConsolidatedStatement.vue'),
        meta: {
            auth: true,
            title: 'Consolidated Statement',
            breadcrumb: 'Consolidated Statement',
            permission: ['view member']
        }
    },

    {
        path: 'calllog/:id',
        name: 'customercall',
        component: () => import('./../CustomerCall/CustomerCalls.vue'),
        meta: {
            auth: true,
            title: 'Call Logs',
            breadcrumb: 'Call Logs',
            permission: ['view member']
        }
    },

    {
        path: ':id/edit',
        name: 'editCustomer',
        component: () => import('./EditCustomer.vue'),
        meta: {
            auth: true,
            makerChecker: true,
            title: 'Edit Member',
            breadcrumb: 'Edit Member',
            permission: ['update member'],
            section: 'customer'
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteCustomer',
        meta: {
            auth: true,
            makerChecker: true,
            title: 'Delete Member',
            permission: ['delete member'],
            section: 'customer'
        }
    },



];

export default customerRegistrationRoutes;
