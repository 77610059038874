const pendingRegistrationRoutes = [
	{
		name: "pendingRegistrationHome",
		path: "",
		component: () => import("./ListPendingRegistrations.vue"),
		meta: {
			auth: true,
			title: "Pending Items Listing",
			breadcrumb: "Pending Items Listing",
			permission: ["view makerchecker"],
		},
	},

	{
		path: ":id",
		name: "showPendingRegistration",
		component: () => import("./ShowPendingRegistration.vue"),
		meta: {
			auth: true,
			title: "Showing Pending Item",
			breadcrumb: "Showing Pending Item",
			permission: ["view makerchecker"],
		},
	},
];

export default pendingRegistrationRoutes;
