const taxRoutes = [

    {

        path: '',
        name: 'taxHome',
        component: () => import ('./ListTaxes.vue'),
        meta: {
            auth: true,
            title: 'Tax Listing',
            breadcrumb: 'Tax Listing',
            permission: ['view tax'] //'view collateral'
        }
    },
    
    {
        path: 'create',
        name: 'createtax',
        component: () => import('./CreateEditTax.vue'),
        meta: {
            auth: true,
            title: 'Create Tax',
            breadcrumb: 'Create Tax',
            permission: ['create tax'] //'create Tax'
        }
    },

    {
        path: ':id/edit',
        name: 'edittax',
        component: () => import ('./CreateEditTax.vue'),
        meta: {
            auth: true,
            title: 'Edit Deposit Status',
            breadcrumb: 'Edit Deposit Status',
            permission: ['update tax'] //'update Deposit status'
        }
    },

];


export default taxRoutes;
