const makerCheckerSectionMasterAdministrationRoutes = [

    {

        name: 'makerCheckerSectionMasterHome',
        path: '',
        component: () => import('./ListMakerCheckerSectionMasters.vue'),
        meta: {
            auth: true,
            title: 'Maker Checker Sections Master Listing',
            breadcrumb: 'Maker Checker Sections Master Listing',
            permission: ['view makercheckersectionmaster']
        }

    },

    {
        path: 'create',
        name: 'createMakerCheckerSectionMaster',
        component: () => import('./CreateMakerCheckerSectionMaster.vue'),
        meta: {
            auth: true,
            title: 'Create Maker Checker Section Master',
            breadcrumb: 'Create Maker Checker Section Master',
            permission: ['create makercheckersectionmaster']
        }
    },

    {
        path: ':id',
        name: 'showMakerCheckerSectionMaster',
        component: () => import('./ShowMakerCheckerSectionMaster.vue'),
        meta: {
            auth: true,
            title: 'Showing Maker Checker Section Master',
            breadcrumb: 'Showing Maker Checker Section Master',
            permission: ['view makercheckersectionmaster']
        }
    },

    {
        path: ':id/edit',
        name: 'editMakerCheckerSectionMaster',
        component: () => import('./EditMakerCheckerSectionMaster.vue'),
        meta: {
            auth: true,
            title: 'Edit Maker Checker Section Master',
            breadcrumb: 'Edit Maker Checker Section Master',
            permission: ['update makercheckersectionmaster']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteMakerCheckerSectionMaster',
        meta: {
            auth: true,
            title: 'Delete Maker Checker Section Master',
            permission: ['delete makercheckersectionmaster']
        }
    },

];

export default makerCheckerSectionMasterAdministrationRoutes;
