<template>

    <modal v-show="showModal"  @close="toggleScheduleCustomerCallPopup" :modalSize="'modal-lg'">

        <template v-slot:header>
            Schedule Customer Call
        </template>

        <template v-slot:body>

            <!-- Hapa == {{ staffAllocationData }}<br>
            Hapa == {{ toggleScheduleCustomerCallPopup }}<br> -->

            <form @submit.prevent="onSubmitted">

                <div class="row">

                    <div class="col-lg-6">
                        <div class="form-group row">
                            <label for="first_name" class="col-sm-5 col-form-label text-right">
                                Customer Name
                            </label>
                            <div class="col-sm-7">

                                <input class="form-control"
                                        :value="staffAllocationData.customer.full_name"
                                        type="text" disabled/>

                                <!-- <input class="form-control"
                                        v-model="model.user_id.$model"
                                        type="hidden" readonly/> -->

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group row">
                            <label for="last_name" class="col-sm-5 col-form-label text-right">
                                Staff Name
                            </label>
                            <div class="col-sm-7">

                                <input class="form-control"
                                        :value="staffAllocationData.employee.full_name"
                                        type="text" disabled/>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">

                        <div class="form-group row">

                            <label for="comments" class="col-sm-5 col-form-label text-right">
                                Call Date Time
                            </label>

                            <div class="col-sm-7">

                                <my-date-picker v-model="model.next_call_at.$model" :min-date="minDate">
                                </my-date-picker>

                            </div>

                        </div>

                    </div>

                    <div class="col-lg-6">

                        <div class="form-group row">

                            <label for="comments" class="col-sm-5 col-form-label text-right">
                                Comments
                            </label>

                            <div class="col-sm-7">

                                <textarea class="form-control" rows="3"
                                    v-model="model.comments.$model"
                                    id="comments">
                                </textarea>

                            </div>

                        </div>

                    </div>

                    <div class="col-lg-12">

                        <hr class="row no-top-margin"/>

                        <div class="form-group">
                            <button class="btn btn-primary mr-3" type="submit"
                                :disabled="model.$invalid"
                                :class="{ 'btn-secondary' : model.$invalid }">
                                Schedule Customer Call
                            </button>
                        </div>

                    </div>

                </div>

            </form>

        </template>

    </modal>

</template>


<script>

    import { defineComponent, ref } from "vue";
    import { useStore } from 'vuex';
    import { useVuelidate } from "@vuelidate/core";
    import { required, decimal,minValue } from "@vuelidate/validators";


    const customer_module = 'customers';
    const module = 'customercalls';

    export default defineComponent({

        name: "ScheduleCustomerCallPopup",

        props: {
            staffAllocationData: {
                type: Object,
                required: true,
            },
            showModal: {
                type: Boolean,
                default: true,
            },
            toggleScheduleCustomerCallPopup: {
                type: Function,
                default: () => {},
            },
        },

        components: {},

        setup(props, { emit }) {

            const scheduleCustomerCallData = {
                customer_employee_allocation_id: props.staffAllocationData.customer_employee_allocation_id,
                comments: "",
                next_call_at: "",
            };

            const store = useStore();
            const staffAllocationData = ref(props.staffAllocationData);
            const showModal = ref(props.showModal);
            const userType = ref(props.userType);
            const toggleScheduleCustomerCallPopup = ref(props.toggleScheduleCustomerCallPopup);
            const minDate = new Date();

            let customerCallForm = ref(scheduleCustomerCallData);

            // set validation rules
            const rules = {
                customer_employee_allocation_id: { required },
                comments: { },
                next_call_at:{ required }
            };

            // validate form
            const model = useVuelidate(rules, customerCallForm);

            async function onSubmitted(){
                await store.dispatch(`${module}/createCustomerCall`, customerCallForm.value)
                    .then(res => {
                        emit('toggleScheduleCustomerCallPopup', toggleScheduleCustomerCallPopup);
                    });
            }

            return {
                onSubmitted,
                // staffAllocationData,
                userType,
                // toggleScheduleCustomerCallPopup,
                model,
                minDate
            };

        },

    });

</script>


<style>

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

    .modal-fade-enter, .modal-fade-leave-to  {
        opacity: 0;
    }
    .modal-fade-enter-active {
        transition: all 0.8s ease-out;
    }
    .modal-fade-enter-active {
        transition: all 0.8s ease-in;
    }




    /* -- zoom -- */
    /* @-webkit-keyframes modal-zoom-enter {
        from {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    @keyframes modal-zoom-enter {
        from {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    .modal-zoom-enter-active {
        -webkit-animation: modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
        animation: modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
    }

    @-webkit-keyframes modal-zoom-leave {
        to {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    @keyframes modal-zoom-leave {
        to {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    .modal-zoom-leave-active {
        -webkit-animation: modal-zoom-leave both;
        animation: modal-zoom-leave both;
    } */

</style>

