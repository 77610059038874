import axios from "axios";

export default {

    namespaced: true,

    state: {

        businesscategories: [],
        businesscategory: {},

    },

    getters: {
        businesscategories(state) {
            return state.businesscategories;
        },
        businesscategory(state) {
            return state.businesscategory;
        }

    },


    mutations: {

        SET_BUSINESS_CATEGORIES(state, businesscategories) {
            return state.businesscategories = businesscategories
        },

        SET_BUSINESS_CATEGORY(state, businesscategory) {
            console.log('Setting business category:', businesscategory);
            return state.businesscategory = businesscategory
        }

    },

    actions: {

        async fetchbusinesscategories({ commit }, params) {
            try {
                const response = await axios.get('/api/v1/business-categories', { params: params });
                commit('SET_BUSINESS_CATEGORIES', response.data);
            } catch (error) {
                return Promise.reject(error);
            }
        },

  

        async fetchbusinesscategory({ commit }, business_category_id) {
            await axios.get(`/api/v1/business-categories/${business_category_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_BUSINESS_CATEGORY', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

         async createbusinesscategory({ commit, dispatch }, data) {
            return await axios.post('/api/v1/business-categories/', data);
         },


        async updatebusinesscategory({ commit, dispatch }, businesscategory) {
            await axios("/api/v1/business-categories/" + businesscategory.business_category_id, 
            { method: "put", data: businesscategory})
        },

    }

}
