const emailMessageTemplateAdministrationRoutes = [

    {

        name: 'emailTemplateHome',
        path: '',
        component: () => import('./ListEmailTemplates.vue'),
        meta: {
            auth: true,
            title: 'Email Templates Listing',
            breadcrumb: 'Email Templates Listing',
            permission: ['view messagetemplate']
        }

    },

    {
        path: 'create',
        name: 'createEmailTemplate',
        component: () => import('./CreateEmailTemplate.vue'),
        meta: {
            auth: true,
            title: 'Create Email Template',
            breadcrumb: 'Create Email Template',
            permission: ['create messagetemplate']
        }
    },

    {
        path: ':id',
        name: 'showEmailTemplate',
        component: () => import('./ShowEmailTemplate.vue'),
        meta: {
            auth: true,
            title: 'Showing Email Template',
            breadcrumb: 'Showing Email Template',
            permission: ['view messagetemplate']
        }
    },

    {
        path: ':id/edit',
        name: 'editEmailTemplate',
        component: () => import('./EditEmailTemplate.vue'),
        meta: {
            auth: true,
            title: 'Edit Email Template',
            breadcrumb: 'Edit Email Template',
            permission: ['update messagetemplate']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteEmailTemplate',
        meta: {
            auth: true,
            title: 'Delete Email Template',
            permission: ['delete messagetemplate']
        }
    },

];

export default emailMessageTemplateAdministrationRoutes;
