<template>

    <span v-if="property.$invalid" class="text-danger">
        <slot>
            <ul class="list-unstyled">
                <li v-for="e in property.$errors" :key="e.$message">
                    <small>{{ e.$message }}</small>
                </li>
            </ul>
        </slot>
    </span>

</template>


<script>

	import { defineComponent } from "vue";

	export default defineComponent({

		name: "ErrorMsg",

		props: {
			property: {
				type: Object,
				required: true,
			}
		},

		setup(props) {

			return {
				// property: props.property,
			};

		},

	});

</script>

