import axios from "axios";

export default {

    namespaced: true,

    state: {

        groupadmins: [],
        groupadmin: {}

    },

    getters: {

        groupadmins(state) {
            return state.groupadmins;
        },
        groupadmin(state) {
            return state.groupadmin;
        }

    },

    mutations: {

        SET_GROUP_ADMINS(state, groupadmins) {
            return state.groupadmins = groupadmins
        },

        SET_GROUP_ADMIN(state, groupadmin) {
            return state.groupadmin = groupadmin
        }

    },

    actions: {

        async fetchGroupAdmins({ commit }, params) {

            await axios.get('/api/v1/groupadmins', { params: params })
                .then(res => {
                    // console.log(res.data);
                    commit('SET_GROUP_ADMINS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchGroupAdmin({ commit }, group_admin_id) {

            await axios.get(`/api/v1/groupadmins/${group_admin_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_GROUP_ADMIN', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createGroupAdmin({ commit, dispatch }, groupadmin) {

            await axios.post('/api/v1/groupadmins', groupadmin)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchGroupAdmins');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateGroupAdmin({ commit, dispatch }, groupadmin) {

            await axios("/api/v1/groupadmins/" + groupadmin.group_admin_id, {
                method: "patch",
                data: groupadmin,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchGroupAdmins');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteGroupAdmin({ commit }, group_admin_id) {

            await axios.delete(`/api/v1/groupadmins/${group_admin_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchGroupAdmins');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
