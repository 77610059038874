import axios from "axios";

export default {

    namespaced: true,

    state: {

        deposits: [],
        deposit: {},
        currentdepositsettings:[],
        sharedepositsettings:[],
        fixeddepositsettings:[]

    },

    getters: {

        deposits(state) {
            return state.deposits;
        },
        deposit(state) {
            return state.deposit;
        },
        currentdepositsettings(state){
            return state.currentdepositsettings; 
        },
        sharedepositsettings(state){
            return state.sharedepositsettings;
        },

        fixeddepositsettings(state){
            return state.fixeddepositsettings; 
        },


    },

    mutations: {

        SET_DEPOSITS(state, deposits) {
            return state.deposits = deposits
        },

        SET_DEPOSIT(state, deposit) {
            return state.deposit = deposit
        },
        
        SET_CURRENT_DEPOSITS(state, currentdepositsettings) {
            return state.currentdepositsettings = currentdepositsettings
        },

        SET_SHARES_DEPOSITS(state, sharedepositsettings) {
            return state.sharedepositsettings = sharedepositsettings
        },

        SET_FIXED_DEPOSITS(state, fixeddepositsettings) {
            return state.fixeddepositsettings = fixeddepositsettings
        },

    },

    actions: {
        async fetchDeposits({ commit }, params) {
            await axios.get('/api/v1/deposit-settings', { params: params })
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_DEPOSITS', res.data);
                        let data=(res.data && res.data.data)?res.data.data:[];
                        commit('SET_CURRENT_DEPOSITS', data.filter(e=>e.is_default==false && (e.deposit_type=='Current')));
                        commit('SET_SHARES_DEPOSITS', data.filter(e=>e.is_default==false && e.deposit_type=='Shares'));
                        commit('SET_FIXED_DEPOSITS', data.filter(e=>e.is_default==false && e.deposit_type=='Fixed'));
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async fetchDeposit({ commit }, deposit_id) {
            await axios.get(`/api/v1/deposit-settings/${deposit_id}`)
                .then(res => {
                    // console.log("res == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_DEPOSIT', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async createDeposit({ commit, dispatch }, deposit) {
            await axios.post('/api/v1/deposit-settings', deposit);
        },

        async updateDeposit({ commit, dispatch }, deposit) {
            await axios("/api/v1/deposit-settings/" + deposit.deposit_id, {method: "patch",data: deposit,});
        },

        async deleteDeposit({ commit }, deposit_id) {

            await axios.delete(`/api/v1/deposit-settings/${deposit_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchDeposits');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
