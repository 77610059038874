export const sidebarnavItems = [

    {
      name: 'adminHome',
      title: 'Dashboard HH',
      icon: 'home',
      class: '',
      routeNames: ['adminHome'],
      submenu: []
    },

    {
      name: 'financialYearsHome',
      title: 'Financial Years HH',
      icon: 'calendar',
      class: '',
      routeNames: ['financialYearsHome'],
      submenu: []
    }

    /* {
      name: 'financialYearsHome',
      title: 'Finance',
      icon: 'briefcase',
      class: '',
      routeNames: ['financialYearsHome'],
      submenu: []
    }, */

    /* <a href="javascript: void(0);">
          <i data-feather="briefcase" class="align-self-center menu-icon"></i>
          <span>Finance</span>
          <span class="menu-arrow">
            <i class="mdi mdi-chevron-right"></i>
          </span>
        </a> */

    /*{ <li :class="{ 'hide-menu' : showMenuItem(['financialYearsHome']) }">
        <router-link :to="{ name: 'financialYearsHome' }">
          <i data-feather="calendar" class="align-self-center menu-icon"></i>
          <span>Financial Years</span>
        </router-link>
      </li> }*/

    /* {
      path: '',
      title: 'Transactions',
      icon: 'mdi mdi-view-module',
      class: 'has-arrow',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: [
        { path: '/transactions', title: 'Transaction List', icon: 'mdi mdi-bullseye', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
       ],
       data: ['read-transaction']
    }, */

];
