const shareAccountRoutes = [

    {

        path: '',
        name: 'shareHome',
        component: () => import('./ListSharesAccounts.vue'),
        meta: {
            auth: true,
            title: 'Shares Deposit Accounts',
            breadcrumb: 'Shares Deposit Accounts',
            permission: ['view shares accounts'] //'view shareaccount'
        }
    },

    {

        path: 'balance',
        name: 'shareBalance',
        component: () => import('./ListSharesBalance.vue'),
        meta: {
            auth: true,
            title: 'Shares Balances',
            breadcrumb: 'Shares Balances',
            permission: ['view shares accounts'] //'view shareaccount'
        }
       
    },

    {
        path: 'customer-Shares/:customerId',
        name: 'customerShares',
        component: () =>import ('./ListSharesAccounts.vue'),
        meta: {
            auth: true,
            title: 'Customer Shares',
            breadcrumb: 'Customer Shares',
            permission: ['view shares accounts'] //'view shareaccount'
        },

    },

    {
        path: 'share-statement/:deposit_application_id',
        name: 'shareStatement',
        component: () =>import ('./SharesStatement.vue'),
        meta: {
            auth: true,
            title: 'Share Statement',
            breadcrumb: 'Share Statement',
            permission: ['view shares accounts']
        },

    },
    
    {
        path: 'transferrepurchase/:customer_id/:deposit_id',
        name: 'transferrepurchase',
        component: () => import('./TransferRepurchaseShare.vue'),
        meta: {
            auth: true,
            title: 'Transfer/Repurchase Shares',
            breadcrumb: 'Transfer/Repurchase Shares',
            permission: ['create shares transfer'] //'create shareaccount'
        }
    },
    {
        path: 'create',
        name: 'createShare',
        component: () => import('./CreateEditShare.vue'),
        meta: {
            auth: true,
            title: 'Create Share Account',
            breadcrumb: 'Create Share Account',
            permission: ['create shares accounts'] //'create shareaccount'
        }
    },

    {
        path: 'calllog/:id',
        name: 'sharescustomercall',
        component: () => import('./../../Registration/CustomerCall/CustomerCalls.vue'),
        meta: {
            auth: true,
            title: 'Member Call Logs',
            breadcrumb: 'Member Call Logs',
            permission: ['view member']
        }
    },

    {
        path: ':id/edit',
        name: 'editShare',
        component: () => import('./CreateEditShare.vue'),
        meta: {
            auth: true,
            title: 'Edit Share Account',
            breadcrumb: 'Edit Share Account',
            permission: ['update shares accounts']
        }
    },

    {
        name: 'viewShareAccountDetails',
        path: 'account-details/:depositApplicationId',
        component: () => import('./ViewAccount.vue'),
        meta: {
            auth: true,
            title: 'Account Details',
            breadcrumb: 'Account Details',
            permission: ['view shares accounts']
        }
    },

];

export default shareAccountRoutes;
