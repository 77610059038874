class TranslationService {

    async getTranslations(store, translationModule) {
        try {
            // Check if translations is stored in session storage
            const storedTranslations = sessionStorage.getItem("translations");
            if (storedTranslations) {
                // If stored, parse and return the color
                return JSON.parse(storedTranslations);
            } else {
                // If not stored, fetch it from Vuex store
                let payload = { report: "1" };
                const translations = await store.dispatch(`${translationModule}/fetchTranslations`, payload);

                // Store translations in sessionStorage
                sessionStorage.setItem("translations", JSON.stringify(translations));

                return translations;
            }
        } catch (error) {
            console.error("Error fetching translations:", error);
            throw error; 
        }
    }

    getTranslatedValue(key) {
        // Access translations data from SessionStorage
        const translations = JSON.parse(sessionStorage.getItem('translations'));
        
        // Return translated text
        // Return the key if translation not found
        return translations[key] || key; 
      }

}

export default TranslationService;
