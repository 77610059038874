const membershipSettingRoutes = [

    {

        name: 'membershipSettingHome',
        path: '',
        component: () => import('./ListMembershipSettings.vue'),
        meta: {
            auth: true,
            title: 'Membership Settings Listing',
            breadcrumb: 'Membership Settings Listing',
            permission: ['view companysetting']
        }

    },

    {
        path: ':id',
        name: 'showMembershipSetting',
        component: () => import('./ShowMembershipSetting.vue'),
        meta: {
            auth: true,
            title: 'Showing Membership Setting',
            breadcrumb: 'Showing Membership Setting',
            permission: ['view companysetting']
        }
    },

    {
        path: ':id/edit',
        name: 'editMembershipSetting',
        component: () => import('./EditMembershipSetting.vue'),
        meta: {
            auth: true,
            title: 'Edit Membership Setting',
            breadcrumb: 'Edit Membership Setting',
            permission: ['update companysetting']
        }
    }
    // ,
    // {
    //     path: ':id/delete',
    //     name: 'editMembershipSetting',
    //     component: () => import('./EditMembershipSetting.vue'),
    //     meta: {
    //         auth: true,
    //         title: 'Edit Membership Setting',
    //         breadcrumb: 'Edit Membership Setting',
    //         permission: ['update membershipsetting']
    //     }
    // }

];

export default membershipSettingRoutes;