import axios from "axios";

export default {
	namespaced: true,

	state: {
		makercheckers: [],
		makerchecker: {},
		makercheckershistory: {},
		makercheckermaxlevel: 0,
	},

	getters: {
		makercheckers(state) {
			return state.makercheckers;
		},
		makerchecker(state) {
			return state.makerchecker;
		},
		makercheckershistory(state) {
			return state.makercheckershistory;
		},
		makercheckermaxlevel(state) {
			return state.makercheckermaxlevel;
		},
	},

	mutations: {
		SET_MAKER_CHECKERS(state, makercheckers) {
			return (state.makercheckers = makercheckers);
		},

		SET_MAKER_CHECKER(state, makerchecker) {
			return (state.makerchecker = makerchecker);
		},

		SET_MAKER_CHECKERS_HISTORY(state, makercheckershistory) {
			return (state.makercheckershistory = makercheckershistory);
		},

		SET_MAKER_CHECKER_MAX_LEVEL(state, maxlevel) {
			state.makercheckermaxlevel = maxlevel;
		},
	},

	actions: {
		/* async fetchMakerCheckers({ commit }, params) {
			await axios
				.get("/api/v1/maker-checkers", { params: params })
				.then((res) => {
					commit("SET_MAKER_CHECKERS", res.data);
					return res.data;
				})
				.catch((err) => {
					return Promise.reject(err);
				});
		}, */

		async fetchMakerCheckers({ commit }, params) {
			try {
			  const response = await axios.get("/api/v1/maker-checkers", { params });
			  // console.log("fetchMakerCheckers response:", response);
			  commit("SET_MAKER_CHECKERS", response.data);
			  return response.data;
			} catch (error) {
			 // console.error("Error in fetchMakerCheckers:", error);
			  // throw error; 
			}
		},

		async fetchMakerCheckersHistory({ commit }, params) {
			await axios
				.get(
					`/api/v1/maker-checkers/history/${params.maker_checker_id}`,
					{ params: params }
				)
				.then((res) => {
					commit("SET_MAKER_CHECKERS_HISTORY", res.data);
					return res.data;
				})
				.catch((err) => {
					return Promise.reject(err);
				});
		},

		async fetchMakerChecker({ commit }, maker_checker_id) {
			try {
				const res = await axios.get(
					`/api/v1/maker-checkers/${maker_checker_id}`
				);

				// console.log("ndio data ======= ", res.data.data);

				if (res.statusText === "OK") {
					commit("SET_MAKER_CHECKER", res.data.data);
					return res.data.data;
				}
			} catch (err) {
				return Promise.reject(err);
			}
		},

		async fetchMakerChecker2({ commit }, maker_checker_id) {
			await axios
				.get(`/api/v1/maker-checkers/${maker_checker_id}`)
				.then((res) => {
					if (res.statusText === "OK") {
						commit("SET_MAKER_CHECKER", res.data.data);
						Promise.resolve(res.data.data);
					}
				})
				.catch((err) => {
					return Promise.reject(err);
				});
		},

		async fetchMakerCheckerMaxLevel({ commit }, maker_checker_id) {
			try {
				const response = await axios.get(
					`/api/v1/maker-checkers/max-level/${maker_checker_id}`
				);
				if (response.statusText === "OK") {
					commit(
						"SET_MAKER_CHECKER_MAX_LEVEL",
						response.data.maxLevel
					);
					return response.data.maxLevel;
				}
			} catch (error) {
				return Promise.reject(error);
			}
		},

		async createMakerChecker({ commit, dispatch }, makerchecker) {
			console.log("createMakerChecker HAAPAPA===", makerchecker);

			await axios
				.post("/api/v1/maker-checkers", makerchecker)
				.then((res) => {
					if (res.statusText === "OK") {
						dispatch("fetchMakerCheckers");
					}
				})
				.catch((err) => {
					return Promise.reject(err);
				});
		},

		async updateMakerChecker({ commit, dispatch }, makerchecker) {
			await axios(
				"/api/v1/maker-checkers/" + makerchecker.media_template_id,
				{
					method: "patch",
					data: makerchecker,
				}
			)
				.then((res) => {
					if (res.statusText === "OK") {
						dispatch("fetchMakerCheckers");
					}
				})
				.catch((err) => {
					return Promise.reject(err);
				});
		},

		async approveMakerChecker({ commit, dispatch }, makerchecker) {
			// console.log("makerchecker store", makerchecker);
			await axios
				.post(
					`/api/v1/maker-checkers/approve/${makerchecker?.maker_checker_id}`,
					makerchecker
				)
				.then((res) => {
					if (res.statusText === "OK") {
						dispatch(
							"fetchMakerChecker",
							makerchecker?.maker_checker_id
						);
					}
				})
				.catch((err) => {
					return Promise.reject(err);
				});
		},

		async rejectMakerChecker({ commit, dispatch }, makerchecker) {
			// console.log("makerchecker store", makerchecker);
			await axios
				.post(
					`/api/v1/maker-checkers/reject/${makerchecker?.maker_checker_id}`,
					makerchecker
				)
				.then((res) => {
					if (res.statusText === "OK") {
						dispatch(
							"fetchMakerChecker",
							makerchecker?.maker_checker_id
						);
					}
				})
				.catch((err) => {
					return Promise.reject(err);
				});
		},

		async deleteMakerChecker({ commit }, maker_checker_id) {
			await axios
				.delete(`/api/v1/maker-checkers/${maker_checker_id}`)
				.then((res) => {
					if (res.statusText === "OK") {
						dispatch("fetchMakerCheckers");
					}
				})
				.catch((err) => {
					return Promise.reject(err);
				});
		},
	},
};
