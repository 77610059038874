import axios from "axios";

export default {

    namespaced: true,

    state: {

        smstemplates: [],
        smstemplate: {},
        smstemplateshistory: {}

    },

    getters: {

        smstemplates(state) {
            return state.smstemplates;
        },
        smstemplate(state) {
            return state.smstemplate;
        },
        smstemplateshistory(state) {
            return state.smstemplateshistory;
        }

    },

    mutations: {

        SET_SMS_TEMPLATES(state, smstemplates) {
            return state.smstemplates = smstemplates
        },

        SET_SMS_TEMPLATE(state, smstemplate) {
            return state.smstemplate = smstemplate
        },

        SET_SMS_TEMPLATES_HISTORY(state, smstemplateshistory) {
            return state.smstemplateshistory = smstemplateshistory
        }

    },

    actions: {

        async fetchSmsTemplates({ commit }, params) {

            await axios.get('/api/v1/sms-templates', { params: params })
                .then(res => {
                    commit('SET_SMS_TEMPLATES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchSmsTemplatesHistory({ commit }, params) {

            await axios.get(`/api/v1/sms-templates/history/${params.sms_template_id}`, { params: params })
                .then(res => {
                    commit('SET_SMS_TEMPLATES_HISTORY', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchSmsTemplate({ commit }, sms_template_id) {

            await axios.get(`/api/v1/sms-templates/${sms_template_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_SMS_TEMPLATE', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createSmsTemplate({ commit, dispatch }, smstemplate) {

            await axios.post('/api/v1/sms-templates', smstemplate)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchSmsTemplates');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateSmsTemplate({ commit, dispatch }, smstemplate) {

            await axios("/api/v1/sms-templates/" + smstemplate.media_template_id, {
                method: "patch",
                data: smstemplate,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchSmsTemplates');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async resetSmsTemplate({ commit, dispatch }, sms_template_id) {

            await axios.post(`/api/v1/sms-templates/reset/${sms_template_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    // dispatch('fetchSmsTemplates');
                    dispatch('fetchSmsTemplate', sms_template_id);
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteSmsTemplate({ commit }, sms_template_id) {

            await axios.delete(`/api/v1/sms-templates/${sms_template_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchSmsTemplates');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
