import axios from "axios";

export default {
	namespaced: true,

	state: {
		companycolors: [],
		companycolor: {},
	},

	getters: {
		companycolors(state) {
			return state.companycolors;
		},
		companycolor(state) {
			return state.companycolor;
		},
	},

	mutations: {
		SET_COMPANY_COLORS(state, companycolors) {
			return (state.companycolors = companycolors);
		},

		SET_COMPANY_COLOR(state, companycolor) {
			return (state.companycolor = companycolor);
		},
	},

	actions: {
		async fetchCompanyColors({ commit }, params) {
			await axios
				.get("/api/v1/company-colors", { params: params })
				.then((res) => {
					console.log("res == ", res);
					commit("SET_COMPANY_COLORS", res.data);
				})
				.catch((err) => {
					return Promise.reject(err);
				});
		},

		
		async fetchCompanyColorById({ commit }, id) {
			await axios
				.get(`/api/v1/company-colors/${id}`)
				.then((res) => {
					if (res.statusText === "OK") {
						commit("SET_COMPANY_COLOR", res.data.data);
					}
				})
				.catch((err) => {
					return Promise.reject(err);
				});
		},

		async fetchCompanyColor({ commit }, company_id) {
			await axios
				.get(`/api/v1/company-colors/company/${company_id}`)
				.then((res) => {
					// console.log("fetchCompanyColor store == ", res.data.data);
					if (res.statusText === "OK") {
						commit("SET_COMPANY_COLOR", res.data.data);
					}
				})
				.catch((err) => {
					return Promise.reject(err);
				});
		},

		/* async fetchActiveCompanyColor({ commit }) {
			await axios
				.get(`/api/v1/company-colors/active-colors`)
				.then((res) => {
					// console.log("fetchCompanyColor store == ", res);
					if (res.statusText === "OK") {
						commit("SET_COMPANY_COLOR", res.data.data);
						return res.data.data;
					}
				})
				.catch((err) => {
					return Promise.reject(err);
				});
		}, */

		async fetchActiveCompanyColor({ commit }) {
            try {
              const res = await axios.get('/api/v1/company-colors/active-colors');
              commit('SET_COMPANY_COLOR', res.data.data);
              return res.data.data;
            } catch (error) {
              // console.error('Error fetching translations:', error); 
              return Promise.reject(error);
            }
        },

		async createUpdateCompanyColor({ commit, dispatch }, companycolor) {
			return new Promise((resolve, reject) => {
				axios
					.post("/api/v1/company-colors/create-update", companycolor)
					.then((response) => {
						console.log("response.statusText", response.statusText);
						if (response.statusText === "OK") {
							resolve(response.data);
						}
					})
					.catch((error) => {
						// console.log("Error updating upload:", error);
						reject(error);
					});
			});
		},

		async updateCompanyColor({ commit, dispatch }, companycolor) {
			return new Promise((resolve, reject) => {
				axios
					.patch("/api/v1/company-colors/" + companycolor.company_color_id, companycolor)
					.then((response) => {
						console.log("response.statusText", response.statusText);
						if (response.statusText === "OK") {
							// dispatch("fetchActiveCompanyColor");
							resolve(response.data);
						}
					})
					.catch((error) => {
						console.log("Error updating upload:", error);
						reject(error);
					});
			});
		},
	},
};
