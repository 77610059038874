<template>
  <div class="container-fluid no-gutters px-0">
    <div class="row">
      <div class="col-lg-12 mt-3xx mb-2">
        <search-bar :bread-crumb-only="true"></search-bar>

        <div class="card">
          <!-- <hr class="small"> -->

          <div class="card-body">
            <form @submit.prevent="onSubmitted">
              <div class="row">
                <div class="col-lg-6">
                  <h4>Role details</h4>

                  <hr />

                  <div
                    class="form-group row"
                    :class="{ 'has-error': model.name.$invalid }"
                  >
                    <label for="name" class="col-sm-4 col-form-label text-right">
                      Role Name
                    </label>
                    <div class="col-sm-8">
                      <input
                        class="form-control"
                        :class="{ 'form-control-danger': model.name.$invalid }"
                        type="text"
                        :disabled="isShow"
                        v-model="model.name.$model"
                      />

                      <error-msg :property="model.name" />
                    </div>
                  </div>

                  <div
                    class="form-group row"
                    :class="{ 'has-error': model.description.$invalid }"
                  >
                    <label for="description" class="col-sm-4 col-form-label text-right">
                      Role Description
                    </label>
                    <div class="col-sm-8">
                      <input
                        class="form-control"
                        :class="{ 'form-control-danger': model.description.$invalid }"
                        type="text"
                        :disabled="isShow"
                        v-model="model.description.$model"
                      />

                      <error-msg :property="model.description" />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="company_id" class="col-sm-4 col-form-label text-right">
                      Company Name
                    </label>
                    <div class="col-sm-8">
                      <select
                        class="form-control"
                        v-model="model.company_id.$model"
                        :disabled="isShow"
                        v-if="companies.data"
                      >
                        <option value="" selected v-if="!isEdit">Select Company</option>
                        <option
                          v-for="(item, index) in companies.data"
                          :key="index"
                          :value="item.company_id"
                        >
                          {{ item.company_name }}
                        </option>
                      </select>
                      <error-msg :property="model.company_id" />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <h4>Select role permissions</h4>

                  <hr />

                  <div class="card">
                    <div class="card-body">
                      <div class="help-activity-height" data-simplebar>
                        <div class="activity">
                          <div class="activity-info">
                            <div class="table-responsive">
                              <table class="table mb-0">
                                <thead class="thead-light">
                                  <tr>
                                    <th>Permission Name</th>
                                    <th>Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(item, index) in permissions.data"
                                    :key="index"
                                  >
                                    <td>
                                      <div class="checkbox checkbox-info">
                                        <input
                                          type="checkbox"
                                          name="permissions"
                                          :value="item.name"
                                          :disabled="isShow"
                                          v-model="model.permissions.$model"
                                          :id="item.name"
                                        />
                                        <label :for="item.name">{{ item.name }}</label>
                                      </div>
                                    </td>
                                    <td>{{ item.description }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="mt-3 row">
                        <table class="table mb-0 mt-3" v-if="!isShow">
                          <tbody>
                            <tr>
                              <td>
                                <div class="checkbox checkbox-info">
                                  <input
                                    type="checkbox"
                                    id="chkCheckAll"
                                    @click="checkAll()"
                                    v-model="isCheckAll"
                                  />
                                  <label for="chkCheckAll">SELECT ALL</label>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <!-- show maker checker form buttons/ status -->
                <maker-checker-button-component
                    :makerChecker="makerChecker"
                    v-if="isMakerChecker"
                />

                <div class="col-lg-12" v-else>
                  <div class="form-group">
                    <button
                      class="btn btn-primary mr-3"
                      type="submit"
                      :disabled="model.$invalid"
                      :class="{ 'btn-secondary': model.$invalid }"
                      v-if="!isShow"
                    >
                      <slot name="submitButton"></slot>
                    </button>
                    <button
                      class="btn btn-primary mr-3"
                      type="button"
                      @click="onEdit"
                      v-if="isShow"
                    >
                      Edit
                    </button>
                    <button class="btn btn-danger" type="button" @click="onCancelled">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, computed, watch, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, url } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

const roleForm = {
  name: "",
  description: "",
  company_id: 1,
  permissions: [],
  role_id: undefined,
};

const module = "roles";
const permission_module = "permissions";
const company_module = "companies";

export default defineComponent({
  name: "RoleDetail",

  components: {},

  props: {
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMakerChecker: {
      type: Boolean,
      required: false,
      default: false,
    },
    makerChecker: {
      type: Object,
      required: false,
    },
  },

  emits: ["submitForm", "cancelForm"],

  setup(props, { emit }) {

    // avoid destructuring props directly in the root scope of setup
    const roleProp = toRefs(props);

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    let role = ref(roleForm);
    let isCheckAll = ref(false);
    let permissions = ref([]);

    // load all companies on create component
    let payload = { report: "1" };

    // load and set company list to the first item
    store.dispatch(`${company_module}/fetchCompanies`, payload).then(() => {
      // Set the first company as the default value
      if (store.getters[`${company_module}/companies`].data.length > 0) {
        role.value.company_id = store.getters[`${company_module}/companies`].data[0].company_id;
      }
    });

    // load all permissions on create component
    store.dispatch(`${permission_module}/fetchPermissions`, payload);

    // on created. Are we in edit mode?
    if (props.isEdit || props.isShow) {
      role = computed(() => store.getters[`${module}/role`]);
    }
    
    // if we are in maker checker, use maker checker data
    if (roleProp.isMakerChecker.value) {
      role = roleProp.makerChecker.value?.data;
    }

    const rules = {
      name: { required },
      description: {},
      permissions: {},
      company_id: {},
    };

    // validate form
    const model = useVuelidate(rules, role);

    permissions = computed(() => store.getters[`${permission_module}/permissions`]);

    function onSubmitted() {
      emit("submitForm", role);
    }

    function onCancelled() {
      emit("cancelForm");
    }

    function onEdit() {
      emit("editForm", role);
    }

    function checked(e) {
      roleForm.status_id = !!e.target.checked;
    }

    function checkAll() {
        isCheckAll.value = !isCheckAll.value; // Toggle the value of isCheckAll

        if (isCheckAll.value === true) {
            // If SELECT ALL is checked, add all permissions to role.value.permissions
            role.value.permissions = permissions.value.data.map((a) => a.name);
        } else {
            // If SELECT ALL is unchecked, clear role.value.permissions
            role.value.permissions = [];
        }
        console.log("isCheckAll", isCheckAll.value);
    }

    /* function checkAll() {
      // isCheckAll = !isCheckAll;
      if (isCheckAll.value == true) {
        isCheckAll = false;
      } else {
        isCheckAll = true;
      }
      // console.log("permissions.value", permissions.value.data);
      // let permissions = permissions;
      if (isCheckAll.value === true) {
        for (var key in permissions.value.data) {
          role.value.permissions.push(permissions.value.data.map((a) => a.name)[key]);
        }
      } else {
        role.value.permissions = [];
      }
      console.log("isCheckAll", isCheckAll);
    } */

    return {
      submitForm: props.submitForm,
      companies: computed(() => store.getters[`${company_module}/companies`]),
      permissions,
      model,
      // role: computed(() => store.getters[`${module}/role`]),
      role,
      checked,
      onSubmitted,
      onEdit,
      onCancelled,
      checkAll,
      isCheckAll,
    };
  },
});
</script>
