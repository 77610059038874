import axios from "axios";

export default {

    namespaced: true,

    state: {

        Loanappraisaldetails: [],
        Loanappraisaldetail: {}

    },

    getters: {

        Loanappraisaldetails(state) {
            return state.Loanappraisaldetails;
        },
        Loanappraisaldetail(state) {
            return state.Loanappraisaldetail;
        }

    },

    mutations: {

        SET_lOAN_APPRAISAL_DETAILS(state, Loanappraisaldetails) {
            return state.Loanappraisaldetails = Loanappraisaldetails
        },

        SET_lOAN_APPRAISAL_DETAIL(state, Loanappraisaldetail) {
            return state.Loanappraisaldetail = Loanappraisaldetail
        }

    },

    actions: {

        async fetchLoanappraisaldetails({ commit }, params) {

            await axios.get('/api/v1/loan-appraisal-detail', { params: params })
                .then(res => {
                    commit('SET_lOAN_APPRAISAL_DETAILS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchLoanappraisaldetail({ commit }, loan_appraisal_detail_id) {

            await axios.get(`/api/v1/loan-appraisal-detail/${loan_appraisal_detail_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_lOAN_APPRAISAL_DETAIL', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createLoanappraisaldetail({ commit, dispatch }, Loanappraisaldetail) {

            await axios.post('/api/v1/loan-appraisal-detail', Loanappraisaldetail)
                .then(res => {
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateLoanappraisaldetail({ commit, dispatch }, Loanappraisaldetail) {

            await axios("/api/v1/loan-appraisal-detail/" + Loanappraisaldetail.loan_appraisal_detail_id, {
                method: "patch",
                data: Loanappraisaldetail,
            }).then(res => {
             
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteLoanappraisaldetail({ commit }, loan_appraisal_detail_id) {

            await axios.delete(`/api/v1/loan-appraisal-detail/${loan_appraisal_detail_id}`)
            .then(res => {
            
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
