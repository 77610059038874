const bankingFinanceRoutes = [

    {

        path: '',
        name: 'bankingHome',
        component: () => import('./HomeBanking.vue'),
        meta: {
            auth: true,
            title: 'banking',
            breadcrumb: 'banking',
            permission: []
        },

    },

  
    {
        path: 'telleraccount',
        name: 'telleraccount',
        component: () => import('./TellerAccountList.vue'),
        meta: {
            auth: true,
            title: 'Teller Account',
            breadcrumb: 'Teller Account',
            permission: ['view telleraccount']
        }
    },

    {
        path: 'vaultaccount',
        name: 'vaultaccount',
        component: () => import('./VaultAccountList.vue'),
        meta: {
            auth: true,
            title: 'Vault Account',
            breadcrumb: 'Vault Account',
            permission: ['view vaultaccount']
        }
    },

    {
        path: 'tellerrequest',
        name: 'tellerrequest',
        component: () => import('./TellerRequests.vue'),
        meta: {
            auth: true,
            title: 'Teller Requests',
            breadcrumb: 'Teller Requests',
            permission: ['view tellerrequest']
        }
    },

    {
        path: 'vaultrequest',
        name: 'vaultrequest',
        component: () => import('./VaultRequests.vue'),
        meta: {
            auth: true,
            title: 'Vault Requests',
            breadcrumb: 'Vault Requests',
            permission: ['view vaultrequest']
        }
    },

    {
        path: 'mytill',
        name: 'mytill',
        component: () => import('./MyVault.vue'),
        meta: {
            auth: true,
            title: 'My Till',
            breadcrumb: 'My Till',
            permission: ['view mytill']
        }
    },

    {
        path: 'branchvault',
        name: 'branchvault',
        component: () => import('./BranchVault.vue'),
        meta: {
            auth: true,
            title: 'Branch Vault',
            breadcrumb: 'Branch Vault',
            permission: ['view branchvault']
        }
    },

    {
        path: 'my-transactions',
        name: 'tellertransactions',
        component: () => import('./MyTransactions.vue'),
        meta: {
            auth: true,
            title: 'My Transactions',
            breadcrumb: 'My Transactions',
            permission: ['view mytransactions']
        }
    },

    {
        path: 'statement/:teller_account_id',
        name: 'statement',
        component: () => import('./Statement.vue'),
        meta: {
            auth: true,
            title: 'Statement',
            breadcrumb: 'Statement',
            permission: ['view statement']
        }
      },

];

export default bankingFinanceRoutes;
