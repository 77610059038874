const accountFinanceRoutes = [

    {

        name: 'accountsHome',
        path: '',
        component: () => import('./ListAccounts.vue'),
        meta: {
            auth: true,
            title: 'Chart Of Accounts',
            breadcrumb: 'Chart Of Accounts',
            permission: ['view account']
        }

    },
    {

        name: 'treeTestHome',
        path: 'tree-test',
        component: () => import('./TreeTest.vue'),
        meta: {
            auth: true,
            title: 'Chart Of Accounts - Tree Test',
            breadcrumb: 'Chart Of Accounts - Tree Test',
            permission: ['view account']
        }

    },

];

export default accountFinanceRoutes;
