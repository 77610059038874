import getters from './getters.js';
import actions from './actions.js';
import mutations from './mutations.js';

export default {
    namespaced: true,
    state: {
        manual_transaction_uploads: { data: [] },
        manual_transaction_upload: {},
        failed_manual_transactions: { data: [] },
        posted_manual_transactions: { data: [] }
    },
    getters,
    actions,
    mutations,
}