const userRegistrationRoutes = [

    {
        name: 'userHome',
        path: '',
        component: () => import('./ListUsers.vue'),
        meta: {
            auth: true,
            title: 'Users Listing',
            breadcrumb: 'Users Listing',
            permission: ['view user']
        }

    },

    {
        path: 'create',
        name: 'createUser',
        component: () => import('./CreateUser.vue'),
        meta: {
            auth: true,
            title: 'Create User',
            breadcrumb: 'Create User',
            permission: ['create user']
        }
    },

    {
        path: ':id',
        name: 'showUser',
        component: () => import('./ShowUser.vue'),
        meta: {
            auth: true,
            title: 'Showing User',
            breadcrumb: 'Showing User',
            permission: ['view user']
        }
    },

    {
        path: ':id/edit',
        name: 'editUser',
        component: () => import('./EditUser.vue'),
        meta: {
            auth: true,
            title: 'Edit User',
            breadcrumb: 'Edit User',
            permission: ['update user']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteUser',
        meta: {
            auth: true,
            title: 'Delete User',
            permission: ['delete user']
        }
    },

];

export default userRegistrationRoutes;
