import axios from "axios";

export default {

    namespaced: true,

    state: {

        maritalstatuses: [],
        maritalstatus: {}

    },

    getters: {

        maritalstatuses(state) {
            return state.maritalstatuses;
        },
        maritalstatus(state) {
            return state.maritalstatus;
        }

    },

    mutations: {

        SET_MARITAL_STATUSES(state, maritalstatuses) {
            return state.maritalstatuses = maritalstatuses
        },

        SET_MARITAL_STATUS(state, maritalstatus) {
            return state.maritalstatus = maritalstatus
        }

    },

    actions: {

        async fetchMaritalStatuses({ commit }, params) {

            await axios.get('/api/v1/maritalstatuses', { params: params })
                .then(res => {
                    // console.log(res.data);
                    commit('SET_MARITAL_STATUSES', res.data);
                    return Promise.resolve(res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchMaritalStatus({ commit }, marital_status_id) {

            await axios.get(`/api/v1/maritalstatuses/${marital_status_id}`)
                .then(res => {
                    // console.log("fetchMaritalStatus store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_MARITAL_STATUS', res.data.data);
                        return Promise.resolve(res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        }

    }

}
