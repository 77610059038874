import axios from "axios";

export default {

    namespaced: true,

    state: {

        collaterals: [],
        collateral_by_customer:[],
        collateral: {}

    },

    getters: {

        collaterals(state) {
            return state.collaterals;
        },
        collateralsbycustomer(state) {
            return state.collateral_by_customer;
        },
        collateral(state) {
            return state.collateral;
        }

    },

    mutations: {

        SET_COLLATERALS(state, collaterals) {
            return state.collaterals = collaterals
        },

        SET_COLLATERAL(state, collateral) {
            return state.collateral = collateral
        },

        SET_COLLATERAL_BY_CUSTOMER(state, collateral){
            return state.collateral_by_customer = collateral
        }

    },

    actions: {

        async fetchcollaterals({ commit }, params) {

            await axios.get('/api/v1/collaterals', { params: params })
                .then(res => {
                    commit('SET_COLLATERALS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },
        async fetchCustomerCollaterals({ commit }, customer_id) {
            try {
                const response = await axios.get(`/api/v1/collaterals/getbycustomerid/${customer_id}`);
                return response.data.data;
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async fetchcollateral({ commit }, collateral_id) {

            await axios.get(`/api/v1/collaterals/${collateral_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_COLLATERAL', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchcollateralbycustomer({ commit }, customer_id) {

            await axios.get(`/api/v1/collaterals/getbycustomerid/${customer_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_COLLATERAL_BY_CUSTOMER', res.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },


        async createcollateral({ commit, dispatch }, collateral) {
            await axios.post('/api/v1/collaterals', collateral)
                .then(res => {

                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updatecollateral({ commit, dispatch }, collateral) {

            await axios("/api/v1/collaterals/" + collateral.collateral_id, {
                method: "patch",
                data: collateral,
            }).then(res => {

            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deletecollateral({ commit }, collateral_id) {

            await axios.delete(`/api/v1/collaterals/${collateral_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchcollaterals');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
