import axios from "axios";


export default {

    namespaced: true,

    state: {
        cashier: {},
        cashierlist: [],
        cashiertransactions:[],
        automaticpayments:[],
        automaticpayment:{},
       
    },

    getters: {
         cashier(state) {
             return state.cashier;
         },
        cashierlist(state) {
            return state.cashierlist;
        },
        cashiertransactions(state) {
            return state.cashiertransactions;
        },

        automaticpayments(state) {
            return state.automaticpayments;
        },
        automaticpayment(state) {
            return state.automaticpayment;
        },

       
    },

    actions: {
        fetchcashierlist({ commit }, params) {
            axios.get('/api/v1/cashmanagement', { params: params })
                .then(res => {
                    commit('FETCH_CASHIER_LIST', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        fetchcashiertransactions({ commit }, params) {
            axios.get('/api/v1/cashmanagement/transactions',{params: params})
                .then(res => {
                    commit('FETCH_CASHIER_TRANSACTIONS', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async fetchcashier({ commit }, id) {
            await axios.get('/api/v1/cashmanagement/' + id, )
                .then(res => {
                    commit('FETCH_CASHIER', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async createcashier({ commit, dispatch }, payload) {
            await axios.post('/api/v1/cashmanagement', payload)
                .then(res => {
                    dispatch('fetchcashierlist');
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updatecashier({ commit, dispatch }, payload) {
            await axios.patch('/api/v1/cashmanagement/' + payload.cashier_management_id, payload )
            .then(res => {
                dispatch('fetchcashierlist');
                
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async acceptcash({ commit, dispatch }, payload) {
            await axios.post('/api/v1/cashmanagement/acceptcash',payload)
            .then(res => {
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async returncash({ commit, dispatch }, payload) {
            await axios.post('/api/v1/cashmanagement/returncash',payload)
            .then(res => {
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async reconcile({ commit, dispatch }, payload) {
            await axios.post('/api/v1/cashmanagement/reconcile',payload)
            .then(res => {
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        //Start of Automatic payments

        
        fetchautomaticpayments({ commit }, params) {
            axios.get('/api/v1/automaticpayments',{params: params})
                .then(res => {
                    commit('FETCH_AUTOMATIC_PAYMENTS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        fetchautomaticpayment({ commit }, id) {
            axios.get('/api/v1/automaticpayments/'+id)
                .then(res => {
                    commit('FETCH_AUTOMATIC_PAYMENT', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

      
        async updateautomaticpayment({ commit, dispatch }, payload) {
            await axios.patch('/api/v1/automaticpayments/' + payload.automatic_payment_id, payload )
            .then(res => {
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async repostautomaticpayment({ commit, dispatch }, payload) {
            await axios.post('/api/v1/automaticpayments',payload)
            .then(res => {
            }).catch(err => {
                return Promise.reject(err);
            })

        },
  
    },

    mutations: {
        FETCH_CASHIER(state, data) {
            state.cashier = data;
        },
        FETCH_CASHIER_LIST(state, data) {
            state.cashierlist = data;
        },

        FETCH_CASHIER_TRANSACTIONS(state, data) {
            state.cashiertransactions = data;
        },

        FETCH_AUTOMATIC_PAYMENTS(state, data) {
            state.automaticpayments = data;
        },

        FETCH_AUTOMATIC_PAYMENT(state, data) {
            state.automaticpayment = data;
        },

      
    }

}