import axios from 'axios';

const BASE_URL = '/api/v1/manual-transaction-uploads';

export default {
    // Fetch all manual transaction uploads
    fetchManualTransactionUploads({ commit }) {
        return axios.get(`${BASE_URL}`)
            .then(response => {
                commit('SET_MANUAL_TRANSACTION_UPLOADS', response.data);
            })
            .catch(error => {
                console.error('Error fetching manual transaction uploads:', error);
                return Promise.reject(error);
            });
    },

    // Fetch a specific manual transaction upload by ID
    fetchManualTransactionUpload({ commit }, id) {
        // console.log(id)
        return axios.get(`${BASE_URL}/${id}/show`)
            .then(response => {
                commit('SET_MANUAL_TRANSACTION_UPLOAD', response.data.data);
            })
            .catch(error => {
                console.error('Error fetching manual transaction upload:', error);
                return Promise.reject(error);
            });
    },

    // Create a new manual transaction upload
    createManualTransactionUpload({ commit }, { file, account_type, account_id, onUploadProgress }) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('account_type', account_type);
        formData.append('account_id', account_id);

        return axios.post(`${BASE_URL}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                if (onUploadProgress) {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onUploadProgress(progress);  // Relay the progress back
                }
            }
        })
            .then(response => {
                commit('ADD_MANUAL_TRANSACTION_UPLOAD', response.data.data);
            })
            .catch(error => {
                console.error('Error creating manual transaction upload:', error);
                return Promise.reject(error);
            });
    },

    // Partially update a manual transaction upload (PATCH)
    patchManualTransactionUpload({ commit }, { id, payload }) {
        return axios.patch(`${BASE_URL}/${id}`, payload)
            .then(response => {
                commit('UPDATE_MANUAL_TRANSACTION_UPLOAD', response.data);
            })
            .catch(error => {
                console.error('Error patching manual transaction upload:', error);
                return Promise.reject(error);
            });
    },

    // Fetch failed manual transactions for a specific upload by ID
    fetchFailedManualTransactions({ commit }, id) {
        return axios.get(`${BASE_URL}/${id}/failed-transactions`)
            .then(response => {
                commit('SET_FAILED_MANUAL_TRANSACTIONS', response.data);
            })
            .catch(error => {
                console.error('Error fetching failed manual transactions:', error);
                return Promise.reject(error);
            });
    },

    // Fetch posted manual transactions for a specific upload by ID
    fetchPostedManualTransactions({ commit }, id) {
        return axios.get(`${BASE_URL}/${id}/posted-transactions`)
            .then(response => {
                commit('SET_POSTED_MANUAL_TRANSACTIONS', response.data);
            })
            .catch(error => {
                console.error('Error fetching posted manual transactions:', error);
                return Promise.reject(error);
            });
    },

    // Fetch the status of a manual transaction upload by ID
    fetchManualTransactionUploadStatus({ commit }, id) {
        return axios.get(`${BASE_URL}/${id}/status`)
            .then(response => {
                commit('SET_MANUAL_TRANSACTION_UPLOAD', response.data); // Assuming status is part of the upload object
            })
            .catch(error => {
                console.error('Error fetching manual transaction upload status:', error);
                return Promise.reject(error);
            });
    }
};