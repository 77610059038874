export const getFilterParams = (filter) => {
  if (typeof filter !== "string") {
    throw new TypeError("Filter must be a string");
  }

  let custom_key = null,
    summary_type = null,
    period = filter.toLowerCase().replace(/\s+/g, "_");
  let start_date = null;
  let end_date = null;
  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };
  const today = new Date();

  switch (filter) {
    case "Today":
      custom_key = "H";
      summary_type = "hourly";
      start_date = end_date = formatDate(today);
      break;
    case "Yesterday":
      custom_key = "H";
      summary_type = "hourly";
      start_date = formatDate(new Date(today.setDate(today.getDate() - 1)));
      end_date = formatDate(new Date());
      break;
    case "Last Week":
      custom_key = "j-M";
      summary_type = "daily";
      // eslint-disable-next-line no-case-declarations
      const lastWeekStart = new Date(
        today.setDate(today.getDate() - today.getDay() - 7)
      );
      // eslint-disable-next-line no-case-declarations
      const lastWeekEnd = new Date(today.setDate(lastWeekStart.getDate() + 6));
      start_date = formatDate(lastWeekStart);
      end_date = formatDate(lastWeekEnd);
      break;
    case "This Month":
      custom_key = "j-M";
      summary_type = "daily";
      // eslint-disable-next-line no-case-declarations
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      // eslint-disable-next-line no-case-declarations
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      start_date = formatDate(startOfMonth);
      end_date = formatDate(endOfMonth);
      break;
    case "Last Month":
      custom_key = "j-M";
      summary_type = "daily";
      // eslint-disable-next-line no-case-declarations
      const startOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      // eslint-disable-next-line no-case-declarations
      const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      start_date = formatDate(startOfLastMonth);
      end_date = formatDate(endOfLastMonth);
      break;
    case "This Year":
      custom_key = "M";
      summary_type = "monthly";
      // eslint-disable-next-line no-case-declarations
      const startOfYear = new Date(today.getFullYear(), 0, 1);
      // eslint-disable-next-line no-case-declarations
      const endOfYear = new Date(today.getFullYear(), 11, 31);
      start_date = formatDate(startOfYear);
      end_date = formatDate(endOfYear);
      break;
  }

  return { custom_key, summary_type, period, start_date, end_date };
};
