import axios from "axios";

export default {

    namespaced: true,

    state: {

        countries: [],
        country: {}

    },

    getters: {

        countries(state) {
            return state.countries;
        },
        country(state) {
            return state.country;
        }

    },

    mutations: {

        SET_COUNTRIES(state, countries) {
            return state.countries = countries
        },

        CLEAR_COUNTRIES(state) {
            return state.countries = []
        },

        SET_COUNTRY(state, country) {
            return state.country = country
        }

    },

    actions: {

        async fetchCountries({ commit }, params) {

            await axios.get('/api/v1/countries', { params: params })
                .then(res => {
                    // console.log(res.data);
                    commit('SET_COUNTRIES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchCountry({ commit }, country_id) {

            await axios.get(`/api/v1/countries/${country_id}`)
                .then(res => {
                    // console.log("fetchCountry store == ", res);
                    if (res.countryText === 'OK') {
                        commit('SET_COUNTRY', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async clearValues({ commit }) {
            commit('CLEAR_COUNTRIES');
        }

    }

}
