import accountFinanceRoutes from './Accounts/accountFinanceRoutes';
import financialYearFinanceRoutes from './FinacialYear/financialYearFinanceRoutes';
import journalFinanceRoutes from './Journals/journalFinanceRoutes';
import payableFinanceRoutes from './Payables/payableFinanceRoutes';
import receivableFinanceRoutes from './Receivables/receivableFinanceRoutes';
import reportFinanceRoutes from './Reports/reportFinanceRoutes';
import Base from '../../Common/Base.vue';

const financeModuleRoutes = [

    {

        path: 'financial-years',
        name: 'financialyearHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Financial Years',
            breadcrumb: 'Financial Years',
            permission: ['view financialyear', 'create financialyear', 'update financialyear', 'delete financialyear']
        },

        children: financialYearFinanceRoutes

    },

    {

        path: 'accounts',
        name: 'accountHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Accounts Listing',
            breadcrumb: 'Accounts Listing',
            permission: ['view account', 'create account', 'update account', 'delete account']
        },

        children: accountFinanceRoutes

    },

    {

        path: 'journals',
        name: 'journalHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Journals Listing',
            breadcrumb: 'Journals Listing',
            permission: ['view journal', 'create journal', 'update journal', 'delete journal']
        },

        children: journalFinanceRoutes

    },

    {

        path: 'payables',
        name: 'payableHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Payables',
            breadcrumb: 'Payables',
            // permission: ['view payable', 'create payable', 'update payable', 'delete payable']
            permission: []
        },

        children: payableFinanceRoutes

    },

    {

        path: 'receivables',
        name: 'receivableHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Receivables',
            breadcrumb: 'Receivables',
            permission: [
                'view financereport', 'create financereport', 'update financereport', 'delete financereport',
                'view salesinvoice', 'create salesinvoice', 'update salesinvoice', 'delete salesinvoice',
                'view otherreceipt', 'create otherreceipt', 'update otherreceipt', 'delete otherreceipt',
                'view customerreceivable', 'create customerreceivable', 'update customerreceivable', 'delete customerreceivable',
                'view customeradjustment', 'create customeradjustment', 'update customeradjustment', 'delete customeradjustment'
            ]
        },

        children: receivableFinanceRoutes

    },


    {

        path: 'reports',
        name: 'reportHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Reports',
            breadcrumb: 'Reports',
            permission: ['view financereport', 'create financereport', 'update financereport', 'delete financereport']
        },

        children: reportFinanceRoutes

    },

];

export default financeModuleRoutes;
