class sessionStorageService {

    /**
     * Save data to sessionStorage.
     * @param {string} key - The key under which to store the data.
     * @param {any} data - The data to be stored.
     */
    static saveData(key, data) {
      try {
        const jsonData = JSON.stringify(data);
        sessionStorage.setItem(key, jsonData);
      } catch (error) {
        console.error("Error saving data to sessionStorage:", error);
      }
    }
  
    /**
     * Retrieve data from sessionStorage.
     * @param {string} key - The key under which the data is stored.
     * @returns {any} - The retrieved data.
     */
    static getData(key) {
      try {
        const jsonData = sessionStorage.getItem(key);
        return jsonData ? JSON.parse(jsonData) : null;
      } catch (error) {
        console.error("Error retrieving data from sessionStorage:", error);
        return null;
      }
    }
  
    /**
     * Remove data from sessionStorage.
     * @param {string} key - The key of the data to be removed.
     */
    static removeData(key) {
      try {
        sessionStorage.removeItem(key);
      } catch (error) {
        console.error("Error removing data from sessionStorage:", error);
      }
    }
  }
  
  export default sessionStorageService;
  