const statusEnum = Object.freeze({
    ACTIVE : 1,
    DISABLED : 2,
    SUSPENDED : 3,
    EXPIRED : 4,
    PENDING : 5,
    CONFIRMED : 6,
    CANCELLED : 7,
    SENT : 8,
    DECLINED : 9,
    APPROVED : 10,
    OPEN : 11,
    AUTOAPPROVED : 12,
    AUTODECLINED : 13,
    PAID : 14,
    UNPAID : 15,
    WAITING : 16,
    WRITTENOFF : 17,
    FULLYDISBURSED : 18,
    PARTIALLYDISBURSED : 19,
    AWAITINGAPPROVAL : 20,
    AWAITINGACTIVATION : 21,
    NOTSENTNOCREDENTIALS : 22,
    INCOMPLETED : 23,
    COMPLETED : 24,
    REJECTED : 25,
    APPROVEDLEVEL : 26,
    INACTIVE : 99,
});

export default statusEnum;
