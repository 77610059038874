import axios from "axios";

export default {

    namespaced: true,

    state: {

        counties: [],
        county: {}

    },

    getters: {

        counties(state) {
            return state.counties;
        },
        county(state) {
            return state.county;
        }

    },

    mutations: {

        SET_COUNTIES(state, counties) {
            return state.counties = counties
        },

        SET_COUNTY(state, county) {
            return state.county = county
        },

        CLEAR_COUNTIES(state) {
            return state.counties = []
        },

    },

    actions: {

        async fetchCounties({ commit }, params) {

            await axios.get('/api/v1/counties', { params: params })
                .then(res => {
                    // console.log(res.data);
                    commit('SET_COUNTIES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchCounty({ commit }, state_id) {

            await axios.get(`/api/v1/counties/${state_id}`)
                .then(res => {
                    // console.log("fetchState store == ", res);
                    if (res.stateText === 'OK') {
                        commit('SET_COUNTY', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async clearValues({ commit }) {
            commit('CLEAR_COUNTIES');
        }

    }

}
