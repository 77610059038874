import sessionStorageService from "./../SessionStorageService";

class CompanyColorService {
  async getActiveCompanyColor(store, companyColorModule) {
    try {

      // Check if companyColor is stored in session storage
      const storedColor = sessionStorageService.getData("activeCompanyColor");
      if (
        storedColor &&
        storedColor !== "null" &&
        storedColor !== "undefined" &&
        storedColor !== ""
      ) {
        // If stored, return the color
        // console.log("Returning stored color");
        return storedColor;
      } else {
        // If not stored, fetch it from Vuex store
        // console.log("Fetching color from Vuex store");
        const companyColor = await store.dispatch(
          `${companyColorModule}/fetchActiveCompanyColor`
        );

        // Store companyColor in sessionStorage
        sessionStorageService.saveData("activeCompanyColor", companyColor);

        return companyColor;
      }
    } catch (error) {
      // console.error("Error fetching company color:", error);
      throw error;
    }
  }
}

export default CompanyColorService;
