import axios from "axios";

export default {

    namespaced: true,

    state: {

        groupsubcategories: [],
        groupsubcategory: {}

    },

    getters: {

        groupsubcategories(state) {
            return state.groupsubcategories;
        },
        groupsubcategory(state) {
            return state.groupsubcategory;
        }

    },

    mutations: {

        SET_GROUP_SUB_CATEGORIES(state, groupsubcategories) {
            return state.groupsubcategories = groupsubcategories
        },

        SET_GROUP_SUB_CATEGORY(state, groupsubcategory) {
            return state.groupsubcategory = groupsubcategory
        }

    },

    actions: {

        async fetchGroupSubCategories({ commit }, params) {

            await axios.get('/api/v1/groupsubcategories', { params: params })
                .then(res => {
                    commit('SET_GROUP_SUB_CATEGORIES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchGroupSubCategory({ commit }, group_sub_category_id) {

            await axios.get(`/api/v1/groupsubcategories/${group_sub_category_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_GROUP_SUB_CATEGORY', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createGroupSubCategory({ commit, dispatch }, groupsubcategory) {

            await axios.post('/api/v1/groupsubcategories', groupsubcategory)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchGroupSubCategories');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateGroupSubCategory({ commit, dispatch }, groupsubcategory) {

            await axios("/api/v1/groupsubcategories/" + groupsubcategory.group_sub_category_id, {
                method: "patch",
                data: groupsubcategory,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchGroupSubCategories');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteGroupSubCategory({ commit }, dispatch, group_sub_category_id) {

            await axios.delete(`/api/v1/groupsubcategories/${group_sub_category_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchGroupSubCategories');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
