<template>


    <div class="preloader">
        <div class="loader_grid">
            <div class="loader_box loader_box1"></div>
            <div class="loader_box loader_box2"></div>
            <div class="loader_box loader_box3"></div>
            <div class="loader_box loader_box4"></div>
            <div class="loader_box loader_box5"></div>
            <div class="loader_box loader_box6"></div>
            <div class="loader_box loader_box7"></div>
            <div class="loader_box loader_box8"></div>
            <div class="loader_box loader_box9"></div>
        </div>
    </div>


    <header class="header_wrap dark_skin hover_menu_style1">
        <div class="top-header bg_blue light_skin d-none d-md-block border-0">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-8">
                        <ul class="contact_detail list_none text-center text-md-left">
                            <li><i class="ti-mobile"></i>+254720000000</li>
                            <li><i class="ti-location-pin"></i>Vision Plaza, Msa Rd, Nairobi, Kenya</li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <!-- <ul
                            class="list_none social_icons text-center border_social rounded_social social_white text-md-right mt-2 mt-md-0 mb-0">
                            <li><a href="#"><i class="ion-social-facebook"></i></a></li>
                            <li><a href="#"><i class="ion-social-twitter"></i></a></li>
                            <li><a href="#"><i class="ion-social-googleplus"></i></a></li>
                            <li><a href="#"><i class="ion-social-youtube-outline"></i></a></li>
                            <li><a href="#"><i class="ion-social-instagram-outline"></i></a></li>
                        </ul> -->
                        <router-link :to="{ name: 'requestdemo' }" class="btn btn-warning float-right" exact-active-class="active">
                            REQUEST FOR DEMO
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">

            <nav class="navbar navbar-expand-lg">

                <!-- <a class="navbar-brand" href="#" @click.prevent="gotoHome()"> -->
                <router-link :to="{ name: 'clientCustomerHome' }" class="navbar-brand">
                    <img class="logo_light" src="../../assets/images/snb_hor_logo.png" alt="snb logo" />
                    <img class="logo_dark" src="../../assets/images/snb_hor_logo.png" alt="snb logo" />
                    <img class="logo_default" src="../../assets/images/snb_hor_logo.png" alt="snb logo" />
                </router-link>
                <!-- </a> -->

                <button class="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation"> <span class="ion-android-menu"></span>
                </button>

                <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">

                    <ul class="navbar-nav">

                        <li>
                            <router-link :to="{ name: 'clientCustomerHome' }" class="nav-link" exact-active-class="active">
                                HOME
                            </router-link>
                        </li>

                        <!-- <li>
                            <router-link :to="{ name: 'about' }" class="nav-link" exact-active-class="active">
                                ABOUT US
                            </router-link>
                        </li> -->

                        <li class="dropdown" v-if="authUser && authUser?.customer">

                            <a
                                class="nav-link dropdown-toggle waves-effect waves-light nav-user"
                                data-toggle="dropdown"
                                href="#"
                                role="button"
                                aria-haspopup="false"
                                aria-expanded="false">
                                MY ACCOUNT
                            </a>

                            <div class="dropdown-menu dropdown-menu-right">

                                <router-link to="/profile" class="dropdown-item">
                                    <i
                                        data-feather="user"
                                        class="align-self-center icon-xs icon-dual mr-1"
                                    ></i>
                                    My Savings
                                </router-link>

                                <router-link to="/profile" class="dropdown-item">
                                    <i
                                        data-feather="user"
                                        class="align-self-center icon-xs icon-dual mr-1"
                                    ></i>
                                    My Transfers
                                </router-link>

                                <router-link to="/profile" class="dropdown-item">
                                    <i
                                        data-feather="user"
                                        class="align-self-center icon-xs icon-dual mr-1"
                                    ></i>
                                    My Shares
                                </router-link>

                            </div>

                        </li>

                        <li>
                            <router-link :to="{ name: 'contact' }" class="nav-link" exact-active-class="active">
                                CONTACT US
                            </router-link>
                        </li>

                        <li v-if="authUser?.employee">
                            <router-link :to="{ name: 'adminHome' }" class="nav-link" exact-active-class="active">
                                ADMIN
                            </router-link>
                        </li>

                        <li class="dropdown ml-5" v-if="authUser && authUser?.customer">

                            <a
                                class="nav-link dropdown-toggle waves-effect waves-light nav-user"
                                data-toggle="dropdown"
                                href="#"
                                role="button"
                                aria-haspopup="false"
                                aria-expanded="false">

                                <img v-if="authUser.customer?.member_photo?.length > 0"
                                    :src="authUser.customer?.member_photo[0].thumb_path"
                                    :alt="authUser?.full_name" class="rounded-circle">

                                <img v-else
                                    src="./../../assets/images/users/no-user.jpg"
                                    alt="profile-user"
                                    class="rounded-circle"
                                />
                            </a>

                            <div class="dropdown-menu dropdown-menu-right">

                                <div class="ml-1 nav-user-name mr-1 pt-2">
                                    {{ authUser?.first_name }} -
                                    {{ authUser?.active_company?.company_name }}
                                </div>

                                <div class="dropdown-divider mb-0"></div>

                                <router-link :to="{ name: 'clientCustomerProfile' }" class="dropdown-item">
                                    <i
                                        data-feather="user"
                                        class="align-self-center icon-xs icon-dual mr-1"
                                    ></i>
                                    Profile
                                </router-link>

                                <a class="dropdown-item" href="#" @click.prevent="toggleSelfPasswordPopup()">
                                    <i data-feather="lock" class="align-self-center icon-xs icon-dual mr-1"></i>
                                    Change Password
                                </a>

                                <a class="dropdown-item" href="#">
                                    <i
                                        data-feather="settings"
                                        class="align-self-center icon-xs icon-dual mr-1"
                                    >
                                    </i>
                                    Settings
                                </a>

                                <div class="dropdown-divider mb-0"></div>

                                <a class="dropdown-item" href="#" @click="logout">
                                    <i
                                        data-feather="power"
                                        class="align-self-center icon-xs icon-dual mr-1"
                                    >
                                    </i>
                                    Logout
                                </a>

                            </div>

                        </li>

                        <li class="ml-5" v-else>
                            <router-link :to="{ name: 'login' }" exact-active-class="active">
                                Login
                            </router-link>
                            |
                            <router-link :to="{ name: 'register' }" exact-active-class="active">
                                Register
                            </router-link>
                        </li>

                    </ul>
                </div>
                <!-- <ul class="navbar-nav attr-nav align-items-center">
                    <li><a href="javascript:void(0);" class="nav-link search_trigger"><i
                                class="ion-ios-search-strong"></i></a>
                        <div class="search-overlay">
                            <span class="close-search"><i class="ion-ios-close-empty"></i></span>
                            <div class="search_wrap">
                                <form>
                                    <input type="text" placeholder="Search" class="form-control" id="search_input">
                                    <button type="submit" class="search_icon"><i
                                            class="ion-ios-search-strong"></i></button>
                                </form>
                            </div>
                        </div>
                    </li>
                </ul> -->
            </nav>
        </div>
    </header>


    <!-- <change-self-password
        :toggle-popup="togglePopup"
        @togglePopup="toggleSelfPasswordPopup"
        v-if="show_change_self_password_modal">
    </change-self-password> -->

</template>

<style scoped>
.ptitle-enter-from {
  opacity: 0;
  transform: translateY(-60px);
}
.ptitle-enter-to {
  opacity: 1;
  transform: translateY(0);
}
.ptitle-enter-active {
  transition: all 2s ease;
}
.ptitle-leave-from {
  opacity: 1;
  transform: translateY(0);
}
.ptitle-leave-to {
  opacity: 0;
  transform: translateY(60px);
}
.ptitle-leave-active {
  transition: all 2s ease;
}
</style>

<script>
import { mapActions } from "vuex";
// import { useStore } from "vuex";

export default {

    props: ['authUser'],

    data() {
        return {
            show_change_self_password_modal: false,
            dialogTableVisible: false,
            pageTitle: "",
            activity: [],
        };
    },

    mounted() {

        function a() {
            $(document).ready(function () {
                $("#AllCompo")
                .autocomplete({
                    source: ["Forms", "Tables", "Charts", "Icones", "Maps"],
                    minLength: 0,
                    scroll: !0,
                })
                .focus(function () {
                    $(this).autocomplete("search", "");
                });
            });
        }

        feather.replace();

        a();
        $(".navigation-menu a").each(function () {
        var t = window.location.href.split(/[?#]/)[0];
        this.href == t &&
            ($(this).parent().addClass("active"),
            $(this).parent().parent().parent().addClass("active"),
            $(this)
            .parent()
            .parent()
            .parent()
            .parent()
            .parent()
            .addClass("active"));
        });

        $(".navbar-toggle").on("click", function (t) {
        $(this).toggleClass("open"), $("#navigation").slideToggle(400);
        });

        $(".navigation-menu>li").slice(-2).addClass("last-elements");

        $('.navigation-menu li.has-submenu a[href="#"]').on("click", function (t) {
        $(window).width() < 992 &&
            (t.preventDefault(),
            $(this)
            .parent("li")
            .toggleClass("open")
            .find(".submenu:first")
            .toggleClass("open"));
        });

        // Waves.init();
    },

    watch: {
        // watch for changes in route and update page title as appropriate
        $route(route) {
        // if you go to the redirect page, do not update
        if (route.path.startsWith("/redirect/")) {
            return;
        }
        this.getPageTitle();
        },
    },

    created() {
        this.getPageTitle();

        // show only when logged in
        // this.getActivity();
    },

    methods: {

        /* getActivity() {
            this.$store.dispatch(`notifications/fetchNotifications`)
            .then(res => {
                this.activity = res;
            })
            .catch(err => {
                return Promise.reject(err);
            });
        }, */

        // toggleSelfPasswordPopup
        toggleSelfPasswordPopup() {
            this.show_change_self_password_modal = !this.show_change_self_password_modal;
        },

        /* logout() {
            this.$auth.logout();
            // console.log("this.$router == ", this.$router);
            this.$router.replace({ name: 'login'});
            location.reload();
        }, */

        getPageTitle() {
            // only show routes with meta.title
            let matched = this.$route.matched.filter(
                (item) => item.meta && item.meta.title
            );
            const first = matched[matched.length - 1];
            if (first) {
                // console.log("first == ", first, first.meta.title);
                this.pageTitle = first.meta.title;
            }
        },

        ...mapActions([
            'auth/logout',
        ]),

        ...mapActions({
            logout: 'auth/logout'
        })

    }

};
</script>
