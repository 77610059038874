const emailMessagingRoutes = [

    {

        name: 'emailHome',
        path: '',
        component: () => import('./ListEmails.vue'),
        meta: {
            auth: true,
            title: 'Email Listing',
            breadcrumb: 'Email Listing',
            permission: ['view email'],
            section: 'email'
        }

    },

    {
        path: 'create',
        name: 'createEmail',
        component: () => import('./CreateEmail.vue'),
        meta: {
            auth: true,
            title: 'Create Email',
            breadcrumb: 'Create Email',
            permission: ['create email'],
            section: 'email'
        }
    },

    {
        path: ':id',
        name: 'showEmail',
        component: () => import('./ShowEmail.vue'),
        meta: {
            auth: true,
            title: 'Showing Email',
            breadcrumb: 'Showing Email',
            permission: ['view email'],
            section: 'email'
        }
    },

    {
        path: ':id/edit',
        name: 'editEmail',
        component: () => import('./EditEmail.vue'),
        meta: {
            auth: true,
            title: 'Edit Email',
            breadcrumb: 'Edit Email',
            permission: ['update email'],
            section: 'email'
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteEmail',
        meta: {
            auth: true,
            title: 'Delete Email',
            permission: ['delete email'],
            section: 'email'
        }
    },

];

export default emailMessagingRoutes;
