const groupSubCategoryRegistrationRoutes = [

    {

        name: 'groupSubCategoryHome',
        path: '',
        component: () => import('./ListGroupSubCategories.vue'),
        meta: {
            auth: true,
            title: 'Group Sub Categories Listing',
            breadcrumb: 'Group Sub Categories Listing',
            permission: ['view groupcategory']
        }

    },

    {
        path: 'create',
        name: 'createGroupSubCategory',
        component: () => import('./CreateGroupSubCategory.vue'),
        meta: {
            auth: true,
            title: 'Create Group Sub Category',
            breadcrumb: 'Create Group Sub Category',
            permission: ['create groupcategory']
        }
    },

    {
        path: ':id',
        name: 'showGroupSubCategory',
        component: () => import('./ShowGroupSubCategory.vue'),
        meta: {
            auth: true,
            title: 'Showing Group Sub Category',
            breadcrumb: 'Showing Group Sub Category',
            permission: ['view groupcategory']
        }
    },

    {
        path: ':id/edit',
        name: 'editGroupSubCategory',
        component: () => import('./EditGroupSubCategory.vue'),
        meta: {
            auth: true,
            title: 'Edit Group Sub Category',
            breadcrumb: 'Edit Group Sub Category',
            permission: ['update groupcategory']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteGroupSubCategory',
        meta: {
            auth: true,
            title: 'Delete Group Sub Category',
            permission: ['delete groupcategory']
        }
    },

];

export default groupSubCategoryRegistrationRoutes;
