const clientCustomerSavingsDepositsTransfersRoutes = [

    {

        path: '',
        name: 'clientCustomerSavingsDepositsTransfersHome',
        component: () => import('./ListTransfers.vue'),
        meta: {
            auth: true,
            title: 'My Transfers',
            breadcrumb: 'My Transfers',
            permission: []
        },

    },

    {
        path: 'create',
        name: 'clientCustomerSavingsDepositsCreateTransfer',
        component: () => import('./CreateTransfer.vue'),
        meta: {
            auth: true,
            title: 'Create Transfer',
            breadcrumb: 'Create Transfer',
            permission: ['view customer']
        }
    },

    {
        path: ':id',
        name: 'clientCustomerSavingsDepositsShowTransfer',
        component: () => import('./ShowTransfer.vue'),
        meta: {
            auth: true,
            title: 'Showing Transfer',
            breadcrumb: 'Showing Transfer',
            permission: ['view customer']
        }
    }

];

export default clientCustomerSavingsDepositsTransfersRoutes;
