import Base from '../../Common/Base.vue';

const clientCustomerGroupModuleRoutes = [

    {
        name: 'clientCustomerGroup',
        path: '',
        component: () =>
            import ('../../../components/Customer/Groups/ListGroups.vue'),
        meta: {
            auth: true,
            title: 'My Groups',
            breadCrumb: 'My Groups',
            permission: ['view customer']
        }
    },

    {
        path: 'create',
        name: 'clientCustomerCreateGroup',
        component: () => import('../../../components/Customer/Groups/CreateGroup.vue'),
        meta: {
            auth: true,
            title: 'Create Group',
            breadcrumb: 'Create Group',
            permission: ['view customer']
        }
    },

    {
        path: ':id/invite-members',
        name: 'clientCustomerInviteGroupMembers',
        component: () => import('../../../components/Customer/Groups/InviteGroupMembers.vue'),
        meta: {
            auth: true,
            title: 'Invite Group Members',
            breadcrumb: 'Invite Group Members',
            permission: ['view customer']
        }
    },

    {
        path: ':id/edit',
        name: 'clientCustomerEditGroup',
        component: () => import('../../../components/Customer/Groups/EditGroup.vue'),
        meta: {
            auth: true,
            title: 'Edit Group',
            breadcrumb: 'Edit Group',
            permission: ['view customer']
        }
    },

    {
        path: ':id',
        name: 'clientCustomerShowGroup',
        component: () => import('../../../components/Customer/Groups/ShowGroup.vue'),
        meta: {
            auth: true,
            title: 'Showing Group',
            breadcrumb: 'Showing Group',
            permission: ['view customer']
        }
    }

];

export default clientCustomerGroupModuleRoutes;
