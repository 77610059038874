<template>
  <modal
    v-show="showModal"
    :modalSize="''"
    :modalHeaderBgColor="'bg-danger text-white'"
    :modalHeaderTextColor="'text-white'"
    @close="togglePopup"
  >
    <template v-slot:header class="modal-header text-white">
      Reject Maker Checker Request
    </template>

    <template v-slot:body>
      <p class="text-default">
        You are about to reject a maker checker request. Click below to proceed.
      </p>

      <form @submit.prevent="onSubmitted">
        <div class="col-lg-6x">
          <div class="form-group row">
            <label for="first_name" class="col-sm-4 col-form-label text-right">
              Enter your comments (optional)
            </label>
            <div class="col-sm-8">
              <textarea class="form-control" rows="3" v-model="model.reason.$model" />
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <hr class="row no-top-margin" />

          <div class="form-group">
            <button
              class="btn btn-primary mr-3"
              type="submit"
              :disabled="model.$invalid"
              :class="{ 'btn-secondary': model.$invalid }"
            >
              Submit
            </button>
            <!-- <button
              class="btn btn-danger float-right"
              type="button"
              data-dismiss="modal"
              @click="togglePopup"
            >
              Cancel
            </button> -->
          </div>
        </div>
      </form>
    </template>
  </modal>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";

const module = "makercheckers";

export default defineComponent({
  name: "RejectMakerChecker",

  props: {
    makerCheckerData: {
      type: Object,
      required: true,
    },
    showModal: {
      type: Boolean,
      default: true,
    },
    modalSize: {
      type: String,
      default: "",
    },
    modalType: {
      type: String,
      default: "",
    },
    togglePopup: {
      type: Function,
      default: () => {},
    },
  },

  components: {},

  emits: ["onSubmitSuccess"],

  setup(props, { emit }) {
    const makerCheckerDataObj = {
      maker_checker_id: "",
      reason: "",
    };

    const store = useStore();

    let makerCheckerId = ref(props.makerCheckerData?.maker_checker_id);

    let makerCheckerDataForm = ref(makerCheckerDataObj);

    // set validation rules
    const rules = {
      maker_checker_id: {},
      reason: {},
    };

    // validate form
    const model = useVuelidate(rules, makerCheckerDataForm);

    async function onSubmitted() {
      try {
        let payload = {
          maker_checker_id: makerCheckerId.value,
          reason: makerCheckerDataForm.value.reason,
        };

        // dispatch the action and wait for it to complete
        await store.dispatch(`${module}/rejectMakerChecker`, payload);

        // if the dispatch is successful, emit the success event
        emit("onSubmitSuccess");
      } catch (error) {
        // console.error("Error:", error);
      }
    }

    return {
      onSubmitted,
      model,
    };
  },
});
</script>
