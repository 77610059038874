import axios from "axios";

export default {

    namespaced: true,

    state: {

        paymentmethods: [],
        paymentmethod: {},
        disbursementpaymentmethod:[],
        //receiptpaymentmethod:[],
        automaticdisbursementmethods:[],
        manualdisbursementmethods:[],
    },

    getters: {

        paymentmethods(state) {
            return state.paymentmethods;
        },
        paymentmethod(state) {
            return state.paymentmethod;
        },

        disbursementpaymentmethod(state){
            return state.disbursementpaymentmethod;
        },
        // receiptpaymentmethod(state){
        //     return state.receiptpaymentmethod;
        // },
        automaticdisbursementmethods(state) {
            return state.automaticdisbursementmethods;
        },
        manualdisbursementmethods(state) {
            return state.manualdisbursementmethods;
        },
    },

    mutations: {

        SET_PAYMENT_METHODS(state, paymentmethods) {
            return state.paymentmethods = paymentmethods
        },

        SET_PAYMENT_METHOD(state, paymentmethod) {
            return state.paymentmethod = paymentmethod
        },

        // SET_DISBURSEMENT_PAYMENT_METHOD(state, paymentmethod) {
        //     return state.disbursementpaymentmethod = paymentmethod
        // },

        // SET_RECEIPT_PAYMENT_METHOD(state, paymentmethod) {
        //     return state.receiptpaymentmethod = paymentmethod
        // },

        AUTOMATIC_DISBURSEMENT_METHODS(state,data) {
            state.automaticdisbursementmethods=data;
        },
        MANUAL_DISBURSEMENT_METHODS(state,data) {
            state.manualdisbursementmethods=data;
        },


    },


    actions: {

        // ['payment_name' => 'Cash', 'payment_code' =>0, 'company_id' => $company_id],
        //     ['payment_name' => 'Cheque', 'payment_code' =>1, 'company_id' => $company_id],
        //     ['payment_name' => 'Mpesa Paybill', 'payment_code' =>2, 'company_id' => $company_id],
        //     ['payment_name' => 'Mpesa ShortCode', 'payment_code' =>3,'is_default'=>true, 'company_id' => $company_id],
        //     ['payment_name' => 'Bank Transfers', 'payment_code' =>4, 'company_id' => $company_id],
        //     ['payment_name' => 'PesaLink', 'payment_code' =>5, 'company_id' => $company_id],
        //     ['payment_name' => 'Atm', 'payment_code' =>6, 'company_id' => $company_id]

        async fetchPaymentMethods({ commit }, params) {
            await axios.get('/api/v1/payment-methods', { params: params })
                .then(res => {
                    if (res.statusText === 'OK') {
                        // console.log("res", res);
                        let data=res.data.data??[];
                        commit('SET_PAYMENT_METHODS', data);
                        commit('AUTOMATIC_DISBURSEMENT_METHODS', data.filter(e=>e.payment_code==3 || e.payment_code==5));
                        commit('MANUAL_DISBURSEMENT_METHODS', data.filter(e=>e.payment_code==1 || e.payment_code==4));

                     }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchPaymentMethod({ commit }, deposit_id) {
            await axios.get(`/api/v1/payment-methods/${deposit_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_PAYMENT_METHOD', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },


        async updatePaymentMethod({ commit, dispatch }, data) {
            await axios.patch(`/api/v1/payment-methods/${data.payment_type_id}`, data)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchPaymentMethods');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },



    }

}
