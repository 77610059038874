const PaymentManagementRoutes = [
    {
        path: 'mpesa-payment',
        name: 'MpesaPayment',
        component: () => import('./Mpesa.vue'),
        meta: {
            auth: true,
            title: 'Mpesa Payment',
            breadcrumb: 'Mpesa Payment',
            permission: ['view mpesa-payment']
        },
    },

    {
        path: 'manual-transaction-upload',
        name: 'ManualTransactionUpload',
        component: () => import('./ManualTransactionUpload/Index.vue'),
        meta: {
            auth: true,
            title: 'Manual Transaction Upload',
            breadcrumb: 'Manual Transaction Upload',
            permission: [
                'create manual-transaction-upload',
                'update manual-transaction-upload',
                'delete manual-transaction-upload',
                'view manual-transaction-upload',
            ]
        },
    },

];

export default PaymentManagementRoutes;