import axios from "axios";

export default {

    namespaced: true,

    state: {

        trialbalance: [],
        journaltransactions: [],
        ledgertransactions: [],
        incomestatement:[],
        balancesheet:[]

    },

    getters: {

        trialbalance(state) {
            return state.trialbalance;
        },
        journaltransactions(state) {
            return state.journaltransactions;
        },
        ledgertransactions(state) {
            return state.ledgertransactions;
        },
        incomestatement(state){
            return state.incomestatement;
        },
        balancesheet(state){
            return state.balancesheet;
        }

    },

    mutations: {

        SET_TRIAL_BALANCE(state, trialbalance) {
            return state.trialbalance = trialbalance
        },
        SET_JOURNAL_TRANSACTIONS(state, journaltransactions) {
            return state.journaltransactions = journaltransactions
        },
        SET_LEDGER_TRANSACTIONS(state, ledgertransactions) {
            return state.ledgertransactions = ledgertransactions
        },

        SET_INCOME_STATEMENT(state, incomestatement) {
            return state.incomestatement = incomestatement
        },

        SET_BALANCE_SHEET(state, balancesheet) {
            return state.balancesheet = balancesheet
        },


    },

    actions: {
        
        async fetchTrialBalance({ commit }, param) {
            await axios.get(`/api/v1/reports/trialBalance`,{params: param})
                .then(res => {
                    commit('SET_TRIAL_BALANCE', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },
 
       
        async fetchIncomeStatement({ commit }, param) {
            await axios.get(`/api/v1/reports/incomeStatement`,{ params: param })
                .then(res => {
                    commit('SET_INCOME_STATEMENT', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchBalanceSheet({ commit }, param) {
            await axios.get(`/api/v1/reports/balanceSheet`,{ params: param })
                .then(res => {
                    commit('SET_BALANCE_SHEET', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchJournalTransactions({ commit }, param) {
            await axios.get(`/api/v1/reports/journalTransactions`,{ params: param })
                .then(res => {
                    commit('SET_JOURNAL_TRANSACTIONS', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },
        
        async fetchLedgerTransactions({ commit }, param) {

            await axios.get(`/api/v1/reports/ledgerTransactions`,{ params: param })
                .then(res => {
                    commit('SET_LEDGER_TRANSACTIONS', res.data.data);

                }).catch(err => {
                    return Promise.reject(err);
                })

        }


    }

}
