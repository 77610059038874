import axios from "axios";

export default {

    namespaced: true,

    state: {

        fixedDepositAccounts: [],
        fixedDepositAccount: {},
        fixedDepositAccountBalances:[]

    },

    getters: {

        fixedDepositAccounts(state) {
            return state.fixedDepositAccounts;
        },
        fixedDepositAccount(state) {
            return state.fixedDepositAccount;
        },

        fixedDepositAccountbalances(state) {
            return state.fixedDepositAccountBalances;
        }

    },

    mutations: {

        SET_FIXED_ACCOUNTS(state, fixedDepositAccounts) {
            return state.fixedDepositAccounts = fixedDepositAccounts
        },

        SET_FIXED_ACCOUNT(state, fixedDepositAccount) {
            return state.fixedDepositAccount = fixedDepositAccount
        },

        SET_FIXED_ACCOUNT_BALANCES(state, fixedDepositAccounts){
            return state.fixedDepositAccountBalances = fixedDepositAccounts
        }

    },

    actions: {
        
        async fetchfixeddepositaccounts({ commit }, params) {

            await axios.get('/api/v1/fixed-deposit-accounts', { params: params })
                .then(res => {
                    commit('SET_FIXED_ACCOUNTS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchfixeddepositaccountbalances({ commit }, params) {

            await axios.get('/api/v1/fixed-deposit-accounts/balances', { params: params })
                .then(res => {
                    commit('SET_FIXED_ACCOUNT_BALANCES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchfixeddepositaccount({ commit }, deposit_application_id) {

            await axios.get(`/api/v1/fixed-deposit-accounts/${deposit_application_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_FIXED_ACCOUNT', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createfixedaccount({ commit, dispatch }, fixedDepositAccount) {

            await axios.post('/api/v1/fixed-deposit-accounts', fixedDepositAccount);
        },

        async updatefixeddepositaccount({ commit, dispatch }, fixedDepositAccount) {

            await axios("/api/v1/fixed-deposit-accounts/" + fixedDepositAccount.deposit_application_id, {
                method: "patch",
                data: fixedDepositAccount,
            });
        },

        async deletefixeddepositaccount({ commit }, deposit_application_id) {

            await axios.delete(`/api/v1/fixed-deposit-accounts/${deposit_application_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchfixeddepositaccounts');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        },

        async fetchfixeddepositbalances({ commit }, params) {

            await axios.get('/api/v1/fixed-deposit-accounts/balances', { params: params })
                .then(res => {
                    commit('SET_FIXED_ACCOUNTS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

    }

}
