import axios from "axios";

export default {

    namespaced: true,

    state: {

        standingorders: [],
        standingorder: {}

    },

    getters: {

        standingorders(state) {
            return state.standingorders;
        },
        standingorder(state) {
            return state.standingorder;
        }

    },

    mutations: {

        SET_STANDING_ORDERS(state, standingorders) {
            return state.standingorders = standingorders
        },

        SET_STANDING_ORDER(state, standingorder) {
            return state.standingorder = standingorder
        }

    },

    actions: {

        async fetchstandingorders({ commit }, params) {

            await axios.get('/api/v1/standing-orders', { params: params })
                .then(res => {
                    commit('SET_STANDING_ORDERS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchstandingorder({ commit }, standing_order_id) {

            await axios.get(`/api/v1/standing-orders/${standing_order_id}`)
                .then(res => {
                    // console.log("fetchProduct store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_STANDING_ORDER', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createstandingorder({ commit, dispatch }, standingorder) {

            // console.log("product createProduct ", product);
            await axios.post('/api/v1/standing-orders', standingorder)
                .then(res => {
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updatestandingorder({ commit, dispatch }, standingorder) {

            await axios("/api/v1/standing-orders/" + standingorder.standing_order_id, {
                method: "patch",
                data: standingorder,
            }).then(res => {
             
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deletestandingorder({ commit }, standing_order_id) {

            await axios.delete(`/api/v1/standing-orders/${standing_order_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchstandingorders');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
