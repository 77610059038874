<template>
  <div class="col-lg-4">
    <div class="card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="card-title">Deposits By Account Type</h4>
          </div>

          <div class="col-auto">
            <div class="dropdown">
              <a
                  href="#"
                  class="btn btn-sm btn-outline-light dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">{{ currentFilter }}<i class="las la-angle-down ml-1"></i></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#" @click.prevent="selectFilter('Yesterday')">Yesterday</a>
                <a class="dropdown-item" href="#" @click.prevent="selectFilter('Last Week')">Last 7 Days</a>
                <a class="dropdown-item" href="#" @click.prevent="selectFilter('This Month')">This Month</a>
                <a class="dropdown-item" href="#" @click.prevent="selectFilter('Last Month')">Last Month</a>
                <a class="dropdown-item" href="#" @click.prevent="selectFilter('This Year')">This Year</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="text-center">
          <div id="ana_device" class="apex-charts">
            <apexchart
                class="apex-charts"
                type="donut"
                height="265"
                :options="optionsDepositsAccountType"
                :series="accountTypeChart"
            >
            </apexchart>
          </div>
          <h6 class="text-primary bg-soft-primary p-3 mb-0">
            <i
                data-feather="calendar"
                class="align-self-center icon-xs mr-1"
            ></i>
            {{ startTimeline }} to  {{ endTimeline }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { numberFormatter } from "@/filters";
import { capitalizeFirstLetter } from "@/helpers/utils";
import { getFilterParams } from "@/helpers/chartUtils";
import sessionStorageService from "@/services/SessionStorageService";

const module = "statistics";
export default defineComponent({
  name: "AccountTypeDeposits",
  props: {},
  setup() {
    const user = sessionStorageService.getData("user");
    const companyId = user?.active_company_id;
    const currentFilter = ref("Today");
    const optionsDepositsAccountType = ref({
      chart: {
        height: 265,
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "85%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "13px",
        offsetX: 0,
        offsetY: 0,
      },
      labels: ["Current", "Fixed Deposit", "Shares"],
      colors: ["#2a76f4", "rgba(42, 118, 244, .5)", "rgba(42, 118, 244, .18)"],
      responsive: [
        {
          breakpoint: 600,
          options: {
            plotOptions: {
              donut: {
                customScale: 0.2,
              },
            },
            chart: {
              height: 240,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      tooltip: {
        y: {
          formatter: function (e) {
            return e + " %";
          },
        },
      },
    });
    const accountTypeChart = ref([]);
    const startTimeline = ref("");
    const endTimeline = ref("");
    const selectFilter = async (filter) => {
      currentFilter.value = filter;
      try {
        const { custom_key, summary_type, period,start_date,end_date } = getFilterParams(filter);
        startTimeline.value= start_date;
        endTimeline.value = end_date;
        const params = {
          company_id: companyId,
          metrics: ["deposits_value", "fixed_deposits_value", "shares_value"],
          summary_type,
          custom_key,
          period,
        };

        const data = await fetchAccountDeposits(params);

        //fetch the category if the filter is today define the TimelineCategory as todays date to todays date if it is yesterday use yesterday to today if last month say the last month starting date

        updateAccountDepositsChartData(data);
      } catch (error) {
        console.error(
            `Error fetching ${filter.toLowerCase()} activity overview statistics: `,
            error
        );
      }
    };
    const fetchAccountDeposits = async (params) => {
      try {
        const response = await store.dispatch(
            `${module}/fetchFinancialOverviewStatistics`,
            params
        );
        return response.data;
      } catch (error) {
        console.error("Error loading fetchAccount type deposits:", error);
      }
    };

    const calculateAccountTypeDeposits = async (filter) => {
      currentFilter.value = filter;

      try {
        const { custom_key, summary_type, period,start_date,end_date } = getFilterParams(filter);

        console.log(start_date)
        startTimeline.value= start_date;
        endTimeline.value = end_date;
        const params = {
          company_id: companyId,
          metrics: ["deposits_value", "fixed_deposits_value", "shares_value"],
          summary_type,
          custom_key,
          period,
        };

        const data = await fetchAccountDeposits(params);
        updateAccountDepositsChartData(data);
      } catch (error) {
        console.error(`Error fetching ${filter.toLowerCase()} disbursed loans statistics: `, error);
      }
    };
    const sumArray = (array) => array.reduce((acc, val) => acc + val, 0);

    const updateAccountDepositsChartData = (data) => {


      const currentDepositSum = sumArray(data.deposits_value);
      const fixedDepositSum =sumArray(data.fixed_deposits_value);
      const sharesDepositsSum = sumArray(data.shares_value);


      const total = currentDepositSum + fixedDepositSum + sharesDepositsSum;

      let depositsPercentage = 0;
      let fixedDepositPercentage = 0;
      let sharesDepositsPercentage = 0;

      if (total > 0) {
        depositsPercentage = (currentDepositSum / total) * 100;
        fixedDepositPercentage = (fixedDepositSum / total) * 100;
        sharesDepositsPercentage = (sharesDepositsSum / total) * 100;
      }
      accountTypeChart.value= [depositsPercentage,fixedDepositPercentage,sharesDepositsPercentage];

    };
    onMounted(() => {
      calculateAccountTypeDeposits("Today");
    });

    return {
      numberFormatter,
      capitalizeFirstLetter,
      optionsDepositsAccountType,
      calculateAccountTypeDeposits,
      currentFilter,
      accountTypeChart,
      selectFilter,
      startTimeline,
      endTimeline
    };
  },
});
</script>

