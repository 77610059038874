import axios from "axios";

export default {

    namespaced: true,

    state: {
        loanpaymentreport:[],
        loandefaultersreport:[],
        partialpaymentsreport:[],
        clientfacilitiesreport:[],
        teamperfomancereport:[],
        branchperfomancereport:[],
        dailydisbursementreport:[],
        monthlydisbursementreport:[],
        paymentanalysisreport:[]
    },



    getters: {

        loanpaymentreport(state){
            return state.loanpaymentreport;
        },
        loandefaultersreport(state){
            return state.loandefaultersreport;
        },
        partialpaymentsreport(state){
            return state.partialpaymentsreport;
        },
        clientfacilitiesreport(state){
            return state.clientfacilitiesreport;
        },
        teamperfomancereport(state){
            return state.teamperfomancereport;
        },
        branchperfomancereport(state){
            return state.branchperfomancereport;
        },
        dailydisbursementreport(state){
            return state.dailydisbursementreport;
        },
        monthlydisbursementreport(state){
            return state.monthlydisbursementreport;
        },
        paymentanalysisreport(state){
            return state.paymentanalysisreport;
        },
        
    },

    mutations: {

        SET_LOAN_PAYMENT_REPORT(state,data){
            return state.loanpaymentreport=data;
        },
        SET_LOAN_DEFAULTERS_REPORT(state,data){
            return state.loandefaultersreport=data;
        },
        SET_PARTIAL_PAYMENT_REPORT(state,data){
            return state.partialpaymentsreport=data;
        },
        SET_CLIENT_FACILITIES_REPORT(state,data){
            return state.clientfacilitiesreport=data;
        },
        SET_TEAM_PERFOMANCE_REPORT(state,data){
            return state.teamperfomancereport=data;
        },
        SET_BRANCH_PERFOMANCE_REPORT(state,data){
            return state.branchperfomancereport=data;
        },
        SET_DAILY_DISBURSEMENT_REPORT(state,data){
            return state.dailydisbursementreport=data;
        },
        SET_MONTHLY_DISBURSEMENT_REPORT(state,data){
            return state.monthlydisbursementreport=data;
        },
        SET_PAYMENT_ANALYSIS_REPORT(state,data){
            return state.paymentanalysisreport=data;
        }

    },

    actions: {
        //paymentanalysis
        async fetchpaymentanalysisreport({ commit }, params) {
            await axios.get('/api/v1/loan-reports/paymentanalysis', { params: params })
                .then(res => {
                    commit('SET_PAYMENT_ANALYSIS_REPORT', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
            },
       async fetchmonthlydisbursementreport({ commit }, params) {
        await axios.get('/api/v1/loan-reports/monthlydisbursements', { params: params })
            .then(res => {
                commit('SET_MONTHLY_DISBURSEMENT_REPORT', res.data);
            }).catch(err => {
                return Promise.reject(err);
            })
        },

       async fetchdailydisbursementreport({ commit }, params) {
        await axios.get('/api/v1/loan-reports/dailydisbursements', { params: params })
            .then(res => {
                commit('SET_DAILY_DISBURSEMENT_REPORT', res.data);
            }).catch(err => {
                return Promise.reject(err);
            })
        },

       async fetchbranchperfomancereport({ commit }, params) {
        await axios.get('/api/v1/loan-reports/branchperfomance', { params: params })
            .then(res => {
                commit('SET_BRANCH_PERFOMANCE_REPORT', res.data);
            }).catch(err => {
                return Promise.reject(err);
            })
        },

       async fetchteamperfomancereport({ commit }, params) {
        await axios.get('/api/v1/loan-reports/teamperfomance', { params: params })
            .then(res => {
                commit('SET_TEAM_PERFOMANCE_REPORT', res.data);
            }).catch(err => {
                return Promise.reject(err);
            })
        },

       async fetchclientfacilitiesreport({ commit }, params) {
        await axios.get('/api/v1/loan-reports/clientfacilities', { params: params })
            .then(res => {
                commit('SET_CLIENT_FACILITIES_REPORT', res.data);
            }).catch(err => {
                return Promise.reject(err);
            })
        },

        async fetchloanpaymentreport({ commit }, params) {

            await axios.get('/api/v1/loan-reports/paymentreport', { params: params })
                .then(res => {
                    commit('SET_LOAN_PAYMENT_REPORT', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchloandefaultersreport({ commit }, params) {

            await axios.get('/api/v1/loan-reports/defaultersreport', { params: params })
                .then(res => {
                    commit('SET_LOAN_DEFAULTERS_REPORT', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },
        async fetchpartialpaymentsreport({ commit }, params) {

            await axios.get('/api/v1/loan-reports/partialpayments', { params: params })
                .then(res => {
                    commit('SET_PARTIAL_PAYMENT_REPORT', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

     

    }

}
