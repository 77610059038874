const depositChargesRoutes = [

    {

        path: '',
        name: 'depositChargeHome',
        component: () => import ('./ListDepositCharges.vue'),
        meta: {
            auth: true,
            title: 'Deposit Charges Listing',
            breadcrumb: 'Deposit Charges Listing',
            permission: ['view depositcharges'] //'view collateral'
        }

    },

    {
        path: 'create',
        name: 'createdepositcharge',
        component: () => import('./CreateEditDepositCharge.vue'),
        meta: {
            auth: true,
            title: 'Create Deposit Charge',
            breadcrumb: 'Create Deposit Charge',
            permission: ['create depositcharges'] //'create collateral'
        }
    },

    {
        path: ':id/edit',
        name: 'editdepositcharge',
        component: () => import ('./CreateEditDepositCharge.vue'),
        meta: {
            auth: true,
            title: 'Edit Deposit Charge',
            breadcrumb: 'Edit Deposit Charge',
            permission: ['update depositcharges'] //'update collateral'
        }
    },

];


export default depositChargesRoutes;
