import axios from "axios";

export default {

    namespaced: true,

    state: {

        documentationfees: [],
        documentationfee: {}

    },

    getters: {

        documentationfees(state) {
            return state.documentationfees;
        },
        documentationfee(state) {
            return state.documentationfee;
        }

    },

    mutations: {

        SET_DOCUMENTATION_FEES(state, documentationfees) {
            return state.documentationfees = documentationfees
        },

        SET_DOCUMENTATION_FEE(state, documentationfee) {
            return state.documentationfee = documentationfee
        }

    },

    actions: {

        async fetchdocumentationfees({ commit }, params) {

            await axios.get('/api/v1/documentation-fees', { params: params })
                .then(res => {
                    commit('SET_DOCUMENTATION_FEES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },


        async fetchdocumentationfee({ commit }, documentation_fee_id) {

            await axios.get(`/api/v1/documentation-fees/${documentation_fee_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_DOCUMENTATION_FEE', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createdocumentationfee({ commit, dispatch }, documentationfee) {
            await axios.post('/api/v1/documentation-fees', documentationfee);

        },

        async updatedocumentationfee({ commit, dispatch }, documentationfee) {

            await axios("/api/v1/documentation-fees/" + documentationfee.documentation_fee_id, {
                method: "patch",
                data: documentationfee,
            });

        },

        async deletedocumentationfee({ commit }, documentation_fee_id) {

            await axios.delete(`/api/v1/documentation-fees/${documentation_fee_id}`);

        }

    }

}
