<template>

    <modal v-show="showModal"  @close="toggleAttachTeamPopup" :modalSize="'modal-md'" >
      <template v-slot:header>
      Attach Team
      </template>
      <template v-slot:body>
        <div class="card">
          <div class="card-body stretchable-modal-body">
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <label
                    for="team_name"
                >
                  Team Name
                </label>
              </div>
              <div class="col-lg-6 col-sm-6">
                <select
                    class="form-control"
                    v-model="state.team_id"
                    v-if="search_teams.data"
                >
                  <option
                      v-for="team in search_teams.data"
                      :key="team.team_id"
                      :value="team.team_id"
                  >
                    {{ team.team_name }}
                  </option>
                </select>
              </div>

          </div>
            <div class="col-lg-12" >
              <br>
              <div class="form-group text-center">
                <button
                    class="btn btn-primary mr-3"
                    @click="attachTeam"
                >
                  Attach
                  <i class="fa fa-paper-plane"></i>
                </button>
              </div>
            </div>


        </div>
        </div>

      </template>
    </modal>
 </template>
<script>
import {defineComponent, computed, ref, reactive, onMounted} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import _ from "lodash";

const team_module = "teams";
export default defineComponent({

  name: "AttachTeamModal",
  components: {},
  props: {
    showModal: {
      type: Boolean,
      default: true,
    },

    userData: {
      type: Object,
      required: true,
    },
    toggleEditCustomerTeamPopup: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const state = reactive({
      team_id: props.userData.teams?.team_id,
    });
    const toggleAttachTeamPopup = () => {
      context.emit("toggleAttachTeamPopup");
    };
    async function fetchTeams() {

      let payload = { 'page': '1' };
      await store.dispatch(`${team_module}/fetchTeams`, payload)
    }
    function attachTeam(){

      let payload = {
        'team_id': state.team_id,
        'customer_id': props.userData.customer_id,

      };


      store.dispatch(`${team_module}/attachCustomerToTeam`, payload).then((res) => {
          toggleAttachTeamPopup();
          store.dispatch(`${team_module}/fetchTeams`);

    });

    }
    onMounted(async() => {
      await fetchTeams();


    });

    return {
      state,
      toggleAttachTeamPopup,
      search_teams : computed(() => store.getters[`${team_module}/teams`]),
      attachTeam
    };
  },
  });


</script>
