import axios from "axios";

export default {

    namespaced: true,

    state: {

        vendors: [],
        vendor: {}

    },

    getters: {

        vendors(state) {
            return state.vendors;
        },
        vendor(state) {
            return state.vendor;
        }

    },

    mutations: {

        SET_VENDORS(state, vendors) {
            return state.vendors = vendors
        },

        SET_VENDOR(state, vendor) {
            return state.vendor = vendor
        }

    },

    actions: {

        async fetchVendors({ commit }, params) {

            await axios.get('/api/v1/vendors', { params: params })
                .then(res => {
                    commit('SET_VENDORS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchVendor({ commit }, vendor_id) {

            await axios.get(`/api/v1/vendors/${vendor_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_VENDOR', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createVendor({ commit, dispatch }, vendor) {

            await axios.post('/api/v1/vendors', vendor)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchVendors');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateVendor({ commit, dispatch }, vendor) {

            await axios("/api/v1/vendors/" + vendor.vendor_id, {
                method: "patch",
                data: vendor,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchVendors');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteVendor({ commit }, vendor_id) {

            await axios.delete(`/api/v1/vendors/${vendor_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchVendors');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
