<template>
  <button
    :type="buttonType"
    :disabled="isDisabled"
    class="px-4"
    :class="[
      {
        'btn-primary': !isDisabled && !isLoading && buttonType === 'submit',
        'btn-outline-secondary': buttonType === 'preview',
        'btn-muted': isDisabled || isLoading,
      },
      customClasses  /*  Apply custom classes */
    ]"
    @click="handleClick"
  >
    <!-- Show spinner when loading -->
    <span v-if="isLoading">
      <i class="mdi mdi-spin mdi-loading mr-2" role="status"></i>Wait...
    </span>
    <span v-else>
      <!-- Show text when not loading -->
      <slot></slot>
    </span>
  </button>
</template>

<script>
import { ref, computed, defineComponent } from "vue";

export default defineComponent({
  name: "ActionButton",

  props: {
    buttonType: {
      type: String,
      required: true,
      validator: (value) => ["submit", "preview"].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    customClasses: {
      type: Array, 
      default: () => [],
      // Accept array of class names e.g.
      // :customClasses="['my-custom-class', 'another-custom-class']"
    },
  },

  setup(props, { emit }) {
    const isDisabled = ref(props.disabled);

    // Computed property to determine if the button should be disabled
    const disabled = computed(() => isDisabled.value || props.isLoading);

    // Function to handle button click
    const handleClick = () => {
      if (!disabled.value) {
        emit("clicked", props.buttonType);
      }
    };

    return {
      isDisabled,
      disabled,
      handleClick,
    };
  },
});
</script>

<style scoped>
/* custom styling here */
</style>
