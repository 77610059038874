const loanStatusRoutes = [

    {

        path: '',
        name: 'loanstatusHome',
        component: () => import ('./ListLoanStatus.vue'),
        meta: {
            auth: true,
            title: 'Loan Status Listing',
            breadcrumb: 'Loan Status Listing',
            permission: [] //'view loan status'
        }

    },

    {
        path: 'create',
        name: 'createloanstatus',
        component: () => import('./CreateEditLoanStatus.vue'),
        meta: {
            auth: true,
            title: 'Create Loan Status',
            breadcrumb: 'Create Loan Status',
            permission: [] //'create loan status'
        }
    },

    {
        path: ':id/edit',
        name: 'editloanstatus',
        component: () => import ('./CreateEditLoanStatus.vue'),
        meta: {
            auth: true,
            title: 'Edit Loan Status',
            breadcrumb: 'Edit Loan Status',
            permission: [] //'update loan status'
        }
    },

];


export default loanStatusRoutes;
