import axios from "axios";

export default {

    namespaced: true,

    state: {

        paybills: [],
        paybill: {}

    },

    getters: {

        paybills(state) {
            return state.paybills;
        },
        paybill(state) {
            return state.paybill;
        }

    },

    mutations: {

        SET_PAYBILLS(state, paybills) {
            return state.paybills = paybills
        },

        SET_PAYBILL(state, paybill) {
            return state.paybill = paybill
        }

    },

    actions: {

        async fetchPaybills({ commit }, params) {

            await axios.get('/api/v1/paybills', { params: params })
                .then(res => {
                    // console.log("fetchPaybills == ", res);
                    commit('SET_PAYBILLS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchPaybill({ commit }, paybill_id) {

            await axios.get(`/api/v1/paybills/${paybill_id}`)
                .then(res => {
                    // console.log("fetchPaybill store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_PAYBILL', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createPaybill({ commit, dispatch }, paybill) {

            await axios.post('/api/v1/paybills', paybill)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchPaybills');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updatePaybill({ commit, dispatch }, paybill) {

            await axios("/api/v1/paybills/" + paybill.paybill_id, {
                method: "patch",
                data: paybill,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchPaybills');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deletePaybill({ commit }, paybill_id) {

            await axios.delete(`/api/v1/paybills/${paybill_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchPaybills');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        },

        async registerMpesaPaybill({ commit, dispatch }, paybill) {

            await axios.post('/api/v1/paybills/register-c2b-url', paybill)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchPaybills');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        }

    }

}
