const AccountManagementRoutes = [
    {
        path: 'cash-deposit',
        name: 'CashDeposit',
        component: () => import('./CashDeposit.vue'),
        meta: {
            auth: true,
            title: 'Cash Deposit',
            breadcrumb: 'Cash Deposit',
            permission: ['cash deposit']
        },
    },

    {
        path: 'cash-withdrawal',
        name: 'CashWithdrawal',
        component: () => import('./CashWithdrawal.vue'),
        meta: {
            auth: true,
            title: 'Cash Withdraw',
            breadcrumb: 'Cash Withdraw',
            permission: ['cash withdrawal']
        },
    },
    
    {
        path: 'cheque-deposit',
        name: 'ChequeDeposit',
        component: () => import('./ChequeDeposit.vue'),
        meta: {
            auth: true,
            title: 'Cheque Deposit',
            breadcrumb: 'Cheque Deposit',
            permission: ['cheque deposit']
        },
    },

    {
        path: 'cheque-withdrawal',
        name: 'ChequeWithdrawal',
        component: () => import('./ChequeWithdrawal.vue'),
        meta: {
            auth: true,
            title: 'Cheque Withdraw',
            breadcrumb: 'Cheque Withdraw',
            permission: ['cheque withdrawal']
        },
    },

    {
        path: 'fund-transfer',
        name: 'FundTransfer',
        component: () => import('./FundTransfer.vue'),
        meta: {
            auth: true,
            title: 'Fund Transfer',
            breadcrumb: 'Fund Transfer',
            permission: ['fund transfer']
        },
    },

    {
        path: 'adjustment',
        name: 'Adjustments',
        component: () => import('./Adjustment.vue'),
        meta: {
            auth: true,
            title: 'Account Adjustment',
            breadcrumb: 'Account Adjustment',
            permission: ['account adjustment']
        },
    },

    {
        path: 'mpesa-withdrawal',
        name: 'MpesaWithdrawal',
        component: () => import('./MpesaWthdrawal.vue'),
        meta: {
            auth: true,
            title: 'Mpesa Withdraw',
            breadcrumb: 'Mpesa Withdraw',
            permission: ['mpesa withdrawal']
        },
    },

    {
        path: 'my-transactions',
        name: 'MyAccountTransactions',
        component: () => import('./MyTransactions.vue'),
        meta: {
            auth: true,
            title: 'My Transactions',
            breadcrumb: 'My Transactions',
            permission: ['view mytransactions']
        }
    },
];

export default AccountManagementRoutes;