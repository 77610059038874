<template>
  <div class="col-lg-4">
    <div class="card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="card-title">
              Recent
              {{ capitalizeFirstLetter(translations["loan"]) }} Applications
            </h4>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="analytic-dash-activity" data-simplebar>
          <div class="activity">
            <template
                v-if="
                    recentLoanApplications.data &&
                    recentLoanApplications.data.length
                  "
            >
              <div
                  class="activity-info"
                  v-for="(item, index) in recentLoanApplications.data"
                  :key="index"
              >
                <div class="icon-info-activity">
                  <router-link
                      class="custom-link"
                      :to="{
                          name: 'showCustomer',
                          params: { id: item.customer_id },
                        }"
                  >
                    <!-- <i class="las la-user-clock bg-soft-primary"></i> -->
                    <img
                        v-if="getMemberPhotoThumb(item)"
                        :src="getMemberPhotoThumb(item)"
                        :alt="`${item.first_name} photo`"
                        class="rounded-circle thumb-md"
                        width="50"
                    />
                    <!-- <i v-else class="las la-user-clock bg-soft-primary"></i> -->
                    <img
                        v-else-if="item.gender === 'Female'"
                        :src="noUserFemaleImage"
                        :alt="`${item.first_name} photo`"
                        class="rounded-circle thumb-md"
                        width="50"
                    />

                    <img
                        v-else
                        :src="noUserImage"
                        :alt="`${item.first_name} photo`"
                        class="rounded-circle thumb-md"
                        width="50"
                    />
                  </router-link>
                </div>
                <div class="activity-info-text">
                  <div
                      class="d-flex justify-content-between align-items-center"
                  >
                    <router-link
                        class="custom-link"
                        :to="{
                            name: 'showCustomer',
                            params: { id: item.customer_id },
                          }"
                    >
                      <p class="text-muted mb-0 font-13">
                            <span>KSH {{ item?.requested_amount }}</span
                            ><br />
                        {{ item?.first_name }} {{item?.last_name}}
                      </p>
                    </router-link>
                    <small class="text-muted"
                    >{{ timeSinceRegistered(item?.created_at) }}
                    </small>
                  </div>
                </div>
              </div>
            </template>

            <records-not-found v-else />

            <!-- <div class="activity-info">
              <div class="icon-info-activity">
                <i class="las la-user-clock bg-soft-primary"></i>
              </div>
              <div class="activity-info-text">
                <div
                  class="d-flex justify-content-between align-items-center"
                >
                  <p class="text-muted mb-0 font-13 w-75">
                    <span>Donald</span> updated the status of
                    <a href="#">Refund #1234</a>
                    to awaiting customer response
                  </p>
                  <small class="text-muted">10 Min ago</small>
                </div>
              </div>
            </div>
            <div class="activity-info">
              <div class="icon-info-activity">
                <i class="mdi mdi-timer-off bg-soft-primary"></i>
              </div>
              <div class="activity-info-text">
                <div
                  class="d-flex justify-content-between align-items-center"
                >
                  <p class="text-muted mb-0 font-13 w-75">
                    <span>Lucy Peterson</span>
                    was added to the group, group name is
                    <a href="#">Overtake</a>
                  </p>
                  <small class="text-muted">50 Min ago</small>
                </div>
              </div>
            </div>
            <div class="activity-info">
              <div class="icon-info-activity">
                <img
                  src="{{ asset('assets/images/users/no-user.jpg') }}"
                  alt=""
                  class="rounded-circle thumb-md"
                />
              </div>
              <div class="activity-info-text">
                <div
                  class="d-flex justify-content-between align-items-center"
                >
                  <p class="text-muted mb-0 font-13 w-75">
                    <span>Joseph Rust</span>
                    opened new showcase
                    <a href="#">Mannat #112233</a>
                    with theme market
                  </p>
                  <small class="text-muted">10 hours ago</small>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import { defineComponent } from "vue";
import { numberFormatter,timeSinceRegistered } from "@/filters";
import { capitalizeFirstLetter } from "@/helpers/utils";
import noUserImage from "@/assets/images/users/no-user.jpg";
import noUserFemaleImage from "@/assets/images/users/no-user-female.jpg";

export default defineComponent({
  name: "RecentLoanApplications",
  props: {
    recentLoanApplications: Object,
    translations:Object
  },
  data: () => ({}),
  // setup
  setup() {

    function getMemberPhotoThumb(item) {
      if (item && item.member_photo && item.member_photo.length > 0) {
        return item.member_photo[0].thumb_path;
      }
      return null;
    }
    return {
      numberFormatter,
      capitalizeFirstLetter,
      getMemberPhotoThumb,
      noUserImage,
      noUserFemaleImage,
      timeSinceRegistered
    };

  }
});
</script>
