import axios from "axios";

export default {

    namespaced: true,

    state: {

        wards: [],
        ward: {}

    },

    getters: {

        wards(state) {
            return state.wards;
        },
        ward(state) {
            return state.ward;
        }

    },

    mutations: {

        SET_WARDS(state, wards) {
            return state.wards = wards
        },

        SET_WARD(state, ward) {
            return state.ward = ward
        },

        CLEAR_WARDS(state) {
            return state.wards = []
        },

    },

    actions: {

        async fetchWards({ commit }, params) {

            await axios.get('/api/v1/wards', { params: params })
                .then(res => {
                    // console.log(res.data);
                    commit('SET_WARDS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchWard({ commit }, ward_id) {

            await axios.get(`/api/v1/wards/${ward_id}`)
                .then(res => {
                    // console.log("fetchState store == ", res);
                    if (res.stateText === 'OK') {
                        commit('SET_WARD', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async clearValues({ commit }) {
            commit('CLEAR_WARDS');
        }

    }

}
