export default {
    // Get all manual transaction uploads
    manualTransactionUploads: (state) => {
        return state.manual_transaction_uploads;
    },

    // Get a specific manual transaction upload by ID
    manualTransactionUploadById: (state) => (id) => {
        return state.manual_transaction_uploads.find(upload => upload.id === id);
    },

    // Get the current manual transaction upload
    currentManualTransactionUpload: (state) => {
        return state.manual_transaction_upload;
    },

    // Get all failed manual transactions
    failedManualTransactions: (state) => {
        return state.failed_manual_transactions;
    },

    // Get all posted manual transactions
    postedManualTransactions: (state) => {
        return state.posted_manual_transactions;
    },

};

