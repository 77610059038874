const pendingAdministrationRoutes = [
	{
		name: "pendingAdministrationHome",
		path: "",
		component: () => import("./ListPendingAdministration.vue"),
		meta: {
			auth: true,
			title: "Pending Items Listing",
			breadcrumb: "Pending Items Listing",
			permission: ["view makerchecker"],
		},
	},

	{
		path: ":id",
		name: "showPendingAdministration",
		component: () => import("./ShowPendingAdministration.vue"),
		meta: {
			auth: true,
			title: "Showing Pending Item",
			breadcrumb: "Showing Pending Item",
			permission: ["view makerchecker"],
		},
	},
];

export default pendingAdministrationRoutes;
