import axios from "axios";

export default {

    namespaced: true,

    state: {

        customer: {}

    },

    getters: {

        customer(state) {
            return state.customer;
        }

    },

    mutations: {

        SET_CUSTOMER(state, customer) {
            return state.customer = customer
        },

        SET_NEXT_OF_KIN(state, nextofkin) {
            return state.customer.next_of_kins.push(nextofkin);
        }

    },

    actions: {

        async addNextOfKin({ commit }, nextofkin) {

            commit('SET_NEXT_OF_KIN', nextofkin);

        },

        async fetchCustomerData({ commit }) {

            await axios.get(`/api/v1/customerdata`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_CUSTOMER', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateCustomerData({ commit, dispatch }, customer) {

            await axios("/api/v1/customerdata", {
                method: "patch",
                data: customer,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchCustomerData');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        }

    }


}
