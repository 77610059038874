const groupRegistrationRoutes = [

    {

        name: 'groupHome',
        path: '',
        component: () => import('./ListGroups.vue'),
        meta: {
            auth: true,
            title: 'Groups Lists',
            breadcrumb: 'Groups Lists',
            permission: ['view group'],
            section: 'group'
        }

    },

    {

        name: 'groupMembers',
        path: ':id/members',
        component: () => import('./ListGroupMembers.vue'),
        meta: {
            auth: true,
            title: 'Group Member Lists',
            breadcrumb: 'Group Member Lists',
            permission: ['view group'],
            section: 'group'
        },

    },

    {
        path: 'calllog/:id',
        name: 'groupcall',
        component: () => import('./../CustomerCall/CustomerCalls.vue'),
        meta: {
            auth: true,
            title: 'Member Call Logs',
            breadcrumb: 'Member Call Logs',
            permission: ['view member'],
            section: 'group'
        }
    },

    {
        path: 'consolidatedstatement/:id',
        name: 'groupconsolidatedstatement',
        component: () => import('./../Customer/ConsolidatedStatement.vue'),
        meta: {
            auth: true,
            title: 'Consolidated Statement',
            breadcrumb: 'Consolidated Statement',
            permission: ['view member'],
            section: 'group'
        }
    },

    {
        path: 'loans/:customerId',
        name: 'groupMemberLoans',
        component: () => import('./../../DepositLoan/Loan/ListCustomerLoans.vue'),
        meta: {
            auth: true,
            title: 'Showing Customer Loans',
            breadcrumb: 'Showing Customer Loans',
            permission: ['view group'],
            section: 'group'
        }
    },

    {
        path: 'consolidatedstatement/:id',
        name: 'consolidatedstatement',
        component: () => import('./../Customer/ConsolidatedStatement.vue'),
        meta: {
            auth: true,
            title: 'Consolidated Statement',
            breadcrumb: 'Consolidated Statement',
            permission: ['vview group'],
            section: 'group'
        }
    },

    {
        path: 'deposits/:customerId',
        name: 'groupMemberDeposits',
        component: () => import('./../../DepositLoan/DepositAccount/ListDepositAccounts.vue'),
        meta: {
            auth: true,
            title: 'Showing Customer Deposits',
            breadcrumb: 'Showing Customer Deposits',
            permission: ['view group'],
            section: 'group'
        }
    },

    {
        path: 'shares/:customerId',
        name: 'groupMemberShares',
        component: () => import('./../../DepositLoan/ShareDepositAccount/ListSharesAccounts.vue'),
        meta: {
            auth: true,
            title: 'Showing Customer Shares',
            breadcrumb: 'Showing Customer Shares',
            permission: ['view group'],
            section: 'group'
        }
    },

    {
        path: 'create',
        name: 'createGroup',
        component: () => import('./CreateGroup.vue'),
        meta: {
            auth: true,
            title: 'Create Group',
            breadcrumb: 'Create Group',
            permission: ['create group'],
            section: 'group'
        }
    },

    {
        path: ':id',
        name: 'showGroup',
        component: () => import('./ShowGroup.vue'),
        meta: {
            auth: true,
            title: 'Showing Group',
            breadcrumb: 'Showing Group',
            permission: ['view group'],
            section: 'group'
        }
    },

    {
        path: ':id/edit',
        name: 'editGroup',
        component: () => import('./EditGroup.vue'),
        meta: {
            auth: true,
            title: 'Edit Group',
            breadcrumb: 'Edit Group',
            permission: ['update group'],
            section: 'group'
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteGroup',
        meta: {
            auth: true,
            title: 'Delete Group',
            permission: ['delete group'],
            section: 'group'
        }
    },

];

export default groupRegistrationRoutes;
