const fixedAccountRoutes = [

    {

        path: '',
        name: 'fixedAccountHome',
        component: () => import('./ListFixedAccounts.vue'),
        meta: {
            auth: true,
            title: 'Fixed Accounts',
            breadcrumb: 'Fixed Accounts',
            permission: ['view fixed accounts']
        }

    },

    {
        path: 'create',
        name: 'createFixedDeposit',
        component: () => import('./CreateEditFixed.vue'),
        meta: {
            auth: true,
            title: 'Create Fixed Deposit Account',
            breadcrumb: 'Create Fixed Deposit Account',
            permission: ['create fixed accounts']
        }
    },

    {
        path: ':id/edit',
        name: 'editFixedDeposit',
        component: () => import('./CreateEditFixed.vue'),
        meta: {
            auth: true,
            title: 'Edit Fixed Deposit Account',
            breadcrumb: 'Edit Fixed Deposit Account',
            permission: ['update fixed accounts']
        }
    },

    {

        path: 'balance',
        name: 'fixedDepositAccountBalance',
        component: () => import('./ListBalances.vue'),
        meta: {
            auth: true,
            title: 'Fixed Deposit Account Balances',
            breadcrumb: 'Fixed Deposit Account Balances',
            permission: ['view fixed accounts'] 
        }

    },

];

export default fixedAccountRoutes;
