import axios from "axios";

export default {

    namespaced: true,

    state: {

        recommends: [],
        recommend: {}

    },

    getters: {

        recommends(state) {
            return state.recommends;
        },
        recommend(state) {
            return state.recommend;
        }

    },

    mutations: {

        SET_RECOMMENDS(state, recommends) {
            return state.recommends = recommends
        },

        SET_RECOMMEND(state, recommend) {
            return state.recommend = recommend
        }

    },

    actions: {

        async fetchRecommends({ commit }, params) {

            await axios.get('/api/v1/recommends', { params: params })
                .then(res => {
                    // console.log("fetchRecommends == ", res);
                    commit('SET_RECOMMENDS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchRecommend({ commit }, recommend_id) {

            await axios.get(`/api/v1/recommends/${recommend_id}`)
                .then(res => {
                    // console.log("fetchRecommend store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_RECOMMEND', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createRecommend({ commit, dispatch }, recommend) {

            await axios.post('/api/v1/recommends', recommend)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchRecommends');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateRecommend({ commit, dispatch }, recommend) {

            await axios("/api/v1/recommends/" + recommend.recommend_id, {
                method: "patch",
                data: recommend,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchRecommends');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteRecommend({ commit }, recommend_id) {

            await axios.delete(`/api/v1/recommends/${recommend_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchRecommends');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
