<template>
  <modal v-show="showSecuritiesModal" @close="closeSecuritiesModal">
    <template v-slot:header>
      <span v-if="!isEdit && !isShow"
      >Add Security Details</span>
      <span v-if="isEdit && !isShow"
      >Editing Security Details - </span>
      <span v-if="isEdit && isShow"
      >Showing Security Details -</span>
    </template>

    <template v-slot:body>
      <!-- model == {{ model }}<br> -->
      <!-- userBankData == {{ userBankData }}<br> -->
      <div class="card security-card" style="height:310px">
        <div class="card-body stretchable-modal-body">
          <form @submit.prevent="onSubmitted">

            <div class="row">
              <div class="col-lg-4 col-md-4">
                <label class="form-label" for="first_name">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                    >Type</span
                    >
                </label>
                <select class="form-control" v-model="securitiesData.type" @change="updateSecurityType(index, $event)">
                  <option value="1">Collateral</option>
                  <option value="2">Guarantor</option>
                </select>
              </div>
              <div class="col-lg-4 col-md-4">
                <label class="form-label" for="first_name">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                    >Balance to use</span
                    >
                </label>
                <select class="form-control" v-model="securitiesData.balance_to_use">
                  <option v-for="option in balanceOptions" :value="option.value" :key="option.value">{{ option.text }}</option>
                </select>
                </div>

            <div class="col-lg-4 col-md-4" id="customer_id_div">
              <label class="form-label" for="first_name">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                    >Name</span
                    >
              </label>
              <Multiselect
                  v-model="securitiesData.unique_no"
                  ref="multiselect"
                  id="customer_id"
                  :options="security_guarantors"
                  mode="single"
                  :searchable="true"
                  trackBy="full_name"
                  label="full_name"
                  valueProp="customer_id"
                  @search-change="onCustomerInputChange($event)"
                  @change="customerIdHasChanged"
              />
            </div>
            </div>
            <div class="row">

              <div class="col-lg-4 col-md-4" v-if="securitiesData.type == 2" >
                <label class="form-label" for="first_name">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                    >Deposit Account</span
                    >
                </label>
                <select
                    class="form-control"
                    v-model="securitiesData.deposit_application_id"
                    @change="captureSelectedDepositAccount"

                    :disabled="!depositAccounts.data || depositAccounts.data.length === 0"
                >
                  <template v-if="depositAccounts.data && depositAccounts.data.length > 0">
                    <option
                        v-for="(account, accountIndex) in depositAccounts.data"
                        :key="accountIndex"
                        :value="account.deposit_application_id"
                    >
                      {{ account.deposit_reference }} {{ account.deposit_name }} {{ account.balance }}
                    </option>
                  </template>
                  <option v-else value="" disabled></option>
                </select>
              </div>

              <div class="col-lg-4 col-md-4" v-if="securitiesData.type == 1" >
                <label class="form-label" for="first_name">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                    >Collaterals</span
                    >
                </label>


                <Multiselect
                    v-model="securitiesData.collateral_id"
                    ref="multiselect"
                    id="collateral_id"
                    :options="collaterals_list"
                    :searchable="true"
                    trackBy="collateral_id"
                    label="collateral_name"
                    valueProp="collateral_id"
                />

              </div>

              <div class="col-lg-4 col-md-4">
                <label class="form-label" for="first_name">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                    >Amount</span
                    >
                </label>
                <input
                    class="form-control error"
                    type="text"
                    v-model="securitiesData.amount"
                />
              </div>

              <div class="col-lg-12" v-if="!isShow">
                <div class="form-group text-center submit-guarantor">
                  <button
                      class="btn btn-primary mr-3"
                      type="submit"
                      :class="'btn-secondary'"
                      :disabled="!securitiesData.balance_to_use"
                  >
                    {{ isEdit ? 'Edit security details' : 'Add security details' }}
                    <i class="fa fa-paper-plane"></i>
                  </button>
                </div>
            </div>
            </div>
          </form>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>

import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import {useStore} from "vuex";
import Multiselect from "@vueform/multiselect";

const deposit_account_module = "currentDepositAccounts";
const customer_module = "customers";
const collaterals_module = "collaterals";

export default defineComponent({
  name: "SecuritiesModal",

  props: {

    showSecuritiesModal: {
      type: Boolean,
      default: false,
    },
    securityCustomers: {
      type: Array,
      required: false,
    },
    securityData:{
      type: Object,
      required: false,
    },
    toggleSecuritiesModal: {
      type: Function,
      default: () => {
      },
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCustomer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    Multiselect,
  },

  setup(props, {emit}) {

    const {
      showSecuritiesModal,
      securityCustomers,
      securityData,
      toggleSecuritiesModal,
      isEdit,
      isShow,
      isCustomer,
    } = toRefs(props);

    const store = useStore();
    const fetchedCustomerId =  ref(null);
    const security_guarantors = ref([]);
    const deposit_application_id = ref(null);
    const selectedDepositInfo = ref('');
    const multiselectHeight = ref(0);
    const multiselect = ref(null); // Add this line
    const collateral_balance_to_use = ref(0);
    const guarantor_balance_to_use = ref(0);
    const selectedGuarantorName = ref(null);
    let payload = ref(null);
    const collaterals_list = ref([]);
    const selectedCustomerName = ref(null);



    const securitiesData = reactive({
      loan_security_id: 0,
      type: {},
      amount: 0,
      unique_no: {},
      description: {},
      customer_id:0,
      deposit_app_id:0,
      balance_to_use: {},
      deposit_application_id: {},
      collateral_id: {},
      type_name:{},
      balance_to_use_text:{}
    })

    const balanceOptions = ref([
      {value: 1, text: "Gross"},
      {value: 2, text: "Net"}
    ]);

    // on created. Are we in edit/create mode?

// Watch for changes in the isEdit prop
    watch(isEdit, (newValue, oldValue) => {
      // Check if isEdit changed to true
      if (newValue) {
        // Access securityData.value if isEdit is true
        securitiesData.loan_security_id = securityData.value.loan_security_id;
        securitiesData.unique_no = securityData.value.unique_no;
        securitiesData.deposit_application_id = securityData.value.deposit_application_id;
        securitiesData.amount = securityData.value.amount;
        securitiesData.balance_to_use = securityData.value.balance_to_use;
        securitiesData.selectedDepositInfo = securityData.value.selectedDepositInfo;
        securitiesData.full_name = securityData.value.full_name;
        securitiesData.type = securityData.value.type;
        securitiesData.collateral_id = securityData.value.collateral_id;


        // Update selectedTextElement if needed
        const selectedTextElement = document.querySelector('#customer_id');
        if (selectedTextElement) {
          selectedTextElement.textContent = securityData.value.full_name;
        }
      }
    });

    const onCustomerInputChange = async (event) => {
      try {
        const res = await store.dispatch(`${customer_module}/fetchSecurityCustomers`, {
          search_terms: event,
          search_param: null,
          report: "1",
        });
        security_guarantors.value = store.getters[`${customer_module}/security_customers`];



      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    async function customerIdHasChanged(selectedGuarantor) {
      if (selectedGuarantor) {


        // Now you can use selectedGuarantorName or any other properties of the selected guarantor as needed

        store.dispatch(`${deposit_account_module}/fetchcurrentDepositAccounts`, {
          customer_id: selectedGuarantor,
          report: "1"
        });

        //if collateral has been selected then fetch list of collaterals and set them to collaterals dropdown
        if(securitiesData.type == 1){

          collaterals_list.value = await store.dispatch(`${collaterals_module}/fetchCustomerCollaterals`, selectedGuarantor);

        }
      }
    }

    function closeSecuritiesModal(){
       securitiesData.deposit_application_id = null; // Clear selected deposit account
       securitiesData.collateral_id = null; // Clear amount field
      securitiesData.amount = ''; // Clear amount field
      securitiesData.unique_no = null; // Reset unique_no field
      isEdit.value=false;
      isShow.value= false;
      emit('toggle-securities-modal'); // Emit an event to toggle the modal

    }

    // Method to capture selected data
    const captureSelectedDepositAccount = (event) => {
      const selectedIndex = event.target.selectedIndex;
      if (selectedIndex !== -1) {
        selectedDepositInfo.value = event.target.options[selectedIndex].text.trim();
      }
    };


    const depositAccounts = computed(
        () => store.getters[`${deposit_account_module}/currentDepositAccounts`]
    );
    async function onSubmitted() {
      const multiselectContainer = document.getElementById('customer_id_div');

      const selectedTextElement = multiselectContainer.querySelectorAll('.multiselect-single-label-text');

      if (selectedTextElement.length > 0) {
        selectedGuarantorName.value = selectedTextElement[0].textContent.trim();
      }
      const selectedOptionText = balanceOptions.value.find(option => option.value === securitiesData.balance_to_use).text;

      const data = {
        deposit_application_id: securitiesData.deposit_application_id,
        selectedDepositInfo: selectedDepositInfo.value,
        amount: securitiesData.amount,
        unique_no: securitiesData.unique_no,
        full_name:selectedGuarantorName.value,
        balance_to_use:securitiesData.balance_to_use,
        balance_to_use_text:selectedOptionText,
        type:securitiesData.type,
        collateral_id:securitiesData.collateral_id,
        loan_security_id: securitiesData.loan_security_id,

      };

      emit('security-form-submitted', data);
      // Reset the form fields
      securitiesData.deposit_application_id = null;
      securitiesData.amount = '';
      securitiesData.unique_no = null;
      securitiesData.full_name = null;
      securitiesData.balance_to_use = null;
      securitiesData.balance_to_use_text = null;
      securitiesData.type = null;
      securitiesData.type_name = null;
      securitiesData.collateral_id = null;

    }


    function updateSecurityType(index, event) {
      var selectedType = parseInt(event.target.value);
      var balanceToUse = 0;
      if (selectedType === 1) {
        balanceToUse = collateral_balance_to_use.value;
        securitiesData.deposit_application_id = null;
      } else if (selectedType === 2) {
        balanceToUse = guarantor_balance_to_use.value;
        securitiesData.collateral_id = null;
      }
      securitiesData.balance_to_use = balanceToUse;

    }
    return {
      onSubmitted,
      securityCustomers,
      securitiesData,
      security_guarantors,
      customerIdHasChanged,
      onCustomerInputChange,
      depositAccounts,
      multiselectHeight,
      captureSelectedDepositAccount,
      closeSecuritiesModal,
      updateSecurityType,
      balanceOptions,
      collaterals_list
    };
  },
});
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active {
  transition: all 0.8s ease-out;
}

.modal-fade-enter-active {
  transition: all 0.8s ease-in;
}

.submit-guarantor{
  padding: 100px;
}
</style>
