import axios from "axios";

export default {

    namespaced: true,

    state: {

        customerbalances: [],
        depositaccounts: [],
        depositaccountstodisbursedloan: [],
        depositaccountstorecoverloan: [],
        depositapplication: {},
        customerdepositstatement: [],
        customerdepositapplicationhistory: [],
        amountinlien: [],
    },
    
    getters: {

        customerbalances(state) {
            return state.customerbalances;
        },
        depositaccounts(state) {
            return state.depositaccounts;
        },

        depositaccountstodisbursedloan(state) {
            return state.depositaccountstodisbursedloan;
        },
        depositaccountstorecoverloan(state) {
            return state.depositaccountstorecoverloan;
        },

        customerdepositstatement(state) {
            return state.customerdepositstatement;
        },
      
        depositapplication(state){
            return state.depositapplication;
        },

        customerdepositapplicationhistory(state){
            return state.customerdepositapplicationhistory;
        },

        amountinlien(state){
            return state.amountinlien;
        }
   
   

    },

    mutations: {

        SET_CUSTOMER_BALANCES(state, customerbalances) {
            return state.customerbalances = customerbalances
        },


        SET_DEPOSIT_ACCOUNTS(state, depositaccounts) {
            return state.depositaccounts = depositaccounts
        },
        SET_DEPOSIT_ACCOUNTS_TO_RECOVER_LOAN(state, depositaccounts) {
            return state.depositaccountstorecoverloan = depositaccounts
        },
        SET_DEPOSIT_ACCOUNTS_TO_DISBURSE_lOAN(state, depositaccounts) {
            return state.depositaccountstodisbursedloan = depositaccounts
        },

        SET_CUSTOMER_DEPOSIT_STATEMENT(state, customerdepositstatement) {
            return state.customerdepositstatement = customerdepositstatement
        },

        SET_CUSTOMER_DEPOSIT_APPLICATION_HISTORY(state, customerdepositapplicationhistory) {
            return state.customerdepositapplicationhistory = customerdepositapplicationhistory
        },

        SET_AMOUNT_IN_LIEN(state, amountinlien) {
            return state.amountinlien = amountinlien
        },

        SET_DEPOSIT_APPLICATION(state, depositapplicaion){
            return state.depositapplication=depositapplicaion;
        }

    },

    actions: {

        async fetchdepositapplication({ commit }, deposit_application_id) {

            await axios.get(`/api/v1/savings/${deposit_application_id}`)
                .then(res => {
                    // console.log("fetchLoanApplication store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_DEPOSIT_APPLICATION', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchCustomerBalances({ commit }, params) {

            await axios.get('/api/v1/savings/balances', { params: params })
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_CUSTOMER_BALANCES', res.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

  

        
        async fetchCustomerDepositStatement({ commit }, deposit_application_id) {
            await axios.get(`/api/v1/savings/savingStatement/${deposit_application_id}`)
                .then(res => {
                    
                    commit('SET_CUSTOMER_DEPOSIT_STATEMENT', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchCustomerDepositApplicationHistory({ commit }, deposit_application_id) {
            await axios.get(`/api/v1/deposit-application-history/${deposit_application_id}`)
                .then(res => {
                    
                    commit('SET_CUSTOMER_DEPOSIT_APPLICATION_HISTORY', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchAmountInLien({ commit }, deposit_application_id) {
            await axios.get(`/api/v1/amount-in-lien/${deposit_application_id}`)
                .then(res => {
                    
                    commit('SET_AMOUNT_IN_LIEN', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async createFixedDeposit({ commit, dispatch }, data) {
           return await axios.post('/api/v1/savings/createfixedsaving', data)
                .then(res => {
                  return res;
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async createShares({ commit, dispatch }, data) {
           return await axios.post('/api/v1/savings/createshares', data)
                .then(res => {
                    return res;
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async withdrawCurrentSavings({ commit, dispatch }, data) {
           return await axios.post('/api/v1/savings/withdrawcurrentsaving', data)
                .then(res => {
                    return res;    
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async savingsadjustment({ commit, dispatch }, data) {
            return await axios.post('/api/v1/savings/savingsadjustment', data)
                 .then(res => {
                     return res;
                 }).catch(err => {
                     return Promise.reject(err);
                 })
         },

        async savingstransfer({ commit, dispatch }, data) {
           return await axios.post('/api/v1/savings/savingstransfer', data)
                .then(res => {
                    return res;
                }).catch(err => {
                    return Promise.reject(err);
                })
        },
        
        async sharespurchase({ commit, dispatch }, data) {
            return await axios.post('/api/v1/savings/sharespurchase', data)
                 .then(res => {
                     return res;
                 }).catch(err => {
                     return Promise.reject(err);
                 })
         },
         async activateAccount({ commit, dispatch }, data) {
            return await axios.post('/api/v1/savings/activateaccount', data)
                 .then(res => {
                     return res;
                 }).catch(err => {
                     return Promise.reject(err);
                 })
         },
        async sharestransferrepurchase({ commit, dispatch }, data) {
           return await axios.post('/api/v1/savings/sharestransferrepurchase', data)
                .then(res => {
                    return res;
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async withdrawFixedDeposit({ commit, dispatch }, data) {
           return await axios.post('/api/v1/savings/withdrawfixedsaving', data)
                .then(res => {
                    return res;
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async createCurrentSavings({ commit, dispatch }, data) {
           return await axios.post('/api/v1/savings/createcurrentsaving', data)
                .then(res => {
                    return res;
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchDepositAccounts({ commit }, params) {
            await axios.get(`/api/v1/savings`, { params: params })
                .then(res => {
                    let data=res.data.data??[];
                    commit('SET_DEPOSIT_ACCOUNTS', res.data);
                    commit('SET_DEPOSIT_ACCOUNTS_TO_DISBURSE_lOAN', data.filter(e=>e.can_be_disbursed_loan=true));
                    commit('SET_DEPOSIT_ACCOUNTS_TO_RECOVER_LOAN', data.filter(e=>e.can_recover_loan=true));
                }).catch(err => {
                    return Promise.reject(err);
                })

        },
    }

}
