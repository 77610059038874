import axios from "axios";

export default {

    namespaced: true,

    state: {

        emails: [],
        email: {}

    },

    getters: {

        emails(state) {
            return state.emails;
        },
        email(state) {
            return state.email;
        }

    },

    mutations: {

        SET_EMAILS(state, emails) {
            return state.emails = emails
        },

        SET_EMAIL(state, email) {
            return state.email = email
        }

    },

    actions: {

        async fetchEmails({ commit }, params) {

            await axios.get('/api/v1/emailsent', { params: params })
                .then(res => {
                    commit('SET_EMAILS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchEmail({ commit }, email_id) {

            await axios.get(`/api/v1/emailsent/${email_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_EMAIL', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createEmail({ commit, dispatch }, email) {

            await axios.post('/api/v1/emailsent', email)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchEmails');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateEmail({ commit, dispatch }, email) {

            await axios("/api/v1/emailsent/" + email.email_id, {
                method: "patch",
                data: email,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchEmails');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteEmail({ commit }, email_id) {

            await axios.delete(`/api/v1/emailsent/${email_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchEmails');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
