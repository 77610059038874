const customerCallRegistrationRoutes = [
  {
    name: "customercallHome",
    path: "",
    component: () => import("./ListCustomerCalls.vue"),
    meta: {
      auth: true,
      title: "Calls Logs",
      breadcrumb: "Calls Logs",
      permission: ["view customercall"],
    },
  },

  {
    name: "viewCallLog",
    path: "calllog/:id",
    component: () => import("./CustomerCalls.vue"),
    meta: {
      auth: true,
      title: "Member Call Logs",
      breadcrumb: "Member Call Logs",
      permission: ["view customercall"],
    },
  },

  {
    name: "callreport",
    path: "callreport",
    component: () => import("./CallReport.vue"),
    meta: {
      auth: true,
      title: "Call Report",
      breadcrumb: "Call Report",
      permission: ["view customercall"],
    },
  },

  {
    name: "customerregistrationreport",
    path: "customerregistrationreport",
    component: () => import("./CustomerRegistrationReport.vue"),
    meta: {
      auth: true,
      title: "Customer Registration Report",
      breadcrumb: "Customer Registration Report",
      permission: ["view customercall"],
    },
  },
];

export default customerCallRegistrationRoutes;
