import axios from "axios";

export default {

    namespaced: true,

    state: {

        banks: [],
        bank: {}

    },

    getters: {

        banks(state) {
            return state.banks;
        },
        bank(state) {
            return state.bank;
        }

    },

    mutations: {

        SET_BANKS(state, banks) {
            return state.banks = banks
        },

        SET_BANK(state, bank) {
            return state.bank = bank
        },

        ADD_BANK(state, bank) {
            // return state.bank = bank
            return state.banks.push(bank);
        }

    },

    actions: {

        async fetchBanks({ commit }, params) {

            await axios.get('/api/v1/banks', { params: params })
                .then(res => {
                    commit('SET_BANKS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchBank({ commit }, bank_id) {

            await axios.get(`/api/v1/banks/${bank_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_BANK', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createBank({ commit, dispatch }, bank) {

            await axios.post('/api/v1/banks', bank)
                .then(res => {
                    if (res.statusText === 'OK') {
                        // dispatch('fetchBanks');
                        console.log("res.data.data", res.data.data);
                        commit('ADD_BANK', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateBank({ commit, dispatch }, bank) {

            console.log("bank ", bank);
            await axios("/api/v1/banks/" + bank.next_of_kin_id, {
                method: "patch",
                data: bank,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchBanks');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteBank({ commit }, dispatch, bank_id) {

            await axios.delete(`/api/v1/banks/${bank_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchBanks');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }


}
