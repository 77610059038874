import axios from "axios";

export default {

    namespaced: true,

    state: {

        grouprequests: [],
        grouprequest: {}

    },

    getters: {

        grouprequests(state) {
            return state.grouprequests;
        },
        grouprequest(state) {
            return state.grouprequest;
        }

    },

    mutations: {

        SET_GROUP_REQUESTS(state, grouprequests) {
            return state.grouprequests = grouprequests
        },

        SET_GROUP_REQUEST(state, grouprequest) {
            return state.grouprequest = grouprequest
        }

    },

    actions: {

        async fetchGroupRequests({ commit }, params) {

            await axios.get('/api/v1/requestgroup', { params: params })
                .then(res => {
                    commit('SET_GROUP_REQUESTS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchGroupRequest({ commit }, group_request_id) {

            await axios.get(`/api/v1/requestgroup/${group_request_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_GROUP_REQUEST', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createGroupRequest({ commit, dispatch }, grouprequest) {

            await axios.post('/api/v1/requestgroup', grouprequest)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchGroupRequests');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateGroupRequest({ commit, dispatch }, grouprequest) {

            await axios("/api/v1/requestgroup/" + grouprequest.group_request_id, {
                method: "patch",
                data: grouprequest,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchGroupRequests');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteGroupRequest({ commit }, dispatch, group_request_id) {

            await axios.delete(`/api/v1/requestgroup/${group_request_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchGroupRequests');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
