<template>
  <div class="card flex-fill">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h4 class="card-title">
            {{ chartName }}
          </h4>
        </div>

        <div class="col-auto">
          <div class="dropdown">
            <a
              href="#"
              class="btn btn-sm btn-outline-light dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ currentFilter }}<i class="las la-angle-down ml-1"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="selectFilter('Today')"
                >Today</a
              >
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="selectFilter('Yesterday')"
                >Yesterday</a
              >
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="selectFilter('Last Week')"
                >Last Week</a
              >
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="selectFilter('This Month')"
                >This Month</a
              >
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="selectFilter('Last Month')"
                >Last Month</a
              >
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="selectFilter('This Year')"
                >This Year</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body">
      <apexchart
        class="apex-charts"
        :type="chartType"
        :height="chartHeight"
        :options="chartOptions"
        :series="chartSeries"
      >
      </apexchart>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";

export default {
  name: "SharedChart",
  props: {
    chartName: {
      type: String,
      required: true,
    },
    xAxisData: {
      type: Array,
      required: true,
    },
    seriesData: {
      type: Array,
      required: true,
    },
    selectFilter: {
      type: Function,
      required: true,
    },
    currentFilter: {
      type: String,
      required: true,
    },
    chartType: {
      type: String,
      default: "line", // Default to "line" if not provided
    },
    yAxisTitle: {
      type: String,
      default: "Value",
    },
    tooltipFormatter: {
      type: Function,
      default: (val) => val,
    },
    chartHeight: {
      type: Number,
      default: 350, // Default height if not provided
    },
    colors: {
      type: Array,
      default: () => ["rgba(0, 227, 150, .6)", "rgba(24, 131, 205, .6)"], // Default colors if not provided
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const chartOptions = ref({
      chart: {
        height: props.chartHeight,
        type: props.chartType,
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: props.colors,
      markers: {
        size: 0,
        colors: ["#000"],
      },
      stroke: {
        show: true,
        curve: props.chartType === "line" ? "smooth" : "straight",
        width: 2,
        // colors: props.chartType === "bar" ? ["transparent"] : ["#000"],
        lineCap: "square",
      },
      noData: {
        text: "Loading...",
      },
      labels: props.xAxisData,
      xaxis: {
        categories: props.xAxisData,
        lines: { show: true },
        type: "category",
      },
      yaxis: {
        title: {
          text: props.yAxisTitle,
        },
        labels: { offsetX: -12, offsetY: 0 },
      },
      grid: {
        borderColor: "#e0e6ed",
        strokeDashArray: 5,
        xaxis: { lines: { show: true } },
        yaxis: { lines: { show: false } },
      },
      legend: { show: props.showLegend },
      tooltip: {
        y: {
          formatter: props.tooltipFormatter,
        },
        marker: { show: true },
        x: { show: false },
      },
      fill: {
        type: "solid",
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.28,
          opacityTo: 0.05,
          stops: [45, 100],
        },
      },
      responsive: [{ breakpoint: 575 }],
    });

    const chartSeries = computed(() => {
      return props.seriesData.map((series) => ({
        name: series.name,
        data: series.data,
      }));
    });

    // Watch for changes in xAxisData and seriesData to update the chart
    watch(
      () => [props.xAxisData, props.seriesData],
      ([newXAxisData, newSeriesData]) => {
        chartOptions.value = {
          ...chartOptions.value,
          xaxis: {
            ...chartOptions.value.xaxis,
            categories: newXAxisData,
          },
          labels: newXAxisData,
        };

        chartSeries.value = newSeriesData.map((series) => ({
          name: series.name,
          data: series.data,
        }));
      },
      { immediate: true }
    );

    return {
      chartOptions,
      chartSeries,
    };
  },
};
</script>
