import axios from "axios";

export default {

    namespaced: true,

    state: {

        customeremployeeallocations: [],
        customeremployeeallocation: {},

    },

    getters: {

        customeremployeeallocations(state) {
            return state.customeremployeeallocations;
        },
        customeremployeeallocation(state) {
            return state.customeremployeeallocation;
        }

    },

    mutations: {

        SET_CUSTOMER_EMPLOYEE_ALLOCATIONS(state, customeremployeeallocations) {
            return state.customeremployeeallocations = customeremployeeallocations
        },

        SET_CUSTOMER_EMPLOYEE_ALLOCATION(state, customeremployeeallocation) {
            return state.customeremployeeallocation = customeremployeeallocation
        },

    },

    actions: {

        async fetchCustomerEmployeeAllocations({ commit }, params) {

            await axios.get('/api/v1/customer-employee-allocations', { params: params })
                .then(res => {
                    commit('SET_CUSTOMER_EMPLOYEE_ALLOCATIONS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },



        async updateCustomerEmployeeAllocation({ commit, dispatch }, state) {

            await axios.put("/api/v1/customer-employee-allocations/" + state.customer_id, state).then(res => {
            }).catch(err => {
                return Promise.reject(err);
            })

        },



    }

}
