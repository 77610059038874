import axios from "axios";

export default {

    namespaced: true,

    state: {

        membershipsettings: [],
        membershipsetting: {}

    },

    getters: {

        membershipsettings(state) {
            return state.membershipsettings;
        },
        membershipsetting(state) {
            return state.membershipsetting;
        }

    },

    mutations: {

        SET_MEMBERSHIP_SETTINGS(state, membershipsettings) {
            return state.membershipsettings = membershipsettings
        },

        SET_MEMBERSHIP_SETTING(state, membershipsetting) {
            return state.membershipsetting = membershipsetting
        }

    },

    actions: {

        async fetchMembershipSettings({ commit }, params) {

            await axios.get('/api/v1/membership-settings', { params: params })
                .then(res => {
                    // console.log("res == ", res);
                    commit('SET_MEMBERSHIP_SETTINGS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchMembershipSetting({ commit }, product_id) {

            await axios.get(`/api/v1/membership-settings/${product_id}`)
                .then(res => {
                    // console.log("fetchMembershipSetting store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_MEMBERSHIP_SETTING', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateMembershipSetting({ commit, dispatch }, membershipsetting) {

            await axios("/api/v1/membership-settings/" + membershipsetting.membership_setting_id, {
                method: "patch",
                data: membershipsetting,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchMembershipSettings');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        }

    }

}
