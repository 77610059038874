<template>
  <div class="container-fluid no-gutters px-0">
    <div class="col-lg-12 mt-3xx mb-2">
      <search-bar :bread-crumb-only="true"></search-bar>

      <div class="card">
        <hr class="small" />

        <div class="card-body">
          <form @submit.prevent="onSubmitted">
            <div class="row">
              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.first_name.$invalid }"
                >
                  <label for="first_name" class="col-sm-4 col-form-label text-right">
                    First Name
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.first_name.$invalid }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.first_name.$model"
                    />

                    <error-msg :property="model.first_name" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.last_name.$invalid }"
                >
                  <label for="last_name" class="col-sm-4 col-form-label text-right">
                    Last Name
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.last_name.$invalid }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.last_name.$model"
                    />

                    <error-msg :property="model.last_name" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.employee_no.$invalid }"
                >
                  <label for="employee_no" class="col-sm-4 col-form-label text-right">
                    Employee No.
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.employee_no.$invalid }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.employee_no.$model"
                    />

                    <error-msg :property="model.employee_no" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.email.$invalid }"
                >
                  <label for="email" class="col-sm-4 col-form-label text-right">
                    Email
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.email.$invalid }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.email.$model"
                    />

                    <error-msg :property="model.email" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6" v-if="!isEdit">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.password.$invalid }"
                >
                  <label for="password" class="col-sm-4 col-form-label text-right">
                    Password
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.password.$invalid }"
                      type="password"
                      :disabled="isShow"
                      v-model="model.password.$model"
                    />

                    <error-msg :property="model.password" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6" v-if="!isEdit">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.password_confirmation.$invalid }"
                >
                  <label
                    for="password_confirmation"
                    class="col-sm-4 col-form-label text-right"
                  >
                    Password Confirm
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{
                        'form-control-danger': model.password_confirmation.$invalid,
                      }"
                      type="password"
                      :disabled="isShow"
                      v-model="model.password_confirmation.$model"
                    />

                    <error-msg :property="model.password_confirmation" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.id_no.$invalid }"
                >
                  <label for="id_no" class="col-sm-4 col-form-label text-right">
                    ID No
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.id_no.$invalid }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.id_no.$model"
                    />

                    <error-msg :property="model.id_no" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.date_of_birth.$invalid }"
                >
                  <label for="date_of_birth" class="col-sm-4 col-form-label text-right">
                    Date of Birth
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.date_of_birth.$invalid }"
                      type="date"
                      :disabled="isShow"
                      v-model="model.date_of_birth.$model"
                    />

                    <error-msg :property="model.date_of_birth" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group row">
                  <label for="gender" class="col-sm-4 col-form-label text-right">
                    Gender
                  </label>
                  <div class="col-sm-8">
                    <div class="form-check-inline my-1">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="male"
                          name="gender"
                          :disabled="isShow"
                          v-model="model.gender.$model"
                          value="Male"
                          class="custom-control-input"
                        />
                        <label class="custom-control-label" for="male">Male</label>
                      </div>
                    </div>
                    <div class="form-check-inline my-1">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="female"
                          name="gender"
                          :disabled="isShow"
                          v-model="model.gender.$model"
                          value="Female"
                          class="custom-control-input"
                        />
                        <label class="custom-control-label" for="female">Female</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.phone.$invalid }"
                >
                  <label for="phone" class="col-sm-4 col-form-label text-right">
                    Phone
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.phone.$invalid }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.phone.$model"
                    />

                    <error-msg :property="model.phone" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.phone_country.$invalid }"
                >
                  <label for="phone_country" class="col-sm-4 col-form-label text-right">
                    Country
                  </label>
                  <div class="col-sm-8">
                    <select
                      class="form-control"
                      :disabled="isShow"
                      v-model="model.phone_country.$model"
                      v-if="countries.data"
                    >
                      <option value="" selected v-if="!isEdit">Select Country</option>
                      <option
                        v-for="(item, index) in countries.data"
                        :key="index"
                        :value="item.iso2"
                      >
                        {{ item.name }}
                      </option>
                    </select>

                    <error-msg :property="model.phone_country" />
                  </div>
                </div>
              </div>

              <input
                class="form-control"
                type="hidden"
                name="user_type"
                value="EMPLOYEE"
              />

              <div class="col-lg-6">
                <div class="form-group row">
                  <label for="company_id" class="col-sm-4 col-form-label text-right">
                    Company Name
                  </label>
                  <div class="col-sm-8">
                    <select
                      :disabled="isShow"
                      class="form-control"
                      v-model="model.company_id.$model"
                      v-if="companies.data"
                    >
                      <option value="" selected v-if="!isEdit">Select Company</option>
                      <option
                        v-for="(item, index) in companies.data"
                        :key="index"
                        :value="item.company_id"
                      >
                        {{ item.company_name }}
                      </option>
                    </select>
                    <error-msg :property="model.company_id" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group row">
                  <label for="branch_id" class="col-sm-4 col-form-label text-right">
                    Branch
                  </label>
                  <div class="col-sm-8">
                    <select
                      class="form-control"
                      :disabled="isShow"
                      v-model="model.branch_id.$model"
                      v-if="branches.data"
                    >
                      <option value="" selected v-if="!isEdit">Select Branch</option>
                      <option
                        v-for="(item, index) in branches.data"
                        :key="index"
                        :value="item.branch_id"
                      >
                        {{ item.branch_name }}
                      </option>
                    </select>
                    <error-msg :property="model.branch_id" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.status_id.$invalid }"
                >
                  <label for="status_id" class="col-sm-4 col-form-label text-right"
                    >Status</label
                  >
                  <div class="col-sm-8">
                    <div>
                      <div class="form-check-inline my-1">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="status_active"
                            name="status_id"
                            :disabled="isShow"
                            v-model="model.status_id.$model"
                            value="1"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="status_active"
                            >Active</label
                          >
                        </div>
                      </div>

                      <div class="form-check-inline my-1">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="status_inactive"
                            name="status_id"
                            :disabled="isShow"
                            v-model="model.status_id.$model"
                            value="99"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="status_inactive"
                            >Inactive</label
                          >
                        </div>
                      </div>
                    </div>

                    <error-msg :property="model.status_id" />
                  </div>
                </div>
              </div>

              <!-- show maker checker form buttons/ status -->
              <maker-checker-button-component
                :makerChecker="makerChecker"
                v-if="isMakerChecker"
              />

              <div class="col-lg-12" v-else>
                <hr class="row" />

                <div class="form-group">
                  <button
                    class="btn btn-primary mr-3"
                    type="submit"
                    :disabled="model.$invalid"
                    :class="{ 'btn-secondary': model.$invalid }"
                    v-if="!isShow"
                  >
                    <slot name="submitButton"></slot>
                  </button>
                  <button
                    class="btn btn-primary mr-3"
                    type="button"
                    @click="onEdit"
                    v-if="isShow"
                  >
                    Edit
                  </button>
                  <button class="btn btn-danger" type="button" @click="onCancelled">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, url } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

const employeeFormTest = {
  employee_no: "23432",
  first_name: "Peter",
  last_name: "Mkenya",
  user_type: "EMPLOYEE",
  password: "123456",
  password_confirmation: "123456",
  id_no: "76878923",
  date_of_birth: "1995-08-09",
  gender: "Male",
  email: "test@example.com",
  phone: "0722000025",
  phone_country: "KE",
  status_id: 1,
  company_id: 2,
  branch_id: undefined,
  employee_id: undefined, 
};

const employeeForm = {
  employee_no: "",
  first_name: "",
  last_name: "",
  user_type: "EMPLOYEE",
  password: "",
  password_confirmation: "",
  id_no: "",
  date_of_birth: "",
  gender: "Male",
  email: "",
  phone: "",
  phone_country: "KE",
  status_id: 1,
  company_id: undefined,
  branch_id: undefined,
  employee_id: undefined,
};

const module = "employees";
const company_module = "companies";
const branch_module = "branches";
const country_module = "countries";

export default defineComponent({
  name: "EmployeeDetail",

  props: {
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMakerChecker: {
      type: Boolean,
      required: false,
      default: false,
    },
    makerChecker: {
      type: Object,
      required: false,
    },
  },

  emits: ["submitForm", "cancelForm"],

  setup(props, { emit }) {
    const employeeProp = toRefs(props);

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    let employee = ref(employeeForm);

    // load all companies on create component
    let payload = { report: "1" };
    store.dispatch(`${company_module}/fetchCompanies`, payload);

    // load countries
    store.dispatch(`${country_module}/fetchCountries`, payload);

    // on created. Are we in edit mode?
    if (props.isEdit || props.isShow) {
      employee = computed(() => store.getters[`${module}/employee`]);

      payload = { report: "1", company_id: employee.value.company_id };
      store.dispatch(`${branch_module}/fetchBranches`, payload);
    }

    // if we are in maker checker, use maker checker data
    if (employeeProp.isMakerChecker.value) {
      employee = employeeProp.makerChecker.value?.data;
    }

    const rules = {
      employee_no: { required },
      first_name: { required },
      last_name: { required },
      user_type: {},
      password: {},
      password_confirmation: {},
      id_no: { required },
      date_of_birth: { required },
      gender: { required },
      email: { email },
      phone: {},
      phone_country: {},
      status_id: { required },
      branch_id: { required },
      company_id: { required },
    };

    // validate form
    const model = useVuelidate(rules, employee);

    function onSubmitted() {
      emit("submitForm", employee);
    }

    function onCancelled() {
      emit("cancelForm", employee);
    }

    function onEdit() {
      emit("editForm", employee);
    }

    function checked(e) {
      employeeForm.status_id = !!e.target.checked;
    }

    return {
      submitForm: props.submitForm,
      companies: computed(() => store.getters[`${company_module}/companies`]),
      branches: computed(() => store.getters[`${branch_module}/branches`]),
      countries: computed(() => store.getters[`${country_module}/countries`]),
      // employee: computed(() => store.getters[`${module}/employee`]),
      employee,
      model,
      // isEdit: props.isEdit,
      // isShow: props.isShow,
      onSubmitted,
      onCancelled,
      onEdit,
      checked,
    };
  },
});
</script>
