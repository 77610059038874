<template>

    <div class="text-center" style="position: absolute;
    top:0px;
    right:0px;
    width:100%;
    height:100%;
    z-index:10000000;
    opacity: 0.4;
    filter: alpha(opacity=40);">
        <i class="la la-spinner text-primary la-spin progress-icon-spin"></i>
    </div>

</template>

<script>
export default {

}
</script>
