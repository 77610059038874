<template>
  <div class="container-fluid no-gutters px-0">
    <div class="row">
      <div class="col-lg-12 mt-3xx mb-2">
        <search-bar :bread-crumb-only="true"></search-bar>

        <!-- tabs -->
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="onSubmitted">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-toggle="tab"
                    href="#member-details"
                    role="tab"
                    aria-selected="true"
                    >Member Details</a
                  >
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#location-details"
                    role="tab"
                    aria-selected="false"
                    >Location Details</a
                  >
                </li>

                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#employment-details"
                    role="tab"
                    aria-selected="false"
                    >Employment Details</a
                  >
                </li>

                <li class="nav-item" v-if="isEdit">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#member-documents"
                    role="tab"
                    aria-selected="false"
                    >Member Documents</a
                  >
                </li>
              </ul>

              <div class="tab-content">
                <!-- member details tab -->
                <div
                  class="tab-pane pt-3 active"
                  id="member-details"
                  name="member-details"
                  role="tabpanel"
                >
                  <div class="row">
                    <!-- first name  -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.first_name.$invalid }"
                      >
                        <label for="first_name"> First Name </label>
                        <input
                          class="form-control"
                          :class="{
                            'form-control-danger': model.first_name.$invalid,
                          }"
                          type="text"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                          v-model="model.first_name.$model"
                        />
                        <error-msg :property="model.first_name" />
                      </div>
                    </div>

                    <!-- last name -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.last_name.$invalid }"
                      >
                        <label for="last_name"> Last Name </label>
                        <input
                          class="form-control"
                          :class="{
                            'form-control-danger': model.last_name.$invalid,
                          }"
                          type="text"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                          v-model="model.last_name.$model"
                        />
                        <error-msg :property="model.last_name" />
                      </div>
                    </div>

                    <!-- Application Reference -->
                    <div class="col-lg-4 col-md-4" v-if="isEdit || isShow">
                      <div
                        class="form-group"
                        :class="{
                          'has-error': model.application_reference.$invalid,
                        }"
                      >
                        <label for="application_reference">
                          Application Reference
                        </label>
                        <input
                          class="form-control"
                          :class="{
                            'form-control-danger':
                              model.application_reference.$invalid,
                          }"
                          type="text"
                          :disabled="true"
                          v-model="model.application_reference.$model"
                        />
                        <error-msg :property="model.application_reference" />
                      </div>
                    </div>

                    <!-- email -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.email.$invalid }"
                      >
                        <label for="email"> Email </label>
                        <input
                          class="form-control"
                          :class="{
                            'form-control-danger': model.email.$invalid,
                          }"
                          type="text"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                          v-model="model.email.$model"
                        />
                        <error-msg :property="model.email" />
                      </div>
                    </div>

                    <!-- ID Number -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.id_no.$invalid }"
                      >
                        <label for="id_no"> ID Number </label>
                        <input
                          class="form-control"
                          :class="{
                            'form-control-danger': model.id_no.$invalid,
                          }"
                          type="text"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                          v-model="model.id_no.$model"
                        />
                        <error-msg :property="model.id_no" />
                      </div>
                    </div>

                    <!-- DOB -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-outline"
                        :class="{ 'has-error': model.date_of_birth.$invalid }"
                      >
                        <label class="form-label" for="date_of_birth">
                          <span class="h6 bg-white text-muted pt-1 pr-2"
                            >Date of Birth</span
                          >
                        </label>
                        <my-date-picker
                          :mode="'date'"
                          :title="'Birthday (day/month/year)'"
                          :styles="'text-left'"
                          :placeholder="'Birthday (day/month/year)'"
                          v-model="model.date_of_birth.$model"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                        >
                        </my-date-picker>
                        <error-msg :property="model.date_of_birth" />
                      </div>
                    </div>

                    <!-- Gender -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.gender.$invalid }"
                      >
                        <label for="gender"> Gender </label>
                        <div>
                          <div class="form-check-inline my-1">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                id="male"
                                name="gender"
                                :disabled="
                                  isShow ||
                                  userstaging.status_id == statusEnum.ACTIVE
                                "
                                v-model="model.gender.$model"
                                value="Male"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="male"
                                >Male</label
                              >
                            </div>
                          </div>
                          <div class="form-check-inline my-1">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                id="female"
                                name="gender"
                                :disabled="
                                  isShow ||
                                  userstaging.status_id == statusEnum.ACTIVE
                                "
                                v-model="model.gender.$model"
                                value="Female"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="female"
                                >Female</label
                              >
                            </div>
                          </div>
                          <error-msg :property="model.gender" />
                        </div>
                      </div>
                    </div>

                    <!-- Is Group -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.is_group.$invalid }"
                      >
                        <label for="is_group"> Is Group </label>
                        <div>
                          <div class="form-check-inline my-1">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                id="group"
                                name="is_group"
                                :disabled="
                                  isShow ||
                                  userstaging.status_id == statusEnum.ACTIVE
                                "
                                v-model="model.is_group.$model"
                                value="1"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="group"
                                >Yes</label
                              >
                            </div>
                          </div>
                          <div class="form-check-inline my-1">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                id="notgroup"
                                name="is_group"
                                :disabled="
                                  isShow ||
                                  userstaging.status_id == statusEnum.ACTIVE
                                "
                                v-model="model.is_group.$model"
                                value="0"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="notgroup"
                                >No</label
                              >
                            </div>
                          </div>
                          <error-msg :property="model.is_group" />
                        </div>
                      </div>
                    </div>

                    <!--- phone code -->

                    <div class="col-lg-4 col-md-4 mb-2">
                      <div class="form-outline">
                        <label class="form-label" for="phone">
                          <span class="h6 bg-white text-muted pt-1 pr-2"
                            >Phone</span
                          >
                        </label>
                        <div class="row">
                          <!-- phone code -->
                          <div class="col-5">
                            <div
                              :class="{
                                'has-error': model.phone_country.$invalid,
                              }"
                            >
                              <select
                                class="form-control"
                                v-model="model.phone_country.$model"
                                :disabled="
                                  isShow ||
                                  userstaging.status_id == statusEnum.ACTIVE
                                "
                                v-if="countries.data"
                              >
                                <option value="" selected>Select Code</option>
                                <option
                                  v-for="(item, index) in countries.data"
                                  :key="index"
                                  :value="item.iso2"
                                >
                                  {{ item.iso2 }} ({{ item.phonecode }})
                                </option>
                              </select>
                              <error-msg :property="model.phone_country" />
                            </div>
                          </div>
                          <!-- Phone -->
                          <div class="col-7">
                            <div>
                              <input
                                class="form-control"
                                type="text"
                                v-model="model.phone.$model"
                                :disabled="
                                  isShow ||
                                  userstaging.status_id == statusEnum.ACTIVE
                                "
                              />
                              <error-msg :property="model.phone" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Company -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.company_id.$invalid }"
                      >
                        <label for="company_id"> Company </label>
                        <div>
                          <select
                            class="form-control"
                            :disabled="
                              isShow ||
                              userstaging.status_id == statusEnum.ACTIVE
                            "
                            v-model="model.company_id.$model"
                            @change="fetchBranches($event)"
                            v-if="companies.data"
                          >
                            <option value="" selected v-if="!isEdit">
                              Select Company
                            </option>
                            <option
                              v-for="(item, index) in companies.data"
                              :key="index"
                              :value="item.company_id"
                            >
                              {{ item.company_name }}
                            </option>
                          </select>
                          <error-msg :property="model.company_id" />
                        </div>
                      </div>
                    </div>

                    <!-- Branch -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.branch_id.$invalid }"
                      >
                        <label for="branch_id"> Branch </label>
                        <div>
                          <select
                            class="form-control"
                            :disabled="
                              isShow ||
                              userstaging.status_id == statusEnum.ACTIVE
                            "
                            v-model="model.branch_id.$model"
                            v-if="branches.data"
                          >
                            <option value="" selected v-if="!isEdit">
                              Select Branch
                            </option>
                            <option
                              v-for="(item, index) in branches.data"
                              :key="index"
                              :value="item.branch_id"
                            >
                              {{ item.branch_name }}
                            </option>
                          </select>
                          <error-msg :property="model.branch_id" />
                        </div>
                      </div>
                    </div>

                    <!-- Team -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.team_id.$invalid }"
                      >
                        <label for="team_id"> Team </label>
                        <div>
                          <select
                            class="form-control"
                            v-model="model.team_id.$model"
                            :disabled="
                              isShow ||
                              userstaging.status_id == statusEnum.ACTIVE
                            "
                            v-if="teams.data"
                          >
                            <option value="" selected>Select Team</option>
                            <option
                              v-for="(item, index) in teams.data"
                              :key="index"
                              :value="item.team_id"
                            >
                              {{ item.team_name }}
                            </option>
                          </select>
                          <error-msg :property="model.team_id" />
                        </div>
                      </div>
                    </div>

                    <!-- Lead Type -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.lead_type.$invalid }"
                      >
                        <label for="lead_type"> Lead Type </label>
                        <div>
                          <div class="form-check-inline my-1">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                id="cold"
                                name="lead_type"
                                :disabled="
                                  isShow ||
                                  userstaging.status_id == statusEnum.ACTIVE
                                "
                                v-model="model.lead_type.$model"
                                value="1"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="cold"
                                >Cold</label
                              >
                            </div>
                          </div>
                          <div class="form-check-inline my-1">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                id="warm"
                                name="lead_type"
                                :disabled="
                                  isShow ||
                                  userstaging.status_id == statusEnum.ACTIVE
                                "
                                v-model="model.lead_type.$model"
                                value="2"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="warm"
                                >Warm</label
                              >
                            </div>
                          </div>
                          <div class="form-check-inline my-1">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                id="hot"
                                name="lead_type"
                                :disabled="
                                  isShow ||
                                  userstaging.status_id == statusEnum.ACTIVE
                                "
                                v-model="model.lead_type.$model"
                                value="3"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="hot"
                                >Hot</label
                              >
                            </div>
                          </div>
                          <error-msg :property="model.gender" />
                        </div>
                      </div>
                    </div>

                    <!-- Status -->
                    <div
                      class="col-lg-4 col-md-4"
                      v-if="userstaging.status_id == statusEnum.ACTIVE"
                    >
                      <div class="form-group">
                        <label for="status"> Status </label>
                        <input
                          class="form-control"
                          :value="model.status.$model.status_name"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                        />
                      </div>
                    </div>

                    <!-- Created at -->
                    <div
                      class="col-lg-4 col-md-4"
                      v-if="userstaging.status_id == statusEnum.ACTIVE"
                    >
                      <div class="form-group">
                        <label for="created_at"> Created At </label>
                        <input
                          class="form-control"
                          :value="formatDate(model.created_at.$model)"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                        />
                      </div>
                    </div>

                    <!-- Activated at -->
                    <div
                      class="col-lg-4 col-md-4"
                      v-if="userstaging.status_id == statusEnum.ACTIVE"
                    >
                      <div class="form-group">
                        <label for="activated_at"> Activated At </label>
                        <input
                          class="form-control"
                          :value="formatDate(model.activated_at.$model)"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                        />
                      </div>
                    </div>

                    <!-- Activated by -->
                    <div
                      class="col-lg-4 col-md-4"
                      v-if="userstaging.status_id == statusEnum.ACTIVE"
                    >
                      <div class="form-group">
                        <label for="activated_by"> Activated By </label>
                        <input
                          class="form-control"
                          :value="model.activater.$model.full_name"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                        />
                      </div>
                    </div>

                    <!-- <div class="col-md-12" v-if="userstaging.status_id != statusEnum.ACTIVE">
                      <hr />

                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div
                            class="form-group"
                            :class="{ 'has-error': model.password.$invalid }"
                          >
                            <label for="password"> Password </label>
                            <input
                              class="form-control"
                              :class="{
                                'form-control-danger': model.password.$invalid,
                              }"
                              type="password"
                              :disabled="isShow || userstaging.status_id == statusEnum.ACTIVE"
                              v-model="model.password.$model"
                            />
                            <error-msg :property="model.password" />
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                          <div
                            class="form-group"
                            :class="{ 'has-error': model.password_confirmation.$invalid }"
                          >
                            <label for="password_confirmation"> Confirm Password </label>
                            <input
                              class="form-control"
                              :class="{
                                'form-control-danger':
                                  model.password_confirmation.$invalid,
                              }"
                              type="password"
                              :disabled="isShow || userstaging.status_id == statusEnum.ACTIVE"
                              v-model="model.password_confirmation.$model"
                            />
                            <error-msg :property="model.password_confirmation" />
                          </div>
                        </div>

                      </div>
                    </div> -->
                  </div>
                </div>

                <!-- location tab -->
                <div
                  class="tab-pane pt-3"
                  id="location-details"
                  name="location-details"
                  role="tabpanel"
                >
                  <div class="row">
                    <!-- Nationality -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.nationality_id.$invalid }"
                      >
                        <label for="nationality_id"> Nationality </label>
                        <div>
                          <select
                            class="form-control"
                            :disabled="
                              isShow ||
                              userstaging.status_id == statusEnum.ACTIVE
                            "
                            v-model="model.nationality_id.$model"
                            v-if="countries.data"
                          >
                            <option value="" selected v-if="!isEdit">
                              Select Nationality
                            </option>
                            <option
                              v-for="(item, index) in countries.data"
                              :key="index"
                              :value="item.country_id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                          <error-msg :property="model.nationality_id" />
                        </div>
                      </div>
                    </div>

                    <!-- Country -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.country_id.$invalid }"
                      >
                        <label for="country_id"> Country </label>
                        <div>
                          <select
                            class="form-control"
                            :disabled="
                              isShow ||
                              userstaging.status_id == statusEnum.ACTIVE
                            "
                            v-model="model.country_id.$model"
                            v-if="countries.data"
                            @change="onChangeCountry($event)"
                          >
                            <option value="" selected v-if="!isEdit">
                              Select Country
                            </option>
                            <option
                              v-for="(item, index) in countries.data"
                              :key="index"
                              :value="item.country_id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                          <error-msg :property="model.country_id" />
                        </div>
                      </div>
                    </div>

                    <!-- County -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.county_id.$invalid }"
                      >
                        <label for="county_id"> County </label>
                        <div>
                          <select
                            class="form-control"
                            :disabled="
                              isShow ||
                              userstaging.status_id == statusEnum.ACTIVE
                            "
                            v-model="model.county_id.$model"
                            v-if="countries.data"
                            @change="onChangeCounty($event)"
                          >
                            <option value="" selected v-if="!isEdit">
                              Select County
                            </option>
                            <option
                              v-for="(item, index) in counties.data"
                              :key="index"
                              :value="item.county_id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                          <error-msg :property="model.county_id" />
                        </div>
                      </div>
                    </div>

                    <!-- Constituency -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.constituency_id.$invalid }"
                      >
                        <label for="constituency_id"> Constituency </label>
                        <div>
                          <select
                            class="form-control"
                            :disabled="
                              isShow ||
                              userstaging.status_id == statusEnum.ACTIVE
                            "
                            v-model="model.constituency_id.$model"
                            v-if="countries.data"
                            @change="onChangeConstituency($event)"
                          >
                            <option value="" selected v-if="!isEdit">
                              Select Constituency
                            </option>
                            <option
                              v-for="(item, index) in constituencies.data"
                              :key="index"
                              :value="item.constituency_id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                          <error-msg :property="model.constituency_id" />
                        </div>
                      </div>
                    </div>

                    <!-- Ward -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.ward_id.$invalid }"
                      >
                        <label for="ward_id"> Ward </label>
                        <div>
                          <select
                            class="form-control"
                            :disabled="
                              isShow ||
                              userstaging.status_id == statusEnum.ACTIVE
                            "
                            v-model="model.ward_id.$model"
                            v-if="wards.data"
                          >
                            <option value="" selected v-if="!isEdit">
                              Select Ward
                            </option>
                            <option
                              v-for="(item, index) in wards.data"
                              :key="index"
                              :value="item.ward_id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                          <error-msg :property="model.ward_id" />
                        </div>
                      </div>
                    </div>

                    <!-- Street Address -->
                    <div class="col-lg-4 col-md-4">
                      <div
                        class="form-group"
                        :class="{ 'has-error': model.street_address.$invalid }"
                      >
                        <label for="street_address"> Street Address </label>
                        <input
                          class="form-control"
                          :class="{
                            'form-control-danger':
                              model.street_address.$invalid,
                          }"
                          type="text"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                          v-model="model.street_address.$model"
                        />
                        <error-msg :property="model.street_address" />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- employment tab -->
                <div
                  class="tab-pane pt-3"
                  id="employment-details"
                  name="employment-details"
                  role="tabpanel"
                >
                  <div class="row">
                    <!-- country -->
                    <div class="col-lg-4 col-md-4 mb-2">
                      <div class="form-group">
                        <label for="employer_category"
                          >Are You Employed/ In Business?
                        </label>
                        <div class="input-group mb-3">
                          <div class="form-check-inline my-1">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                id="employed"
                                name="employer_category"
                                v-model="model.employer_category.$model"
                                :disabled="
                                  isShow ||
                                  userstaging.status_id == statusEnum.ACTIVE
                                "
                                value="Employed"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="employed"
                                >Employed</label
                              >
                            </div>
                          </div>
                          <div class="form-check-inline my-1">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                id="business"
                                name="employer_category"
                                v-model="model.employer_category.$model"
                                :disabled="
                                  isShow ||
                                  userstaging.status_id == statusEnum.ACTIVE
                                "
                                value="Business"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="business"
                                >In Business</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 mb-2">
                      <div
                        class="form-outline"
                        :class="{
                          'has-error': model.employer_business_name.$invalid,
                        }"
                      >
                        <label class="form-label" for="street_address">
                          Employer/ Business Name
                        </label>
                        <input
                          class="form-control"
                          :class="{
                            'form-control-danger':
                              model.employer_business_name.$invalid,
                          }"
                          type="text"
                          v-model="model.employer_business_name.$model"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                        />

                        <error-msg :property="model.employer_business_name" />
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 mb-2">
                      <div
                        class="form-outline"
                        :class="{
                          'has-error': model.employer_address.$invalid,
                        }"
                      >
                        <label class="form-label" for="street_address">
                          Employer/ Business Address
                        </label>
                        <input
                          class="form-control"
                          :class="{
                            'form-control-danger':
                              model.employer_address.$invalid,
                          }"
                          type="text"
                          v-model="model.employer_address.$model"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                        />
                        <error-msg :property="model.employer_address" />
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 mb-2">
                      <div
                        class="form-outline"
                        :class="{
                          'has-error': model.employer_postal_code.$invalid,
                        }"
                      >
                        <label class="form-label" for="street_address">
                          Employer/ Business Postal Code
                        </label>
                        <input
                          class="form-control"
                          :class="{
                            'form-control-danger':
                              model.employer_postal_code.$invalid,
                          }"
                          type="text"
                          v-model="model.employer_postal_code.$model"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                        />
                        <error-msg :property="model.employer_postal_code" />
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 mb-2">
                      <div class="form-outline">
                        <label class="form-label" for="phone">
                          <span class="h6 bg-white text-muted pt-1 pr-2"
                            >Employer Phone</span
                          >
                        </label>
                        <div class="row">
                          <!-- phone code -->
                          <div class="col-5">
                            <div
                              :class="{
                                'has-error':
                                  model.employer_phone_country.$invalid,
                              }"
                            >
                              <select
                                class="form-control"
                                v-model="model.employer_phone_country.$model"
                                :disabled="
                                  isShow ||
                                  userstaging.status_id == statusEnum.ACTIVE
                                "
                                v-if="countries.data"
                              >
                                <option value="" selected>Select Code</option>
                                <option
                                  v-for="(item, index) in countries.data"
                                  :key="index"
                                  :value="item.iso2"
                                >
                                  {{ item.iso2 }} ({{ item.phonecode }})
                                </option>
                              </select>
                              <error-msg
                                :property="model.employer_phone_country"
                              />
                            </div>
                          </div>
                          <!-- Phone -->
                          <div class="col-7">
                            <div>
                              <input
                                class="form-control"
                                type="text"
                                v-model="model.employer_phone.$model"
                                :disabled="
                                  isShow ||
                                  userstaging.status_id == statusEnum.ACTIVE
                                "
                              />
                              <error-msg :property="model.employer_phone" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 mb-2">
                      <div
                        class="form-outline"
                        :class="{
                          'has-error': model.employment_station.$invalid,
                        }"
                      >
                        <label class="form-label" for="street_address">
                          Employer/ Business Location e.g. Nairobi
                        </label>
                        <input
                          class="form-control"
                          :class="{
                            'form-control-danger':
                              model.employment_station.$invalid,
                          }"
                          type="text"
                          v-model="model.employment_station.$model"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                        />
                        <error-msg :property="model.employment_station" />
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 mb-2">
                      <div
                        class="form-outline"
                        :class="{
                          'has-error':
                            model.employment_terms_of_service.$invalid,
                        }"
                      >
                        <label class="form-label" for="street_address">
                          Employment/ Business Terms of Service
                        </label>
                        <select
                          class="form-control"
                          :class="{
                            'form-control-danger':
                              model.employment_terms_of_service.$invalid,
                          }"
                          v-model="model.employment_terms_of_service.$model"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                        >
                          <option
                            v-for="(type, index) in employmentTypes"
                            :key="index"
                            :value="type.value"
                          >
                            {{ type.label }}
                          </option>
                        </select>

                        <error-msg
                          :property="model.employment_terms_of_service"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- documents tab -->
                <div
                  class="tab-pane pt-3"
                  id="member-documents"
                  role="tabpanel"
                  v-if="isEdit"
                >
                  <p class="mb-0 text-muted">Member Photo (Image)</p>

                  <div class="card card-image-box">
                    <div class="card-body">
                      <div
                        class="col-sm-6 float-left transition"
                        style="padding-left: 0"
                      >
                        <upload-file
                          :section="'user_staging'"
                          :sectionId="userstaging.user_staging_id"
                          :uploadType="'member_photo'"
                          :uploadItem="'image'"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                          @uploadSuccess="onMemberPhotoUploadSuccess"
                        >
                        </upload-file>
                      </div>

                      <div class="col-sm-6 float-right image-box transition">
                        <img
                          v-if="userstaging?.member_photo?.length > 0"
                          :src="userstaging.member_photo[0].thumb_path"
                          :alt="userstaging.first_name"
                          class="rounded float-right"
                          height="150"
                          width="150"
                        />

                        <div
                          class="no-image rounded float-right text-center d-flex align-items-center justify-content-center"
                          v-else
                        >
                          <span class="text-danger">No Member Image</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <p class="mb-0 text-muted">
                    Identification Document Front (Image)
                  </p>

                  <div class="card card-image-box">
                    <div class="card-body">
                      <div
                        class="col-sm-6 float-left transition"
                        style="padding-left: 0"
                      >
                        <upload-file
                          :section="'user_staging'"
                          :sectionId="userstaging.user_staging_id"
                          :uploadType="'member_id_front'"
                          :uploadItem="'image'"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                          @uploadSuccess="onMemberIdFrontUploadSuccess"
                        >
                        </upload-file>
                      </div>

                      <div class="col-sm-6 float-right image-box transition">
                        <img
                          v-if="userstaging?.member_id_front?.length"
                          :src="userstaging.member_id_front[0].thumb_path"
                          :alt="userstaging.first_name"
                          class="rounded float-right"
                          height="150"
                          width="150"
                        />

                        <div
                          class="no-image rounded float-right text-center d-flex align-items-center justify-content-center"
                          v-else
                        >
                          <span class="text-danger"
                            >No Identification Doc (Front Image)</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <p class="mb-0 text-muted">
                    Identification Document Back (Image)
                  </p>

                  <div class="card card-image-box">
                    <div class="card-body">
                      <div
                        class="col-sm-6 float-left transition"
                        style="padding-left: 0"
                      >
                        <upload-file
                          :section="'user_staging'"
                          :sectionId="userstaging.user_staging_id"
                          :uploadType="'member_id_back'"
                          :uploadItem="'image'"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                          @uploadSuccess="onMemberIdBackUploadSuccess"
                        >
                        </upload-file>
                      </div>

                      <div class="col-sm-6 float-right image-box transition">
                        <img
                          v-if="userstaging?.member_id_back?.length"
                          :src="userstaging.member_id_back[0].thumb_path"
                          :alt="userstaging.first_name"
                          class="rounded float-right"
                          height="150"
                          width="150"
                        />

                        <div
                          class="no-image rounded float-right text-center d-flex align-items-center justify-content-center"
                          v-else
                        >
                          <span class="text-danger"
                            >No Identification Doc (Back Image)</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <p class="mb-0 text-muted">Member KRA PIN (PDF)</p>

                  <div class="card card-doc-box">
                    <div class="card-body">
                      <div
                        class="col-sm-6 float-left transition"
                        style="padding-left: 0"
                      >
                        <upload-file
                          :section="'user_staging'"
                          :sectionId="userstaging.user_staging_id"
                          :uploadType="'member_kra_pin'"
                          :uploadItem="'doc'"
                          :accept="'application/pdf'"
                          :disabled="
                            isShow || userstaging.status_id == statusEnum.ACTIVE
                          "
                          @uploadSuccess="onMemberKraPinUploadSuccess"
                        >
                        </upload-file>
                      </div>

                      <div class="col-sm-6 float-right doc-box transition">
                        <ul
                          class="list-group"
                          v-if="userstaging?.member_kra_pin?.length"
                          :src="userstaging.member_kra_pin[0].upload_path"
                          :alt="userstaging.first_name"
                        >
                          <li
                            class="list-group-item d-flex justify-content-between align-items-center"
                          >
                            <div>
                              <i
                                class="la la-file-pdf la-3x icon-center text-danger mr-2"
                              ></i>
                              Member KRA PIN Document
                            </div>
                            <span>
                              <button
                                type="button"
                                class="btn btn-outline-success waves-effect waves-light"
                              >
                                <i class="la la-download font-18 mr-2"></i
                                >Download
                              </button>
                            </span>
                          </li>
                        </ul>

                        <ul class="list-group" v-else>
                          <li
                            class="list-group-item d-flex justify-content-between align-items-center doc-text-box"
                          >
                            <div class="text-right" style="align-self: center">
                              <h4 class="text-danger la-sm-text">
                                <i
                                  class="la la-file-pdf la-sm-text text-danger font-16 mr-2"
                                ></i>
                                No KRA PIN Document
                              </h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <hr />
                </div>
              </div>

              <div class="form-group mt-2">
                <hr />

                <maker-checker-button-component
                  :makerChecker="makerChecker"
                  v-if="isMakerChecker"
                />

                <div class="col-lg-12 row" v-else>
                  <div class="form-group">
                    <!-- status_id: {{ model.status_id.$model }}<br>
                                                    Enum: {{ statusEnum.COMPLETED }} -->
                    <button
                      class="btn btn-primary mr-3"
                      type="submit"
                      :disabled="model.$invalid"
                      :class="{ 'btn-secondary': model.$invalid }"
                      v-if="
                        !isShow && model.status_id.$model != statusEnum.ACTIVE
                      "
                    >
                      <slot name="submitButton"></slot>
                    </button>
                    <button
                      class="btn btn-primary mr-3"
                      type="button"
                      @click="onEdit"
                      v-if="
                        isShow && model.status_id.$model != statusEnum.ACTIVE
                      "
                    >
                      Edit
                    </button>
                    <button
                      class="btn btn-danger mr-3"
                      type="button"
                      @click="onCancelled"
                    >
                      Cancel
                    </button>
                    <button
                      class="btn btn-success"
                      type="button"
                      @click="onMakeMember"
                      v-if="
                        userstaging.status_id != statusEnum.ACTIVE &&
                        userstaging.status_id != statusEnum.INCOMPLETED &&
                        isShow
                      "
                    >
                      Make Member
                      <i class="fa fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, toRefs, watch, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { formatSize } from "../../../../filters";
import { isEmpty } from "../../../../utils";
import { statusEnum } from "../../../../enums";
import { handleError } from "../../../../utils";
import { formatDate, getStatusColorCss } from "@/filters";

const customerForm = {
  first_name: "",
  last_name: "",
  application_reference: "",
  user_type: "CUSTOMER",
  id_no: "",
  date_of_birth: "1992-08-09",
  gender: "Male",
  email: "",
  phone: "",
  phone_country: "KE",
  country_id: "113",
  nationality_id: "113",
  county_id: undefined,
  constituency_id: undefined,
  ward_id: undefined,
  company_id: undefined,
  branch_id: undefined,
  team_id: undefined,
  status_id: undefined,
  user_staging_id: undefined,
  street_address: "",
  postal_code: "",
  postal_address: "",
  is_group: "0",
  created_at: "",
  activated_at: "",
  member_photo: [],
  member_id_back: [],
  member_id_front: [],
  member_kra_pin: [],
  activater: [],
  status: {},
  lead_type: undefined,
  employer_category: "Employed",
  employer_business_name: "",
  employer_address: "",
  employer_postal_code: "",
  employer_phone_country: "KE",
  employer_phone: "",
  employment_station: "",
  employment_terms_of_service: "",
};

const memberPhotoFile = ref({});

const module = "userstagings";
const company_module = "companies";
const branch_module = "branches";
const country_module = "countries";
const county_module = "counties";
const constituency_module = "constituencies";
const ward_module = "wards";
const statuses_module = "statuses";
const user_module = "users";
const membership_settings_module = "membershipsettings";
const team_module = "teams";

export default defineComponent({
  name: "UserStagingDetail",

  props: {
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    authUser: {
      required: false,
    },
    isMakerChecker: {
      type: Boolean,
      required: false,
      default: false,
    },
    makerChecker: {
      type: Object,
      required: false,
    },
  },

  emits: ["submitForm", "cancelForm", "makeMemberForm"],

  setup(props, { emit }) {
    // avoid destructuring props directly in the root scope of setup
    const userStagingProp = toRefs(props);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const employmentTypes = [
      {
        label: "Permanent",
        value: "Permanent",
      },
      {
        label: "Contract",
        value: "Contract",
      },
      {
        label: "Other",
        value: "Others",
      },
    ];

    const user_data = ref(null);
    let userstaging = ref(customerForm);

    // userstaging id
    const user_staging_id = route.params && route.params.id;

    // if we are in edit mode, get userstaging data from db
    if (props.isEdit || props.isShow) {
      userstaging = computed(() => store.getters[`${module}/userstaging`]);
    }

    // if we are in maker checker, use maker checker data
    if (userStagingProp.isMakerChecker.value) {
      userstaging = userStagingProp.makerChecker.value?.data;
    }

    const rules = {
      first_name: { required },
      last_name: { required },
      application_reference: {},
      user_type: {},
      id_no: { required },
      date_of_birth: { required },
      gender: { required },
      email: { email },
      phone: {},
      phone_country: {},
      country_id: { required },
      nationality_id: {},
      county_id: {},
      constituency_id: {},
      ward_id: {},
      company_id: { required },
      user_staging_id: {},
      branch_id: {},
      status_id: {},
      team_id: {},
      street_address: {},
      postal_code: {},
      postal_address: {},
      is_group: { required },
      created_at: {},
      activated_at: {},
      member_photo: {},
      member_id_back: {},
      member_id_front: {},
      member_kra_pin: {},
      activater: {},
      status: {},
      lead_type: {},
      employer_category: {},
      employer_business_name: {},
      employer_address: {},
      employer_postal_code: {},
      employer_phone_country: {},
      employer_phone: {},
      employment_station: {},
      employment_terms_of_service: {},
    };

    // validate form
    const model = useVuelidate(rules, userstaging);

    // load all companies on create component
    let payload = { report: "1" };
    store.dispatch(`${company_module}/fetchCompanies`, payload);

    // load countries
    store.dispatch(`${country_module}/fetchCountries`, payload);

    // load counties
    let county_payload = { country_id: "113", report: "1" };
    store.dispatch(`${county_module}/fetchCounties`, county_payload);

    let team_payload = { relations: "1" };
    store.dispatch(`${team_module}/fetchTeams`, team_payload);

    // load statuses data
    let status_payload = {
      report: "1", // fetch all data
      section: "users_staging", // get users statuses only
    };
    store.dispatch(`${statuses_module}/fetchStatuses`, status_payload);

    // console.log("userstaging value ::: ", userstaging.value);

    // Are we in edit mode?
    if (props.isEdit) {
      payload = { report: "1", company_id: userstaging.value.company_id };
      store.dispatch(`${branch_module}/fetchBranches`, payload);

      // START ON PAGE REFRESH
      // check if we should fetch counties
      if (userstaging.value.country_id) {
        let country_payload = {
          country_id: userstaging.value.country_id,
          report: "1",
        };
        store.dispatch(`${county_module}/fetchCounties`, country_payload);
      }

      // check if we should fetch constituencies
      if (userstaging.value.county_id) {
        let county_payload = {
          county_id: userstaging.value.county_id,
          report: "1",
        };
        store.dispatch(
          `${constituency_module}/fetchConstituencies`,
          county_payload
        );
      }

      // check if we should fetch wards
      if (userstaging.value.constituency_id) {
        let constituency_payload = {
          constituency_id: userstaging.value.constituency_id,
          report: "1",
        };
        store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
      }
      // END ON PAGE REFRESH
    }

    if (!props.isEdit && !props.isShow) {
      // set default company
      onMounted(() => {
        // set default company id
        userstaging.value.company_id = props.authUser.active_company_id;
      });
    }

    // START ON ROUTE CHANGES
    // watch for country changes
    watch(
      () => userstaging.value.country_id,
      (newVal, oldVal) => {
        console.log("country_id", newVal, oldVal);
        // fetch counties
        let country_payload = { country_id: newVal, report: "1" };
        store.dispatch(`${county_module}/fetchCounties`, country_payload);
      }
    );

    // watch for county changes
    watch(
      () => userstaging.value.county_id,
      (newVal, oldVal) => {
        // fetch constituencies
        let county_payload = { county_id: newVal, report: "1" };
        store.dispatch(
          `${constituency_module}/fetchConstituencies`,
          county_payload
        );
      }
    );

    // watch for constituency changes
    watch(
      () => userstaging.value.constituency_id,
      (newVal, oldVal) => {
        // fetch wards
        let constituency_payload = { constituency_id: newVal, report: "1" };
        store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
      }
    );
    // END ON ROUTE CHANGES

    if (props.resetForm) {
      // reset form
      // customerForm.reset();
      var thisForm = document.getElementsByName("member-details");
      thisForm.reset();
    }

    function onSubmitted() {
      emit("submitForm", userstaging);
    }

    function onCancelled() {
      emit("cancelForm", userstaging);
    }

    function onEdit() {
      emit("editForm", userstaging);
    }

    if (props.isEdit || props.isShow) {
      // retrieve company onboarding method
      store.dispatch(
        `${membership_settings_module}/fetchMembershipSetting`,
        props.authUser.active_company_id
      );
    }

    // make new member
    async function onMakeMember() {
      // console.log("userstaging === ", userstaging);
      emit("makeMemberForm", userstaging);
    }

    function onChangeCountry(event) {
      let country_payload = { country_id: event.target.value, report: "1" };
      store.dispatch(`${county_module}/fetchCounties`, country_payload);
    }

    function onChangeCounty(event) {
      // console.log("onChangeCounty event", event.target);
      let county_payload = { county_id: event.target.value, report: "1" };
      store.dispatch(
        `${constituency_module}/fetchConstituencies`,
        county_payload
      );
    }

    function onChangeConstituency(event) {
      // console.log("onChangeConstituency event", event.target);
      let constituency_payload = {
        constituency_id: event.target.value,
        report: "1",
      };
      store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
    }

    function fetchBranches(event) {
      // console.log("event", event);
      store.dispatch(`${branch_module}/fetchBranches`, event.target.value);
    }

    function onMemberPhotoUploadSuccess(res) {
      // show image - replace in userstaging object
      userstaging.value.member_photo[0] = res.data.data;
    }

    function onMemberIdFrontUploadSuccess(res) {
      userstaging.value.member_id_front[0] = res.data.data;
    }

    function onMemberIdBackUploadSuccess(res) {
      userstaging.value.member_id_back[0] = res.data.data;
    }

    function onMemberKraPinUploadSuccess(res) {
      userstaging.value.member_kra_pin[0] = res.data.data;
    }

    return {
      submitForm: props.submitForm,
      companies: computed(() => store.getters[`${company_module}/companies`]),
      branches: computed(() => store.getters[`${branch_module}/branches`]),
      countries: computed(() => store.getters[`${country_module}/countries`]),
      counties: computed(() => store.getters[`${county_module}/counties`]),
      constituencies: computed(
        () => store.getters[`${constituency_module}/constituencies`]
      ),
      wards: computed(() => store.getters[`${ward_module}/wards`]),
      statuses: computed(() => store.getters[`${statuses_module}/statuses`]),
      membership_setting: computed(
        () => store.getters[`${membership_settings_module}/membershipsetting`]
      ),
      userstaging,
      model,
      onSubmitted,
      onCancelled,
      onEdit,
      onMakeMember,
      formatSize,
      memberPhotoFile,
      isEmpty,
      onMemberPhotoUploadSuccess,
      onMemberIdFrontUploadSuccess,
      onMemberIdBackUploadSuccess,
      onMemberKraPinUploadSuccess,
      onChangeCountry,
      onChangeCounty,
      onChangeConstituency,
      user_data,
      statusEnum,
      fetchBranches,
      formatDate,
      getStatusColorCss,
      teams: computed(() => store.getters[`${team_module}/teams`]),
      employmentTypes,
    };
  },
});
</script>
