import axios from "axios";

export default {

    namespaced: true,

    state: {

        constituencies: [],
        constituency: {}

    },

    getters: {

        constituencies(state) {
            return state.constituencies;
        },
        constituency(state) {
            return state.constituency;
        }

    },

    mutations: {

        SET_CONSTITUENCIES(state, constituencies) {
            return state.constituencies = constituencies
        },

        SET_CONSTITUENCY(state, constituency) {
            return state.constituency = constituency
        },

        CLEAR_CONSTITUENCIES(state) {
            return state.constituencies = []
        },

    },

    actions: {

        async fetchConstituencies({ commit }, params) {

            await axios.get('/api/v1/constituencies', { params: params })
                .then(res => {
                    // console.log(res.data);
                    commit('SET_CONSTITUENCIES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchConstituency({ commit }, state_id) {

            await axios.get(`/api/v1/constituencies/${state_id}`)
                .then(res => {
                    // console.log("fetchState store == ", res);
                    if (res.stateText === 'OK') {
                        commit('SET_CONSTITUENCY', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async clearValues({ commit }) {
            commit('CLEAR_CONSTITUENCIES');
        }

    }

}
