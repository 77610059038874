import axios from "axios";

export default {

    namespaced: true,

    state: {

        depositcontracts: [],
        depositcontract: {}

    },

    getters: {

        depositcontracts(state) {
            return state.depositcontracts;
        },
        depositcontract(state) {
            return state.depositcontract;
        }

    },

    mutations: {

        SET_DEPOSIT_CONTRACTS(state, depositcontracts) {
            return state.depositcontracts = depositcontracts
        },

        SET_DEPOSIT_CONTRACT(state, depositcontract) {
            return state.depositcontract = depositcontract
        }

    },

    actions: {

        async fetchdepositcontracts({ commit }, params) {

            await axios.get('/api/v1/deposit-contracts', { params: params })
                .then(res => {
                    commit('SET_DEPOSIT_CONTRACTS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchdepositcontract({ commit }, deposit_contract_id) {

            await axios.get(`/api/v1/deposit-contracts/${deposit_contract_id}`)
                .then(res => {
                    // console.log("fetchProduct store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_DEPOSIT_CONTRACT', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createdepositcontract({ commit, dispatch }, depositcontract) {
            // console.log("product createProduct ", product);
            await axios.post('/api/v1/deposit-contracts', depositcontract);
        },

        async updatedepositcontract({ commit, dispatch }, depositcontract) {
            await axios("/api/v1/deposit-contracts/" + depositcontract.deposit_contract_id, {method: "patch",data: depositcontract});
        },

        async deletedepositcontract({ commit }, deposit_contract_id) {
            await axios.delete(`/api/v1/deposit-contracts/${deposit_contract_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchdepositcontracts');
                }
            }).catch(err => {
                return Promise.reject(err);
            });
        }

    }

}
