import axios from "axios";

export default {

    namespaced: true,

    state: {

        emailtemplatesmaster: [],
        emailtemplatemaster: {},
        emailtemplatesmasterhistory: {}

    },

    getters: {

        emailtemplatesmaster(state) {
            return state.emailtemplatesmaster;
        },
        emailtemplatemaster(state) {
            return state.emailtemplatemaster;
        },
        emailtemplatesmasterhistory(state) {
            return state.emailtemplatesmasterhistory;
        }

    },

    mutations: {

        SET_EMAIL_TEMPLATES_MASTER(state, emailtemplatesmaster) {
            return state.emailtemplatesmaster = emailtemplatesmaster
        },

        SET_EMAIL_TEMPLATE_MASTER(state, emailtemplatemaster) {
            return state.emailtemplatemaster = emailtemplatemaster
        },

        SET_EMAIL_TEMPLATES_MASTER_HISTORY(state, emailtemplatesmasterhistory) {
            return state.emailtemplatesmasterhistory = emailtemplatesmasterhistory
        }

    },

    actions: {

        async fetchEmailTemplatesMaster({ commit }, params) {

            await axios.get('/api/v1/email-templates-master', { params: params })
                .then(res => {
                    commit('SET_EMAIL_TEMPLATES_MASTER', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchEmailTemplatesMasterHistory({ commit }, params) {

            await axios.get(`/api/v1/email-templates-master/history/${params.email_template_master_id}`, { params: params })
                .then(res => {
                    commit('SET_EMAIL_TEMPLATES_MASTER_HISTORY', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchEmailTemplateMaster({ commit }, email_template_master_id) {

            await axios.get(`/api/v1/email-templates-master/${email_template_master_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_EMAIL_TEMPLATE_MASTER', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createEmailTemplateMaster({ commit, dispatch }, emailtemplatemaster) {

            await axios.post('/api/v1/email-templates-master', emailtemplatemaster)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchEmailTemplatesMaster');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateEmailTemplateMaster({ commit, dispatch }, emailtemplatemaster) {

            await axios("/api/v1/email-templates-master/" + emailtemplatemaster.media_template_master_id, {
                method: "patch",
                data: emailtemplatemaster,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchEmailTemplatesMaster');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async resetEmailTemplateMaster({ commit, dispatch }, email_template_master_id) {

            await axios.post(`/api/v1/email-templates-master/reset/${email_template_master_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    // dispatch('fetchEmailTemplates');
                    dispatch('fetchEmailTemplateMaster', email_template_master_id);
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async resetAllEmailTemplateMaster({ commit, dispatch }) {

            await axios.post(`/api/v1/email-templates-master/resetall`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchEmailTemplatesMaster');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteEmailTemplateMaster({ commit }, email_template_master_id) {

            await axios.delete(`/api/v1/email-templates-master/${email_template_master_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchEmailTemplatesMaster');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
