import axios from "axios";

export default {

    namespaced: true,
    state: {
        customers: [],
        customer: {},

    },
    getters: {

        customers(state) {
            return state.customers;
        },
        customer(state) {
            return state.customer;
        }

    },

    mutations: {

        SET_CUSTOMERS(state, customers) {
            return state.customers = customers
        },

        SET_CUSTOMER(state,customer) {
            return state.customer = customer
        }

    },

    actions: {

        async fetchCustomers({ commit }, params) {

            await axios.get('/api/v1/customer-allocations', { params: params })
                .then(res => {
                    // console.log(res.data.data);
                    commit('SET_CUSTOMERS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },


        async fetchCustomer({ commit }, params) {

            const customer_id = params.team_id;

            await axios.get(`/api/v1/customer-allocations/${customer_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_CUSTOMER', res.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },


    }

}
