import axios from "axios";

export default {

    namespaced: true,

    state: {

        roles: [],
        rolesnames: [],
        role: {}

    },

    getters: {

        roles(state) {
            return state.roles;
        },
        rolesnames(state) {
            return state.rolesnames;
        },
        role(state) {
            return state.role;
        }

    },

    mutations: {

        SET_ROLES_NAMES(state, rolesnames) {
            return state.rolesnames = rolesnames
        },

        SET_ROLES(state, roles) {
            return state.roles = roles
        },

        SET_ROLE(state, role) {
            return state.role = role
        }

    },

    actions: {

        async fetchRoles({ commit }, params) {

            await axios.get('/api/v1/roles', { params: params })
                .then(res => {
                    commit('SET_ROLES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchRolesNames({ commit }, params) {

            await axios.get('/api/v1/roles/names', { params: params })
                .then(res => {
                    commit('SET_ROLES_NAMES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchRole({ commit }, role_id) {

            await axios.get(`/api/v1/roles/${role_id}`)
                .then(res => {
                    // console.log("fetchRole store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_ROLE', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createRole({ commit, dispatch }, role) {

            await axios.post('/api/v1/roles', role)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchRoles');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateRole({ commit, dispatch }, role) {

            // console.log("roleddd == ", role);
            await axios("/api/v1/roles/" + role.id, {
                method: "patch",
                data: role,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchRoles');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteRole({ commit }, role_id) {

            await axios.delete(`/api/v1/roles/${role_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchRoles');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
