import axios from "axios";

export default {

    namespaced: true,

    state: {

        notifications: [],

    },

    getters: {

        notifications(state) {
            return state.notifications;
        }

    },

    mutations: {
        SET_NOTIFICATIONS(state, notifications) {
            state.notifications = notifications;
        }
    },

    actions: {

        /* fetchNotifications({ commit }, params) {
            return NotificationService.getNotifications(params).then(
              notifications => {
                commit('SET_NOTIFICATIONS', notifications);
                return Promise.resolve(notifications);
              },
              error => {
                return Promise.reject(error);
              }
            );
        }, */

        async fetchNotifications({ commit }, params) {

            await axios.get('/api/v1/notifications', { params: params })
                .then(res => {
                    // console.log(res.data);
                    commit('SET_NOTIFICATIONS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

    }

}
