import axios from "axios";

export default {

    namespaced: true,

    state: {

        loanapplicationapprovals: [],
        loanapplicationapproval: {},

    },

    getters: {
        loanapplicationapprovals(state) {
            return state.loanapplicationapprovals;
        },
        loanapplicationapproval(state) {
            return state.loanapplicationapproval;
        }

    },


    mutations: {

        SET_LOAN_APPLICATION_APPROVALS(state, loanapplicationapprovals) {
            return state.loanapplicationapprovals = loanapplicationapprovals
        },

        SET_LOAN_APPLICATION_APPROVAL(state, loanapplicationapproval) {
            return state.loanapplicationapproval = loanapplicationapproval
        }

    },

    actions: {

        async fetchloanapplicationapprovals({ commit }, params) {
            try {
                const response = await axios.get('/api/v1/loan-application-approvers', { params: params });
                commit('SET_LOAN_APPLICATION_APPROVALS', response.data);
            } catch (error) {
                return Promise.reject(error);
            }
        },

  

        async fetchloanapplicationapproval({ commit }, loan_application_approver_id) {
            await axios.get(`/api/v1/loan-application-approvers/${loan_application_approver_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_LOAN_APPLICATION_APPROVAL', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

         async createloanapplicationapproval({ commit, dispatch }, data) {
            return await axios.post('/api/v1/loan-application-approvers/', data);
         },


        async updateloanapplicationapproval({ commit, dispatch }, loanapplicationapproval) {
            await axios("/api/v1/loan-application-approvers/" + loanapplicationapproval.loan_application_approver_id, 
            { method: "put", data: loanapplicationapproval})
        },

    }

}
