import axios from "axios";

export default {
  namespaced: true,
  state: {
    team_members: [],
    team_member: {},
  },
  getters: {
    team_members(state) {
      return state.team_members;
    },
    team_member(state) {
      return state.team_member;
    },
  },

  mutations: {
    SET_TEAM_MEMBERS(state, team_members) {
      return (state.team_members = team_members);
    },

    SET_TEAM_MEMBER(state, team_member) {
      return (state.team_member = team_member);
    },
  },

  actions: {
    async fetchTeamMembers({ commit }, params) {
      await axios
        .get("/api/v1/team-members", { params: params })
        .then((res) => {
          commit("SET_TEAM_MEMBERS", res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async fetchTeamMember({ commit }, params) {
      const team_member_id = params.team_id;

      await axios
        .get(`/api/v1/team-members/${team_member_id}`)
        .then((res) => {
          if (res.statusText === "OK") {
            commit("SET_TEAM_MEMBER", res.data);
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async fetchManualTeamMember({ commit }, params) {
      try {
        const team_member_id = params.team_id;
        const response = await axios.get(
          `/api/v1/team-members/${team_member_id}`
        );
        if (response.statusText === "OK") {
          commit("SET_TEAM_MEMBER", response.data);
          return response.data;
        }
      } catch (error) {
        // Handle errors
        return Promise.reject(error);
      }
    },

    async createTeamMember({ commit, dispatch }, team_member) {
      await axios
        .post("/api/v1/team-members", team_member)
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchTeamMembers");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async updateTeamMember({ commit, dispatch }, team_member) {
      await axios(
        "/api/v1/team-members/" +
          team_member.employee_allocation_team_member_id,
        {
          method: "put",
          data: team_member,
        }
      )
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchTeamMembers");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async deleteTeamMember({ commit, dispatch }, team_member_id) {
      await axios
        .delete(`/api/v1/team-members/${team_member_id}`)
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchTeamMembers");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
};
