import makerCheckerSectionAdministrationRoutes from "./Sections/makerCheckerSectionAdministrationRoutes";
import makerCheckerSectionMasterAdministrationRoutes from "./SectionsMaster/makerCheckerSectionMasterAdministrationRoutes";

import Base from '../../../Common/Base.vue';
import makerCheckerAdministrationRoutes from "./Data/makerCheckerAdministrationRoutes";
import makerCheckerSectionUserLevelAdministrationRoutes from "./UserLevels/makerCheckerSectionUserLevelAdministrationRoutes";

const makerCheckerAdministrationModuleRoutes = [

    {

        path: '',
        name: 'makerCheckerHome',
        component: () => import('./HomeMakerChecker.vue'),
        meta: {
            auth: true,
            title: 'Maker Checkers',
            breadcrumb: 'Maker Checkers',
            permission: []
        },

    },
    
    {

        path: 'maker-checkers',
        name: 'makerCheckerHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Maker Checker Listing ',
            breadcrumb: 'Maker Checker Listing',
            permission: ['view makerchecker', 'create makerchecker', 'update makerchecker', 'delete makerchecker']
        },

        children: makerCheckerAdministrationRoutes

    },
    
    {

        path: 'maker-checker-user-levels',
        name: 'makerCheckerSectionUserLevelHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Maker Checker User Level Listing ',
            breadcrumb: 'Maker Checker User Level Listing',
            permission: ['view makercheckersectionuserlevel', 'create makercheckersectionuserlevel', 'update makercheckersectionuserlevel', 'delete makercheckersectionuserlevel']
        },

        children: makerCheckerSectionUserLevelAdministrationRoutes

    },

    {

        path: 'maker-checker-sections',
        name: 'makerCheckerSectionHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Maker Checker Sections Listing ',
            breadcrumb: 'Maker Checker Sections Listing',
            permission: ['view makercheckersection', 'create makercheckersection', 'update makercheckersection', 'delete makercheckersection']
        },

        children: makerCheckerSectionAdministrationRoutes

    },

    {

        path: 'maker-checker-sections-master',
        name: 'makerCheckerSectionMasterHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Maker Checker Section Master Listing',
            breadcrumb: 'Maker Checker Section Master Listing',
            permission: ['view makercheckersectionmaster', 'create makercheckersectionmaster', 'update makercheckersectionmaster', 'delete makercheckersectionmaster']
        },

        children: makerCheckerSectionMasterAdministrationRoutes

    },

];

export default makerCheckerAdministrationModuleRoutes;
