import axios from "axios";

export default {

    namespaced: true,

    state: {

        emailtemplates: [],
        emailtemplate: {},
        emailtemplateshistory: {}

    },

    getters: {

        emailtemplates(state) {
            return state.emailtemplates;
        },
        emailtemplate(state) {
            return state.emailtemplate;
        },
        emailtemplateshistory(state) {
            return state.emailtemplateshistory;
        }

    },

    mutations: {

        SET_EMAIL_TEMPLATES(state, emailtemplates) {
            return state.emailtemplates = emailtemplates
        },

        SET_EMAIL_TEMPLATE(state, emailtemplate) {
            return state.emailtemplate = emailtemplate
        },

        SET_EMAIL_TEMPLATES_HISTORY(state, emailtemplateshistory) {
            return state.emailtemplateshistory = emailtemplateshistory
        }

    },

    actions: {

        async fetchEmailTemplates({ commit }, params) {

            await axios.get('/api/v1/email-templates', { params: params })
                .then(res => {
                    commit('SET_EMAIL_TEMPLATES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchEmailTemplatesHistory({ commit }, params) {

            await axios.get(`/api/v1/email-templates/history/${params.email_template_id}`, { params: params })
                .then(res => {
                    commit('SET_EMAIL_TEMPLATES_HISTORY', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchEmailTemplate({ commit }, email_template_id) {

            await axios.get(`/api/v1/email-templates/${email_template_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_EMAIL_TEMPLATE', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createEmailTemplate({ commit, dispatch }, emailtemplate) {

            await axios.post('/api/v1/email-templates', emailtemplate)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchEmailTemplates');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateEmailTemplate({ commit, dispatch }, emailtemplate) {

            await axios("/api/v1/email-templates/" + emailtemplate.media_template_id, {
                method: "patch",
                data: emailtemplate,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchEmailTemplates');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async resetEmailTemplate({ commit, dispatch }, email_template_id) {

            await axios.post(`/api/v1/email-templates/reset/${email_template_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    // dispatch('fetchEmailTemplates');
                    dispatch('fetchEmailTemplate', email_template_id);
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteEmailTemplate({ commit }, email_template_id) {

            await axios.delete(`/api/v1/email-templates/${email_template_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchEmailTemplates');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
