
<template>
  <div style="height:285px;overflow-y: auto;">
    <table class="table table-borderless" v-if="lineitems !== null">
      <thead>
        <th width="20%">Account</th>
        <th width="30%">Description</th>
        <th width="20%">Quantity</th>
        <th width="20%">Unit Amount</th>
        <th width="10%">
          <span class="btn btn-outline-success" @click="addRow">
            <i class="fas fa-plus mr-2"></i>
          </span>
        </th>
      </thead>
      <tbody>
        <tr
          v-for="(input, index) in lineitems"
          style="margin: 0px; padding: 0px"
          :key="index"
        >
          <td>
            <Multiselect
              v-model="input.account_product_id"
              id="customer_id"
              :options="nonBankingAccounts"
              mode="single"
              :searchable="true"
              trackBy="account_code_name"
              label="account_code_name"
              valueProp="account_id"
              class="form-control"
            />
          </td>

          <td>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Description"
              required
              v-model="input.description"
            />
          </td>

          <td>
            <input
              type="number"
              min="1"
              step="1"
              class="form-control"
              placeholder="Enter Quantity"
              required
              v-model="input.quantity"
            />
          </td>

          <td>
            <currency-input
              type="text"
              class="form-control"
              placeholder="Enter Unit Amount"
              required
              v-model="input.unit_amount"
            />
          </td>

          <td>
            <span
              @click="deleteRow(index)"
              class="text-right btn btn-outline-danger"
            >
              <i class="mdi mdi-delete mr-2"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div>Total: {{ toThousandFilter(getTotals()) }}</div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { toThousandFilter } from "../../../../filters";
import Multiselect from "@vueform/multiselect";

const financeaccounts_module = "financeaccounts";

export default defineComponent({
  name: "journalComponent",

  components: { Multiselect },

  emits: ["addItem", "deleteItem"],

  setup() {
    const store = useStore();
    const router = useRouter();

    function addRow() {
      store.dispatch("shared/addRow");
    }

    function deleteRow(index) {
      store.dispatch("shared/deleteRow", index);
    }

    function getTotals() {
      if (lineitems.value && lineitems.value.length != 0) {
        return lineitems.value
          .map((e) => parseInt(e.quantity) * parseFloat(e.unit_amount))
          .reduce((a, b) => a + b)
          .toFixed(2);
      }
      return 0;
    }

    store.dispatch(`${financeaccounts_module}/fetchPostingAccounts`);

    const nonBankingAccounts = computed(
      () => store.getters[`${financeaccounts_module}/nonBankingAccounts`]
    );

    const lineitems = computed(() => store.getters["shared/lineitems"]);

    return {
      // state,
      store,
      router,
      addRow,
      deleteRow,
      lineitems,
      nonBankingAccounts,
      getTotals,
      toThousandFilter,
    };
  },
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
