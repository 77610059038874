import emailMessageTemplateAdministrationRoutes from "./Email/emailMessageTemplateAdministrationRoutes";
import smsMessageTemplateAdministrationRoutes from "./Sms/smsMessageTemplateAdministrationRoutes";

import Base from '../../../Common/Base.vue';

const messageTemplateAdministrationModuleRoutes = [

    {

        path: '',
        name: 'messageTemplateHome',
        component: () => import('./HomeMessageTemplate.vue'),
        meta: {
            auth: true,
            title: 'Message Templates',
            breadcrumb: 'Message Templates',
            permission: []
        },

    },

    {

        path: 'sms',
        name: 'smsTemplateHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Sms Templates Listing ',
            breadcrumb: 'Sms Templates Listing',
            permission: ['view messagetemplate', 'create messagetemplate', 'update messagetemplate', 'delete messagetemplate']
        },

        children: smsMessageTemplateAdministrationRoutes

    },

    {

        path: 'emails',
        name: 'emailTemplateHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Email Templates Listing',
            breadcrumb: 'Email Templates Listing',
            permission: ['view messagetemplate', 'create messagetemplate', 'update messagetemplate', 'delete messagetemplate']
        },

        children: emailMessageTemplateAdministrationRoutes

    },

];

export default messageTemplateAdministrationModuleRoutes;
