import emailMessagingRoutes from "./Email/emailMessagingRoutes";
import recommendMessagingRoutes from "./Recommends/recommendMessagingRoutes";
import smsMessagingRoutes from "./Sms/smsMessagingRoutes";

import Base from '../../Common/Base.vue';
import pendingMessagingRoutes from "./PendingMessaging/pendingMessagingRoutes";

const messagingModuleRoutes = [

    {

        path: '',
        name: 'messagingHome',
        component: () => import('./HomeMessaging.vue'),
        meta: {
            auth: true,
            title: 'Messaging',
            breadcrumb: 'Messaging',
            permission: []
        },

    },

    {

        path: 'sms',
        name: 'smsHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Sms Listing',
            breadcrumb: 'Sms Listing',
            permission: ['view sms', 'create sms', 'update sms', 'delete sms']
        },

        children: smsMessagingRoutes

    },

    {

        path: 'recommends',
        name: 'recommendHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Recommends Listing',
            breadcrumb: 'Recommends Listing',
            permission: ['view recommend', 'create recommend', 'update recommend', 'delete recommend']
        },

        children: recommendMessagingRoutes

    },

    {

        path: 'emails',
        name: 'emailHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Email Listing',
            breadcrumb: 'Email Listing',
            permission: ['view email', 'create email', 'update email', 'delete email']
        },

        children: emailMessagingRoutes

    },

    {

        path: 'pending-items',
        name: 'pendingMessagingHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Pending Items',
            breadcrumb: 'Pending Items',
            permission: ['view makerchecker', 'update makerchecker']
        },

        children: pendingMessagingRoutes

    },

];

export default messagingModuleRoutes;
