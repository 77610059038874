const loanExposureLimitRoutes = [

    {

        path: '',
        name: 'loanExposureLimitHome',
        component: () => import ('./ListLoanExposureLimits.vue'),
        meta: {
            auth: true,
            title: 'Loan Exposure Limits Listing',
            breadcrumb: 'Loan Exposure Limits Listing',
            permission: ['view loanexposurelimit']
        }

    },

    {
        path: ':id',
        name: 'showLoanExposureLimit',
        component: () => import ('./ShowLoanExposureLimit.vue'),
        meta: {
            auth: true,
            title: 'Showing Loan Exposure Limit',
            breadcrumb: 'Showing Loan Exposure Limit',
            permission: ['view loanexposurelimit']
        }
    },

    {
        path: 'create',
        name: 'createLoanExposureLimit',
        component: () => import('./CreateLoanExposureLimit.vue'),
        meta: {
            auth: true,
            title: 'Create Loan Exposure Limit',
            breadcrumb: 'Create Loan Exposure Limit',
            permission: ['create loanexposurelimit']
        }
    },

    {
        path: ':id/edit',
        name: 'editLoanExposureLimit',
        component: () => import ('./EditLoanExposureLimit.vue'),
        meta: {
            auth: true,
            title: 'Edit Loan Exposure Limit',
            breadcrumb: 'Edit Loan Exposure Limit',
            permission: ['update loanexposurelimit']
        }
    },

];


export default loanExposureLimitRoutes;
