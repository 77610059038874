<template>
  <div class="container-fluid no-gutters px-0">
    <div class="col-lg-12 mt-3xx mb-2">
      <search-bar :bread-crumb-only="true"></search-bar>

      <!-- <div
        class="card"
        :class="{
          'border-success': makerCheckeData?.status_id === statusEnum.APPROVED,
          'border-danger': makerCheckeData?.status_id === statusEnum.REJECTED,
          'border-warning': makerCheckeData?.status_id === statusEnum.APPROVEDLEVEL,
        }"
      > -->
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="onSubmitted">
            <div class="row">
              <!-- <pre>branch :: {{ branch }}</pre> -->
              <!-- 
                   <pre>isMakerChecker ::: {{ isMakerChecker }}</pre>
                   <pre>isShow ::: {{ isShow }}</pre>
                   <pre>branch ::: {{ branch }}</pre>
                   <pre>formData ::: {{ formData }}</pre> 
              -->

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.branch_name.$invalid }"
                >
                  <label for="branch_name" class="col-sm-4 col-form-label text-right">
                    Branch Name
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.branch_name.$invalid }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.branch_name.$model"
                    />
                    <error-msg :property="model.branch_name" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.branch_code.$invalid }"
                >
                  <label for="branch_code" class="col-sm-4 col-form-label text-right">
                    Branch Code
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.branch_code.$invalid }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.branch_code.$model"
                    />

                    <error-msg :property="model.branch_code" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group row">
                  <label for="branch_type" class="col-sm-4 col-form-label text-right">
                    Branch Type
                  </label>
                  <div class="col-sm-8">
                    <!-- login type row -->
                    <div class="form-group mb-0 row">
                      <!-- <label class="col-md-3 my-1 control-label">Login As:</label> -->
                      <!-- <div class="col-md-9"> -->
                      <div class="form-check-inline my-1">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="main"
                            name="branch_type"
                            :disabled="isShow"
                            v-model="model.branch_type.$model"
                            value="MAIN"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="main">Main</label>
                        </div>
                      </div>
                      <div class="form-check-inline my-1">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="other"
                            name="branch_type"
                            :disabled="isShow"
                            v-model="model.branch_type.$model"
                            value="OTHER"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="other">Other</label>
                        </div>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group row">
                  <label for="company_id" class="col-sm-4 col-form-label text-right">
                    Company Name
                  </label>
                  <div class="col-sm-8">
                    <select
                      :disabled="isShow"
                      class="form-control"
                      v-model="model.company_id.$model"
                      v-if="companies.data"
                    >
                      <option value="" selected v-if="!isEdit">Select Company</option>
                      <option
                        v-for="(item, index) in companies.data"
                        :key="index"
                        :value="item.company_id"
                      >
                        {{ item.company_name }}
                      </option>
                    </select>
                    <error-msg :property="model.company_id" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.contact_person_name.$invalid }"
                >
                  <label
                    for="contact_person_name"
                    class="col-sm-4 col-form-label text-right"
                  >
                    Contact Person Name
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{
                        'form-control-danger': model.contact_person_name.$invalid,
                      }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.contact_person_name.$model"
                    />

                    <error-msg :property="model.contact_person_name" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.contact_person_phone.$invalid }"
                >
                  <label
                    for="contact_person_phone"
                    class="col-sm-4 col-form-label text-right"
                  >
                    Contact Person Phone
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{
                        'form-control-danger': model.contact_person_phone.$invalid,
                      }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.contact_person_phone.$model"
                    />

                    <error-msg :property="model.contact_person_phone" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.contact_person_email.$invalid }"
                >
                  <label
                    for="contact_person_email"
                    class="col-sm-4 col-form-label text-right"
                  >
                    Contact Person Email
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{
                        'form-control-danger': model.contact_person_email.$invalid,
                      }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.contact_person_email.$model"
                    />

                    <error-msg :property="model.contact_person_email" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.branch_phone.$invalid }"
                >
                  <label for="branch_phone" class="col-sm-4 col-form-label text-right">
                    Branch Phone
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.branch_phone.$invalid }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.branch_phone.$model"
                    />

                    <error-msg :property="model.branch_phone" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.branch_email.$invalid }"
                >
                  <label for="branch_email" class="col-sm-4 col-form-label text-right">
                    Branch Email
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.branch_email.$invalid }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.branch_email.$model"
                    />

                    <error-msg :property="model.branch_email" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.branch_country.$invalid }"
                >
                  <label for="branch_country" class="col-sm-4 col-form-label text-right">
                    Branch Country
                  </label>
                  <div class="col-sm-8">
                    <select
                      class="form-control"
                      :disabled="isShow"
                      v-model="model.branch_country.$model"
                      v-if="countries.data"
                    >
                      <option value="" selected v-if="!isEdit">Select Country</option>
                      <option
                        v-for="(item, index) in countries.data"
                        :key="index"
                        :value="item.iso2"
                      >
                        {{ item.name }}
                      </option>
                    </select>

                    <error-msg :property="model.branch_country" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.branch_town.$invalid }"
                >
                  <label for="branch_town" class="col-sm-4 col-form-label text-right">
                    Branch Town
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{ 'form-control-danger': model.branch_town.$invalid }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.branch_town.$model"
                    />

                    <error-msg :property="model.branch_town" />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.branch_street_address.$invalid }"
                >
                  <label
                    for="branch_street_address"
                    class="col-sm-4 col-form-label text-right"
                  >
                    Street Address
                  </label>
                  <div class="col-sm-8">
                    <input
                      class="form-control"
                      :class="{
                        'form-control-danger': model.branch_street_address.$invalid,
                      }"
                      type="text"
                      :disabled="isShow"
                      v-model="model.branch_street_address.$model"
                    />

                    <error-msg :property="model.branch_street_address" />
                  </div>
                </div>
              </div>

              <!-- <div class="col-lg-6">
                                <div class="form-group row">
                                    <label for="status" class="col-sm-4 col-form-label text-right">Status</label>
                                    <div class="col-sm-8">
                                        <div class="custom-control custom-switch mt-2">
                                            <input type="checkbox" class="custom-control-input"
                                                v-model="model.status_id.$model"
                                                id="status_id"
                                                checked="checked" />
                                            <label class="custom-control-label" for="status">Active</label>
                                        </div>
                                        <error-msg :property="model.status_id" />
                                    </div>
                                </div>
                            </div> -->

              <div class="col-lg-6">
                <div
                  class="form-group row"
                  :class="{ 'has-error': model.status_id.$invalid }"
                >
                  <label for="status_id" class="col-sm-4 col-form-label text-right"
                    >Status</label
                  >
                  <div class="col-sm-8">
                    <div>
                      <div class="form-check-inline my-1">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="status_active"
                            name="status_id"
                            :disabled="isShow"
                            v-model="model.status_id.$model"
                            value="1"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="status_active"
                            >Active</label
                          >
                        </div>
                      </div>

                      <div class="form-check-inline my-1">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="status_inactive"
                            name="status_id"
                            :disabled="isShow"
                            v-model="model.status_id.$model"
                            value="99"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="status_inactive"
                            >Inactive</label
                          >
                        </div>
                      </div>
                    </div>

                    <error-msg :property="model.status_id" />
                  </div>
                </div>
              </div>

              <hr class="row" />

              <div class="col-lg-12">
                <div class="form-group">
                  <label
                    for="street_address"
                    class="col-sm-12 col-form-label no-gutters px-0 text-left"
                    >Branch Location
                  </label>

                  <div class="col-sm-12 no-gutters px-0">
                    <map-leaflet
                      :latlng="{
                        lat: model.branch_latitude.$model,
                        lng: model.branch_longitude.$model,
                      }"
                      :streetAddress="model.branch_street_address.$model"
                      @changedLocation="changedLocation"
                      @update:latlng="updateLatLng"
                      :isEdit="isEdit"
                      :isShow="isShow"
                      :isNew="isNew"
                      v-if="model.branch_latitude.$model && model.branch_longitude.$model"
                    />
                  </div>
                </div>
              </div>

              <!-- show maker checker form buttons/ status -->
              <maker-checker-button-component
                :makerChecker="makerChecker"
                v-if="isMakerChecker"
              />

              <!-- show regular form buttons -->
              <div class="col-lg-12" v-else>
                <hr />
                <div class="form-group">
                  <button
                    class="btn btn-primary mr-3"
                    type="submit"
                    :disabled="model.$invalid"
                    :class="{ 'btn-secondary': model.$invalid }"
                    v-if="!isShow"
                  >
                    <slot name="submitButton"></slot>
                  </button>

                  <button
                    class="btn btn-primary mr-3"
                    type="button"
                    @click="onEdit"
                    v-if="isShow"
                  >
                    Edit
                  </button>

                  <button class="btn btn-danger" type="button" @click="onCancelled">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, toRefs, watch, nextTick } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import { statusEnum } from "@/enums";

const branchForm_gd = {
  branch_name: "Jogoo Rd",
  branch_code: "222",
  branch_type: "OTHER",
  contact_person_name: "TestUser",
  contact_person_phone: "0720666556",
  contact_person_email: "test@new.com",
  branch_phone: "0720666556",
  branch_email: "test@test.com",
  branch_town: "Nbi",
  branch_country: "KE",
  branch_street_address: "123 Doni",
  branch_longitude: "36.817223",
  branch_latitude: "-1.286389",
  status_id: 1,
  branch_id: undefined,
  company_id: undefined,
  company: { company_id: undefined },
};

const branchForm = {
  branch_name: "",
  branch_code: "",
  branch_type: "OTHER",
  contact_person_name: "",
  contact_person_phone: "",
  contact_person_email: "",
  branch_phone: "",
  branch_email: "",
  branch_town: "",
  branch_country: "KE",
  branch_street_address: "",
  branch_longitude: "36.817223",
  branch_latitude: "-1.286389",
  status_id: 1,
  branch_id: undefined,
  company_id: undefined,
  company: { company_id: undefined },
};

const module = "branches";
const company_module = "companies";
const country_module = "countries";

export default defineComponent({
  name: "BranchDetail",

  components: {},

  props: {
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMakerChecker: {
      type: Boolean,
      required: false,
      default: false,
    },
    makerChecker: {
      type: Object,
      required: false,
    },
  },

  emits: ["submitForm", "cancelForm"],

  setup(props, { emit }) {
    
    // avoid destructuring props directly in the root scope of setup
    const branchProp = toRefs(props);

    const store = useStore();

    let branch = ref(branchForm);
    let borderColor = ref("");

    // load all companies on create component
    let payload = { report: "1" };
    store.dispatch(`${company_module}/fetchCompanies`, payload);

    // load countries
    store.dispatch(`${country_module}/fetchCountries`, payload);

    // on created. Are we in edit mode?
    if (props.isEdit || props.isShow) {
      branch = computed(() => store.getters[`${module}/branch`]);
    }

    // if we are in maker checker, use maker checker data
    if (branchProp.isMakerChecker.value) {
      branch = branchProp.makerChecker.value?.data;
    }

    const rules = {
      branch_name: { required },
      branch_code: { required },
      branch_type: { required },
      contact_person_name: { required },
      contact_person_phone: { required },
      contact_person_email: { email },
      branch_phone: {},
      branch_email: { email },
      branch_town: {},
      branch_country: { required },
      branch_street_address: {},
      branch_latitude: {},
      branch_longitude: {},
      status_id: { required },
      company_id: { required },
    };

    // validate form
    const model = useVuelidate(rules, branch);

    // return formData
    const formData = computed(() => {
      return branch;
    });

    function onSubmitForm() {
      emit("submitForm");
    }

    function onSubmitted() {
      emit("submitForm", branch);
    }

    function onCancelled() {
      emit("cancelForm", branch);
    }

    function onEdit() {
      emit("editForm", branch);
    }

    function changedLocation(location) {
      branch.value.branch_latitude = location.lat;
      branch.value.branch_longitude = location.lng;
    }

    const updateLatLng = ({ lat, lng }) => {
      branch.value.branch_latitude = lat;
      branch.value.branch_longitude = lng;
    };

    return {
      submitForm: props.submitForm,
      companies: computed(() => store.getters[`${company_module}/companies`]),
      countries: computed(() => store.getters[`${country_module}/countries`]),
      model,
      branch,
      onSubmitted,
      onCancelled,
      changedLocation,
      onEdit,
      updateLatLng,
      onSubmitForm,
      formData,
      statusEnum,
    };
  },
});
</script>
