import axios from "axios";

export default {

    namespaced: true,

    state: {

        companysettings: [],
        companysetting: {}

    },

    getters: {

        companysettings(state) {
            return state.companysettings;
        },
        companysetting(state) {
            return state.companysetting;
        }

    },

    mutations: {

        SET_COMPANY_SETTINGS(state, companysettings) {
            return state.companysettings = companysettings
        },

        SET_COMPANY_SETTING(state, companysetting) {
            return state.companysetting = companysetting
        }

    },

    actions: {

        async fetchCompanySettings({ commit }, params) {

            await axios.get('/api/v1/company-settings', { params: params })
                .then(res => {
                    // console.log("res == ", res);
                    commit('SET_COMPANY_SETTINGS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchCompanySetting({ commit }, product_id) {

            await axios.get(`/api/v1/company-settings/${product_id}`)
                .then(res => {
                    // console.log("fetchCompanySetting store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_COMPANY_SETTING', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateCompanySetting({ commit, dispatch }, companysetting) {

            await axios("/api/v1/company-settings/" + companysetting.company_setting_id, {
                method: "patch",
                data: companysetting,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchCompanySettings');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        }

    }

}
