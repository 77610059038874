import axios from "axios";

export default {

    namespaced: true,

    state: {

        translations: [],

    },

    getters: {

        translations(state) {
            return state.translations;
        }

    },

    mutations: {

        SET_TRANSLATIONS(state, translations) {
            return state.translations = translations
        }

    },

    actions: {

        async fetchTranslations({ commit }, params) {
            try {
              const res = await axios.get('/api/v1/translations', { params: params });
              commit('SET_TRANSLATIONS', res.data.data);
              return res.data.data;
            } catch (error) {
              // console.error('Error fetching translations:', error); 
              return Promise.reject(error);
            }
        }

    }


}
