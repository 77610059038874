const branchRegistrationRoutes = [

    {

        name: 'branchHome',
        path: '',
        component: () => import('./ListBranches.vue'),
        meta: {
            auth: true,
            title: 'Branches Listing',
            breadcrumb: 'Branches Listing',
            permission: ['view branch'],
            section: 'branch'
        }

    },

    {
        path: 'create',
        name: 'createBranch',
        component: () => import('./CreateBranch.vue'),
        meta: {
            auth: true,
            title: 'Create Branch',
            breadcrumb: 'Create Branch',
            permission: ['create branch'],
            section: 'branch'
        }
    },

    {
        path: ':id',
        name: 'showBranch',
        component: () => import('./ShowBranch.vue'),
        meta: {
            auth: true,
            title: 'Showing Branch',
            breadcrumb: 'Showing Branch',
            permission: ['view branch'],
            section: 'branch'
        }
    },

    {
        path: ':id/edit',
        name: 'editBranch',
        component: () => import('./EditBranch.vue'),
        meta: {
            auth: true,
            title: 'Edit Branch',
            breadcrumb: 'Edit Branch',
            permission: ['update branch'],
            section: 'branch'
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteBranch',
        meta: {
            auth: true,
            title: 'Delete Branch',
            permission: ['delete branch'],
            section: 'branch'
        }
    },

];

export default branchRegistrationRoutes;
