import moment from "moment";
import { statusEnum } from "../enums";

// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from "../utils";

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + "s";
}

/**
 * @param {number} time
 */
export function timeAgoOld(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), " min");
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), " hr");
  } else {
    return pluralize(~~(between / 86400), " day");
  }
}

export function timeAgo(timeInSeconds) {
  if (timeInSeconds < 60) {
    return pluralize(~~timeInSeconds, " sec");
  } else if (timeInSeconds < 3600) {
    return pluralize(~~(timeInSeconds / 60), " min");
  } else if (timeInSeconds < 86400) {
    return pluralize(~~(timeInSeconds / 3600), " hr");
  } else {
    return pluralize(~~(timeInSeconds / 86400), " day");
  }
}

export function timeSinceRegistered(registeredDate) {
  const timeInSeconds =
    (Date.now() - new Date(registeredDate).getTime()) / 1000;

  // console.log("timeSinceRegistered === ", registeredDate, timeInSeconds);

  if (timeInSeconds < 3600) {
    // Less than 1 hour
    return timeAgo(timeInSeconds) + " ago";
  } else if (timeInSeconds < 86400) {
    // Less than 24 hours
    return timeAgo(timeInSeconds) + " ago";
  } else {
    // More than 24 hours
    const days = Math.floor(timeInSeconds / 86400);
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  }
}

export function getStatusColorCss(status_id) {
  const statusMap = {
    [statusEnum.INACTIVE]: "text-danger",
    // [statusEnum.ACTIVE]: "text-success",
    [statusEnum.APPROVED]: "text-success",
    [statusEnum.CONFIRMED]: "text-success",
    [statusEnum.AUTOAPPROVED]: "text-success",
    [statusEnum.COMPLETED]: "text-success",
    [statusEnum.INCOMPLETED]: "text-danger",
    [statusEnum.PAID]: "text-success",
    [statusEnum.FULLYDISBURSED]: "text-success",
    [statusEnum.APPROVEDLEVEL]: "text-info",
    [statusEnum.AWAITINGACTIVATION]: "text-warning",
    [statusEnum.PENDING]: "text-warning",
    [statusEnum.UNPAID]: "text-warning",
    [statusEnum.REJECTED]: "badge badge-soft-danger text-danger",
    [statusEnum.DISABLED]: "text-danger",
    [statusEnum.SUSPENDED]: "text-danger",
    [statusEnum.EXPIRED]: "text-danger",
    [statusEnum.CANCELLED]: "text-danger",
    [statusEnum.DECLINED]: "text-danger",
    [statusEnum.AUTODECLINED]: "text-danger",
    [statusEnum.NOTSENTNOCREDENTIALS]: "text-danger",
    [statusEnum.WRITTENOFF]: "text-danger",
    [statusEnum.WAITING]: "text-info",
    [statusEnum.OPEN]: "text-info",
    [statusEnum.AWAITINGAPPROVAL]: "text-info",
    [statusEnum.PARTIALLYDISBURSED]: "text-info",
  };

  // default to empty string if status_id is not found
  return statusMap[status_id] || "";
}

export function getMembershipOnboardingMethod(onboardingMethod) {
  const onboardingMethodMap = {
    [onboardingMethod.ON_MEETING_BASIC_KYC]: "On Meeting Basic KYC",
    [onboardingMethod.ON_REGISTRATION_FEE_PAYMENT]:
      "On Registration Fee Payment",
    [onboardingMethod.MANUAL]: "Manual",
  };

  // default to empty string if onboardingMethod is not found
  return onboardingMethodMap[onboardingMethod] || "";
}

export function formatDate(value, dateformat) {
  if (!dateformat) {
    dateformat = "DD-MMM-YYYY HH:mm";
  }
  if (value) {
    return moment(String(value)).format(dateformat);
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: "Qi" },
    { value: 1e15, symbol: "Qa" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].symbol
      );
    }
  }
  if (num) {
    return num.toString();
  }
  return 0;
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num,places=2) {
  if (num != null && num != undefined && !isNaN(num)) {
    return (+num).toLocaleString(undefined, { minimumFractionDigits: places });
  }
  return null;
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatSize(size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + " MB";
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + " KB";
  }
  return size.toString() + " B";
}
