const TeamAllocationRoutes = [
    {

        name: 'customerAllocationHome',
        path: '',
        component: () => import('./ListCustomerAllocations.vue'),
        meta: {
            auth: true,
            title: 'Staff Allocations',
            breadcrumb: 'Staff Allocations',
        }

    },


];

export default TeamAllocationRoutes;
