<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal fade bd-example-modal-lg show"
        id="exampleModalLarge"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        aria-hidden="true"
        style="padding-right: 17px; display: block"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          :class="modalSize"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header" :class="modalHeaderBgColor">
              <h6 class="modal-title m-0" :class="modalHeaderTextColor" id="modalTitle">
                <slot name="header"> </slot>
              </h6>
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
                aria-label="Close"
                @click="close"
              >
                Close
              </button>
            </div>

            <div class="modal-body body-height" id="modalDescription">
              <slot name="body"> </slot>
            </div>

            <!-- <div class="modal-footer">

                            <button type="button" class="btn btn-secondary btn-sm"
                            aria-label="Close"
                            data-dismiss="modal"  @click="close">Close</button>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent, computed, ref } from "vue";

export default defineComponent({
  name: "Modal",

  props: {
    modalSize: {
      type: String,
      default: "modal-lg",
    },
    modalHeaderBgColor: {
      type: String,
      default: "",
    },
    modalHeaderTextColor: {
      type: String,
      default: "",
    },
  },

  components: {},

  emits: ["close"],

  setup(props, { emit }) {
    const modalSize = ref(props.modalSize);

    function close() {
      emit("close");
    }

    return {
      close,
      // modalSize,
    };
  },
});
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}
.modal-fade-enter-active {
  transition: all 0.8s ease-out;
}
.modal-fade-enter-active {
  transition: all 0.8s ease-in;
}

.body-height {
  max-height: 500px;
  overflow-x: auto;
}

/* -- zoom -- */
/* @-webkit-keyframes modal-zoom-enter {
        from {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    @keyframes modal-zoom-enter {
        from {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    .modal-zoom-enter-active {
        -webkit-animation: modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
        animation: modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
    }

    @-webkit-keyframes modal-zoom-leave {
        to {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    @keyframes modal-zoom-leave {
        to {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    .modal-zoom-leave-active {
        -webkit-animation: modal-zoom-leave both;
        animation: modal-zoom-leave both;
    } */
</style>
