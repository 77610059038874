const teamMemberRoutes = [

    {
    name: 'teamMemberHome',
        path: '',
    component: () => import('./ListTeamMembers.vue'),
    meta: {
    auth: true,
        title: 'Team Members',
        breadcrumb: 'Team Members',
    }
},
    {
        name: 'createTeamMember',
        path: 'create',
        component: () => import('./CreateTeamMember.vue'),
        meta: {
            auth: true,
            title: 'Add Team Members',
            breadcrumb: 'Add Team Members',
        }
    },

    {

        path: ':id/edit',
        name: 'editTeamMember',
        component: () => import('./EditTeamMember.vue'),
        meta: {
            auth: true,
            title: 'Edit Team Members',
            breadcrumb: 'Edit Team Members',
        }
    },

 ]
export default teamMemberRoutes;
