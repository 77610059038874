const groupCategoryRegistrationRoutes = [

    {

        name: 'groupCategoryHome',
        path: '',
        component: () => import('./ListGroupCategories.vue'),
        meta: {
            auth: true,
            title: 'Group Categories Listing',
            breadcrumb: 'Group Categories Listing',
            permission: ['view groupcategory']
        }

    },

    {
        path: 'create',
        name: 'createGroupCategory',
        component: () => import('./CreateGroupCategory.vue'),
        meta: {
            auth: true,
            title: 'Create Group Category',
            breadcrumb: 'Create Group Category',
            permission: ['create groupcategory']
        }
    },

    {
        path: ':id',
        name: 'showGroupCategory',
        component: () => import('./ShowGroupCategory.vue'),
        meta: {
            auth: true,
            title: 'Showing Group Category',
            breadcrumb: 'Showing Group Category',
            permission: ['view groupcategory']
        }
    },

    {
        path: ':id/edit',
        name: 'editGroupCategory',
        component: () => import('./EditGroupCategory.vue'),
        meta: {
            auth: true,
            title: 'Edit Group Category',
            breadcrumb: 'Edit Group Category',
            permission: ['update groupcategory']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteGroupCategory',
        meta: {
            auth: true,
            title: 'Delete Group Category',
            permission: ['delete groupcategory']
        }
    },

];

export default groupCategoryRegistrationRoutes;
