import Base from '../../Common/Base.vue';

const clientCustomerLoanModuleRoutes = [

    {
        name: 'clientCustomerLoan',
        path: '',
        component: () =>
            import ('../../../components/Customer/Loans/ListLoans.vue'),
        meta: {
            auth: true,
            title: 'My Loans',
            breadCrumb: 'My Loans',
            permission: ['view customer']
        }
    },

    {
        path: 'create',
        name: 'clientCustomerCreateLoan',
        component: () => import('../../../components/Customer/Loans/CreateLoan.vue'),
        meta: {
            auth: true,
            title: 'Create Loan',
            breadcrumb: 'Create Loan',
            permission: ['view customer']
        }
    },

    {
        path: ':id/edit',
        name: 'clientCustomerEditLoan',
        component: () => import('../../../components/Customer/Loans/EditLoan.vue'),
        meta: {
            auth: true,
            title: 'Edit Loan',
            breadcrumb: 'Edit Loan',
            permission: ['view customer']
        }
    },

    {
        path: ':id',
        name: 'clientCustomerShowLoan',
        component: () => import('../../../components/Customer/Loans/ShowLoan.vue'),
        meta: {
            auth: true,
            title: 'Showing Loan',
            breadcrumb: 'Showing Loan',
            permission: ['view customer']
        }
    }

];

export default clientCustomerLoanModuleRoutes;
