<template>
  <modal v-show="showModal" @close="toggleUserBankPopup">
    <template v-slot:header>
      <span v-if="!isEdit && !isShow"
        >Add Bank Record For Member - {{ userData.full_name }}</span
      >
      <span v-if="isEdit && !isShow"
        >Editing Bank Record For Member - {{ userData.full_name }}</span
      >
      <span v-if="isEdit && isShow"
        >Showing Bank Record For Member - {{ userData.full_name }}</span
      >
    </template>

    <template v-slot:body>
      <!-- model == {{ model }}<br> -->
      <!-- userBankData == {{ userBankData }}<br> -->

      <div class="card">
        <div class="card-body">
          <form @submit.prevent="onSubmitted">
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <div class="form-outline"> 
                  <label class="form-label" for="first_name">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >First Name</span
                    >
                  </label>
                  <input
                    class="form-control mt-n3"
                    :value="userData.first_name"
                    type="text"
                    disabled
                  />

                  <error-msg :property="model.first_name" />

                  <input
                    class="form-control"
                    name="customer_id"
                    :value="
                      userBankData ? userBankData.nextofkinable_id : undefined
                    "
                    type="hidden"
                  />

                  <input
                    class="form-control"
                    name="user_type"
                    :value="
                      userBankData ? userBankData.nextofkinable_type : undefined
                    "
                    type="hidden"
                  />

                  <input
                    class="form-control"
                    name="user_bank_id"
                    :value="
                      userBankData ? userBankData.user_bank_id : undefined
                    "
                    type="hidden"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-4">
                <div class="form-outline">
                  <label class="form-label" for="last_name">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >Last Name</span
                    >
                  </label>
                  <input
                    class="form-control mt-n3"
                    :value="userData.last_name"
                    type="text"
                    disabled
                  />

                  <error-msg :property="model.last_name" />
                </div>
              </div>

              <div class="col-lg-4 col-md-4">
                <div
                  class="form-outline"
                  :class="{ 'has-error': model.bank_id.$invalid }"
                >
                  <label class="form-label" for="bank_id">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >Bank Name</span
                    >
                  </label>
                  <select
                    class="form-control mt-n3"
                    :disabled="isShow"
                    v-model="model.bank_id.$model"
                    v-if="banks.data"
                  >
                    <option
                      v-for="(item, index) in banks.data"
                      :key="index"
                      :value="item.bank_id"
                    >
                      {{ item.bank_name }}
                    </option>
                  </select>

                  <error-msg :property="model.bank_id" />
                </div>
              </div>

              <div class="col-lg-4 col-md-4">
                <div class="form-outline">
                  <label class="form-label" for="branch_name">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >Bank Branch Name</span
                    >
                  </label>
                  <input
                    class="form-control mt-n3"
                    :class="{
                      'form-control-danger': model.branch_name.$invalid,
                    }"
                    v-model="model.branch_name.$model"
                    type="text"
                    :disabled="isShow"
                  />
                  <error-msg :property="model.branch_name" />
                </div>
              </div>

              <div class="col-lg-4 col-md-4">
                <div class="form-outline">
                  <label class="form-label" for="account_no">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >Account No</span
                    >
                  </label>
                  <input
                    class="form-control mt-n3"
                    :class="{
                      'form-control-danger': model.account_no.$invalid,
                    }"
                    v-model="model.account_no.$model"
                    type="text"
                    :disabled="isShow"
                  />

                  <error-msg :property="model.account_no" />
                </div>
              </div>

              <div class="col-lg-4 col-md-4">
                <div class="form-group row">
                  <label
                    for="is_primary_account"
                    class="col-sm-5 col-form-label text-right"
                  >
                    Is Primary Account
                  </label>
                  <div class="col-sm-7">
                    <div class="form-check-inline my-1">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="yes"
                          name="is_primary_account"
                          :disabled="isShow"
                          v-model="model.is_primary_account.$model"
                          value="true"
                          class="custom-control-input"
                        />
                        <label class="custom-control-label" for="yes"
                          >Yes</label
                        >
                      </div>
                    </div>
                    <div class="form-check-inline my-1">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="no"
                          name="is_primary_account"
                          :disabled="isShow"
                          v-model="model.is_primary_account.$model"
                          value="false"
                          class="custom-control-input"
                        />
                        <label class="custom-control-label" for="no">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12" v-if="!isShow">
                <hr class="row no-top-margin" />

                <div class="form-group text-center">
                  <button
                    class="btn btn-primary mr-3"
                    type="submit"
                    :disabled="model.$invalid"
                    :class="{ 'btn-secondary': model.$invalid }"
                  >
                    Submit
                     <i class="fa fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const user_bank_module = "userbanks";
const user_bank_data_module = "userbanksdata";
const bank_module = "banks";
const statuses_module = "statuses";

export default defineComponent({
  name: "UserBankPopup",

  props: {
    userData: {
      type: Object,
      required: true,
    },
    userBankData: {
      type: Object,
      required: false,
    },
    userType: {
      type: String,
      required: false,
    },
    showModal: {
      type: Boolean,
      default: true,
    },
    toggleUserBankPopup: {
      type: Function,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCustomer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {},

  setup(props, { emit }) {
    const userBankData = {
      customer_id: props.userData.customer_id,
      user_type: props.userType,
      user_bank_id: undefined,
      first_name: "",
      last_name: "",
      bank_id: undefined,
      is_primary_account: false,
      branch_name: "",
      account_no: "",
      status_id: "1",
    };

    const store = useStore();
    const userData = ref(props.userData);
    const showModal = ref(props.showModal);
    const userType = ref(props.userType);
    const isCustomer = ref(props.isCustomer);
    const toggleUserBankPopup = ref(props.toggleUserBankPopup);

    let userBankForm = ref(userBankData);

    // on created. Are we in edit mode?
    if (props.isEdit) {
      userBankForm = ref(props.userBankData);
    }

    // set validation rules
    const rules = {
      customer_id: {},
      user_type: {},
      user_bank_id: {},
      first_name: {},
      last_name: {},
      bank_id: { required },
      is_primary_account: { required },
      branch_name: {},
      account_no: { required },
      status_id: {},
    };

    // validate form
    const model = useVuelidate(rules, userBankForm);

    // load banks
    let payload = { report: "1" };
    store.dispatch(`${bank_module}/fetchBanks`, payload);

    // load statuses data
    let status_payload = {
      report: "1", // fetch all data
      section: "users", // get userbanks statuses only
    };
    store.dispatch(`${statuses_module}/fetchStatuses`, status_payload);

    async function onSubmitted() {
      if (!props.isEdit) {
        if (isCustomer.value) {
          await store
            .dispatch(
              `${user_bank_data_module}/createUserBankData`,
              userBankForm.value
            )
            .then((res) => {
              emit("toggleUserBankPopup", toggleUserBankPopup);
            });
        } else {
          await store
            .dispatch(`${user_bank_module}/createUserBank`, userBankForm.value)
            .then((res) => {
              emit("toggleUserBankPopup", toggleUserBankPopup);
            });
        }
      } else {
        if (isCustomer.value) {
          await store
            .dispatch(
              `${user_bank_data_module}/updateUserBankData`,
              userBankForm
            )
            .then((res) => {
              emit("toggleUserBankPopup", toggleUserBankPopup);
            });
        } else {
          await store
            .dispatch(`${user_bank_module}/updateUserBank`, userBankForm)
            .then((res) => {
              emit("toggleUserBankPopup", toggleUserBankPopup);
            });
        }
      }
    }

    return {
      onSubmitted,
      // userData,
      // userBankData: props.userBankData,
      // userType,
      // isCustomer,
      // toggleUserBankPopup,
      model,
      banks: computed(() => store.getters[`${bank_module}/banks`]),
      statuses: computed(() => store.getters[`${statuses_module}/statuses`]),
      // isEdit: props.isEdit,
      // isShow: props.isShow,
    };
  },
});
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}
.modal-fade-enter-active {
  transition: all 0.8s ease-out;
}
.modal-fade-enter-active {
  transition: all 0.8s ease-in;
}
</style>
