import axios from "axios";

export default {

    namespaced: true,

    state: {
        financialyear: {},
        financialyears: [],
        closePosting: null,
        closepostingstatus: null,
        editFinancialYear: null,
        closeFinancialYear: null,
        financialYearModal: false,
        financialYearsRouteState: null,
        closeFinancialYearStatus: null,
        closeFinancialYearMessage: null,

    },

    getters: {
        financialyear(state) {
            return state.financialyear;
        },
        financialyears(state) {
            return state.financialyears;
        },
        editFinancialYear(state) {
            return state.editFinancialYear;
        },
        closeFinancialYear(state) {
            return state.closeFinancialYear;
        },
        closeFinancialYearStatus(state) {
            return state.closeFinancialYearStatus;
        },
        closeFinancialYearMessage(state) {
            return state.closeFinancialYearMessage;
        },
        closePosting(state) {
            return state.closePosting;
        },
        closepostingstatus(state) {
            return state.closepostingstatus;

        },
        financialYearModal(state) {
            return state.financialYearModal;
        },
        financialYearsRouteState(state) {
            return state.financialYearsRouteState;
        }
    },

    actions: {
        fetchFinancialYears({ commit }, params) {
            // commit('LOADING_STATUS', true);
            axios.get('/api/v1/financialyears', { params: params })
                .then(res => {
                    // console.log(res.data.data[1]);
                    // console.log(res.data.data);
                    // commit('LOADING_STATUS', false);
                    commit('FETCH_FINANCIAL_YEARS', res.data.data);
                }).catch(err => {
                    console.log(err)
                    commit('ERROR', err);
                })
        },

        async createFinancialYear({ commit, dispatch }, payload) {

            console.log("payload createpayload ", payload);
            await axios.post('/api/v1/financialyears', payload)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchFinancialYears');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateFinancialYear({ commit, dispatch }, payload) {

            console.log("payload --- ", payload);
            await axios("/api/v1/financialyears/" + payload.financial_year_id, {
                method: "patch",
                data: payload,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchFinancialYears');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async setFinancialYear({ commit, dispatch }, payload) {

            // console.log("payload createpayload ", payload);
            commit('FETCH_FINANCIAL_YEAR', payload);

        },

        async setFinancialYearsRouteState({ commit, dispatch }, payload) {

            // console.log("payload createpayload ", payload);
            commit('FINANCIAL_ROUTE_STATE', payload);

        },

        async closeFinancialYears({ commit }, id) {
            // commit('LOADING_STATUS', true);
            await axios.put('/api/v1/financialyears/closeYear/' + id, )
                .then(res => {

                    // console.log(res.data.data);
                    // commit('LOADING_STATUS', false);
                    commit('CLOSE_FINANCIAL_YEARS', res.data.data);
                    commit('CLOSE_FINANCIAL_YEARS_STATUS', res.data.data);
                    return true;
                }).catch(err => {
                    // console.log(err.response.data.message)
                    commit('CLOSE_FINANCIAL_YEARS_STATUS', err.response.data.status_code);
                    commit('CLOSE_FINANCIAL_YEARS_MESSAGE', err.response.data.message);
                    return true;
                })
        },
        async closePosting({ commit }, id) {

            // commit('LOADING_STATUS', true);
            await axios.put('/api/v1/financialyears/closePosting/' + id, )
                .then(res => {
                    // console.log(res.status);
                    // commit('LOADING_STATUS', false);
                    commit('CLOSE_POSTING', res.data.data);
                    commit('CLOSE_POSTING_STATUS', res.status);
                }).catch(err => {
                    // console.log(err.response.status)
                    commit('CLOSE_POSTING_STATUS', err.response.status);

                })
        },
        clearStatusandMessage({ commit }) {
            commit('CLOSE_FINANCIAL_YEARS_STATUS', null);
            commit('CLOSE_FINANCIAL_YEARS_MESSAGE', null);
        },
        toggleYearsModalState({ commit }, params) {
            commit('FINANCIAL_YEARS_MODAL', params.fymstate);
            commit('FINANCIAL_ROUTE_STATE', params.type);
            commit('EDIT_FINANCIAL_YEARS', params.data);
        }
    },

    mutations: {

        EDIT_FINANCIAL_YEARS(state, data) {
            state.editFinancialYear = data;
        },
        CLOSE_POSTING(state, data) {
            state.closePosting = data;
        },
        CLOSE_POSTING_STATUS(state, data) {
            state.closepostingstatus = data;
        },
        CLOSE_FINANCIAL_YEARS(state, data) {
            state.closeFinancialYear = data;
        },
        CLOSE_FINANCIAL_YEARS_STATUS(state, data) {
            state.closeFinancialYearStatus = data;
        },
        CLOSE_FINANCIAL_YEARS_MESSAGE(state, data) {
            state.closeFinancialYearMessage = data;
        },
        FETCH_FINANCIAL_YEAR(state, data) {
            state.financialyear = data;
        },
        FETCH_FINANCIAL_YEARS(state, data) {
            state.financialyears = data;
        },
        FINANCIAL_ROUTE_STATE(state, data) {
            state.financialYearsRouteState = data;
        },
        FINANCIAL_YEARS_MODAL(state, financialYearModal) {
            state.financialYearModal = financialYearModal;
        },
    }

}
