const groupAdminRegistrationRoutes = [

    {

        name: 'groupAdminHome',
        path: '',
        component: () => import('./ListGroupAdmins.vue'),
        meta: {
            auth: true,
            title: 'Group Admins Listing',
            breadcrumb: 'Group Admins Listing',
            permission: ['view groupadmin']
        }

    },

    {
        path: 'create',
        name: 'createGroupAdmin',
        component: () => import('./CreateGroupAdmin.vue'),
        meta: {
            auth: true,
            title: 'Create Group Admin',
            breadcrumb: 'Create Group Admin',
            permission: ['create groupadmin']
        }
    },

    {
        path: ':id',
        name: 'showGroupAdmin',
        component: () => import('./ShowGroupAdmin.vue'),
        meta: {
            auth: true,
            title: 'Showing Group Admin',
            breadcrumb: 'Showing Group Admin',
            permission: ['view groupadmin']
        }
    },

    {
        path: ':id/edit',
        name: 'editGroupAdmin',
        component: () => import('./EditGroupAdmin.vue'),
        meta: {
            auth: true,
            title: 'Edit Group Admin',
            breadcrumb: 'Edit Group Admin',
            permission: ['update groupadmin']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteGroupAdmin',
        meta: {
            auth: true,
            title: 'Delete Group Admin',
            permission: ['delete groupadmin']
        }
    },

];

export default groupAdminRegistrationRoutes;
