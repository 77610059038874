const userStagingRegistrationRoutes = [

    {
        name: 'userStagingHome',
        path: '',
        component: () => import('./ListUsersStaging.vue'),
        meta: {
            auth: true,
            title: 'Staged Users Listing',
            breadcrumb: 'Staged Users Listing',
            permission: ['view member'],
            section: 'userstaging'
        }

    },

    {
        path: 'create',
        name: 'createUserStaging',
        component: () => import('./CreateUserStaging.vue'),
        meta: {
            auth: true, 
            title: 'Create Staged Member',
            breadcrumb: 'Create Staged Member',
            permission: ['create member'],
            section: 'userstaging'
        }
    },

    {
        path: ':id',
        name: 'showUserStaging',
        component: () => import('./ShowUserStaging.vue'),
        meta: {
            auth: true,
            title: 'Showing Staged User',
            breadcrumb: 'Showing Staged User',
            permission: ['view member'],
            section: 'userstaging'
        }
    },

    {
        path: ':id/edit',
        name: 'editUserStaging',
        component: () => import('./EditUserStaging.vue'),
        meta: {
            auth: true,
            title: 'Edit Staged User',
            breadcrumb: 'Edit Staged User',
            permission: ['update member'],
            section: 'userstaging'
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteUser',
        meta: {
            auth: true,
            title: 'Delete Staged User',
            permission: ['delete member'],
            section: 'userstaging'
        }
    },

];

export default userStagingRegistrationRoutes;
