const shariacontractRoutes = [

    {

        path: '',
        name: 'financingcontractHome',
        component: () => import ('./ListShariaContracts.vue'),
        meta: {
            auth: true,
            title: 'Financing Contract Listing',
            breadcrumb: 'Financing Contract Listing',
            permission: [] //'view collateral'
        }

    },

    {
        path: 'create',
        name: 'createfinancingcontract',
        component: () => import('./CreateEditShariaContract.vue'),
        meta: {
            auth: true,
            title: 'Create Financing Contract',
            breadcrumb: 'Create Financing Contract',
            permission: [] //'create collateral'
        }
    },

    {
        path: ':id/edit',
        name: 'editfinancingcontract',
        component: () => import ('./CreateEditShariaContract.vue'),
        meta: {
            auth: true,
            title: 'Edit Financing Contract',
            breadcrumb: 'Edit Financing Contract',
            permission: [] //'update collateral'
        }
    },

];


export default shariacontractRoutes;
