const financialYearFinanceRoutes = [

    {

        name: 'financialYearsHome',
        path: 'financial-years',
        component: () => import ('./ListFinancialYear.vue'),
        meta: {
            auth: true,
            title: 'Financial Years',
            breadcrumb: 'Financial Years',
            permission: ['view financialyear', 'create financialyear', 'update financialyear', 'delete financialyear']
        }

    },

];

export default financialYearFinanceRoutes;
