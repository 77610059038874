<template>
  <div>
    <template v-if="loggedIn && twofactor" class="client-page">
      <div class="page-wrapper">
        <top-bar-customer :authUser="user" />

        <!-- <router-view :authUser="user" v-if="user"></router-view> -->

        <!-- start vuerouter new change -->
        <router-view v-slot="{ Component }" :authUser="user" v-if="user">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
        <!-- end vuerouter new change -->

        <footer-customer :authUser="user" />
      </div>
    </template>

    <template class="client-page" v-else>
      <div class="page-wrapper">
        <top-bar-customer />

        <!-- <router-view /> -->

        <!-- start vuerouter new change -->
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
        <!-- end vuerouter new change -->

        <footer-customer />
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";

const module = "auth";

export default defineComponent({
  name: "CustomerLayout",

  setup() {
    const store = useStore();

    let loggedIn = ref(false);
    let twofactor = ref(false);
    let user = ref({});

    loggedIn = computed(() => {
      return store.getters[`${module}/loggedIn`];
    });

    twofactor = computed(() => {
      return store.getters[`${module}/twofactor`];
    });

    user = computed(() => {
      return store.getters[`${module}/user`];
    });

    return {
      user,
      twofactor,
      loggedIn,
    };
  },
});
</script>
