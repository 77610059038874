import axios from "axios";

export default {

    namespaced: true,

    state: {

        smstemplatesmaster: [],
        smstemplatemaster: {},
        smstemplatesmasterhistory: {}

    },

    getters: {

        smstemplatesmaster(state) {
            return state.smstemplatesmaster;
        },
        smstemplatemaster(state) {
            return state.smstemplatemaster;
        },
        smstemplatesmasterhistory(state) {
            return state.smstemplatesmasterhistory;
        }

    },

    mutations: {

        SET_SMS_TEMPLATES_MASTER(state, smstemplatesmaster) {
            return state.smstemplatesmaster = smstemplatesmaster
        },

        SET_SMS_TEMPLATE_MASTER(state, smstemplatemaster) {
            return state.smstemplatemaster = smstemplatemaster
        },

        SET_SMS_TEMPLATES_MASTER_HISTORY(state, smstemplatesmasterhistory) {
            return state.smstemplatesmasterhistory = smstemplatesmasterhistory
        }

    },

    actions: {

        async fetchSmsTemplatesMaster({ commit }, params) {

            await axios.get('/api/v1/sms-templates-master', { params: params })
                .then(res => {
                    commit('SET_SMS_TEMPLATES_MASTER', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchSmsTemplatesMasterHistory({ commit }, params) {

            await axios.get(`/api/v1/sms-templates-master/history/${params.sms_template_id}`, { params: params })
                .then(res => {
                    commit('SET_SMS_TEMPLATES_MASTER_HISTORY', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchSmsTemplateMaster({ commit }, sms_template_id) {

            await axios.get(`/api/v1/sms-templates-master/${sms_template_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_SMS_TEMPLATE_MASTER', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createSmsTemplateMaster({ commit, dispatch }, smstemplatemaster) {

            await axios.post('/api/v1/sms-templates-master', smstemplatemaster)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchSmsTemplatesMaster');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateSmsTemplateMaster({ commit, dispatch }, smstemplatemaster) {

            await axios("/api/v1/sms-templates-master/" + smstemplatemaster.media_template_master_id, {
                method: "patch",
                data: smstemplatemaster,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchSmsTemplatesMaster');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async resetSmsTemplateMaster({ commit, dispatch }, sms_template_id) {

            await axios.post(`/api/v1/sms-templates-master/reset/${sms_template_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    // dispatch('fetchSmsTemplates');
                    dispatch('fetchSmsTemplateMaster', sms_template_id);
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async resetAllSmsTemplateMaster({ commit, dispatch }) {

            await axios.post(`/api/v1/sms-templates-master/resetall`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchEmailTemplatesMaster');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteSmsTemplateMaster({ commit }, sms_template_id) {

            await axios.delete(`/api/v1/sms-templates-master/${sms_template_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchSmsTemplatesMaster');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
