const teamMemberTypeRoutes = [
    {
        name: 'teamMemberTypeHome',
        path: '',
        component: () => import('./ListTeamMemberTypes.vue'),
        meta: {
            auth: true,
            title: 'Team Member Types',
            breadcrumb: 'Team Member Types',
        }
    }


]
export default teamMemberTypeRoutes;
