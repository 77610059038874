import axios from "axios";

export default {

    namespaced: true,

    state: {

        makercheckersectionuserlevels: [],
        makercheckersectionuserlevel: {},
        makercheckersectionuserlevelshistory: {}

    },

    getters: {

        makercheckersectionuserlevels(state) {
            return state.makercheckersectionuserlevels;
        },
        makercheckersectionuserlevel(state) {
            return state.makercheckersectionuserlevel;
        },
        makercheckersectionuserlevelshistory(state) {
            return state.makercheckersectionuserlevelshistory;
        }

    },

    mutations: {

        SET_MAKER_CHECKER_SECTION_USER_LEVELS(state, makercheckersectionuserlevels) {
            return state.makercheckersectionuserlevels = makercheckersectionuserlevels
        },

        SET_MAKER_CHECKER_SECTION_USER_LEVEL(state, makercheckersectionuserlevel) {
            return state.makercheckersectionuserlevel = makercheckersectionuserlevel
        },

        SET_MAKER_CHECKER_SECTION_USER_LEVELS_HISTORY(state, makercheckersectionuserlevelshistory) {
            return state.makercheckersectionuserlevelshistory = makercheckersectionuserlevelshistory
        }

    },

    actions: {

        async fetchMakerCheckerSectionUserLevels({ commit }, params) {

            await axios.get('/api/v1/maker-checker-section-user-levels', { params: params })
                .then(res => {
                    commit('SET_MAKER_CHECKER_SECTION_USER_LEVELS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchMakerCheckerSectionUserLevelsHistory({ commit }, params) {

            await axios.get(`/api/v1/maker-checker-section-user-levels/history/${params.maker_checker_section_user_level_id}`, { params: params })
                .then(res => {
                    commit('SET_MAKER_CHECKER_SECTION_USER_LEVELS_HISTORY', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchMakerCheckerSectionUserLevel({ commit }, maker_checker_section_user_level_id) {

            await axios.get(`/api/v1/maker-checker-section-user-levels/${maker_checker_section_user_level_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_MAKER_CHECKER_SECTION_USER_LEVEL', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createMakerCheckerSectionUserLevel({ commit, dispatch }, makercheckersectionuserlevel) {

            await axios.post('/api/v1/maker-checker-section-user-levels', makercheckersectionuserlevel)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchMakerCheckerSectionUserLevels');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateMakerCheckerSectionUserLevel({ commit, dispatch }, makercheckersectionuserlevel) {

            await axios("/api/v1/maker-checker-section-user-levels/" + makercheckersectionuserlevel.maker_checker_section_user_level_id, {
                method: "patch",
                data: makercheckersectionuserlevel,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchMakerCheckerSectionUserLevels');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteMakerCheckerSectionUserLevel({ commit }, maker_checker_section_user_level_id) {

            await axios.delete(`/api/v1/maker-checker-section-user-levels/${maker_checker_section_user_level_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchMakerCheckerSectionUserLevels');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
