<template>
  <transition name="modal" v-if="financialYearModal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" role="dialog">
          <div class="modal-header">
            <p class="main-title">
              {{ financialYearsRouteState == 1 ? "Create New" : "Edit" }}
              Financial Year
            </p>
            <button class="modal-default-button" @click="closeModalFun">
              <i style="font-size: 21px" class="fas fa-window-close"></i>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="createFinancialYear">
              <div class="row">
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label text-right"
                      >Start Date</label
                    >
                    <div class="col-sm-8">
                      <input
                        type="date"
                        class="form-control"
                        v-model="state.start_date"
                        required
                        :disabled="financialYearsRouteState !== 1"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label text-right"
                      >End Date</label
                    >
                    <div class="col-sm-8">
                      <input
                        type="date"
                        class="form-control"
                        v-model="state.end_date"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr class="row" />

              <div class="row">
                <div class="offset-2 col-8 offset-2">
                  <div class="form-group">
                    <button class="btn btn-outline-success mr-3" type="submit">
                      Submit
                    </button>
                    <button class="btn btn-outline-danger" type="reset">
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.vrs-title {
  color: #424242;
  font-weight: bolder;
}
.main-title {
  color: #212121;
  font-weight: bolder;
  margin: 0;
  font-size: 20px;
}
.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
  overflow-y: auto;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  position: relative;
}

.modal-container {
  width: 60%;
  margin: 50px auto;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
  border: none;
  background: inherit;
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>



<script>
import { defineComponent, reactive, onMounted, onUpdated } from "vue";
import { useStore, mapGetters } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "createFinancialYear",

  components: {},

  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      start_date: "",
      end_date: "",
    });

    function createFinancialYear() {
      if (store.getters.financialYearsRouteState == 1) {
        axios("/api/v1/financialyears", {
          method: "post",
          data: state,
        })
          .then((res) => {
            this.$swal({
              position: "top-end",
              icon: "success",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
            resetForm();
          })
          .catch((err) => {
            // console.log(err);
            this.$swal({
              position: "top-end",
              icon: "error",
              text: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } else {
        axios(
          "/api/v1/financialyears/" +
            store.getters.editFinancialYear.financial_year_id,
          {
            method: "patch",
            data: state,
          }
        ).catch((err) => {
          // console.log(err.response);

          this.$swal({
            position: "top-end",
            icon: "error",
            text: err.response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });

        store.dispatch("toggleYearsModalState", {
          fymstate: false,
          type: null,
          data: null,
        });
        resetForm();
        store.dispatch("fetchFinancialYears");
      }
    }

    function closeModalFun() {
      store.dispatch("toggleYearsModalState", {
        fymstate: false,
        type: null,
        data: null,
      });
      $("body").removeClass("modal-open");
      store.dispatch("fetchFinancialYears");
    }

    function resetForm() {
      Object.assign(state, {
        start_date: "",
        end_date: "",
      });
    }

    onUpdated(() => {
      if (store.getters.editFinancialYear !== null) {
        state.start_date = store.getters.editFinancialYear.start_date;
        state.end_date = store.getters.editFinancialYear.end_date;
      } else {
        state.start_date = "";
        state.end_date = "";
      }
    });

    return {
      state,
      store,
      closeModalFun,
      createFinancialYear,
    };
  },

  computed: {
    ...mapGetters(["financialYearModal", "financialYearsRouteState"]),
  },
});
</script>