import axios from "axios";

export default {

    namespaced: true,

    state: {
    },

    getters: {

    },

    mutations: {

    },

    actions: {

        async getApproval({ commit, dispatch }, payload) {
            await axios.post('/api/v1/approvals/get', payload)
                .then(res => {
                    return Promise.resolve(res.data)
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

    }

}
