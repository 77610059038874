import axios from "axios";

export default {

    namespaced: true,

    state: {

        mpesasettings: [],
        mpesasetting: {},
        mpesab2csettings: {},
        mpesac2bsettings: {},

    },

    getters: {

        mpesasettings(state) {
            return state.mpesasettings;
        },
        mpesasetting(state) {
            return state.mpesasetting;
        },
        mpesab2csettings(state) {
            return state.mpesab2csettings;
        },
        mpesac2bsettings(state) {
            return state.mpesac2bsettings;
        }

    },

    mutations: {

        SET_MPESASETTINGS(state, mpesasettings) {
            return state.mpesasettings = mpesasettings
        },

        SET_MPESASETTING(state, mpesasetting) {
            return state.mpesasetting = mpesasetting
        },

        SET_MPESAB2CSETTINGS(state, mpesab2csettings) {
            return state.mpesab2csettings = mpesab2csettings
        },

        SET_MPESAC2BSETTINGS(state, mpesac2bsettings) {
            return state.mpesac2bsettings = mpesac2bsettings
        }

    },

    actions: {

        async fetchMpesaSettings({ commit }, params) {

            await axios.get('/api/v1/mpesa-settings', { params: params })
                .then(res => {
                    // console.log("fetchMpesaSettings == ", res);
                    commit('SET_MPESASETTINGS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchMpesaSetting({ commit }, mpesa_setting_id) {

            await axios.get(`/api/v1/mpesa-settings/${mpesa_setting_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_MPESASETTING', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchMpesab2cSettings({ commit }) {

            await axios.get(`/api/v1/mpesa-settings?type=mpesab2c`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_MPESAB2CSETTINGS', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchMpesac2bSettings({ commit }) {

            await axios.get(`/api/v1/mpesa-settings?type=mpesac2b`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_MPESAC2BSETTINGS', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createMpesaSetting({ commit, dispatch }, mpesasetting) {

            await axios.post('/api/v1/mpesa-settings', mpesasetting)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchMpesaSettings');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateMpesaSetting({ commit, dispatch }, mpesasetting) {

            await axios("/api/v1/mpesa-settings/" + mpesasetting.mpesa_config_id, {
                method: "patch",
                data: mpesasetting,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchMpesaSettings');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteMpesaSetting({ commit }, mpesa_setting_id) {

            await axios.delete(`/api/v1/mpesa-settings/${mpesa_setting_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchMpesaSettings');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
