import { ref } from "vue";
import Swal from "sweetalert2";

export default function useSweetAlert() {
	const showDialog = (
		title,
		text,
		iconType,
		confirmText,
		cancelText,
		htmlText,
		showTextArea = false,
		textAreaLabel = "Message",
		textAreaPlaceholder = "Type your message here..."
	) => {
		return new Promise((resolve, reject) => {
			// configuration for input field
			let inputConfig = {};

			if (showTextArea) {
				const { value } = Swal.fire({
					input: "textarea",
					inputLabel: textAreaLabel,
					inputPlaceholder: textAreaPlaceholder,
					inputAttributes: {
						"aria-label": textAreaPlaceholder,
					},
					showCancelButton: true,
				});

				// set the input value if provided
				inputConfig.value = value;
			}

			/* Swal.fire({
				title: title,
				text: text,
				html: htmlText,
				icon: iconType || "question",
				showCancelButton: true,
				confirmButtonText: confirmText || "Yes",
				cancelButtonText: cancelText || "No",
				confirmButtonColor: "#3085d6",
				// confirmButtonColor: "#03d87f",
				cancelButtonColor: "#f5325c",
			}).then((result) => {
				if (result.isConfirmed) {
					resolve(true); // User clicked Confirm
				} else {
					resolve(false); // User clicked Cancel or dismissed the dialog
				}
			}); */

			Swal.fire({
				title: title,
				text: text,
				html: htmlText,
				icon: iconType || "question",
				showCancelButton: true,
				confirmButtonText: confirmText || "Yes",
				cancelButtonText: cancelText || "No",
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#f5325c",
				// spread the input config into Swal.fire options
				...inputConfig, 
			}).then((result) => {
				if (result.isConfirmed) {
					resolve({
						confirmed: true,
						// return the input value if provided
						inputValue: inputConfig.value, 
					});
				} else {
					resolve({
						confirmed: false,
						inputValue: null,
					});
				}
			});
		});
	};

	return {
		showDialog,
	};
}
