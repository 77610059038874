require("./bootstrap");

import { createApp } from "vue";
import "es6-promise/auto";

import App from "./App.vue";
import Toaster from "@meforma/vue-toaster";
import RequestApproval from "./components/Common/RequestApproval.vue";
import AuthHeader from "./components/Common/AuthHeader.vue";
import FooterApp from "./components/Common/FooterApp.vue";
import FooterAuth from "./components/Common/FooterAuth.vue";
import TopBar from "./components/Common/TopBar.vue";
import TopBarCustomer from "./components/Common/TopBarCustomer.vue";
import FooterCustomer from "./components/Common/FooterCustomer.vue";
import SideBar from "./components/Common/SideBar.vue";
import SearchBar from "./components/Common/SearchBar.vue";
import SearchBarListing from "./components/Common/SearchBarListing.vue";
import ReportDownload from "./components/Common/ReportDownload.vue";
import Loading from "./components/Common/Loading.vue";
import Loader from "./components/Common/Loader.vue";
import Breadcrumb from "./components/Common/Breadcrumb.vue";
import BreadcrumbCustomer from "./components/Common/BreadcrumbCustomer.vue";
import PageTitleCustomer from "./components/Common/PageTitleCustomer.vue";
import Pagination from "./components/Common/Pagination.vue";
import Paginate from "./components/Common/Paginate.vue";
import MapLeaflet from "./components/Common/MapData/MapLeaflet.vue";
// import MapLeaflet3 from "./components/Common/MapData/MapLeaflet3.vue";
import ErrorMsg from "./components/Common/ErrorMsg.vue";
import JournalComponent from "./components/Admin/Finance/Journals/JournalsComponent.vue";
import LineItemsComponent from "./components/Admin/Finance/Shared/lineItems.vue";
import FinancialYearsModal from "./components/Admin/Finance/FinacialYear/FinancialYearModal.vue";
import Modal from "./components/Common/Modal.vue";
import UploadFile from "./components/Common/UploadFile.vue";
import ActivateMember from "./components/Common/ActivateMember.vue";
import EnableDisableUser from "./components/Common/EnableDisableUser.vue";
import AttachTeamModal from "./components/Common/Modals/AttachTeam.vue";
import ScheduleCustomerCall from "./components/Common/ScheduleCustomerCall.vue";
import ChangeUserPassword from "./components/Common/ChangeUserPassword.vue";
import ChangeSelfPassword from "./components/Common/ChangeSelfPassword.vue";
import MyDatePicker from "./components/Common/MyDatePicker.vue";
import RecordsNotFound from "./components/Common/RecordsNotFound.vue";
import CurrencyInput from "./components/Common/CurrencyInput.vue";
import DynamicTable from "./components/Common/DynamicTable.vue";
import NextOfKinPopup from "./components/Common/Modals/NextOfKinPopup.vue";
import UserBankPopup from "./components/Common/Modals/UserBankPopup.vue";
import CustomerPersonalInfo from "./components/Customer/Common/CustomerPersonalInfo.vue";
import CustomerLocationInfo from "./components/Customer/Common/CustomerLocationInfo.vue";
import ApproveMakerCheckerModal from "./components/Common/ApproveMakerCheckerModal.vue";
import RejectMakerCheckerModal from "./components/Common/RejectMakerCheckerModal.vue";
import MakerCheckerButtonComponent from "./components/Common/MakerCheckerButtonComponent.vue";
import SecuritiesModal from "./components/Common/Modals/SecuritiesModal.vue";
import { handleError } from "./utils";

/* styles */
import "./assets/styles.css";

// import VuePhoneNumberInput from "vue-phone-number-input";

// calendar
import VCalendar from "v-calendar";
import { setupCalendar } from "v-calendar";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

const app = createApp(App);

// Use v-calendar & v-date-picker components
app.use(VCalendar);

// import site routes
import router from "./services/router";
app.use(router);

// import vuex
import store from "./store/index";
app.use(store);
window.store = store;

// import axios
import VueAxios from "vue-axios";
import axios from "./services/api";
app.use(VueAxios, axios);

// import form js
import Form from "./services/Form";
window.Form = Form;

window.createToast = createToast;

// apexcharts
import VueApexCharts from "vue3-apexcharts";
app.use(VueApexCharts);

// feather icons
import VueFeatherIcon from "vue-feather-icon";
app.use(VueFeatherIcon);

app.use(Toaster, {
  position: "top-right",
});

//vue sweetalerts
const vueSweetAlertOptions = {
  confirmButtonColor: "#1761fd",
  cancelButtonColor: "#e42129",
  position: "center",
};
app.use(VueSweetalert2, vueSweetAlertOptions);

//vue sweetalerts
/* app.use(createToast, {
    position: 'bottom-right',
    transition: 'zoom'
}); */

import Multiselect from "@vueform/multiselect";
import ShowMakerCheckerVue from "./components/Admin/Administration/MakerChecker/Data/ShowMakerChecker.vue";
import ListMakerCheckersVue from "./components/Admin/Administration/MakerChecker/Data/ListMakerCheckers.vue";
import ActionButton from "./components/Common/FormElements/ActionButton.vue";
// import DisbursedLoans from "./components/Admin/DashboardComponent/DisbursedLoans.vue";
import ActivityOverview from "./components/Admin/DashboardComponent/ActivityOverview.vue";
import AccountTypeDeposits from "./components/Admin/DashboardComponent/AccountTypeDeposits.vue";
import RecentLoanApplications from "./components/Admin/DashboardComponent/RecentLoanApplications.vue";
import RecentRegisteredCustomers from "./components/Admin/DashboardComponent/RecentRegisteredCustomers.vue";
import SummaryDataCard from "./components/Admin/DashboardComponent/SummaryDataCard.vue";

// import { StatusColorDirective } from "./directives/StatusColorDirective";

app.component("multiselect", Multiselect);
app.component("get-approval", RequestApproval);
app.component("footer-app", FooterApp);
app.component("footer-auth", FooterAuth);
app.component("top-bar", TopBar);
app.component("top-bar-customer", TopBarCustomer);
app.component("footer-customer", FooterCustomer);
app.component("auth-header", AuthHeader);
app.component("side-bar", SideBar);
app.component("search-bar", SearchBar);

app.component("search-bar-listing", SearchBarListing);
app.component("report-download", ReportDownload);
app.component("loading", Loading);
app.component("loader", Loader);
app.component("pagination", Pagination);
app.component("paginate", Paginate);
app.component("breadcrumb", Breadcrumb);
app.component("breadcrumb-customer", BreadcrumbCustomer);
app.component("page-title-customer", PageTitleCustomer);
app.component("my-date-picker", MyDatePicker);
app.component("map-leaflet", MapLeaflet);
// app.component("map-leaflet3", MapLeaflet3);
app.component("error-msg", ErrorMsg);
app.component("journal-component", JournalComponent);
app.component("line-items-component", LineItemsComponent);
app.component("financial-years-modal", FinancialYearsModal);
app.component("modal", Modal);
app.component("records-not-found", RecordsNotFound);
app.component("upload-file", UploadFile);
app.component("activate-member", ActivateMember);
app.component("enable-disable-user", EnableDisableUser);
app.component("attach-team-modal", AttachTeamModal);

app.component("schedule-customer-call", ScheduleCustomerCall);
app.component("change-self-password", ChangeSelfPassword);
app.component("change-user-password", ChangeUserPassword);
app.component("currency-input", CurrencyInput);
// app.component("vue-phone-number-input", VuePhoneNumberInput);
app.component("dynamic-table", DynamicTable);
app.component("next-of-kin-popup", NextOfKinPopup);
app.component("user-bank-popup", UserBankPopup);

//referee modal
app.component("referee-modal", SecuritiesModal);
// maker checker
app.component("show-maker-checker", ShowMakerCheckerVue);
app.component("list-maker-checkers", ListMakerCheckersVue);
app.component("approve-maker-checker-modal", ApproveMakerCheckerModal);
app.component("reject-maker-checker-modal", RejectMakerCheckerModal);
app.component("maker-checker-button-component", MakerCheckerButtonComponent);

// customer
app.component("customer-personal-info", CustomerPersonalInfo);
app.component("customer-location-info", CustomerLocationInfo);

// shared form elements
app.component("action-button", ActionButton);
// app.component("disbursed-loans", DisbursedLoans);
app.component("activity-overview", ActivityOverview);
app.component("account-type-deposits", AccountTypeDeposits);
app.component("recent-loan-applications", RecentLoanApplications);
app.component("recent-registered-customers", RecentRegisteredCustomers);
app.component("summary-data-card", SummaryDataCard);

// directives
// app.directive('status-color', StatusColorDirective);

// do something with router
/* router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);

    console.log("store.state ", store.state);

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    // const currentUser = store.state.auth.currentUser;
    const currentUser = this.$auth.currentUser;

    if(requiresAuth && !currentUser) {
        return next({
            path:'/login',
            // Store the full path to redirect the user to after login
            query: {redirect: to.fullPath}
        });
    }

    if(to.path == '/login' && currentUser) {
            return next('/');
    }
    next();

}); */

/////////////////// start interceptors ///////////////
store.dispatch("loader/done");

// console.log("sessionStorage === " + sessionStorage.getItem('user'));
// console.log("vuex === " + localStorage.length);
// console.log("secure === " + localStorage.getItem('vuex'));

if (
  sessionStorage.getItem("user") === null &&
  localStorage.getItem("vuex") !== ""
) {
  localStorage.clear();
  /* localStorage.setItem('vuex', "");
    localStorage.setItem('_secure__ls__metadata', ""); */
  // location.reload();
}

// Request interceptor
window.axios.interceptors.request.use(
  function (config) {
    // Access the current route
    const currentRoute = router.currentRoute;

    if (currentRoute) {
      const currentRouteSection = currentRoute?.value.meta?.section;
      if (currentRouteSection) {
        // Append route data to headers
        config.headers["X-Current-Route-Section"] = currentRouteSection;
      }
    }

    // change loader state to pending
    if (config.showLoader) {
      store.dispatch("loader/pending");
    }

    // Do something before request is sent - add request headers
    config.headers["Accept"] = "application/json";
    // config.headers['Content-Type'] = 'application/json';
    if (sessionStorage.getItem("access_token")) {
      config.headers.Authorization =
        "Bearer " + sessionStorage.getItem("access_token");
    }
    // config.headers['Authorization'] = token;
    // log request

    return config;
  },
  function (error) {
    // change loader state to done
    if (error.config.showLoader) {
      store.dispatch("loader/done");
    }

    // show error
    createToast(error, {
      type: "error",
      position: "bottom-right",
      transition: "bounce",
      showIcon: "true",
      timeout: 6000,
    });

    // Do something with request error
    // console.log("request error === ", error);
    return Promise.reject(error);
  }
);

// Response interceptor
window.axios.interceptors.response.use(
  function (response) {
    // change loader state to done
    if (response.config.showLoader) {
      store.dispatch("loader/done");
    }

    // show success
    // console.log("response ", response);
    if (response?.data.show_message) {
      createToast(response.data.message, {
        type: "success",
        position: "bottom-right",
        transition: "slide",
        showIcon: true,
        timeout: 4000,
      });
    }

    return response;
  },
  function (error) {
    let error_response = error?.response;

    if (error_response) {
      if (error_response?.data.show_message) {
        if (sessionStorage.getItem("user")) {
          let error_message = handleError(error_response.data);
          createToast(error_message, {
            type: "error",
            position: "bottom-right",
            transition: "bounce",
            showIcon: true,
            timeout: 4000,
          });
        }
      } else {
        createToast(error_response?.statusText, {
          type: "error",
          position: "bottom-right",
          transition: "bounce",
          showIcon: true,
          timeout: 4000,
        });
      }
    }

    // change loader state to done
    if (error_response?.config.showLoader) {
      store.dispatch("loader/done");
    }

    // if response code is 401, show login screen
    if (error_response?.data.status_code == 401) {
      if (sessionStorage.getItem("user")) {
        store.dispatch("auth/frontendlogout");
      }
      localStorage.clear();
      localStorage.removeItem("vuex");
      localStorage.removeItem("_secure__ls__metadata");

      // Store the current route path in session storage
      const currentRoutePath = router.currentRoute.value.fullPath;
      // console.log("currentRoutePath === ", currentRoutePath);
      sessionStorage.setItem("redirectRoutePath", currentRoutePath);
    }

    // if response code is 419, show 2fa screen
    if (error_response?.data.status_code == 419) {
      store.dispatch("loader/done");
      window.location = "/auth/login-verification";
    }
    return Promise.reject(error_response);
  }
);
/////////////////// end interceptors ///////////////

app.mount("#app");
