const groupRequestRegistrationRoutes = [

    {

        name: 'groupRequestHome',
        path: '',
        component: () => import('./ListGroupRequests.vue'),
        meta: {
            auth: true,
            title: 'Group Requests Listing',
            breadcrumb: 'Group Requests Listing',
            permission: ['view grouprequest']
        }

    },

    {
        path: 'create',
        name: 'createGroupRequest',
        component: () => import('./CreateGroupRequest.vue'),
        meta: {
            auth: true,
            title: 'Create Group Request',
            breadcrumb: 'Create Group Request',
            permission: ['create grouprequest']
        }
    },

    {
        path: ':id',
        name: 'showGroupRequest',
        component: () => import('./ShowGroupRequest.vue'),
        meta: {
            auth: true,
            title: 'Showing Group Request',
            breadcrumb: 'Showing Group Request',
            permission: ['view grouprequest']
        }
    },

    {
        path: ':id/edit',
        name: 'editGroupRequest',
        component: () => import('./EditGroupRequest.vue'),
        meta: {
            auth: true,
            title: 'Edit Group Request',
            breadcrumb: 'Edit Group Request',
            permission: ['update grouprequest']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteGroupRequest',
        meta: {
            auth: true,
            title: 'Delete Group Request',
            permission: ['delete grouprequest']
        }
    },

];

export default groupRequestRegistrationRoutes;
