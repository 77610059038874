const recommendMessagingRoutes = [

    {

        name: 'recommendHome',
        path: '',
        component: () => import('./ListRecommends.vue'),
        meta: {
            auth: true,
            title: 'Recommends Listing',
            breadcrumb: 'Recommends Listing',
            permission: ['view recommend']
        }

    },

    {
        path: 'create',
        name: 'createRecommend',
        component: () => import('./CreateRecommend.vue'),
        meta: {
            auth: true,
            title: 'Create Recommend',
            breadcrumb: 'Create Recommend',
            permission: ['create recommend']
        }
    },

    {
        path: 'show/:id',
        name: 'showRecommend',
        component: () => import('./ShowRecommend.vue'),
        meta: {
            auth: true,
            title: 'Showing Recommend',
            breadcrumb: 'Showing Recommend',
            permission: ['view recommend']
        }
    },

    {
        path: ':id/edit',
        name: 'editRecommend',
        component: () => import('./EditRecommend.vue'),
        meta: {
            auth: true,
            title: 'Edit Recommend',
            breadcrumb: 'Edit Recommend',
            permission: ['update recommend']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteRecommend',
        meta: {
            auth: true,
            title: 'Delete Recommend',
            permission: ['delete recommend']
        }
    },

];

export default recommendMessagingRoutes;
