const teamRoutes = [
  {
    name: "teamHome",
    path: "",
    component: () => import("./ListTeams.vue"),
    meta: {
      auth: true,
      title: "Team Listing",
      breadcrumb: "Team Listing",
    },
  },
];

export default teamRoutes;
