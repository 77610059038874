import axios from "axios";

export default {

    namespaced: true,

    state: {

        permissions: [],
        permission: {}

    },

    getters: {

        permissions(state) {
            return state.permissions;
        },
        permission(state) {
            return state.permission;
        }

    },

    mutations: {

        SET_PERMISSIONS(state, permissions) {
            return state.permissions = permissions
        },

        SET_PERMISSION(state, permission) {
            return state.permission = permission
        }

    },

    actions: {

        async fetchPermissions({ commit }, params) {

            await axios.get('/api/v1/permissions', { params: params })
                .then(res => {
                    commit('SET_PERMISSIONS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchPermission({ commit }, permission_id) {

            await axios.get(`/api/v1/permissions/${permission_id}`)
                .then(res => {
                    // console.log("fetchPermission store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_PERMISSION', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createPermission({ commit, dispatch }, permission) {

            await axios.post('/api/v1/permissions', permission)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchPermissions');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updatePermission({ commit, dispatch }, permission) {

            await axios("/api/v1/permissions/" + permission.permission_id, {
                method: "patch",
                data: permission,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchPermissions');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deletePermission({ commit }, permission_id) {

            await axios.delete(`/api/v1/permissions/${permission_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchPermissions');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
