<template>

    <footer class="footer_dark">
        <div class="top_footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 mb-4 mb-lg-0 animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                        <div class="footer_logo">
                            <a href="index-5.html"><img alt="logo" src="../../assets/images/snb_hor_logo_light.png"></a>
                        </div>
                        <p>Phasellus blandit massa enim. elit id varius nunc. Lorem ipsum dolor sit amet, consectetur
                            industry.</p>
                        <ul class="contact_info contact_info_light list_none">
                            <li>
                                <span class="ti-location-pin"></span>
                                <address>Vision Plaza, Msa Rd, Nairobi, Kenya</address>
                            </li>
                            <li>
                                <span class="ti-email"></span>
                                <a href="mailto:info@snb.co.ke">info@snb.co.ke</a>
                            </li>
                            <li>
                                <span class="ti-mobile"></span>
                                <p>+ 254 720 000 000</p>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-4 col-md-6 mb-4 mb-lg-0 animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                        <h6 class="widget_title">QUICK LINKS</h6>
                        <ul class="list_none widget_links">
                            <li>
                                <router-link :to="{ name: 'about' }" exact-active-class="active">
                                About Us
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'requestdemo' }" exact-active-class="active">
                                Request For Demo
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'about' }" exact-active-class="active">
                                Portfolio
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'requestdemo' }" exact-active-class="active">
                                Clients
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'contact' }" exact-active-class="active">
                                Contact Us
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-4 col-md-6 mb-4 mb-lg-0 animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                        <h6 class="widget_title">RECENT POSTS</h6>
                        <ul class="recent_post border_bottom_dash list_none">
                            <li>
                                <div class="post_footer">
                                    <div class="post_img">
                                        <a href="#"><img src="../../assets/images/play-icon.png" alt="letest_post1" /></a>
                                    </div>
                                    <div class="post_content">
                                        <h6><a href="#">Lorem ipsum dolor sit amet, consectetur</a></h6>
                                        <p class="small m-0">April 14, 2022</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="post_footer">
                                    <div class="post_img px-2">
                                        <a href="#"><img src="../../assets/images/play-icon.png" alt="letest_post2" /></a>
                                    </div>
                                    <div class="post_content">
                                        <h6><a href="#">Lorem ipsum dolor sit amet, consectetur</a></h6>
                                        <p class="small m-0">April 14, 2022</p>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                    <!-- <div class="col-lg-4 col-md-6 animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                        <h6 class="widget_title">Get in Touch</h6>
                        <div class="field_form widget_contact">
                            <form method="post" name="enq">
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <input type="text" required="required" placeholder="Enter Name *"
                                            id="first-name" class="form-control" name="name">
                                    </div>
                                    <div class="form-group col-md-12">
                                        <input type="email" required="required" placeholder="Enter Email *" id="email"
                                            class="form-control" name="email">
                                    </div>

                                    <div class="form-group col-md-12">
                                        <textarea required="required" placeholder="Message *" id="description"
                                            class="form-control" name="message" rows="3"></textarea>
                                    </div>
                                    <div class="col-md-12">
                                        <button type="submit" title="Submit Your Message!" class="btn btn-default"
                                            id="submitButton" name="submit" value="Submit">Submit</button>
                                    </div>
                                    <div class="col-md-12">
                                        <div id="alert-msg" class="alert-msg text-center"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="bottom_footer bg-dark">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <p class="copyright m-md-0 text-center text-md-left">&copy; {{ new Date().getFullYear() }} SNB - Save N Borrow
                        </p>
                    </div>
                    <div class="col-md-6">
                        <ul class="list_none footer_social text-center text-md-right">
                            <li><a href="#"><i class="ion-social-facebook"></i></a></li>
                            <li><a href="#"><i class="ion-social-twitter"></i></a></li>
                            <li><a href="#"><i class="ion-social-googleplus"></i></a></li>
                            <li><a href="#"><i class="ion-social-youtube-outline"></i></a></li>
                            <li><a href="#"><i class="ion-social-instagram-outline"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>

</template>

<script>
    import { defineComponent } from "vue";

	export default defineComponent({

		name: "FooterCustomer",

    });
</script>
