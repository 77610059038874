export default {
    // Sets the list of manual transaction uploads
    SET_MANUAL_TRANSACTION_UPLOADS(state, payload) {
        state.manual_transaction_uploads = payload;
    },

    // Adds a new manual transaction upload
    ADD_MANUAL_TRANSACTION_UPLOAD(state, payload) {
        state.manual_transaction_uploads.data.push(payload);
    },

    // Updates a specific manual transaction upload
    UPDATE_MANUAL_TRANSACTION_UPLOAD(state, payload) {
        const index = state.manual_transaction_uploads.data.findIndex(
            (upload) => upload.id === payload.id
        );
        if (index !== -1) {
            state.manual_transaction_uploads.data.splice(index, 1, payload);
        }
    },

    // Removes a specific manual transaction upload
    REMOVE_MANUAL_TRANSACTION_UPLOAD(state, id) {
        state.manual_transaction_uploads = state.manual_transaction_uploads.data.filter(
            (upload) => upload.id !== id
        );
    },

    // Sets the current manual transaction upload
    SET_MANUAL_TRANSACTION_UPLOAD(state, payload) {
        state.manual_transaction_upload = payload;
    },

    // Clears the current manual transaction upload
    CLEAR_MANUAL_TRANSACTION_UPLOAD(state) {
        state.manual_transaction_upload = {};
    },

    // Sets the list of failed manual transactions
    SET_FAILED_MANUAL_TRANSACTIONS(state, payload) {
        state.failed_manual_transactions = payload;
    },

    // Adds a failed manual transaction
    ADD_FAILED_MANUAL_TRANSACTION(state, payload) {
        state.failed_manual_transactions.push(payload);
    },

    // Sets the list of posted manual transactions
    SET_POSTED_MANUAL_TRANSACTIONS(state, payload) {
        state.posted_manual_transactions = payload;
    },

    // Adds a posted manual transaction
    ADD_POSTED_MANUAL_TRANSACTION(state, payload) {
        state.posted_manual_transactions.push(payload);
    }
}