<template>

    <div class="flex-item text-right float-right">

        <button type="button" :class="buttonSize"
            class="btn btn-outline-success dropdown-toggle text-center"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Download Report">
            <i class="fa fa-download mr-2"></i>
            <i class="mdi mdi-chevron-down"></i>
        </button>

        <div class="dropdown-menu">

            <a class="dropdown-item thelink" @click="downloadXlsxClick">
                <i class="la la-file-excel-o text-success mr-1 la-2x icon-center icon-bigger"></i> Excel
            </a>

            <a class="dropdown-item thelink" @click="downloadPdfClick">
                <i class="la la-file-pdf-o text-danger mr-1 la-2x icon-center icon-bigger"></i> PDF
            </a>

            <a class="dropdown-item thelink" @click="printPdfClick">
                <i class="la la-file-pdf-o text-danger mr-1 la-2x icon-center icon-bigger"></i> Print
            </a>

        </div>

    </div>

</template>

<script>

import { defineComponent, ref } from "vue";

export default defineComponent({

    components: { },

    name: "ReportDownload",

    props: {

        downloadXlsxHandler: {
            type: Function,
            default: () => {},
        },
        downloadPdfHandler: {
            type: Function,
            default: () => {},
        },
        printPdfHandler: {
            type: Function,
            default: () => {},
        },
        buttonSize: {
            type: String,
            default: "btn-md",
        },

    },

    emits: [],

    setup(props, { emit }) {

        const buttonSize = ref(props.buttonSize);

        function downloadXlsxClick() {
            props.downloadXlsxHandler();
        }

        function downloadPdfClick() {
            props.downloadPdfHandler();
        }

        function printPdfClick() {
            props.printPdfHandler();
        }

        return {
            downloadXlsxClick,
            downloadPdfClick,
            printPdfClick,
            // buttonSize
        };
    },

});
</script>
