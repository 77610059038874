export default {

    namespaced: true,

    state: {

        loadingStatus: false,
        requestsPending: 0,

    },

    getters: {

        loadingStatus(state) {
            return state.loadingStatus;
        },

        requestsPending(state) {
            return state.requestsPending;
        }

    },

    actions: {

        show({ commit }) {
            commit('SHOW');
        },

        hide({ commit }) {
            commit('HIDE');
        },

        pending({ commit }) {
            // console.log("In Pending Action");
            commit('PENDING');
        },

        done({ commit }) {
            commit('DONE');
        }

    },

    mutations: {

        SHOW(state) {
            state.loadingStatus = true;
        },

        HIDE(state) {
            state.loadingStatus = false;
        },

        PENDING(state) {
            /* if (state.requestsPending === 0) {
                this.commit("loader/SHOW");
            }

            state.requestsPending++; */
            this.commit("loader/SHOW");
        },

        DONE(state) {

            if (state.requestsPending >= 1) {
                // state.requestsPending--;
                state.requestsPending = 0; /* remove later */
            }

            if (state.requestsPending <= 0) {
                this.commit("loader/HIDE");
            }

        }
    }

};
