const paymentMethodRoutes = [

    {

        name: 'paymentMethodHome',
        path: '',
        component: () => import('./ListPaymentMethods.vue'),
        meta: {
            auth: true,
            title: 'Payment Method Listing',
            breadcrumb: 'Payment Method Listing',
            permission: ['view paymentmethod']
        }

    },

];

export default paymentMethodRoutes;
