const { $for } = require("core-js");

! function (n) {

    "use strict";


    ///////////////////////////////////////////
    /*===================================*
	01. LOADING JS
	/*===================================*/
	$(window).on('load', function() {
		var preLoder = $(".preloader");
		preLoder.delay(700).fadeOut(500);
	});

	/*===================================*
	02. SMOOTH SCROLLING JS
	*===================================*/
	// Select all links with hashes
	var headerHeight = $(".header_wrap").height() - 10;
    $('a.page-scroll').on('click', function(event) {
        // On-page links
        if ( location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname ) {
          // Figure out element to scroll to
          var target = $(this.hash),
              speed= $(this).data("speed") || 800;
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top - headerHeight
            }, speed);
          }
        }
    });

	/*===================================*
	03. MENU JS
	*===================================*/
	//Main navigation scroll spy for shadow
	$(window).on('scroll', function() {
		var scroll = $(window).scrollTop();

	    if (scroll >= 50) {
	        $('header').addClass('nav-fixed');
	    } else {
	        $('header').removeClass('nav-fixed');
	    }

	});

	//Show Hide dropdown-menu Main navigation
    $(function() {
	// $( document ).ready( function () {
		$( '.dropdown-menu a.dropdown-toggler' ).on( 'click', function ( e ) {
			var $el = $( this );
			var $parent = $( this ).offsetParent( ".dropdown-menu" );
			if ( !$( this ).next().hasClass( 'show' ) ) {
				$( this ).parents( '.dropdown-menu' ).first().find( '.show' ).removeClass( "show" );
			}
			var $subMenu = $( this ).next( ".dropdown-menu" );
			$subMenu.toggleClass( 'show' );

			$( this ).parent( "li" ).toggleClass( 'show' );

			$( this ).parents( 'li.nav-item.dropdown.show' ).on( 'hidden.bs.dropdown', function ( e ) {
				$( '.dropdown-menu .show' ).removeClass( "show" );
			} );

			return false;
		});
	});

	//Hide Navbar Dropdown After Click On Links
	var navBar = $(".header_wrap");
	var navbarLinks = navBar.find(".navbar-collapse ul li a.page-scroll");

    $.each( navbarLinks, function( i, val ) {

      var navbarLink = $(this);

        navbarLink.on('click', function () {
          navBar.find(".navbar-collapse").collapse('hide');
		  $("header").removeClass("active");
        });

    });

	//Main navigation Active Class Add Remove
	$('.navbar-toggler').on('click', function() {
		$("header").toggleClass("active");
		if($('.search-overlay').hasClass('open'))
		{
			$(".search-overlay").removeClass('open');
			$(".search_trigger").removeClass('open');
		}
	});

	/*===================================*
	04. SEARCH JS
	*===================================*/

	$(".close-search").on("click", function() {
		$('.search-overlay').removeClass("open");
		$('.search_overlay').remove();
	});

	var removeClass = true;
	$(".search_trigger").click(function () {
		$(".search-overlay").after('<div class="search_overlay"></div>');
		$(".search-overlay").toggleClass('open');
		removeClass = false;
		if($('.navbar-collapse').hasClass('show'))
		{
			$(".navbar-collapse").removeClass('show');
			$(".navbar-toggler").addClass('collapsed');
			$(".navbar-toggler").attr("aria-expanded", false);
		}
	});
	$(".search-overlay").click(function() {
		removeClass = false;
	});
	$("html").click(function () {
		if (removeClass) {
			$("body").removeClass('open');
			$(".search-overlay").removeClass('open');
			 $('.search_overlay').remove();
		}
		removeClass = true;
	});

	/*===================================*
	05. SLIDER JS
	*===================================*/
	$( window ).on( "load", function() {

		$('.portfolio_slider,.carousel_slide1').each( function() {
			var $carousel = $(this);
			$carousel.owlCarousel({
				dots : $carousel.data("dots"),
				loop : $carousel.data("loop"),
				margin: $carousel.data("margin"),
				items : 1,
				autoHeight: $carousel.data("autoheight"),
				nav: $carousel.data("nav"),
				navText: ['<i class="ion-ios-arrow-back"></i>', '<i class="ion-ios-arrow-forward"></i>'],
				autoplay : $carousel.data("autoplay"),
				animateIn : $carousel.data("animate-in"),
				animateOut: $carousel.data("animate-out"),
				autoplayTimeout : $carousel.data("autoplay-timeout"),
			});
		});

		var owl = $('.owl-thumbs-slider');
		owl.owlCarousel({
			loop: false,
			items: 4,
			dots: false,
			margin: 10,
			nav: true,
			thumbs: true,
			navText: ['<i class="ion-ios-arrow-back"></i>', '<i class="ion-ios-arrow-forward"></i>'],
		});

		$('.carousel_slide2').each( function() {
			var $carousel = $(this);
			$carousel.owlCarousel({
				loop: $carousel.data("loop"),
				margin: $carousel.data("margin"),
				dots : $carousel.data("dots"),
				autoHeight: true,
				center: $carousel.data("center"),
				rewind: $carousel.data("rewind"),
				autoplay : $carousel.data("autoplay"),
				nav: $carousel.data("nav"),
				navText: ['<i class="ion-ios-arrow-back"></i>', '<i class="ion-ios-arrow-forward"></i>'],
				autoplayTimeout : $carousel.data("autoplay-timeout"),
				responsive: {
					 0: {
						 items: 1,
					 },
					 380: {
						 items: 1,
					 },
					 576: {
						 items: 2,
					 },
					 1000: {
						 items: 2,
					 },
					 1199: {
						 items: 2
					 }
				}
			});
		});

		$('.carousel_slide3').each( function() {
			 var $carousel = $(this);
			 $carousel.owlCarousel({
				 loop: $carousel.data("loop"),
				 margin: $carousel.data("margin"),
				 dots : $carousel.data("dots"),
				 autoHeight: true,
				 center: $carousel.data("center"),
				 rewind: $carousel.data("rewind"),
				 autoplay : $carousel.data("autoplay"),
				 nav: $carousel.data("nav"),
				 navText: ['<i class="ion-ios-arrow-back"></i>', '<i class="ion-ios-arrow-forward"></i>'],
				 autoplayTimeout : $carousel.data("autoplay-timeout"),
				 responsive: {
					 0: {
						 items: 1,
					 },
					 380: {
						 items: 1,
					 },
					 576: {
						 items: 2,
					 },
					 1000: {
						 items: 3,
					 },
					 1199: {
						 items: 3
					 }
				 }
			 });
		});

		$('.carousel_slide4').each( function() {
			var $carousel = $(this);
			$carousel.owlCarousel({
				dots : $carousel.data("dots"),
				loop : $carousel.data("loop"),
				margin: $carousel.data("margin"),
				autoHeight: true,
				center: $carousel.data("center"),
				rewind: $carousel.data("rewind"),
				autoplay : $carousel.data("autoplay"),
				nav: $carousel.data("nav"),
				navText: ['<i class="ion-ios-arrow-back"></i>', '<i class="ion-ios-arrow-forward"></i>'],
				autoplayTimeout : $carousel.data("autoplay-timeout"),
				responsive: {
					 0: {
						 items: 1,
					 },
					 380: {
						 items: 1,
					 },
					 576: {
						 items: 2,
					 },
					 1000: {
						 items: 3,
					 },
					 1199: {
						 items: 4
					 }
				}
			});
		});

		$('.carousel_slide5').each( function() {
			var $carousel = $(this);
			$carousel.owlCarousel({
				dots : $carousel.data("dots"),
				loop : $carousel.data("loop"),
				margin: $carousel.data("margin"),
				autoHeight: true,
				center: $carousel.data("center"),
				rewind: $carousel.data("rewind"),
				autoplay : $carousel.data("autoplay"),
				nav: $carousel.data("nav"),
				navText: ['<i class="ion-ios-arrow-back"></i>', '<i class="ion-ios-arrow-forward"></i>'],
				autoplayTimeout : $carousel.data("autoplay-timeout"),
				responsive: {
					 0: {
						 items: 1,
					 },
					 380: {
						 items: 1,
					 },
					 576: {
						 items: 3,
					 },
					 1000: {
						 items: 4,
					 },
					 1199: {
						 items: 5
					 }
				}
			});
		});

		$('.cl_logo_slider').each( function() {
			var $carousel = $(this);
			$carousel.owlCarousel({
				dots : $carousel.data("dots"),
				loop : $carousel.data("loop"),
				margin: $carousel.data("margin"),
				autoHeight: true,
				rewind: $carousel.data("rewind"),
				autoplay : $carousel.data("autoplay"),
				nav: $carousel.data("nav"),
				navText: ['<i class="ion-ios-arrow-back"></i>', '<i class="ion-ios-arrow-forward"></i>'],
				autoplayTimeout : $carousel.data("autoplay-timeout"),
				responsive: {
					 0: {
						 items: 2,
					 },
					 380: {
						 items: 3,
					 },
					 600: {
						 items: 4,
					 },
					 1000: {
						 items: 5,
					 },
					 1199: {
						 items: 6
					 }
				}
			});
		});

		$('.cl_logo_slider5').each( function() {
			var $carousel = $(this);
			$carousel.owlCarousel({
				dots : $carousel.data("dots"),
				loop : $carousel.data("loop"),
				margin: $carousel.data("margin"),
				autoHeight: true,
				rewind: $carousel.data("rewind"),
				autoplay : $carousel.data("autoplay"),
				nav: $carousel.data("nav"),
				navText: ['<i class="ion-ios-arrow-back"></i>', '<i class="ion-ios-arrow-forward"></i>'],
				autoplayTimeout : $carousel.data("autoplay-timeout"),
				responsive: {
					 0: {
						 items: 2,
					 },
					 380: {
						 items: 2,
					 },
					 600: {
						 items: 3,
					 },
					 1000: {
						 items: 4,
					 },
					 1199: {
						 items: 5
					 }
				}
			});
		});
	});

	$('.carousel_slider').each( function() {
			var $carousel = $(this);
			$carousel.owlCarousel({
				dots : $carousel.data("dots"),
				loop : $carousel.data("loop"),
				items: $carousel.data("items"),
				margin: $carousel.data("margin"),
				mouseDrag: $carousel.data("mouse-drag"),
				touchDrag: $carousel.data("touch-drag"),
				autoHeight: $carousel.data("autoheight"),
				center: $carousel.data("center"),
				nav: $carousel.data("nav"),
				rewind: $carousel.data("rewind"),
				navText: ['<i class="ion-ios-arrow-back"></i>', '<i class="ion-ios-arrow-forward"></i>'],
				autoplay : $carousel.data("autoplay"),
				animateIn : $carousel.data("animate-in"),
				animateOut: $carousel.data("animate-out"),
				autoplayTimeout : $carousel.data("autoplay-timeout"),
				smartSpeed: $carousel.data("smart-speed"),
				responsive: $carousel.data("responsive")
			});
		});

	/*===================================*
     07.COUNTDOWN JS
    *===================================*/
    $('.countdown_time').each(function() {
        var endTime = $(this).data('time');
        $(this).countdown(endTime, function(tm) {
            $(this).html(tm.strftime('<span class="countdown_box"><span class="countdown days">%D </span><span class="cd_text">Days</span></span><span class="countdown_box"><span class="countdown hours">%H</span><span class="cd_text">Hours</span></span><span class="countdown_box"><span class="countdown minutes">%M</span><span class="cd_text">Minutes</span></span><span class="countdown_box"><span class="countdown seconds">%S</span><span class="cd_text">Seconds</span></span>'));
        });
    });
	
    /*===================================*
	32. Select dropdowns
	*===================================*/
	if ($('select').length) {
        // Traverse through all dropdowns
        $.each($('select'), function (i, val) {
            var $el = $(val);

            if (!$el.val()) {
                $el.addClass('not_chosen');
            }

            $el.on('change', function () {
                if (!$el.val())
                    $el.addClass('not_chosen');
                else
                    $el.removeClass('not_chosen');
            });

        });
    }

    /*===================================*
	09. SCROLLUP JS
	*===================================*/
	$(window).scroll(function() {
		if ($(this).scrollTop() > 150) {
			$('.scrollup').fadeIn();
		} else {
			$('.scrollup').fadeOut();
		}
	});

	$(".scrollup").on('click', function (e) {
		e.preventDefault();
		$('html, body').animate({
			scrollTop: 0
		}, 600);
		return false;
	});


	/*===================================*
	10. POPUP JS
	*===================================*/
    if ($('.content-popup').length) {
        $('.content-popup').magnificPopup({
            type: 'inline',
            preloader: true,
            mainClass: 'mfp-zoom'
        });
    }

    if ($('.image_gallery').length) {
        $('.image_gallery').each(function() { // the containers for all your galleries
            $(this).magnificPopup({
                delegate: 'a', // the selector for gallery item
                type: 'image',
                gallery: {
                    enabled:true
                },
                zoom: {
                    enabled: true,
                    duration: 300, // don't foget to change the duration also in CSS
                    opener: function(element) {
                        return element.find('img');
                    }
                }
            });
        });
    }

    if ($('.popup-ajax').length) {
        $(document).ready(function() {
            $('.popup-ajax').magnificPopup({
                type: 'ajax',
            });
        });
    }

    if ($('.image_popup').length) {
        $('.portfolio_item .image_popup').on('click', function () {
            $(this).find('.link_container').magnificPopup('open');
        });
    }

    if ($('.link_container').length) {
        $('.link_container').each(function () {
            $(this).magnificPopup({
                delegate: '.image_popup',
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        });
    }

	/*==============================================================
    12. FIT VIDEO JS
    ==============================================================*/
    if ($(".fit-videos").length > 0){
        $(".fit-videos").fitVids();
    }

    /*===================================*
	13. ANIMATION JS
	*===================================*/
	$(function() {

		function ckScrollInit(items, trigger) {
			items.each(function() {
				var ckElement = $(this),
					AnimationClass = ckElement.attr('data-animation'),
					AnimationDelay = ckElement.attr('data-animation-delay');

				ckElement.css({
					'-webkit-animation-delay': AnimationDelay,
					'-moz-animation-delay': AnimationDelay,
					'animation-delay': AnimationDelay,
					opacity: 0
				});

				var ckTrigger = (trigger) ? trigger : ckElement;

				ckTrigger.waypoint(function() {
					ckElement.addClass("animated").css("opacity", "1");
					ckElement.addClass('animated').addClass(AnimationClass);
				}, {
					triggerOnce: true,
					offset: '90%',
				});
			});
		}

		ckScrollInit($('.animation'));
		ckScrollInit($('.staggered-animation'), $('.staggered-animation-wrap'));

	});

    /*===================================*
	14. BACKGROUND IMAGE JS
	*===================================*/
	/*data image src*/
	$(".background_bg").each(function() {
		var attr = $(this).attr('data-img-src');
		if (typeof attr !== typeof undefined && attr !== false) {
			$(this).css('background', 'url(' + attr + ') center center/cover');
		}
	});

    /*===================================*
	23. COUNTER JS
	*===================================*/
    if ($(".counter").length > 0){
        $('.counter').counterUp({
            time: 1500
        });
    }

	/* $(function() {
		$("#b_color-default, #b_color_rgb, #b_color_hsl").colorpicker();
	}); */

	if ($("#b_color-default").length > 0){
		// $("#b_color-default").colorpicker();
		$("#b_color_rgb_primary").colorpicker();
		$("#b_color_rgb_secondary").colorpicker();
		// $("#b_color-default, #b_color_rgb, #b_color_hsl").colorpicker();
	}


    /*===================================*
	25. PARALLAX JS
	*===================================*/
	/* $(window).on('load', function() {
        $('.parallax_bg').parallaxBackground();
	}); */

	/*===================================*
	26. ONLOAD POPUP JS
	*===================================*/
	$(window).on('load',function(){
		setTimeout(function() {
			$("#onload-popup").modal('show', {}, 500)
		}, 3000);
	});

    /*===================================*
	29. TAB SLIDE JS
	*===================================*/
	if ($(".sliding_tab li a").length > 0){
		if ($(window).width() >= 768){
			$(".sliding_tab").append('<li class="tab_bg"></li>')
			$('.sliding_tab li:first-child a').addClass('active_hover');
			var a = $(".sliding_tab li a.active_hover").position().left,
			i = $(".sliding_tab li a.active_hover").css("width"),
			k = $(".sliding_tab li a.active_hover").css("height");
			$(".tab_bg").css({
				left: a,
				width: i,
				height: k
		})
		$(".sliding_tab li a").on("click", function() {
			$(".sliding_tab li a.active_hover").removeClass("active_hover"),
			$(this).closest('.sliding_tab li a').addClass("active_hover")
			var t = $(".sliding_tab li a.active_hover").position().left,
				w = $(".sliding_tab li a.active_hover").css("width"),
				k = $(".sliding_tab li a.active_hover").css("height");
			$(".tab_bg").css({
				left: t,
				width: w,
				height: k
			})
		})
		}
	}

    /*===================================*
	31.List Grid JS
	*===================================*/
	$('.shorting_icon').on('click',function(e) {
		if ($(this).hasClass('grid')) {
			$('.shop_container').removeClass('list').addClass('grid');
			$(this).addClass('active').siblings().removeClass('active');
		}
		else if($(this).hasClass('list')) {
			$('.shop_container').removeClass('grid').addClass('list');
			$(this).addClass('active').siblings().removeClass('active');
		}
	});

    /*===================================*
	35. Scroll To Fixed Item Js
	*===================================*/
	$('.scroll_wrapper').after('<div class="limit_box"></div>');
	var fsi = $(".fixed_scroll_item");
	if ($(fsi).length > 0){
		$(fsi).scrollToFixed({
			minWidth: fsi.data('minwidth-fixed'),
			marginTop: fsi.data('margintop'),
			removeOffsets: true,
			limit: function () {
				var limit = $(".limit_box").offset().top - fsi.innerHeight() - 100;
				return limit;
			}
		});
	}
    ///////////////////////////////////////////

    if ($(".equalheight").length) {
        $('.equalheight').matchHeight();
      }

    function t() {
        n(window).width() < 1025 ? n("body").addClass("enlarge-menu") : n("body").removeClass("enlarge-menu")
    }

    function a() {
        n(document).ready(function () {
            n("#AllCompo").autocomplete({
                source: ["Forms", "Tables", "Charts", "Icones", "Maps"],
                minLength: 0,
                scroll: !0
            }).focus(function () {
                n(this).autocomplete("search", "")
            })
        })
    }! function () {
        if (0 != n("#Dash_Date").length) {
            var i = n("#Dash_Date"),
                t = moment(),
                a = moment();
            i.daterangepicker({
                startDate: t,
                endDate: a,
                opens: "left",
                applyClass: "btn btn-sm btn-primary",
                cancelClass: "btn btn-sm btn-secondary",
                ranges: {
                    Today: [moment(), moment()],
                    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
                    "Last 7 Days": [moment().subtract(6, "days"), moment()],
                    "Last 30 Days": [moment().subtract(29, "days"), moment()],
                    "This Month": [moment().startOf("month"), moment().endOf("month")],
                    "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
                }
            }, e), e(t, a, "")
        }

        function e(t, a, e) {
            var n = "",
                s = "";
            a - t < 100 || "Today" == e ? (n = "Today:", s = t.format("MMM D")) : "Yesterday" == e ? (n = "Yesterday:", s = t.format("MMM D")) : s = t.format("MMM D") + " - " + a.format("MMM D"), i.find("#Select_date").html(s), i.find("#Day_Name").html(n)
        }
    }(), n(".metismenu").metisMenu(), n(window).resize(function () {
        t()
    }), n(".button-menu-mobile").on("click", function (t) {
        t.preventDefault(), n("body").toggleClass("enlarge-menu")
    }), t(), n('[data-toggle="tooltip"]').tooltip(), n(".main-icon-menu .nav-link").on("click", function (t) {
        n("body").removeClass("enlarge-menu"), t.preventDefault(), n(this).addClass("active"), n(this).siblings().removeClass("active"), n(".main-menu-inner").addClass("active");
        var a = n(this).attr("href");
        n(a).addClass("active"), n(a).siblings().removeClass("active")
    }), n(".leftbar-tab-menu a, .left-sidenav a").each(function () {
        var t = window.location.href.split(/[?#]/)[0];
        if (this.href == t) {
            n(this).addClass("active"), n(this).parent().addClass("active"), n(this).parent().parent().addClass("in"), n(this).parent().parent().addClass("mm-show"), n(this).parent().parent().parent().addClass("mm-active"), n(this).parent().parent().prev().addClass("active"), n(this).parent().parent().parent().addClass("active"), n(this).parent().parent().parent().parent().addClass("mm-show"), n(this).parent().parent().parent().parent().parent().addClass("mm-active");
            var a = n(this).closest(".main-icon-menu-pane").attr("id");
            n("a[href='#" + a + "']").addClass("active")
        }
    }), feather.replace(), a(), n(".navigation-menu a").each(function () {
        var t = window.location.href.split(/[?#]/)[0];
        this.href == t && (n(this).parent().addClass("active"), n(this).parent().parent().parent().addClass("active"), n(this).parent().parent().parent().parent().parent().addClass("active"))
    }), n(".navbar-toggle").on("click", function (t) {
        n(this).toggleClass("open"), n("#navigation").slideToggle(400)
    }), n(".navigation-menu>li").slice(-2).addClass("last-elements"), n('.navigation-menu li.has-submenu a[href="#"]').on("click", function (t) {
        n(window).width() < 992 && (t.preventDefault(), n(this).parent("li").toggleClass("open").find(".submenu:first").toggleClass("open"))
    }), Waves.init()
}(jQuery);
