import clientCustomerSavingsDepositsDepositsRoutes from './Deposits/clientCustomerSavingsDepositsDepositsModuleRoutes';
import clientCustomerSavingsDepositsSharesRoutes from './Shares/clientCustomerSavingsDepositsSharesModuleRoutes';
import clientCustomerSavingsDepositsTransfersRoutes from './Transfers/clientCustomerSavingsDepositsTransfersModuleRoutes';

import Base from '../../Common/Base.vue';

const clientCustomerSavingsDepositsModuleRoutes = [

    {

        path: '',
        name: 'clientCustomerSavingsDepositsHome',
        component: () => import('./HomeSavingsDeposits.vue'),
        meta: {
            auth: true,
            title: 'Savings & Deposits',
            breadcrumb: 'Savings & Deposits',
            permission: []
        },

    },

    {

        path: 'my-deposits',
        name: 'clientCustomerSavingsDepositsDepositsHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'My Deposits',
            breadcrumb: 'My Deposits',
            permission: ['view customer']
        },

        children: clientCustomerSavingsDepositsDepositsRoutes

    },

    {

        path: 'my-transfers',
        name: 'clientCustomerSavingsDepositsTransfersHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'My Transfers',
            breadcrumb: 'My Transfers',
            permission: ['view customer']
        },

        children: clientCustomerSavingsDepositsTransfersRoutes

    },

    {

        path: 'my-shares',
        name: 'clientCustomerSavingsDepositsSharesHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'My Shares',
            breadcrumb: 'My Shares',
            permission: ['view customer']
        },

        children: clientCustomerSavingsDepositsSharesRoutes

    },


];

export default clientCustomerSavingsDepositsModuleRoutes;
