import axios from "axios";

export default {

    namespaced: true,

    state: {

        groups: [],
        group: {}

    },

    getters: {

        groups(state) {
            return state.groups;
        },
        group(state) {
            return state.group;
        }

    },

    mutations: {

        SET_GROUPS(state, groups) {
            return state.groups = groups
        },

        SET_GROUP(state, group) {
            return state.group = group
        }

    },

    actions: {

        async fetchCustomerGroups({ commit }, params) {

            await axios.get('/api/v1/customerdata/groups', { params: params })
                .then(res => {
                    commit('SET_GROUPS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchGroups({ commit }, params) {

            await axios.get('/api/v1/groupsdata', { params: params })
                .then(res => {
                    commit('SET_GROUPS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchGroup({ commit }, group_id) {

            await axios.get(`/api/v1/groupsdata/${group_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_GROUP', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createGroup({ commit, dispatch }, group) {

            await axios.post('/api/v1/groupsdata', group)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchGroups');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateGroup({ commit, dispatch }, group) {

            await axios("/api/v1/groupsdata/" + group.customer_id, {
                method: "patch",
                data: group,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchGroups');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteGroup({ commit }, group_id) {

            await axios.delete(`/api/v1/groupsdata/${group_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchGroups');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
