const makerCheckerAdministrationRoutes = [

    {

        name: 'makerCheckerHome',
        path: '',
        component: () => import('./ListMakerCheckers.vue'),
        meta: {
            auth: true,
            title: 'Maker Checker Listing',
            breadcrumb: 'Maker Checker Listing',
            permission: ['view makerchecker']
        }

    },

    /* {
        name: 'createMakerChecker',
        path: 'create',
        component: () => import('./CreateMakerChecker.vue'),
        meta: {
            auth: true,
            title: 'Create Maker Checker',
            breadcrumb: 'Create Maker Checker',
            permission: ['create makerchecker']
        }
    }, */

    {
        path: ':id',
        name: 'showMakerChecker',
        component: () => import('./ShowMakerChecker.vue'),
        meta: {
            auth: true,
            title: 'Showing Maker Checker',
            breadcrumb: 'Showing Maker Checker',
            permission: ['view makerchecker']
        }
    },

    /* {
        path: ':id/edit',
        name: 'editMakerChecker',
        component: () => import('./EditMakerChecker.vue'),
        meta: {
            auth: true,
            title: 'Edit Maker Checker',
            breadcrumb: 'Edit Maker Checker',
            permission: ['update makerchecker']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteMakerChecker',
        meta: {
            auth: true,
            title: 'Delete Maker Checker',
            permission: ['delete makerchecker']
        }
    }, */

];

export default makerCheckerAdministrationRoutes;
