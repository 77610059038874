const pendingTreasuryRoutes = [
	{
		name: "pendingTreasuryHome",
		path: "",
		component: () => import("./ListPendingTreasury.vue"),
		meta: {
			auth: true,
			title: "Pending Items Listing",
			breadcrumb: "Pending Items Listing",
			permission: ["view makerchecker"],
		},
	},

	{
		path: ":id",
		name: "showPendingTreasury",
		component: () => import("./ShowPendingTreasury.vue"),
		meta: {
			auth: true,
			title: "Showing Pending Item",
			breadcrumb: "Showing Pending Item",
			permission: ["view makerchecker"],
		},
	},
];

export default pendingTreasuryRoutes;
