import axios from "axios";

export default {

    namespaced: true,

    state: {

        taxes: [],
        tax: {}

    },

    getters: {

        taxes(state) {
            return state.taxes;
        },
        tax(state) {
            return state.tax;
        }

    },

    mutations: {

        SET_TAXES(state, taxes) {
            return state.taxes = taxes
        },

        SET_TAX(state, tax) {
            return state.tax = tax
        }

    },

    actions: {

        async fetchtaxes({ commit }, params) {

            await axios.get('/api/v1/taxes', { params: params })
                .then(res => {
                    commit('SET_TAXES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchtax({ commit }, tax_id) {
            await axios.get(`/api/v1/taxes/${tax_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    commit('SET_TAX', res.data.data);
                }
            }).catch(err => {
                return Promise.reject(err);
            })
        },

        async createtax({ commit, dispatch }, tax) {
            await axios.post('/api/v1/taxes', tax);
        },

        async updatetax({ commit, dispatch }, tax) {
            await axios("/api/v1/taxes/" + tax.tax_id, {method: "patch",data: tax,});
        },

        async deletetax({ commit }, tax_id) {
            await axios.delete(`/api/v1/taxes/${tax_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchtaxes');
                }
            }).catch(err => {
                return Promise.reject(err);
            });
        }

    }

}
