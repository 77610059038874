import axios from "axios";

export default {

    namespaced: true,

    state: {

        loanstatuses: [],
        loanstatus: {}

    },

    getters: {

        loanstatuses(state) {
            return state.loanstatuses;
        },
        loanstatus(state) {
            return state.loanstatus;
        }

    },

    mutations: {

        SET_lOAN_STATUSES(state, loanstatuses) {
            return state.loanstatuses = loanstatuses
        },

        SET_lOAN_STATUS(state, loanstatus) {
            return state.loanstatus = loanstatus
        }

    },

    actions: {

        async fetchloanstatuses({ commit }, params) {

            await axios.get('/api/v1/loan-status', { params: params })
                .then(res => {
                    commit('SET_lOAN_STATUSES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchloanstatus({ commit }, loan_status_id) {

            await axios.get(`/api/v1/loan-status/${loan_status_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_lOAN_STATUS', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createloanstatus({ commit, dispatch }, loanstatus) {

            await axios.post('/api/v1/loan-status', loanstatus)
                .then(res => {
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateloanstatus({ commit, dispatch }, loanstatus) {

            await axios("/api/v1/loan-status/" + loanstatus.loan_status_id, {
                method: "patch",
                data: loanstatus,
            }).then(res => {
             
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteloanstatus({ commit }, loan_status_id) {

            await axios.delete(`/api/v1/loan-status/${loan_status_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchloanstatuses');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
