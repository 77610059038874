const loanAppraisalRoutes = [

    {

        name: 'financingfeeHome',
        path: '',
        component: () => import('./ListLoanDocumentationFee.vue'),
        meta: {
            auth: true,
            title: 'Financing Fee Listing',
            breadcrumb: 'Financing Fee Listing',
            permission: ['view documentation-fee']
        }


    },
    {
        path: 'create',
        name: 'createfinancingfee',
        component: () =>
            import('./CreateEditDocFee.vue'),
        meta: {
            auth: true,
            title: 'Create Financing Fee',
            breadcrumb: 'Create Financing Fee',
            permission: ['create documentation-fee']
        }
    },

    {
        path: ':id/edit',
        name: 'editfinancingfee',
        component: () =>
            import('./CreateEditDocFee.vue'),
        meta: {
            auth: true,
            title: 'Edit Financing Fee',
            breadcrumb: 'Edit Financing Fee',
            permission: ['update documentation-fee']
        }
    },


];

export default loanAppraisalRoutes;
