const makerCheckerSectionUserLevelAdministrationRoutes = [

    {

        name: 'makerCheckerSectionUserLevelHome',
        path: '',
        component: () => import('./ListMakerCheckerSectionUserLevels.vue'),
        meta: {
            auth: true,
            title: 'Maker Checker User Level Listing',
            breadcrumb: 'Maker Checker User Level Listing',
            permission: ['view makercheckersectionuserlevel']
        }

    },

    {
        name: 'createMakerCheckerSectionUserLevel',
        path: 'create',
        component: () => import('./CreateMakerCheckerSectionUserLevel.vue'),
        meta: {
            auth: true,
            title: 'Create Maker Checker User Level',
            breadcrumb: 'Create Maker Checker User Level',
            permission: ['create makercheckersectionuserlevel']
        }
    },

    {
        path: ':id',
        name: 'showMakerCheckerSectionUserLevel',
        component: () => import('./ShowMakerCheckerSectionUserLevel.vue'),
        meta: {
            auth: true,
            title: 'Showing Maker Checker User Level',
            breadcrumb: 'Showing Maker Checker User Level',
            permission: ['view makercheckersectionuserlevel']
        }
    },

    {
        path: ':id/edit',
        name: 'editMakerCheckerSectionUserLevel',
        component: () => import('./EditMakerCheckerSectionUserLevel.vue'),
        meta: {
            auth: true,
            title: 'Edit Maker Checker User Level',
            breadcrumb: 'Edit Maker Checker User Level',
            permission: ['update makercheckersectionuserlevel']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteMakerCheckerSectionUserLevel',
        meta: {
            auth: true,
            title: 'Delete Maker Checker User Level',
            permission: ['delete makercheckersectionuserlevel']
        }
    },

];

export default makerCheckerSectionUserLevelAdministrationRoutes;
