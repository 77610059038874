<template>
  <footer class="footer text-center text-sm-left">
    &copy; {{ new Date().getFullYear() }}
    {{ companyColor ? companyColor?.company_name_text : "SNB - Save N Borrow" }}
    <span class="d-none d-sm-inline-block float-right">
      <i class="mdi mdi-calendar text-info"></i> Pendo Media
    </span>
  </footer>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterApp",

  props: {
    companyColor: {
      type: Object,
      default: null,
    },
  },
});
</script>
