<template>
  <div class="row">
    <h6 v-if="table_title" :class="title_class" class="dynamic-table-title">
      {{ table_title }}
    </h6>
    <div class="table-container" style="max-height: 450px; overflow-y: auto">
      <table class="table dynamic-table table-bordered mb-0">
        <thead>
          <tr>
            <th v-for="(item, index) in table_headers" :key="index">
              {{ item }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in table_data" :key="index">
            <td v-for="(col, index) in item" :key="index">
              {{ col }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DynamicTable",

  props: {
    tableData: {
      type: Array,
      required: true,
    },
    tableTitle: {
      type: String,
      required: false,
    },
    titleClass: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    let table_headers = Object.keys(props.tableData[0]);

    return {
      table_data: props.tableData,
      table_headers,
      table_title: props.tableTitle,
      title_class: props.titleClass,
    };
  },
});
</script>
<style>
.table-container thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  z-index: 1;
}
</style>

