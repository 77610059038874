import Base from '../Common/Base.vue';
import clientCustomerGroupModuleRoutes from './Groups/clientCustomerGroupModuleRoutes';
import clientCustomerRecommendModuleRoutes from './Recommends/clientCustomerRecommendModuleRoutes';
import clientCustomerSavingsDepositsModuleRoutes from './SavingsDeposits/clientCustomerSavingsDepositsModuleRoutes';
import clientCustomerLoanModuleRoutes from './Loans/clientCustomerLoanModuleRoutes';

const clientCustomerModuleRoutes = [

    {

        path: '',
        name: 'clientCustomerHome',
        component: () => import('../../components/Customer/CustomerDashboard'),
        meta: {
            auth: true,
            title: 'Dashboard',
            breadcrumb: 'Dashboard',
            permission: ['view customer']
        },

    },

    {
        path: 'profile',
        name: 'clientCustomerProfile',
        component: () =>
            import ('../../components/Customer/CustomerProfile'),
        meta: {
            auth: true,
            title: 'My Profile',
            breadCrumb: 'My Profile',
            permission: ['view customer']
        }
    },

    {

        path: 'recommendations',
        name: 'clientCustomerRecommends',
        component: Base,
        meta: {
            auth: true,
            title: 'Recommendations',
            breadcrumb: 'Recommendations',
            permission: ['view customer']
        },

        children: clientCustomerRecommendModuleRoutes

    },

    {

        path: 'loans',
        name: 'clientCustomerLoans',
        component: Base,
        meta: {
            auth: true,
            title: 'Loans',
            breadcrumb: 'Loans',
            permission: ['view customer']
        },

        children: clientCustomerLoanModuleRoutes

    },

    {

        path: 'groups',
        name: 'clientCustomerGroups',
        component: Base,
        meta: {
            auth: true,
            title: 'Groups',
            breadcrumb: 'Groups',
            permission: ['view customer']
        },

        children: clientCustomerGroupModuleRoutes

    },

    {

        path: 'savings-deposits',
        name: 'clientCustomerSavingsDepositsHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Savings & Deposits',
            breadcrumb: 'Savings & Deposits',
            permission: ['view customer']
        },

        children: clientCustomerSavingsDepositsModuleRoutes

    },

];

export default clientCustomerModuleRoutes;
