const depositSettingRoutes = [

    {

        name: 'depositSettingHome',
        path: '',
        component: () =>
            import ('./ListDepositSettings.vue'),
        meta: {
            auth: true,
            title: 'Deposit Settings Listing',
            breadcrumb: 'Deposit Settings Listing',
            permission: ['view depositsetting']
        }

    },

    {
        path: 'create',
        name: 'createDepositSetting',
        component: () =>
            import ('./CreateEditDepositSetting.vue'),
        meta: {
            auth: true,
            title: 'Create Deposit Setting',
            breadcrumb: 'Create Deposit Setting',
            permission: ['create depositsetting']
        }
    },

    {
        path: ':id',
        name: 'showDepositSetting',
        component: () =>
            import ('./ShowDepositSetting.vue'),
        meta: {
            auth: true,
            title: 'Showing Deposit Setting',
            breadcrumb: 'Showing Deposit Setting',
            permission: ['view depositsetting']
        }
    },

    {
        path: ':id/edit',
        name: 'editDepositSetting',
        component: () =>
            import ('./CreateEditDepositSetting.vue'),
        meta: {
            auth: true,
            title: 'Edit Deposit Setting',
            breadcrumb: 'Edit Deposit Setting',
            permission: ['update depositsetting']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteDepositSetting',
        meta: {
            auth: true,
            title: 'Delete Deposit Setting',
            permission: ['delete depositsetting']
        }
    },

];

export default depositSettingRoutes;
