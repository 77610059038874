import axios from "axios";

export default {

    namespaced: true,

    state: {

        nextofkins: [],
        nextofkin: {}

    },

    getters: {

        nextofkins(state) {
            return state.nextofkins;
        },
        nextofkin(state) {
            return state.nextofkin;
        }

    },

    mutations: {

        SET_NEXT_OF_KINS(state, nextofkins) {
            return state.nextofkins = nextofkins
        },

        SET_NEXT_OF_KIN(state, nextofkin) {
            return state.nextofkin = nextofkin
        },

        ADD_NEXT_OF_KIN(state, nextofkin) {
            // return state.nextofkin = nextofkin
            return state.nextofkins.push(nextofkin);
        }

    },

    actions: {

        async fetchNextOfKinsData({ commit }, params) {

            await axios.get('/api/v1/nextofkinsdata', { params: params })
                .then(res => {
                    commit('SET_NEXT_OF_KINS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchNextOfKinData({ commit }, nextofkin_id) {

            await axios.get(`/api/v1/nextofkinsdata/${nextofkin_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_NEXT_OF_KIN', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createNextOfKinData({ commit, dispatch }, nextofkin) {

            await axios.post('/api/v1/nextofkinsdata', nextofkin)
                .then(res => {
                    if (res.statusText === 'OK') {
                        // dispatch('fetchNextOfKins');
                        // console.log("res.data.data", res.data.data);
                        commit('ADD_NEXT_OF_KIN', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateNextOfKinData({ commit, dispatch }, nextofkin) {

            // console.log("nextofkin dataa***** ", nextofkin);
            await axios("/api/v1/nextofkinsdata/" + nextofkin.next_of_kin_id, {
                method: "patch",
                data: nextofkin,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchNextOfKins');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteNextOfKinData({ commit }, nextofkin_id) {

            await axios.delete(`/api/v1/nextofkinsdata/${nextofkin_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchNextOfKins');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }


}
