<template>
  <div class="card-body bg-light-alt text-center">
    <span class="text-muted d-none d-sm-inline-block">
      &copy; {{ new Date().getFullYear() }} 
      {{ companyColor ? companyColor?.company_name_text : "SNB - Save N Borrow" }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterAuth",

  props: {
    companyColor: {
      type: Object,
      default: null,
    },
  },
});
</script>
