const emailMessageTemplateMasterAdministrationRoutes = [

    {

        name: 'emailTemplateMasterHome',
        path: '',
        component: () => import('./ListEmailTemplatesMaster.vue'),
        meta: {
            auth: true,
            title: 'Email Templates Master Listing',
            breadcrumb: 'Email Templates Master Listing',
            permission: ['view messagetemplatemaster']
        }

    },

    {
        path: 'create',
        name: 'createEmailTemplateMaster',
        component: () => import('./CreateEmailTemplateMaster.vue'),
        meta: {
            auth: true,
            title: 'Create Email Template Master',
            breadcrumb: 'Create Email Template Master',
            permission: ['create messagetemplatemaster']
        }
    },

    {
        path: ':id',
        name: 'showEmailTemplateMaster',
        component: () => import('./ShowEmailTemplateMaster.vue'),
        meta: {
            auth: true,
            title: 'Showing Email Template Master',
            breadcrumb: 'Showing Email Template Master',
            permission: ['view messagetemplatemaster']
        }
    },

    {
        path: ':id/edit',
        name: 'editEmailTemplateMaster',
        component: () => import('./EditEmailTemplateMaster.vue'),
        meta: {
            auth: true,
            title: 'Edit Email Template Master',
            breadcrumb: 'Edit Email Template Master',
            permission: ['update messagetemplatemaster']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteEmailTemplateMaster',
        meta: {
            auth: true,
            title: 'Delete Email Template Master',
            permission: ['delete messagetemplatemaster']
        }
    },

];

export default emailMessageTemplateMasterAdministrationRoutes;
