<template>
  <modal v-show="showModal" @close="toggleNextOfKinPopup">
    <template v-slot:header>
      <span v-if="!isEdit && !isShow"
        >Add Next Of Kin Record For Member - {{ userData.full_name }}</span
      >
      <span v-if="isEdit && !isShow"
        >Editing Next Of Kin Record For Member - {{ userData.full_name }}</span
      >
      <span v-if="isEdit && isShow"
        >Showing Next Of Kin Record For Member - {{ userData.full_name }}</span
      >
    </template>

    <template v-slot:body>
      <!-- {{ model }} -->

      <!-- nextOfKinData == {{ nextOfKinData }}<br> -->
      <!-- isEdit == {{ isEdit }}<br> -->

      <div class="card">
        <div class="card-body">
          <form @submit.prevent="onSubmitted">
            <div class="row">
              <!-- first name -->
              <div class="col-lg-4 col-md-4">
                <div class="form-outline">
                  <label class="form-label" for="first_name">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >First Name</span
                    >
                  </label>
                  <input
                    class="form-control mt-n3"
                    :class="{
                      'form-control-danger': model.first_name.$invalid,
                    }"
                    v-model="model.first_name.$model"
                    type="text"
                    :disabled="isShow"
                  />

                  <error-msg :property="model.first_name" />

                  <input
                    class="form-control"
                    name="customer_id"
                    :value="
                      nextOfKinData ? nextOfKinData.nextofkinable_id : undefined
                    "
                    type="hidden"
                  />

                  <input
                    class="form-control"
                    name="user_type"
                    :value="
                      nextOfKinData
                        ? nextOfKinData.nextofkinable_type
                        : undefined
                    "
                    type="hidden"
                  />

                  <input
                    class="form-control"
                    name="next_of_kin_id"
                    :value="
                      nextOfKinData ? nextOfKinData.next_of_kin_id : undefined
                    "
                    type="hidden"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-4">
                <div class="form-outline">
                  <label class="form-label" for="last_name">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >Last Name</span
                    >
                  </label>
                  <input
                    class="form-control mt-n3"
                    :class="{
                      'form-control-danger': model.last_name.$invalid,
                    }"
                    v-model="model.last_name.$model"
                    type="text"
                    :disabled="isShow"
                  />

                  <error-msg :property="model.last_name" />
                </div>
              </div>

              <!-- ID No -->
              <div class="col-lg-4 col-md-4">
                <div class="form-outline">
                  <label class="form-label" for="id_no">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >ID No</span
                    >
                  </label>
                  <input
                    class="form-control mt-n3"
                    :class="{ 'form-control-danger': model.id_no.$invalid }"
                    v-model="model.id_no.$model"
                    type="text"
                    :disabled="isShow"
                  />

                  <error-msg :property="model.id_no" />
                </div>
              </div>

              <div class="col-lg-4 col-md-4">
                <div class="form-outline">
                  <label class="form-label" for="percentage">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >Percentage</span
                    >
                  </label>
                  <input
                    class="form-control mt-n3"
                    :class="{
                      'form-control-danger': model.percentage.$invalid,
                    }"
                    v-model="model.percentage.$model"
                    type="text"
                    :disabled="isShow"
                  />

                  <error-msg :property="model.percentage" />
                </div>
              </div>

              <div class="col-lg-4 col-md-4">
                <div class="form-outline">
                  <label class="form-label" for="email">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >Email</span
                    >
                  </label>
                  <input
                    class="form-control mt-n3"
                    :class="{ 'form-control-danger': model.email.$invalid }"
                    v-model="model.email.$model"
                    type="text"
                    :disabled="isShow"
                  />

                  <error-msg :property="model.email" />
                </div>
              </div>

              <div class="col-lg-4 col-md-4">
                <!--- DOB -->
                <div
                  class="form-outline"
                  :class="{ 'has-error': model.date_of_birth.$invalid }"
                >
                  <label class="form-label" for="date_of_birth">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2">
                      DOB
                    </span>
                  </label>
                  <input
                    class="form-control mt-n3"
                    type="date"
                    v-model="model.date_of_birth.$model"
                    placeholder="Birthday ('day'/'month'/'year')"
                    :isDisabled="isShow"
                  />
                  <error-msg :property="model.date_of_birth" />
                </div>
              </div>

              <div class="col-lg-4 col-md-4">
                <div class="form-group">  
                    <div class="form-check-inline my-1">
                      <div class="custom-controlx custom-radiox">
                        <input
                          type="radio"
                          id="male"
                          name="gender"
                          :disabled="isShow"
                          v-model="model.gender.$model"
                          value="Male"
                          class="custom-control-inputx mr-2"
                          style="z-order: 9999"
                        />
                        <label class="custom-control-labelx" for="male"
                          >Male</label
                        >
                      </div>
                    </div>
                    <div class="form-check-inline my-1">
                      <div class="custom-controlx custom-radiox">
                        <input
                          type="radio"
                          id="female"
                          name="gender"
                          :disabled="isShow"
                          v-model="model.gender.$model"
                          value="Female"
                          class="custom-control-inputx mr-2"
                          style="z-order: 9999"
                        />
                        <label class="custom-control-labelx" for="female"
                          >Female</label
                        >
                      </div>
                    </div> 
                </div>

                <!-- <div class="form-group row">
                                <label for="gender" class="col-sm-4 col-form-label text-right">
                                    Gender
                                </label>
                                <div class="col-sm-8">
    
    
                                    <div class="form-check-inline my-1">
                                        <div class="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                id="male"
                                                name="gender" :disabled="isShow"
                                                v-model="model.gender.$model"
                                                value="Male"
                                                class="custom-control-input"
                                            />
                                            <label class="custom-control-label" for="male">Male</label>
                                        </div>
                                    </div>
                                    <div class="form-check-inline my-1">
                                        <div class="custom-control custom-radio">
                                            <input
                                                type="radio"
                                                id="female"
                                                name="gender" :disabled="isShow"
                                                v-model="model.gender.$model"
                                                value="Female"
                                                class="custom-control-input"
                                            />
                                            <label class="custom-control-label" for="female">Female</label>
                                        </div>
                                    </div>
    
                                </div>
                            </div> -->
              </div>

              <!-- <div class="col-lg-6">
    
                            <div class="form-group row"
                                    :class="{ 'has-error' : model.nationality_id.$invalid }">
                                <label for="nationality_id" class="col-sm-4 col-form-label text-right">
                                    Nationality
                                </label>
                                <div class="col-sm-8">
    
                                    <select
                                        class="form-control" :disabled="isShow"
                                        v-model="model.nationality_id.$model"
                                        v-if="countries.data"
                                        >
                                        <option value="" selected v-if="!isEdit">Select Nationality</option>
                                        <option
                                            v-for="(item, index) in countries.data"
                                            :key="index"
                                            :value="item.country_id"
                                        >
                                            {{ item.name }}
                                        </option>
                                    </select>
    
                                    <error-msg :property="model.nationality_id" />
    
                                </div>
                            </div>
    
                        </div> -->

              <div class="col-lg-4 col-md-4">
                <div
                  class="form-outline"
                  :class="{ 'has-error': model.relationship.$invalid }"
                >
                  <label class="form-label" for="relationship">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2">
                      Relationship
                    </span>
                  </label>
                  <input
                    class="form-control mt-n3"
                    :class="{
                      'form-control-danger': model.relationship.$invalid,
                    }"
                    v-model="model.relationship.$model"
                    placeholder="e.g.spouse, brother, etc"
                    type="text"
                    :disabled="isShow"
                  />

                  <error-msg :property="model.relationship" />
                </div>
              </div>

              <!-- <div class="col-lg-4 col-md-4">
                            <div class="form-outline"
                                    :class="{ 'has-error' : model.phone.$invalid }">
                                <label class="form-label" for="date_of_birth">
                                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2">Phone</span>
                                </label> 
                                <div class="col-sm-8">
    
                                    <div class="row">
    
                                        <span class="col-sm-5">
    
                                            <select
                                                class="form-control" :disabled="isShow"
                                                v-model="model.phone_country.$model"
                                                v-if="countries.data"
                                                >
                                                <option
                                                    v-for="(item, index) in countries.data"
                                                    :key="index"
                                                    :value="item.iso2"
                                                >
                                                    {{ item.iso2 }} ({{ item.phonecode }})
                                                </option>
                                            </select>
    
                                        </span>
    
                                        <span class="col-sm-7">
    
                                            <input class="form-control"
                                                    :class="{ 'form-control-danger' : model.phone.$invalid }"
                                                    type="text" :disabled="isShow"
                                                    v-model="model.phone.$model"
                                                    />
    
                                        </span>
    
                                        <error-msg :property="model.phone" />
    
                                    </div>
    
                                </div>
                            </div>
                        </div> -->

              <!-- Phone Code -->
              <div class="col-lg-2 col-md-2">
                <div
                  class="form-outline"
                  :class="{ 'has-error': model.phone.$invalid }"
                >
                  <label class="form-label" for="phone_country">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >Code
                    </span>
                  </label>
                  <select
                    class="form-control mt-n3"
                    :disabled="isShow"
                    v-model="model.phone_country.$model"
                    v-if="countries.data"
                  >
                    <option
                      v-for="(item, index) in countries.data"
                      :key="index"
                      :value="item.iso2"
                    >
                      {{ item.iso2 }} ({{ item.phonecode }})
                      <!-- ({{ item.phonecode }}) - {{ item.name }} -->
                    </option>
                  </select>
                  <error-msg :property="model.phone_country" />
                </div>
              </div>

              <!-- Phone Number -->
              <div class="col-lg-2 col-md-2">
                <div
                  class="form-outline"
                  :class="{ 'has-error': model.phone.$invalid }"
                >
                  <label class="form-label" for="phone_country">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2">
                      Phone
                    </span>
                  </label>
                  <input
                    class="form-control mt-n3"
                    :class="{ 'form-control-danger': model.phone.$invalid }"
                    type="text"
                    placeholder="Phone Ex 254720000000"
                    :disabled="isShow"
                    v-model="model.phone.$model"
                  />

                  <error-msg :property="model.phone" />
                </div>
              </div>

              <!-- Country -->
              <div class="col-lg-4 col-md-4">
                <div
                  class="form-outline"
                  :class="{ 'has-error': model.country_id.$invalid }"
                >
                  <label class="form-label" for="country_id">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2">
                      Select Country
                    </span>
                  </label>
                  <select
                    class="form-control mt-n3"
                    :disabled="isShow"
                    v-model="model.country_id.$model"
                    v-if="countries.data"
                  >
                    <option value="" selected v-if="!isEdit">
                      Select Country
                    </option>
                    <option
                      v-for="(item, index) in countries.data"
                      :key="index"
                      :value="item.country_id"
                    >
                      {{ item.name }}
                    </option>
                  </select>

                  <error-msg :property="model.country_id" />
                </div>
              </div>

              <!--- county -->
              <div class="col-lg-4 col-md-4">
                <div
                  class="form-outline"
                  :class="{ 'has-error': model.county_id.$invalid }"
                >
                  <label class="form-label" for="country_id">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >County</span
                    >
                  </label>
                  <select
                    class="form-control mt-n3"
                    :disabled="isShow"
                    v-model="model.county_id.$model"
                    v-if="counties.data"
                    @change="onChangeCounty($event)"
                  >
                    <option
                      v-for="(item, index) in counties.data"
                      :key="index"
                      :value="item.county_id"
                    >
                      {{ item.name }}
                    </option>
                  </select>

                  <error-msg :property="model.county_id" />
                </div>
              </div>

              <!--- constituency -->
              <div class="col-lg-4 col-md-4">
                <div
                  class="form-outline"
                  :class="{
                    'has-error': model.constituency_id.$invalid,
                  }"
                >
                  <label class="form-label" for="constituency_id">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >Constituency</span
                    >
                  </label>
                  <select
                    class="form-control mt-n3"
                    :disabled="isShow"
                    v-model="model.constituency_id.$model"
                    v-if="constituencies.data"
                    @change="onChangeConstituency($event)"
                  >
                    <option
                      v-for="(item, index) in constituencies.data"
                      :key="index"
                      :value="item.constituency_id"
                    >
                      {{ item.name }}
                    </option>
                  </select>

                  <records-not-found
                    :message="'Please select <strong>county</strong>'"
                    v-else
                  />
                  <error-msg :property="model.constituency_id" />
                </div>
              </div>

              <!--- ward -->
              <div class="col-lg-4 col-md-4">
                <div
                  class="form-outline"
                  :class="{ 'has-error': model.ward_id.$invalid }"
                >
                  <label class="form-label" for="ward_id">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >Ward</span
                    >
                  </label>
                  <select
                    class="form-control mt-n3"
                    :disabled="isShow"
                    v-model="model.ward_id.$model"
                    v-if="wards.data"
                  >
                    <option
                      v-for="(item, index) in wards.data"
                      :key="index"
                      :value="item.ward_id"
                    >
                      {{ item.name }}
                    </option>
                  </select>

                  <records-not-found
                    :message="'Please select <strong>constituency</strong>'"
                    v-else
                  />

                  <error-msg :property="model.ward_id" />
                </div>
              </div>

              <div class="col-lg-4 col-md-4">
                <!--- street address -->
                <div
                  class="form-outline"
                  :class="{
                    'has-error': model.street_address.$invalid,
                  }"
                >
                  <label class="form-label" for="street_address">
                    <span class="h6 bg-white text-muted pt-1 pl-2 pr-2"
                      >Street Address</span
                    >
                  </label>
                  <input
                    class="form-control mt-n3"
                    :class="{
                      'form-control-danger': model.street_address.$invalid,
                    }"
                    type="text"
                    :disabled="isShow"
                    v-model="model.street_address.$model"
                  />

                  <error-msg :property="model.street_address" />
                </div>
              </div>

              <div class="col-lg-12" v-if="!isShow">
                <hr class="row no-top-margin" />

                <div class="form-group text-center">
                  <button
                    class="btn btn-primary mr-3"
                    type="submit"
                    :disabled="model.$invalid"
                    :class="{ 'btn-secondary': model.$invalid }"
                  >
                    Submit
                    <i class="fa fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { defineComponent, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useRouter, useRoute } from "vue-router";

const module = "users";
const next_of_kin_module = "nextofkins";
const next_of_kin_data_module = "nextofkinsdata";
const customer_module = "customers";
const country_module = "countries";
const county_module = "counties";
const constituency_module = "constituencies";
const ward_module = "wards";
const statuses_module = "statuses";

export default defineComponent({
  name: "NextOfKinPopup",

  props: {
    userData: {
      type: Object,
      required: true,
    },
    nextOfKinData: {
      type: Object,
      required: false,
    },
    userType: {
      type: String,
      required: false,
    },
    showModal: {
      type: Boolean,
      default: true,
    },
    toggleNextOfKinPopup: {
      type: Function,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCustomer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {},

  setup(props, { emit }) {
    const nextOfKinData = {
      customer_id: props.userData.customer_id,
      next_of_kin_id: undefined,
      user_type: props.userType,
      first_name: "",
      last_name: "",
      percentage: "",
      email: "",
      gender: "Male",
      phone: "",
      phone_country: "KE",
      relationship: "Brother",
      id_no: "",
      passport_no: "",
      date_of_birth: "",
      country_id: "113",
      nationality_id: "113",
      county_id: undefined,
      constituency_id: undefined,
      ward_id: undefined,
      street_address: "",
      status_id: "1",
    };

    const store = useStore();
    const userData = ref(props.userData);
    const showModal = ref(props.showModal);
    const userType = ref(props.userType);
    const toggleNextOfKinPopup = ref(props.toggleNextOfKinPopup);
    const isCustomer = ref(props.isCustomer);
    const maxDate = new Date();

    // set validation rules
    const rules = {
      customer_id: {},
      next_of_kin_id: {},
      user_type: {},
      first_name: { required },
      last_name: { required },
      percentage: {},
      email: { email },
      gender: { required },
      phone: {},
      phone_country: {},
      relationship: { required },
      id_no: {},
      passport_no: {},
      date_of_birth: { required },
      country_id: { required },
      nationality_id: { required },
      county_id: {},
      constituency_id: {},
      ward_id: {},
      street_address: {},
      status_id: {},
    };

    let nextOfKinForm = ref(nextOfKinData);

    // on created. Are we in edit mode?
    if (props.isEdit) {
      // nextOfKin = computed(() => store.getters[`${next_of_kin_module}/nextOfKin`]);
      nextOfKinForm = ref(props.nextOfKinData);

      // START ON PAGE REFRESH
      // check if we should fetch counties
      if (nextOfKinForm.value.country_id) {
        let country_payload = {
          country_id: nextOfKinForm.value.country_id,
          report: "1",
        };
        store.dispatch(`${county_module}/fetchCounties`, country_payload);
      }

      // check if we should fetch constituencies
      if (nextOfKinForm.value.county_id) {
        let county_payload = {
          county_id: nextOfKinForm.value.county_id,
          report: "1",
        };
        store.dispatch(
          `${constituency_module}/fetchConstituencies`,
          county_payload
        );
      }

      // check if we should fetch wards
      if (nextOfKinForm.value.constituency_id) {
        let constituency_payload = {
          constituency_id: nextOfKinForm.value.constituency_id,
          report: "1",
        };
        store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
      }
      // END ON PAGE REFRESH
    }

    // validate form
    const model = useVuelidate(rules, nextOfKinForm);

    // let nextOfKin = ref(nextOfKinData);

    // load countries
    let payload = { report: "1" };
    store.dispatch(`${country_module}/fetchCountries`, payload);

    // load counties
    let county_payload = { country_id: "113", report: "1" };
    store.dispatch(`${county_module}/fetchCounties`, county_payload);

    // load statuses data
    let status_payload = {
      report: "1", // fetch all data
      section: "users", // get users statuses only
    };
    store.dispatch(`${statuses_module}/fetchStatuses`, status_payload);

    async function onSubmitted() {
      console.log("nextOfKinForm.value", nextOfKinForm);
      if (!props.isEdit) {
        if (isCustomer.value) {
          await store
            .dispatch(
              `${next_of_kin_data_module}/createNextOfKinData`,
              nextOfKinForm.value
            )
            .then((res) => {
              emit("toggleNextOfKinPopup", toggleNextOfKinPopup);
            });
        } else {
          await store
            .dispatch(
              `${next_of_kin_module}/createNextOfKin`,
              nextOfKinForm.value
            )
            .then((res) => {
              emit("toggleNextOfKinPopup", toggleNextOfKinPopup);
            });
        }
      } else {
        if (isCustomer.value) {
          await store
            .dispatch(
              `${next_of_kin_data_module}/updateNextOfKinData`,
              nextOfKinForm
            )
            .then((res) => {
              emit("toggleNextOfKinPopup", toggleNextOfKinPopup);
            });
        } else {
          await store
            .dispatch(`${next_of_kin_module}/updateNextOfKin`, nextOfKinForm)
            .then((res) => {
              emit("toggleNextOfKinPopup", toggleNextOfKinPopup);
            });
        }
      }
    }

    function onChangeCountry(event) {
      let country_payload = { country_id: event.target.value, report: "1" };
      store.dispatch(`${county_module}/fetchCounties`, country_payload);
    }

    function onChangeCounty(event) {
      let county_payload = { county_id: event.target.value, report: "1" };
      store.dispatch(
        `${constituency_module}/fetchConstituencies`,
        county_payload
      );
    }

    function onChangeConstituency(event) {
      let constituency_payload = {
        constituency_id: event.target.value,
        report: "1",
      };
      store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
    }

    return {
      onSubmitted,
      // userData,
      // nextOfKinData: props.nextOfKinData,
      // userType,
      // isCustomer,
      // toggleNextOfKinPopup,
      model,
      countries: computed(() => store.getters[`${country_module}/countries`]),
      counties: computed(() => store.getters[`${county_module}/counties`]),
      constituencies: computed(
        () => store.getters[`${constituency_module}/constituencies`]
      ),
      wards: computed(() => store.getters[`${ward_module}/wards`]),
      statuses: computed(() => store.getters[`${statuses_module}/statuses`]),
      // isEdit: props.isEdit,
      // isShow: props.isShow,
      onChangeCountry,
      onChangeCounty,
      onChangeConstituency,
      maxDate,
    };
  },
});
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active {
  transition: all 0.8s ease-out;
}

.modal-fade-enter-active {
  transition: all 0.8s ease-in;
}

/* -- zoom -- */

/* @-webkit-keyframes modal-zoom-enter {
        from {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    @keyframes modal-zoom-enter {
        from {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    .modal-zoom-enter-active {
        -webkit-animation: modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
        animation: modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
    }

    @-webkit-keyframes modal-zoom-leave {
        to {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    @keyframes modal-zoom-leave {
        to {
            -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
        }
    }

    .modal-zoom-leave-active {
        -webkit-animation: modal-zoom-leave both;
        animation: modal-zoom-leave both;
    } */
</style>
