const clientCustomerSavingsDepositsSharesRoutes = [

    {

        path: '',
        name: 'clientCustomerSavingsDepositsSharesHome',
        component: () => import('./ListShares.vue'),
        meta: {
            auth: true,
            title: 'My Shares',
            breadcrumb: 'My Shares',
            permission: []
        },

    },

    {
        path: 'create',
        name: 'clientCustomerSavingsDepositsCreateShare',
        component: () => import('./CreateShare.vue'),
        meta: {
            auth: true,
            title: 'Create Shares',
            breadcrumb: 'Create Shares',
            permission: ['view customer']
        }
    },

    {
        path: ':id',
        name: 'clientCustomerSavingsDepositsShowShare',
        component: () => import('./ShowShare.vue'),
        meta: {
            auth: true,
            title: 'Showing Shares',
            breadcrumb: 'Showing Shares',
            permission: ['view customer']
        }
    }

];

export default clientCustomerSavingsDepositsSharesRoutes;
