import axios from "axios";

export default {
  namespaced: true,

  state: {
    yearlyStatistics: [],
    dailyStatistics: [],
    monthlyStatistics: [],
    hourlyStatistics: [],
    hourlyGraphStatistics: [],
    totalRegisteredCustomers: [],
    totalOutstandingLoans: [],
    totalSavings: [],
    disbursedLoanStatistics: [],
    financialOverviewStatistics: [],
    customerOverviewStatistics: [],
  },

  getters: {
    hourlyStatistics(state) {
      return state.hourlyStatistics;
    },
    hourlyGraphStatistics(state) {
      return state.hourlyGraphStatistics;
    },
    dailyStatistics(state) {
      return state.dailyStatistics;
    },
    monthlyStatistics(state) {
      return state.monthlyStatistics;
    },
    yearlyStatistics(state) {
      return state.yearlyStatistics;
    },
    totalRegisteredCustomers(state) {
      return state.totalRegisteredCustomers;
    },
    totalOutstandingLoans(state) {
      return state.totalOutstandingLoans;
    },
    totalSavings(state) {
      return state.totalSavings;
    },
    disbursedLoanStatistics(state) {
      return state.disbursedLoanStatistics;
    },
    financialOverviewStatistics(state) {
      return state.financialOverviewStatistics;
    },
    customerOverviewStatistics(state) {
      return state.customerOverviewStatistics;
    },
  },

  mutations: {
    SET_DAILY_STATISTICS(state, dailyStatistics) {
      state.dailyStatistics = dailyStatistics;
    },
    SET_HOURLY_STATISTICS(state, hourlyStatistics) {
      state.hourlyStatistics = hourlyStatistics;
    },
    SET_HOURLY_GRAPH_STATISTICS(state, hourlyGraphStatistics) {
      state.hourlyGraphStatistics = hourlyGraphStatistics;
    },
    SET_MONTHLY_STATISTICS(state, monthlyStatistics) {
      state.monthlyStatistics = monthlyStatistics;
    },
    SET_YEARLY_STATISTICS(state, yearlyStatistics) {
      state.yearlyStatistics = yearlyStatistics;
    },
    SET_TOTAL_REGISTERED_CUSTOMERS(state, totalRegisteredCustomers) {
      state.totalRegisteredCustomers = totalRegisteredCustomers;
    },
    SET_TOTAL_OUTSTANDING_LOANS(state, totalOutstandingLoans) {
      state.totalOutstandingLoans = totalOutstandingLoans;
    },
    SET_TOTAL_SAVINGS(state, totalSavings) {
      state.totalSavings = totalSavings;
    },
    SET_DISBURSED_LOAN_STATISTICS(state, disbursedLoanStatistics) {
      state.disbursedLoanStatistics = disbursedLoanStatistics;
    },
    SET_FINANCIAL_OVERVIEW_STATISTICS(state, financialOverviewStatistics) {
      state.financialOverviewStatistics = financialOverviewStatistics;
    },
    SET_CUSTOMER_OVERVIEW_STATISTICS(state, customerOverviewStatistics) {
      state.customerOverviewStatistics = customerOverviewStatistics;
    },
  },

  actions: {
    async fetchHourlyStatistics({ commit }, params) {
      await axios
        .get("/api/v1/statistics/hourly", { params: params })
        .then((res) => {
          commit("SET_HOURLY_STATISTICS", res.data);
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    async fetchHourlyGraphStatistics({ commit }, params) {
      await axios
        .get("/api/v1/statistics/hourly", { params: params })
        .then((res) => {
          commit("SET_HOURLY_GRAPH_STATISTICS", res.data);
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    async fetchDailyStatistics({ commit }, params) {
      await axios
        .get("/api/v1/statistics/daily", { params: params })
        .then((res) => {
          commit("SET_DAILY_STATISTICS", res.data);
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    async fetchMonthlyStatistics({ commit }, params) {
      await axios
        .get("/api/v1/statistics/monthly", { params: params })
        .then((res) => {
          commit("SET_MONTHLY_STATISTICS", res.data);
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    async fetchYearlyStatistics({ commit }, params) {
      await axios
        .get("/api/v1/statistics/yearly", { params: params })
        .then((res) => {
          commit("SET_YEARLY_STATISTICS", res.data);
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    async fetchTotalRegisteredCustomers({ commit }, params) {
      await axios
        .get("/api/v1/statistics/total", { params: params })
        .then((res) => {
          commit("SET_TOTAL_REGISTERED_CUSTOMERS", res.data);
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    async fetchTotalOutstandingLoans({ commit }, params) {
      await axios
        .get("/api/v1/statistics/total", { params: params })
        .then((res) => {
          commit("SET_TOTAL_OUTSTANDING_LOANS", res.data);
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    async fetchTotalSavings({ commit }, params) {
      await axios
        .get("/api/v1/statistics/total", { params: params })
        .then((res) => {
          commit("SET_TOTAL_SAVINGS", res.data);
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    async fetchDisbursedLoanStatistics2({ commit }, data) {
      await axios
        .post("/api/v1/statistics/multiple-metrics", data)
        .then((res) => {
          commit("SET_DISBURSED_LOAN_STATISTICS", res.data);
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    // using multiple-metrics endpoint
    async fetchDisbursedLoanStatistics({ commit }, data) {
      try {
        const response = await axios.post(
          "/api/v1/statistics/multiple-metrics",
          data
        );
        commit("SET_DISBURSED_LOAN_STATISTICS", response.data);
        return response.data; // Return the data directly
      } catch (error) {
        throw error; // re-throw to propagate the error to the caller
      }
    },

    // using multiple-metrics endpoint
    async fetchFinancialOverviewStatistics({ commit }, data) {
      try {
        const response = await axios.post(
          "/api/v1/statistics/multiple-metrics",
          data
        );
        commit("SET_FINANCIAL_OVERVIEW_STATISTICS", response.data);
        return response.data; // Return the data directly
      } catch (error) {
        throw error; // re-throw to propagate the error to the caller
      }
    },

    // using multiple-metrics endpoint
    async fetchCustomerOverviewStatistics({ commit }, data) {
      try {
        const response = await axios.post(
          "/api/v1/statistics/multiple-metrics",
          data
        );
        commit("SET_CUSTOMER_OVERVIEW_STATISTICS", response.data);
        return response.data; // Return the data directly
      } catch (error) {
        throw error; // re-throw to propagate the error to the caller
      }
    },
  },
};
