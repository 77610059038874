<template>

        <div class="card-bodyd w-100">

            <div class="row no-gutters">

			<div class="col-md-4 col-sm-12 justify-content-start align-middle d-none d-sm-inline-block">
				<p class="my-4 text-centerxx align-middle text-muted">
					Page <b>{{ data.current_page }}</b> of
					<b>{{ data.last_page }}</b> &nbsp;(<b>{{ data.from }}</b> -
					<b>{{ data.to }}</b> of <b>{{ data.total }}</b> results)
				</p>
			</div>

			<div class="col-md-8 col-sm-12 align-middle">

				<nav class="my-4">

					<ul class="pagination pagination-primary justify-content-start mr-4">

						<li
							class="page-item first"
							:class="{ disabled: isFirstPage }"
						>
							<a
								href="#"
								class="page-link"
								@click.prevent="onClickFirstPage"
								aria-label="Go to first page"
								title="First page"
							>
								<!-- <em class="icon ni ni-chevrons-left"></em> -->
                                <span aria-hidden="true">&#171;</span>
                                <span class="sr-only">First</span>
							</a>
						</li>

						<li
							class="page-item"
							:class="{ disabled: isFirstPage }"
						>
							<a
								href="#"
								class="page-link"
								@click.prevent="onClickPreviousPage"
								aria-label="Go to previous page"
								title="Previous page"
							>
                                <span aria-hidden="true">&#8249;</span>
                                <span class="sr-only">Previous</span>
							</a>
						</li>

						<li
							v-for="page in pages"
							class="page-item"
							:class="{
								active: isCurrent(page.name),
								disabled: page.isDisabled,
							}"
							:key="page.name"
						>
							<a
								href="#"
								class="page-link"
								@click.prevent="onClickPage(page.name)"
								:aria-label="`Go to page number ${page.name}`"
							>
								{{ page.name }}
							</a>
						</li>

                        <li
							class="page-item"
							:class="{ disabled: isLastPage }"
						>
							<a
								href="#"
								class="page-link"
								@click.prevent="onClickNextPage"
								aria-label="Go to next page"
								title="Next page"
							>
                                <span aria-hidden="true">&#8250;</span>
                                <span class="sr-only">Next</span>
							</a>
						</li>

						<li
							class="page-item last"
							:class="{ disabled: isLastPage }"
						>
							<a
								href="#"
								class="page-link"
								@click.prevent="onClickLastPage"
								aria-label="Go to last page"
								title="Last page"
							>
                                <span aria-hidden="true">&#187;</span>
                                <span class="sr-only">Last</span>
							</a>
						</li>

					</ul>

				</nav>

			</div>

            </div>

		</div>

</template>

<script>

	import { computed, defineComponent } from "vue";

	export default defineComponent({

		name: "Pagination",

		props: {
			max: {
				type: Number,
				required: false,
				default: 10,
			},
			range: {
				type: Number,
				required: false,
				default: 5,
			},
			data: {
				type: Object,
				required: true,
			},
		},

		emits: ["changed"],

		setup(props, { emit }) {
			const firstPage = computed(() => {
				if (props.data?.current_page === 1) {
					return 1;
				}
				if (props.data?.current_page === props.data?.last_page) {
					return props.data?.last_page - props.max + 1;
				}
				return props.data?.current_page - 1;
			});
			const lastPage = computed(() => {
				return Math.min(
					firstPage.value + props.max - 1,
					props.data?.last_page
				);
			});
			const pages = computed(() => {
				const range = [];
				for (let i = firstPage.value; i <= lastPage.value; i += 1) {
					range.push({
						name: i,
						// isDisabled: i === props.data?.current_page,
					});
				}
				return range;
			});
			const isFirstPage = computed(() => {
				return props.data?.current_page === 1;
			});
			const isLastPage = computed(() => {
				return props.data?.current_page === props.data?.last_page;
			});
			function onClickFirstPage() {
				emit("changed", 1);
			}
			function onClickPreviousPage() {
				emit("changed", props.data?.current_page - 1);
			}
			function onClickPage(page = 1) {
				emit("changed", page);
			}
			function onClickNextPage() {
				emit("changed", props.data?.current_page + 1);
			}
			function onClickLastPage() {
				emit("changed", props.data?.last_page);
			}
			function isCurrent(page = 1) {
				return props.data?.current_page === page;
			}
			return {
				firstPage,
				lastPage,
				pages,
				isFirstPage,
				isLastPage,
				onClickFirstPage,
				onClickPreviousPage,
				onClickPage,
				onClickNextPage,
				onClickLastPage,
				isCurrent,
			};
		},

	});
</script>

