import axios from "axios";

export default axios.create({
    /* baseUrl: `http://localhost:8900/api/v1/`,
    withCredentials: false,
    headers: {
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        Accept: "application/json",
        "Content-Type": "application/json"
    } */
})

