import axios from "axios";

export default {

    namespaced: true,

    state: {

        registrationFeeSettings: [],
        registrationFeeSetting: {}

    },

    getters: {

        registrationFeeSettings(state) {
            return state.registrationFeeSettings;
        },
        registrationFeeSetting(state) {
            return state.registrationFeeSetting;
        }

    },

    mutations: {

        SET_COMPANY_SETTINGS(state, registrationFeeSettings) {
            return state.registrationFeeSettings = registrationFeeSettings
        },

        SET_COMPANY_SETTING(state, registrationFeeSetting) {
            return state.registrationFeeSetting = registrationFeeSetting
        }

    },

    actions: {

        async fetchregistrationFeeSettings({ commit }, params) {

            await axios.get('/api/v1/registration-fee-settings', { params: params })
                .then(res => {
                    // console.log("res == ", res);
                    commit('SET_COMPANY_SETTINGS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchregistrationFeeSetting({ commit }, registration_fee_setting_id) {

            await axios.get(`/api/v1/registration-fee-settings/${registration_fee_setting_id}`)
                .then(res => {
                    // console.log("fetchregistrationFeeSetting store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_COMPANY_SETTING', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createregistrationFeeSetting({ commit, dispatch }, registrationFeeSetting) {
            await axios("/api/v1/registration-fee-settings/", {
                method: "post",
                data: registrationFeeSetting,
            })
        },

        async updateregistrationFeeSetting({ commit, dispatch }, registrationFeeSetting) {

            await axios("/api/v1/registration-fee-settings/" + registrationFeeSetting.registration_fee_setting_id, {
                method: "patch",
                data: registrationFeeSetting,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchregistrationFeeSettings');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        }

    }

}
