import axios from "axios";
import {downloadFile} from "@/utils";

export default {

    namespaced: true,

    state: {

        loanofferletters: [],
        loanofferletter: null,

    },

    getters: {
        loanofferletters(state) {

            return state.loanofferletters;
        },
        loanofferletter(state) {
            return state.loanofferletter;
        },
    },

    mutations: {
        SET_LOAN_OFFER_LETTER_VARIABLES(state, loanofferletters) {
            return state.loanofferletters = loanofferletters
        },
        SET_LOAN_OFFER_LETTER_TEMPLATES(state, loanOfferLetterTemplates) {
            return state.loanofferletters = loanOfferLetterTemplates
        },
        SET_LOAN_OFFER_LETTER_TEMPLATE(state, loanOfferLetterTemplate) {
            return state.loanofferletter = loanOfferLetterTemplate
        }
    },

    actions: {

        async fetchOfferLetterVariables({ commit }, company_id) {

            await axios.get(`/api/v1/loan-offer-letter-templates/create`)
                .then(res => {
                    commit('SET_LOAN_OFFER_LETTER_VARIABLES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },
        async fetchOfferLetterTemplates({ commit }, params) {

            await axios.get('/api/v1/loan-offer-letter-templates', { params: params })
                .then(res => {
                    commit('SET_LOAN_OFFER_LETTER_TEMPLATES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },
        async fetchOfferLetterTemplate({ commit }, loan_application_id) {

            await axios.get(`/api/v1/exports/loan-offer-letter/render/${loan_application_id}`)

                .then(res => {

                    downloadFile(res.data.filename, res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },
        async editLoanOfferTemplate({ commit }, params){
            return  await axios.get(`/api/v1/loan-offer-letter-templates/edit/${params}`);
        },

        async updateLoanOfferTemplate({ commit }, params){
            const { data, id } = params;
            return await axios.put(`/api/v1/loan-offer-letter-templates/update-template/${id}`, data);
        },

        async deleteOfferLetterTemplate({ commit }, params){
           return  await axios.delete(`/api/v1/loan-offer-letter-templates/${params}`);
        },

       async  storeLoanOfferTemplate({ commit }, data){
           const headers = {
               'Content-Type': 'application/json'
           };
           return await axios.post('/api/v1/loan-offer-letter-templates/', data, { headers });
       },

        async createCompanyOfferLetter({ commit, dispatch }, company) {
            // try {
            //     const res = await axios.post('/api/v1/companies', company);
            //     if (res.statusText === 'OK') {
            //         dispatch('fetchCompanies');
            //     }
            // } catch (err) {
            //     return Promise.reject(err);
            // }
        },
    }

}
