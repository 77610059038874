import axios from "axios";

export default {

    namespaced: true,

    state: {

        depositstatuses: [],
        depositstatus: {}

    },

    getters: {

        depositstatuses(state) {
            return state.depositstatuses;
        },
        depositstatus(state) {
            return state.depositstatus;
        }

    },

    mutations: {

        SET_DEPOSIT_STATUSES(state, depositstatuses) {
            return state.depositstatuses = depositstatuses
        },

        SET_DEPOSIT_STATUS(state, depositstatus) {
            return state.depositstatus = depositstatus
        }

    },

    actions: {

        async fetchdepositstatuses({ commit }, params) {

            await axios.get('/api/v1/deposit-status', { params: params })
                .then(res => {
                    commit('SET_DEPOSIT_STATUSES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchdepositstatus({ commit }, deposit_status_id) {

            await axios.get(`/api/v1/deposit-status/${deposit_status_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_DEPOSIT_STATUS', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createdepositstatus({ commit, dispatch }, depositstatus) {
            await axios.post('/api/v1/deposit-status', depositstatus);
        },

        async updatedepositstatus({ commit, dispatch }, depositstatus) {
            await axios("/api/v1/deposit-status/" + depositstatus.deposit_status_id, { method: "patch", data: depositstatus})
        },

        async deletedepositstatus({ commit }, deposit_status_id) {

            await axios.delete(`/api/v1/deposit-status/${deposit_status_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchdepositstatuses');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        },

    }

}
