const vendorRegistrationRoutes = [

    {
        name: 'vendorHome',
        path: '',
        component: () => import('./ListVendors.vue'),
        meta: {
            auth: true,
            title: 'Vendors Listing',
            breadcrumb: 'Vendors Listing',
            permission: ['view vendor']
        }

    },

    {
        path: 'create',
        name: 'createVendor',
        component: () => import('./CreateVendor.vue'),
        meta: {
            auth: true,
            title: 'Create Vendor',
            breadcrumb: 'Create Vendor',
            permission: ['create vendor']
        }
    },

    {
        path: ':id',
        name: 'showVendor',
        component: () => import('./ShowVendor.vue'),
        meta: {
            auth: true,
            title: 'Showing Vendor',
            breadcrumb: 'Showing Vendor',
            permission: ['view vendor']
        }
    },

    {
        path: ':id/edit',
        name: 'editVendor',
        component: () => import('./EditVendor.vue'),
        meta: {
            auth: true,
            title: 'Edit Vendor',
            breadcrumb: 'Edit Vendor',
            permission: ['update vendor']
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteVendor',
        meta: {
            auth: true,
            title: 'Delete Vendor',
            permission: ['delete vendor']
        }
    },

];

export default vendorRegistrationRoutes;
