import axios from "axios";

export default {
  namespaced: true,

  state: {
    teams: [],
    team: {},
    search_teams: [],
  },

  getters: {
    teams(state) {
      return state.teams;
    },
    team(state) {
      return state.team;
    },
    search_teams(state) {
      return state.teams;
    },
  },

  mutations: {
    SET_TEAMS(state, teams) {
      return (state.teams = teams);
    },

    SET_TEAM(state, team) {
      return (state.team = team);
    },
    SET_SEARCH_TEAMS(state, teams) {
      return (state.search_teams = teams);
    },
  },

  actions: {
    async fetchTeams({ commit }, params) {
      await axios
        .get("/api/v1/teams", { params: params })
        .then((res) => {
          commit("SET_TEAMS", res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async fetchSearchTeams({ commit }, params) {
      await axios
        .get("/api/v1/teams", { params: params })
        .then((res) => {
          // console.log(res.data.data);
          commit("SET_SEARCH_TEAMS", res.data);

          // return res.data;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async fetchTeam({ commit }, params) {
      let team_id = params.team_id;
      await axios
        .get(`/api/v1/teams/${team_id}`)
        .then((res) => {
          // console.log("fetchBranch store == ", res);
          if (res.statusText === "OK") {
            commit("SET_TEAM", res.data);
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async createTeam({ commit, dispatch }, team) {
      await axios
        .post("/api/v1/teams", team)
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchTeams");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async updateTeam({ commit, dispatch }, team) {
      await axios("/api/v1/teams/" + team.team_id, {
        method: "put",
        data: team,
      })
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchTeams");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async deleteTeam({ commit }, dispatch, team_id) {
      await axios
        .delete(`/api/v1/teams/${team_id}`)
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchTeams");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    async attachCustomerToTeam({ commit, dispatch }, team) {
      await axios
        .post("/api/v1/teams/assign-team", team)
        .then((res) => {
          if (res.statusText === "OK") {
            dispatch("fetchTeams");
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
};
