const dataUploadRoutes = [

    {

        name: 'dataUploadHome',
        path: '',
        component: () => import('./CreateUploadData.vue'),
        meta: {
            auth: true,
            title: 'Upload Data',
            breadcrumb: 'Upload Data',
            permission: ['create dataupload']
        }

    },

];

export default dataUploadRoutes;
