import axios from "axios";

export default {

    namespaced: true,

    state: {

        loanschedules: [],
        loanschedule: {}

    },

    getters: {

        loanschedules(state) {
            return state.loanschedules;
        },
        loanschedule(state) {
            return state.loanschedule;
        }

    },

    mutations: {

        SET_LOAN_SCHEDULES(state, loanschedules) {
            return state.loanschedules = loanschedules
        },

        SET_LOAN_SCHEDULE(state, loanschedule) {
            return state.loanschedule = loanschedule
        },


    },

    actions: {

        async fetchloanschedules({ commit }, params) {

            await axios.get('/api/v1/loan-schedules', { params: params })
                .then(res => {
                    commit('SET_LOAN_SCHEDULES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchloanschedule({ commit }, loan_schedule_id) {

            await axios.get(`/api/v1/loan-schedules/${loan_schedule_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_LOAN_SCHEDULE', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

     

    
        async createloanschedule({ commit, dispatch }, loanschedule) {
            await axios.post('/api/v1/loan-schedules', loanschedule)
                .then(res => {
          
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateloanschedule({ commit, dispatch }, loanschedule) {

            await axios("/api/v1/loan-schedules/" + loanschedule.loan_schedule_id, {
                method: "patch",
                data: loanschedule,
            }).then(res => {
          
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteloanschedule({ commit }, loan_schedule_id) {

            await axios.delete(`/api/v1/loan-schedules/${loan_schedule_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchloanschedules');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
