window._ = require('lodash');

import axios from 'axios';

try {
    window.$ = window.jQuery = require('jquery');

} catch (e) {
    console.log(e);
}

// window.axios = require('axios');
window.axios = axios;

// axios.defaults.baseURL = "http://165.232.186.98:8900/";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.defaults.withCredentials = true;

axios.defaults.showLoader = true;

// feather-icons
window.feather = require('feather-icons');

require('./assets/js/jquery.min.js');
require('./assets/js/bootstrap.bundle.min.js');
require('./assets/js/metismenu.min.js');

require('./assets/js/simplebar.min.js');
require('./assets/js/waves.js');
require('./assets/js/feather.min.js');

require('./assets/js/jquery-ui.min.js');
require('./assets/js/nestable/jquery.nestable.min.js');

// tippy
// require('./assets/js/tippy/tippy.all.min.js');

/* start add */
require('./assets/js/owlcarousel/js/owl.carousel.min.js');
require('./assets/js/isotope.min.js');
require('./assets/plugins/bootstrap-colorpicker/js/bootstrap-colorpicker.min.js');

require('./assets/js/scripts.js');
