import axios from "axios";

export default {

    namespaced: true,

    state: {

        mpesapayments: [],
        mpesapayment: {}

    },

    getters: {

        mpesapayments(state) {
            return state.mpesapayments;
        },
        mpesapayment(state) {
            return state.mpesapayment;
        }

    },

    mutations: {

        SET_MPESA_PAYMENTS(state, mpesapayments) {
            return state.mpesapayments = mpesapayments
        },

        SET_MPESA_PAYMENT(state, mpesapayment) {
            return state.mpesapayment = mpesapayment
        }

    },

    actions: {

        async fetchmpesapayments({ commit }, params) {

            await axios.get('/api/v1/mpesa', { params: params })
                .then(res => {
                    commit('SET_MPESA_PAYMENTS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },
        
        async updatempesapaymnent({ commit, dispatch }, payload) {
            await axios.patch('/api/v1/mpesa/' + payload.mpesa_payment_id, payload )
            .then(res => {
               // dispatch('fetchmpesapayments');
                
            }).catch(err => {
                return Promise.reject(err);
            })

        },
        async repostpayment({ commit, dispatch }, payload) {
            await axios.post('/api/v1/mpesa/repost', payload )
            .then(res => {
               // dispatch('fetchmpesapayments');
                
            }).catch(err => {
                return Promise.reject(err);
            })

        },

    }

}
