const smsMessagingRoutes = [

    {

        name: 'smsHome',
        path: '',
        component: () => import('./ListSms.vue'),
        meta: {
            auth: true,
            title: 'Sms Listing',
            breadcrumb: 'Sms Listing',
            permission: ['view sms'],
            section: 'sms'
        }

    },

    {
        path: 'create',
        name: 'createSms',
        component: () => import('./CreateSms.vue'),
        meta: {
            auth: true,
            title: 'Create Sms',
            breadcrumb: 'Create Sms',
            permission: ['create sms'],
            section: 'sms'
        }
    },

    {
        path: ':id',
        name: 'showSms',
        component: () => import('./ShowSms.vue'),
        meta: {
            auth: true,
            title: 'Showing Sms',
            breadcrumb: 'Showing Sms',
            permission: ['view sms'],
            section: 'sms'
        }
    },

    {
        path: ':id/edit',
        name: 'editSms',
        component: () => import('./EditSms.vue'),
        meta: {
            auth: true,
            title: 'Edit Sms',
            breadcrumb: 'Edit Sms',
            permission: ['update sms'],
            section: 'sms'
        }
    },

    {
        path: 'delete/:id',
        name: 'deleteSms',
        meta: {
            auth: true,
            title: 'Delete Sms',
            permission: ['delete sms'],
            section: 'sms'
        }
    },

];

export default smsMessagingRoutes;
