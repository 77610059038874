import axios from "axios";

export default {

    namespaced: true,

    state: {

        currentDepositAccounts: [],
        currentDepositAccount: {},
        currentDepositAccountBalances: [],
        transactions: [],
        depositstatushistories: [],
        amountinliens: [],
        depositstatements: [],
        pendingpayments: [],
    },

    getters: {

        currentDepositAccounts(state) {
            return state.currentDepositAccounts;
        },
        currentDepositAccount(state) {
            return state.currentDepositAccount;
        },

        currentDepositAccountbalances(state) {
            return state.currentDepositAccountBalances;
        },

        transactions(state) {
            return state.transactions;
        },

        depositstatushistories(state) {
            return state.depositstatushistories;
        },

        amountinliens(state) {
            return state.amountinliens;
        },

        depositstatements(state) {
            return state.depositstatements;
        },

        pendingpayments(state) {
            return state.pendingpayments;
        },
    },

    mutations: {

        SET_CURRENT_ACCOUNTS(state, currentDepositAccounts) {
            return state.currentDepositAccounts = currentDepositAccounts
        },

        SET_CURRENT_ACCOUNT(state, currentDepositAccount) {
            return state.currentDepositAccount = currentDepositAccount
        },

        SET_CURRENT_ACCOUNT_BALANCES(state, currentDepositAccounts) {
            return state.currentDepositAccountBalances = currentDepositAccounts
        },

        FETCH_TRANSACTIONS(state, transactions) {
            state.transactions = transactions;
        },

        FETCH_DEPOSIT_STATUS_HISTORIES(state, depositstatushistories) {
            state.depositstatushistories = depositstatushistories;
        },

        FETCH_AMOUNT_IN_LIEN(state, amountinliens) {
            state.amountinliens = amountinliens;
        },

        FETCH_DEPOSIT_STATEMENTS(state, depositstatements) {
            state.depositstatements = depositstatements;
        },

        FETCH_PENDING_PAYMENTS(state, pendingpayments) {
            state.pendingpayments = pendingpayments;
        },

    },

    actions: {

        async fetchcurrentDepositAccounts({ commit }, params) {

            await axios.get('/api/v1/current-deposit-accounts', { params: params })
                .then(res => {
                    commit('SET_CURRENT_ACCOUNTS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchcurrentDepositAccountbalances({ commit }, params) {

            await axios.get('/api/v1/current-deposit-accounts/balances', { params: params })
                .then(res => {
                    commit('SET_CURRENT_ACCOUNT_BALANCES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchcurrentDepositAccount({ commit }, deposit_application_id) {

            await axios.get(`/api/v1/current-deposit-accounts/${deposit_application_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_CURRENT_ACCOUNT', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createfixedaccount({ commit, dispatch }, currentDepositAccount) {

            return await axios.post('/api/v1/current-deposit-accounts', currentDepositAccount);

        },

        async updatecurrentDepositAccount({ commit, dispatch }, currentDepositAccount) {

            return await axios.patch("/api/v1/current-deposit-accounts/" + currentDepositAccount.deposit_application_id, currentDepositAccount);

        },

        async deletecurrentDepositAccount({ commit }, deposit_application_id) {

            await axios.delete(`/api/v1/current-deposit-accounts/${deposit_application_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchcurrentDepositAccounts');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        },

        async fetchcurrentDepositAccountBalances({ commit }, params) {

            await axios.get('/api/v1/current-deposit-accounts/balances', { params: params })
                .then(res => {
                    commit('SET_CURRENT_ACCOUNT_BALANCES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        fetchtransactions({ commit }, params) {
            axios.get('/api/v1/current-deposit-accounts/transactions', { params: params })
                .then(res => {
                    commit('FETCH_TRANSACTIONS', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async fetchdepositstatushistories({ commit }, params) {

            await axios.get('/api/v1/current-deposit-accounts/status-history', { params: params })
                .then(res => {
                    commit('FETCH_DEPOSIT_STATUS_HISTORIES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchamountinlien({ commit }, params) {

            await axios.get('/api/v1/current-deposit-accounts/amountinlien', { params: params })
                .then(res => {
                    commit('FETCH_AMOUNT_IN_LIEN', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchCustomerDepositStatement({ commit }, params) {
            await axios.get('/api/v1/current-deposit-accounts/depositstatements', { params: params })
                .then(res => {
                    commit('FETCH_DEPOSIT_STATEMENTS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async fetchCustomerPendingPayments({ commit }, params) {
            await axios.get('/api/v1/current-deposit-accounts/pendingpayments', { params: params })
                .then(res => {
                    commit('FETCH_PENDING_PAYMENTS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async changeCurrentAccountStatus({ commit, dispatch }, currentDepositAccount) {
            await axios.post('/api/v1/current-deposit-accounts/change-account-status', currentDepositAccount);
        },

        async createCashDeposits({ commit, dispatch }, data) {
            return await axios.post('/api/v1/current-deposit-accounts/cash-deposit', data);
        },

        async createCashWithdrawals({ commit, dispatch }, data) {
            return await axios.post('/api/v1/current-deposit-accounts/cash-withdrawal', data);
        },

        async createMpesaWithdrawals({ commit, dispatch }, data) {
            return await axios.post('/api/v1/current-deposit-accounts/mpesa-withdrawal', data);
        },

        async createChequeDeposits({ commit, dispatch }, data) {
            return await axios.post('/api/v1/current-deposit-accounts/cheque-deposit', data);
        },

        async createChequeWithdrawals({ commit, dispatch }, data) {
            return await axios.post('/api/v1/current-deposit-accounts/cheque-withdrawal', data);

        },

        async initiateFundTransfer({ commit, dispatch }, data) {
            return await axios.post('/api/v1/current-deposit-accounts/fund-transfer', data);

        },

        async createAdjustment({ commit, dispatch }, data) {
            return await axios.post('/api/v1/current-deposit-accounts/adjustment', data);

        },


    }

}
