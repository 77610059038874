import axios from "axios";

export default {

    namespaced: true,

    state: {

        companies: [],
        company: {}

    },

    getters: {

        companies(state) {
            // console.log("getters state.companies", state.companies);
            return state.companies;
        },
        company(state) {
            return state.company;
        }

    },

    mutations: {

        SET_COMPANIES(state, companies) {
            return state.companies = companies
        },

        SET_COMPANY(state, company) {
            return state.company = company
        }

    },

    actions: {

        async fetchCompaniesList({ commit }, params) {
            try {
                const res = await axios.get('/api/v1/companies-list', { params: params });
                commit('SET_COMPANIES', res.data);
            } catch (err) {
                return Promise.reject(err);
            }
        },

        async fetchCompanies({ commit }, params) {
            try {
                const res = await axios.get('/api/v1/companies', { params: params });
                commit('SET_COMPANIES', res.data);
            } catch (err) {
                return Promise.reject(err);
            }
        },

        async fetchCompany({ commit }, company_id) {
            try {
                const res = await axios.get(`/api/v1/companies/${company_id}`);
                if (res.statusText === 'OK') {
                    commit('SET_COMPANY', res.data.data);
                }
            } catch (err) {
                return Promise.reject(err);
            }
        },

        async createCompany({ commit, dispatch }, company) {
            try {
                const res = await axios.post('/api/v1/companies', company);
                if (res.statusText === 'OK') {
                    dispatch('fetchCompanies');
                }
            } catch (err) {
                return Promise.reject(err);
            }
        },

        async updateCompany({ commit, dispatch }, company) {
            try {
                const res = await axios.patch(`/api/v1/companies/${company.company_id}`, company);
                if (res.statusText === 'OK') {
                    dispatch('fetchCompanies');
                }
            } catch (err) {
                return Promise.reject(err);
            }
        },

        async deleteCompany({ commit, dispatch }, company_id) {
            try {
                const res = await axios.delete(`/api/v1/companies/${company_id}`);
                if (res.statusText === 'OK') {
                    dispatch('fetchCompanies');
                }
            } catch (err) {
                return Promise.reject(err);
            }
        }

    }

}
