<template>

  <div
    class="modal fade"
    id="requestApproval"
    tabindex="-1"
    role="dialog"
    aria-labelledby="approvalModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md" role="document">

      <div class="modal-content">

        <div class="modal-header">
          <h6 class="modal-title m-0" id="approvalModalLabel">
            Request Approval
          </h6>
          <a
            href="#"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="la la-times"></i>
            </span>
          </a>
        </div>

        <div class="modal-body">
          <div class="form-group">
            <label>Subject</label>
            <input class="form-control" v-model="note.subject" />
          </div>

          <div class="form-group">
            <label>Select Approver</label>
            <select class="form-control" v-model="note.user_id">
              <option value="10">User 10</option>
            </select>
          </div>

          <div class="form-group">
            <label>Add Message</label>
            <textarea class="form-control form-control-lg" rows="3" v-model="note.message" />
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>

          <a href="#" @click.prevent="getApproval()" class="btn btn-primary">
            Submit Request</a
          >
        </div>

      </div>

    </div>

  </div>

</template>

<script>
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({

  props: ["data", "table",  "redirect", "authUser"],

  setup(props) {
    const store = useStore();
    const note = reactive({
      user_id: undefined,
      subject: "",
      message: "",
      data: props.data,
      type: props.table,
    });
    const admins = ref([])

    const user = ref(props.authUser);

    // const user = computed(() => store.getters['userData/getLoggedInUserDetails'])

    function getAdmins() {
      store
        .dispatch(`users/fetchUsers`, {})
        .then((res) => {
          admins.value = res.data
        })
        .catch((err) => {
          return err;
        });
    }

    function getApproval() {
      store
        .dispatch(`approvals/getApproval`, note)
        .then((res) => {
          // router.push(props.redirect || '/');
        })
        .catch((err) => {
          return err;
        });
    }

    return { note, getApproval, user };
  },
});
</script>
