import emailMessageTemplateMasterAdministrationRoutes from "./Email/emailMessageTemplateMasterAdministrationRoutes";
import smsMessageTemplateMasterAdministrationRoutes from "./Sms/smsMessageTemplateMasterAdministrationRoutes";

import Base from '../../../Common/Base.vue';

const messageTemplateMasterAdministrationModuleRoutes = [

    {

        path: '',
        name: 'messageTemplateMasterHome',
        component: () => import('./HomeMessageTemplateMaster.vue'),
        meta: {
            auth: true,
            title: 'Message Templates Master',
            breadcrumb: 'Message Templates Master',
            permission: []
        },

    },

    {

        path: 'sms',
        name: 'smsTemplateMasterHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Sms Templates Master Listing ',
            breadcrumb: 'Sms Templates Master Listing',
            permission: ['view messagetemplatemaster', 'create messagetemplatemaster', 'update messagetemplatemaster', 'delete messagetemplatemaster']
        },

        children: smsMessageTemplateMasterAdministrationRoutes

    },

    {

        path: 'emails',
        name: 'emailTemplateMasterHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Email Templates Master Listing',
            breadcrumb: 'Email Templates Master Listing',
            permission: ['view messagetemplatemaster', 'create messagetemplatemaster', 'update messagetemplatemaster', 'delete messagetemplatemaster']
        },

        children: emailMessageTemplateMasterAdministrationRoutes

    },

];

export default messageTemplateMasterAdministrationModuleRoutes;
