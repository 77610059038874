import axios from "axios";

export default {

    namespaced: true,

    state: {

        failed_jobs: [],
        failed_job: {}

    },

    getters: {
        failed_jobs(state) {
            return state.failed_jobs
        },
        failed_job(state) {
            return state.failed_job;
        }
    },

    mutations: {

        SET_FAILEDJOBS(state, failed_jobs) {
            return state.failed_jobs = failed_jobs
        },

        SET_FAILEDJOB(state, failed_job) {
            return state.failed_job = failed_job
        }

    },

    actions: {

        async fetchFailedJobs({ commit }, params) {
                

            await axios.get('/api/v1/failed-jobs', { params: params })
                .then(res => {
                    console.log("res == ", res);
                    commit('SET_FAILEDJOBS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async retryFailedJob({ commit }, params){

            return  await axios.post(`/api/v1/failed-jobs/${params}`);

        },
  
       async  deleteFailedJob({ commit }, params){
            return  await axios.delete(`/api/v1/failed-jobs/${params}`);

        }
  

    }

}
