const loanapplicationRoutes = [

    {

        name: 'loanApplicationHome',
        path: '',
        component: () =>
            import ('./LoanApplications.vue'),
        meta: {
            auth: true,
            title: 'Loan Applications',
            breadcrumb: 'Loan Applications',
            permission: ['view loanapplication']
        },

    },

    {
        path: 'create',
        name: 'issueLoan',
        component: () =>
            import ('./CreateEditLoan.vue'),
        meta: {
            auth: true,
            title: 'Create Loan Facility',
            breadcrumb: 'Loan Application',
            permission:['create loanapplication'],
            section: 'loanapplication'
        }
    },

     {
        path: 'show/:id',
        name: 'showLoanApplication',
        component: () =>
            import ('../Loan/ShowLoan.vue'),
        meta: {
            auth: true,
            title: 'Showing Loan Application',
            breadcrumb: 'Loan Facilty',
            permission: ['view loanapplication'],
            section: 'loanapplication'
        }
    },

     {
        path: ':id/edit',
        name: 'editLoan',
        component: () =>
            import ('./CreateEditLoan.vue'),
        meta: {
            auth: true,
            title: 'Edit Loan Facility',
            breadcrumb: 'Loan Application',
            permission: [],
            section: 'loanapplication'
        }
    },




];

export default loanapplicationRoutes;
