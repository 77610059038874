import axios from "axios";


export default {

    namespaced: true,

    state: {
        telleraccount: {},
        telleraccounts: [],
        tellerrequests: [],
        usertill: {},
        transactions:[],
    },

    getters: {

        telleraccount(state) {
            return state.telleraccount;
        },
        telleraccounts(state) {
            return state.telleraccounts;
        },
        tellerrequests(state) {
            return state.tellerrequests;
        },

        usertill(state) {
            return state.usertill;
        },

        transactions(state) {
            return state.transactions;
        },

    },

    actions: {
        fetchtelleraccounts({ commit }, params) {
            axios.get('/api/v1/telleraccount', { params: params })
                .then(res => {
                    commit('FETCH_telleraccount_LIST', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        fetchtellerrequests({ commit }, params) {
            axios.get('/api/v1/telleraccount/request', { params: params })
                .then(res => {
                    commit('FETCH_TELLER_REQUEST', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async fetchtelleraccount({ commit }, id) {
            await axios.get('/api/v1/telleraccount/' + id,)
                .then(res => {
                    commit('FETCH_telleraccount', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async createtelleraccount({ commit, dispatch }, payload) {
            await axios.post('/api/v1/telleraccount', payload);

        },

        async createtellerrequest({ commit, dispatch }, payload) {
            await axios.post('/api/v1/telleraccount/request', payload);
        },

        async createtellerdispatch({ commit, dispatch }, payload) {
            await axios.post('/api/v1/telleraccount/dispatch', payload);
        },

        async fetchusertill({ commit }) {
            await axios.get('/api/v1/telleraccount/usertill')
                .then(res => {
                    commit('FETCH_USER_TILL', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        fetchtransactions({ commit }, params) {
            axios.get('/api/v1/telleraccount/transactions',{params: params})
                .then(res => {
                    commit('FETCH_TRANSACTIONS', res.data.data);
                }).catch(err => {
                    return Promise.reject(err);
                })
        },

        async changetelleraccountstatus({ commit, dispatch }, payload) {
            await axios.post('/api/v1/telleraccount/changetellerstatus', payload);
        },

        async postvariance({ commit, dispatch }, payload) {
            await axios.post('/api/v1/telleraccount/postvariance', payload);
        },


    },

    mutations: {
        FETCH_telleraccount(state, data) {
            state.telleraccount = data;
        },
        FETCH_telleraccount_LIST(state, data) {
            state.telleraccounts = data;
        },

        FETCH_TELLER_REQUEST(state, data) {
            state.tellerrequests = data;
        },

        FETCH_USER_TILL(state, data) {
            state.usertill = data;
        },

        FETCH_TRANSACTIONS(state, data) {
            state.transactions = data;
        },

    }

}