import axios from "axios";

export default {

    namespaced: true,

    state: {

        groupcategories: [],
        groupcategory: {}

    },

    getters: {

        groupcategories(state) {
            return state.groupcategories;
        },
        groupcategory(state) {
            return state.groupcategory;
        }

    },

    mutations: {

        SET_GROUP_CATEGORIES(state, groupcategories) {
            return state.groupcategories = groupcategories
        },

        SET_GROUP_CATEGORY(state, groupcategory) {
            return state.groupcategory = groupcategory
        }

    },

    actions: {

        async fetchGroupCategories({ commit }, params) {

            await axios.get('/api/v1/groupcategories', { params: params })
                .then(res => {
                    commit('SET_GROUP_CATEGORIES', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchGroupCategory({ commit }, group_category_id) {

            await axios.get(`/api/v1/groupcategories/${group_category_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        commit('SET_GROUP_CATEGORY', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createGroupCategory({ commit, dispatch }, groupcategory) {

            await axios.post('/api/v1/groupcategories', groupcategory)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchGroupCategories');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateGroupCategory({ commit, dispatch }, groupcategory) {

            await axios("/api/v1/groupcategories/" + groupcategory.group_category_id, {
                method: "patch",
                data: groupcategory,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchGroupCategories');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteGroupCategory({ commit }, group_category_id) {

            await axios.delete(`/api/v1/groupcategories/${group_category_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchGroupCategories');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
