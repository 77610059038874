import axios from "axios";

export default {

    namespaced: true,

    state: {

        failedstandingorders: [],
        failedstandingorder: {}

    },

    getters: {

        failedstandingorders(state) {
            return state.failedstandingorders;
        },
        failedstandingorder(state) {
            return state.failedstandingorder;
        }

    },

    mutations: {

        SET_FAILED_STANDING_ORDERS(state, failedstandingorders) {
            return state.failedstandingorders = failedstandingorders
        },

        SET_FAILED_STANDING_ORDER(state, failedstandingorder) {
            return state.failedstandingorder = failedstandingorder
        }

    },

    actions: {

        async fetchfailedstandingorders({ commit }, params) {

            await axios.get('/api/v1/failed-standing-orders', { params: params })
                .then(res => {
                    commit('SET_FAILED_STANDING_ORDERS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchfailedstandingorder({ commit }, failed_standing_order_id) {

            await axios.get(`/api/v1/failed-standing-orders/${failed_standing_order_id}`)
                .then(res => {
                    // console.log("fetchProduct store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_FAILED_STANDING_ORDER', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createfailedstandingorder({ commit, dispatch }, failedstandingorder) {

            // console.log("product createProduct ", product);
            await axios.post('/api/v1/failed-standing-orders', failedstandingorder)
                .then(res => {
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updatefailedstandingorder({ commit, dispatch }, failedstandingorder) {

            await axios("/api/v1/failed_standing_orders/" + failedstandingorder.failed_standing_order_id, {
                method: "patch",
                data: failedstandingorder,
            }).then(res => {
             
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deletefailedstandingorder({ commit }, failed_standing_order_id) {

            await axios.delete(`/api/v1/failed-standing-orders/${failed_standing_order_id}`)
            .then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchfailedstandingorders');
                }
            }).catch(err => {
                return Promise.reject(err);
            });

        }

    }

}
