import bankingFinanceRoutes from './Banking/bankingFinanceRoutes';
import FinancingManagementRoutes from './FinancingManagement/FinancingManagementRoutes';
import standingOrderRoutes from './StandingOrder/standingOrderRoutes';
import AccountManagementRoutes from './AccountManagement/AccountManagementRoute';
import PaymentManagementRoutes from './PaymentManagement/paymentManagementRoutes';
import SharesManagementRoutes from './SharesManagement/sharesManagementRoutes';

import Base from '../../Common/Base.vue';
import pendingTreasuryRoutes from './PendingTreasury/pendingTreasuryRoutes';

const treasuryModuleRoutes = [

    {

        path: '',
        name: 'treasuryHome',
        component: () => import('./HomeTreasury.vue'),
        meta: {
            auth: true,
            title: 'Treasury',
            breadcrumb: 'Treasury',
            permission: ['view treasury']
        },

    },

    {

        path: 'banking',
        name: 'bankingHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Banking',
            breadcrumb: 'Banking',
            permission: ['view cashier', 'create cashier', 'update cashier', 'delete cashier', 'reconcile cashier',
                'view cashierdeposit', 'create cashierdeposit', 'update cashierdeposit', 'delete cashierdeposit',
                'reconcile cashierdeposit', 'view cashiersummary', 'create cashiersummary', 'update cashiersummary',
                'delete cashiersummary', 'view telleraccount', 'view vaultaccount', 'view tellerrequest', 'view vaultrequest',
                'view mytill', 'view branchvault', 'view mytransactions', 'view statement']
        },

        children: bankingFinanceRoutes

    },

    {

        path: 'financing-management',
        name: 'financingMagementHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Finance Management',
            breadcrumb: 'Finance Management',
            permission: ['view loans', 'loan repayment', 'loan adjustment', 'loan rebate', 'loan waiver', 'loan restructure', 'loan write-off']
        },

        children: FinancingManagementRoutes

    },

    {

        path: 'account-management',
        name: 'accountManagementHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Account Management',
            breadcrumb: 'Account Management',
            permission: ['view cashierdeposit', 'create cashierdeposit', 'update cashierdeposit', 'delete cashierdeposit',
                'reconcile cashierdeposit', 'view cashiersummary', 'create cashiersummary', 'update cashiersummary',
                'delete cashiersummary', 'cash deposit', 'cash withdrawal', 'cheque deposit', 'cheque withdrawal'
                , 'fund transfer', 'account adjustment', 'mpesa withdrawal', 'transactions']
        },

        children: AccountManagementRoutes

    },

    {

        path: 'standingorder',
        name: 'standingOrderHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Standing Order',
            breadcrumb: 'Standing Order',
            permission: ['view standingorder', 'create standingorder', 'update standingorder', 'delete standingorder']
        },

        children: standingOrderRoutes

    },

    {

        path: 'payment-management',
        name: 'paymentManagementHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Payment Management',
            breadcrumb: 'Account Management',
            permission: ['view mpesa-payment', 'view mpesa-reconciliation']
        },

        children: PaymentManagementRoutes

    },

    {

        path: 'shares-management',
        name: 'sharesManagementHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Shares Management',
            breadcrumb: 'Shares Management',
            permission: []
        },

        children: SharesManagementRoutes

    },

    {

        path: 'pending-items',
        name: 'pendingTreasuryHomes',
        component: Base,
        meta: {
            auth: true,
            title: 'Pending Items',
            breadcrumb: 'Pending Items',
            permission: ['view makerchecker', 'update makerchecker']
        },

        children: pendingTreasuryRoutes

    },


];

export default treasuryModuleRoutes;
