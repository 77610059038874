import axios from "axios";

export default {

    namespaced: true,

    state: {

        statuses: [],
        status: {}

    },

    getters: {

        statuses(state) {
            return state.statuses;
        },
        status(state) {
            return state.status;
        }

    },

    mutations: {

        SET_STATUSES(state, statuses) {
            return state.statuses = statuses
        },

        SET_STATUS(state, status) {
            return state.status = status
        }

    },

    actions: {

        async fetchStatuses({ commit }, params) {

            await axios.get('/api/v1/statuses', { params: params })
                .then(res => {
                    // console.log(res.data);
                    commit('SET_STATUSES', res.data);
                    return Promise.resolve(res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchStatus({ commit }, status_id) {

            await axios.get(`/api/v1/statuses/${status_id}`)
                .then(res => {
                    // console.log("fetchStatus store == ", res);
                    if (res.statusText === 'OK') {
                        commit('SET_STATUS', res.data.data);
                        return Promise.resolve(res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        }

    }

}
