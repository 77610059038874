import axios from "axios";

export default {

    namespaced: true,

    state: {

        customercalls: [],
        customercall: {},
        customercallshistory: {},
        callreports:[],
        customeractivationreports:[]

    },

    getters: {

        customercalls(state) {
            return state.customercalls;
        },
        customercall(state) {
            return state.customercall;
        },
        customercallshistory(state) {
            return state.customercallshistory;
        },
        callreports(state){
            return state.callreports;
        },
        customeractivationreports(state){
            return state.customeractivationreports;
        }
    },

    mutations: {

        SET_CUSTOMER_CALLS(state, customercalls) {
            return state.customercalls = customercalls
        },

        SET_CUSTOMER_CALL_REPORT(state, callreports) {
            return state.callreports = callreports
        },

        SET_CUSTOMER_ACTIVATION_REPORT(state, customeractivationreports) {
            return state.customeractivationreports = customeractivationreports
        },

        SET_CUSTOMER_CALL(state, customercall) {
            return state.customercall = customercall
        },

        SET_CUSTOMER_CALLS_HISTORY(state, customercallshistory) {
            return state.customercallshistory = customercallshistory
        }

    },

    actions: {

        async fetchCustomerCalls({ commit }, params) {

            await axios.get('/api/v1/customer-calls', { params: params })
                .then(res => {
                    commit('SET_CUSTOMER_CALLS', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchcustomeractivationreports({ commit }, params) {
            commit('SET_CUSTOMER_ACTIVATION_REPORT', []);
            await axios.get('/api/v1/customer-calls/customeractivationreport', { params: params })
                .then(res => {
                    commit('SET_CUSTOMER_ACTIVATION_REPORT', res?.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchcallreports({ commit }, params) {
            commit('SET_CUSTOMER_CALL_REPORT', []);
            await axios.get('/api/v1/customer-calls/callreport', { params: params })
                .then(res => {
                    commit('SET_CUSTOMER_CALL_REPORT', res?.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchCustomerCallsHistory({ commit }, params) {

            await axios.get(`/api/v1/customer-calls/history/${params.customerCall_id}`, { params: params })
                .then(res => {
                    commit('SET_CUSTOMER_CALLS_HISTORY', res.data);
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async fetchCustomerCall({ commit }, group_request_id) {

            await axios.get(`/api/v1/customer-calls/${group_request_id}`)
                .then(res => {
                   
                    if (res.statusText === 'OK') {
                        commit('SET_CUSTOMER_CALL', res.data.data);
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async createCustomerCall({ commit, dispatch }, customercall) {

            await axios.post('/api/v1/customer-calls', customercall)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchCustomerCalls');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                })

        },

        async updateCustomerCall({ commit, dispatch }, customercall) {

            await axios("/api/v1/customer-calls/" + customercall.group_request_id, {
                method: "patch",
                data: customercall,
            }).then(res => {
                if (res.statusText === 'OK') {
                    dispatch('fetchCustomerCalls');
                }
            }).catch(err => {
                return Promise.reject(err);
            })

        },

        async deleteCustomerCall({ commit }, dispatch, group_request_id) {

            await axios.delete(`/api/v1/customer-calls/${group_request_id}`)
                .then(res => {
                    if (res.statusText === 'OK') {
                        dispatch('fetchCustomerCalls');
                    }
                }).catch(err => {
                    return Promise.reject(err);
                });

        }

    }

}
