<template>
  <div
    class="col-lg-12"
    v-if="
      makerChecker?.status_id !== statusEnum.REJECTED &&
      makerChecker?.status_id !== statusEnum.APPROVED
    "
  >
    <hr />
    <div class="form-group">
      <button class="btn btn-success mr-4" type="button" @click="showApproveDialog">
        Approve
      </button>
      <button class="btn btn-danger" type="button" @click="showRejectDialog">
        Reject
      </button>
    </div>

    <!-- approve maker checker modal -->
    <approve-maker-checker-modal
      :maker-checker-data="makerChecker"
      :toggle-popup="toggleApprovePopup"
      @togglePopup="toggleApprovePopup"
      @onSubmitSuccess="onApproveSubmitSuccess"
      v-if="showApproveModal"
    >
    </approve-maker-checker-modal>

    <!-- reject maker checker modal -->
    <reject-maker-checker-modal
      :maker-checker-data="makerChecker"
      :toggle-popup="toggleRejectPopup"
      @togglePopup="toggleRejectPopup"
      @onSubmitSuccess="onRejectSubmitSuccess"
      v-if="showRejectModal"
    >
    </reject-maker-checker-modal>
  </div>

  <div class="card" :class="borderStatusClass" v-else>
    <div class="card-header" :class="bgStatusClass">
      <h3 class="card-title text-center" :class="textStatusClass">
        {{ getStatusText(makerChecker?.status_id) }}
      </h3>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-bordered mb-0 table-centered">
          <thead>
            <tr>
              <th width="30%">
                {{
                  makerChecker?.status_id === statusEnum.REJECTED
                    ? "Rejected At"
                    : "Approved At"
                }}:
              </th>
              <th width="30%">
                {{
                  makerChecker?.status_id === statusEnum.REJECTED
                    ? "Rejected By"
                    : "Approved By"
                }}:
              </th>
              <th width="40%">Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{
                  makerChecker?.status_id === statusEnum.REJECTED
                    ? formatDate(makerChecker?.rejected_at)
                    : formatDate(makerChecker?.approved_at)
                }}
              </td>
              <td>
                {{
                  makerChecker?.status_id === statusEnum.REJECTED
                    ? makerChecker?.rejector?.full_name
                    : makerChecker?.approver?.full_name
                }}
              </td>
              <td>
                {{ makerChecker?.reason }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  width: 100%;
  margin-bottom: 0;
}
.card-body {
  padding: 1rem;
}
.border-danger {
  border-color: #f5325c;
}
.border-success {
  border-color: #03d87f;
}
</style>

<script setup>
import { computed, toRefs, ref, defineProps, getCurrentInstance } from "vue";

import statusEnum from "@/enums/statusEnum.js";

import { formatDate } from "@/filters";

import { useRouter } from "vue-router";

const props = defineProps(["makerChecker"]);

const { makerChecker } = toRefs(props);

const showModal = ref(false);
const showApproveModal = ref(false);
const showRejectModal = ref(false);

const router = useRouter();

const showApproveDialog = async () => {
  showApproveModal.value = true;
};

const showRejectDialog = async (id) => {
  showRejectModal.value = true;
};

// toggle approve popup
function toggleApprovePopup() {
  showApproveModal.value = !showApproveModal.value;
}

// toggle reject popup
function toggleRejectPopup() {
  showRejectModal.value = !showRejectModal.value;
}

// on submit success
function onApproveSubmitSuccess() {
  showApproveModal.value = false;
  router.push({ name: "makerCheckerHome" });
}
function onRejectSubmitSuccess() {
  showRejectModal.value = false;
  router.push({ name: "makerCheckerHome" });
}

const getStatusText = (statusId) => {
  if (statusId === statusEnum.APPROVED) {
    return "APPROVED";
  } else if (statusId === statusEnum.REJECTED) {
    return "REJECTED";
  } else if (statusId === statusEnum.APPROVEDLEVEL) {
    return "APPROVEDLEVEL";
  } else {
    return "";
  }
};

const textStatusClass = computed(() => {
  return {
    "text-success": makerChecker.value?.status_id === statusEnum.APPROVED,
    "text-danger": makerChecker.value?.status_id === statusEnum.REJECTED,
    "text-warning": makerChecker.value?.status_id === statusEnum.APPROVEDLEVEL,
  };
});

const borderStatusClass = computed(() => {
  return {
    "border-success": makerChecker.value?.status_id === statusEnum.APPROVED,
    "border-danger": makerChecker.value?.status_id === statusEnum.REJECTED,
    "border-warning": makerChecker.value?.status_id === statusEnum.APPROVEDLEVEL,
  };
});

const bgStatusClass = computed(() => {
  return {
    "bg-soft-success": makerChecker.value?.status_id === statusEnum.APPROVED,
    "bg-soft-danger": makerChecker.value?.status_id === statusEnum.REJECTED,
    "bg-soft-warning": makerChecker.value?.status_id === statusEnum.APPROVEDLEVEL,
  };
});
</script>
