const collateralRoutes = [

    {

        path: '',
        name: 'collateralHome',
        component: () => import('./ListCollaterals.vue'),
        meta: {
            auth: true,
            title: 'Collateral Listing',
            breadcrumb: 'Collateral Listing',
            permission: ['view collateral'] //'view collateral'
        }

    },

    {
        path: 'create',
        name: 'createcollateral',
        component: () => import('./CreateEditCollateral.vue'),
        meta: {
            auth: true,
            title: 'Create Collateral',
            breadcrumb: 'Create Collateral',
            permission: ['create collateral']
        }
    },

    {
        path: ':id/edit',
        name: 'editcollateral',
        component: () => import('./CreateEditCollateral.vue'),
        meta: {
            auth: true,
            title: 'Edit Collateral',
            breadcrumb: 'Edit Collateral',
            permission: ['update collateral']
        }
    },
];


export default collateralRoutes;
